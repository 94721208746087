// src/components/5 - General/Context/WorkspaceContext.js

import React, { createContext, useState, useEffect } from "react";
import { useQueries, useQuery } from "@tanstack/react-query";
import { fetchCompaniesForWorkspace, fetchCompanyById, fetchUsersFromCompany } from "../../4 - API/API-Company";
import { fetchUserById } from "../../4 - API/API-Users";
import { useSocket } from "../Socket/SocketContext";

export const WorkspaceContext = createContext();

export const WorkspaceProvider = ({ children }) => {
  const [currentWorkspace, setCurrentWorkspace] = useState(null);
  const [currentUserCompany, setCurrentUserCompany] = useState(null);

  // Initialize storedWorkspaceId from localStorage
  const [storedWorkspaceId, setStoredWorkspaceId] = useState(() => localStorage.getItem("storedWorkspaceId") || null);

  const { permissions } = useSocket();
  const hasToken = localStorage.getItem("token") !== null;
  const hasUserId = localStorage.getItem("userId") !== null;
  const isInDashboard = window.location.pathname === "/dashboard/*";
  const isLoggedIn = hasToken && hasUserId && !isInDashboard;

  // Fetch workspace data
  const {
    data: workspaceData,
    isLoading: isLoadingWorkspaces,
    error: workspaceError,
  } = useQuery({
    queryKey: ["workspaces"],
    queryFn: fetchCompaniesForWorkspace,
    enabled: isLoggedIn,
  });

  // Destructure workspace data with default values
  const { workspaceIds = [], adminCompanyId = null, currentUserCompanyId = null, currentUserId = null } = workspaceData || {};

  // Fetch current user data
  const {
    data: currentUserData,
    isLoading: isLoadingUser,
    error: userError,
  } = useQuery({
    queryKey: ["user", currentUserId],
    queryFn: () => fetchUserById(currentUserId),
    enabled: Boolean(currentUserId) && isLoggedIn,
  });

  // Fetch current user company data
  const {
    data: currentCompanyData,
    isLoading: isLoadingCompany,
    error: companyError,
  } = useQuery({
    queryKey: ["company", currentUserCompanyId],
    queryFn: () => fetchCompanyById(currentUserCompanyId),
    enabled: Boolean(currentUserCompanyId) && isLoggedIn,
  });

  // Fetch multiple companies based on workspaceIds (Named workspaces only available to admins, array would be empty for regular users)
  const companyQueries = useQueries({
    queries: workspaceIds.map((id) => ({
      queryKey: ["company", id],
      queryFn: () => fetchCompanyById(id),
      enabled: Boolean(id) && isLoggedIn,
    })),
  });

  const isLoadingCompanies = companyQueries.some((query) => query.isLoading);
  const isCompaniesError = companyQueries.some((query) => query.isError);
  const companiesError = companyQueries.find((query) => query.isError)?.error;

  // Fetch admin company data
  const {
    data: adminCompanyData,
    isLoading: isLoadingAdminCompany,
    error: adminCompanyError,
  } = useQuery({
    queryKey: ["company", adminCompanyId],
    queryFn: () => fetchCompanyById(adminCompanyId),
    enabled: Boolean(adminCompanyId) && isLoggedIn,
  });

  // Fetch company user ids for settings page
  const {
    data: companyUsersResponse,
    isLoading: isLoadingCompanyUserIds,
    error: companyUserIdsError,
  } = useQuery({
    queryKey: ["company-users", currentUserCompanyId],
    queryFn: () => fetchUsersFromCompany(currentUserCompanyId),
    enabled: Boolean(currentUserCompanyId) && isLoggedIn,
  });

  const companyUserIds = companyUsersResponse?.success ? companyUsersResponse.userIds : [];
  const userQueries = useQueries({
    queries: companyUserIds.map((id) => ({
      queryKey: ["user", id],
      queryFn: () => fetchUserById(id),
      enabled: Boolean(id) && isLoggedIn,
    })),
  });

  const isLoadingUsers = userQueries.some((query) => query.isLoading);
  const isUsersError = userQueries.some((query) => query.isError);
  const usersError = userQueries.find((query) => query.isError)?.error;

  useEffect(() => {
    if (currentCompanyData) {
      setCurrentWorkspace(currentCompanyData);
      setCurrentUserCompany(currentCompanyData);
    }
  }, [currentCompanyData]);

  // Permission handlers

  // Check if user can edit a module, means he can view the module contents, use the module for the intended customer use and edit settings for that module
  const canEditModule = (moduleName) => {
    return permissions[moduleName]?.canEdit || false;
  };

  // Check if user can use a module, means he can view the module contents and use the module for the intended customer use
  const canUseModule = (moduleName) => {
    return permissions[moduleName]?.canUse || canEditModule(moduleName);
  };

  // Check if user can access a module, means he can view the module contents
  const canAccessModule = (moduleName) => {
    return permissions[moduleName]?.canView || canUseModule(moduleName);
  };

  const teamMembers = userQueries.map((q) => q.data).filter(Boolean);
  const workspaces = companyQueries.map((q) => q.data).filter(Boolean);

  const isLoading =
    isLoadingWorkspaces ||
    isLoadingUser ||
    isLoadingCompany ||
    isLoadingAdminCompany ||
    isLoadingCompanies ||
    isLoadingCompanyUserIds ||
    isLoadingUsers;
  const isError = isCompaniesError || userError || companyError || adminCompanyError || workspaceError || companyUserIdsError || isUsersError;
  const error = workspaceError || userError || companyError || adminCompanyError || companiesError || companyUserIdsError || usersError;

  return (
    <WorkspaceContext.Provider
      value={{
        setCurrentWorkspace,
        isLoading,
        isError,
        error,
        workspaces,
        teamMembers,
        currentWorkspace,
        currentUserCompany,
        themeColor: currentUserCompany?.primaryColor || "primary",
        currentUserCompanyId,
        adminCompany: adminCompanyData,
        adminCompanyId,
        currentUser: currentUserData,
        storedWorkspaceId,
        setStoredWorkspaceId,
        currentUserId,
        permissions,
        canAccessModule,
        canUseModule,
        canEditModule,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
};
