// client/src/components/3 - Modal/3 - Projects Modals/CreateProjectModal.jsx

import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Spinner,
  IconButton,
  HStack,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  useColorModeValue, // 1) Import from Chakra
} from "@chakra-ui/react";
import { Select, chakraComponents } from "chakra-react-select";
import { PiArrowRightBold, PiXBold } from "react-icons/pi";
import ReusableModal from "../9 - General Modals/ReusableModal";
import { ProjectContext } from "../../5 - General/Context/ProjectsContext";
import { WorkspaceContext } from "../../5 - General/Context/WorkspaceContext";
import UtilsDatePicker from "../../5 - General/Utils/UtilsDatePicker";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";
import TagMenu from "../../2 - Components/Reusable/TagMenu";
import UserAvatar from "../../2 - Components/Reusable/UserAvatar";
import { useQuery } from "@tanstack/react-query";
import { getAvailableUserAndCompanyOptions } from "../../4 - API/API-Projects";
import isEqual from "lodash/isEqual";

const CreateProjectModal = ({ isOpen, onClose }) => {
  // -----------------------------
  // 1) State / Context
  // -----------------------------
  const { currentUser } = useContext(WorkspaceContext);
  const { createProject } = useContext(ProjectContext);
  const customToast = useCustomToast();

  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState("Not Started");
  const [priority, setPriority] = useState("Medium");

  const [companyOptions, setCompanyOptions] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [memberOptions, setMemberOptions] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectAllUsers, setSelectAllUsers] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Query
  const { data: availableUserAndCompanyOptions = [], isLoading: isAvailableUserAndCompanyOptionsLoading } = useQuery({
    queryKey: ["availableUsers", currentUser?._id],
    queryFn: () => getAvailableUserAndCompanyOptions(currentUser._id),
    enabled: isOpen && Boolean(currentUser),
    staleTime: 5 * 60 * 1000,
  });

  // -----------------------------
  // 2) Color definitions
  // -----------------------------
  const arrowColor = useColorModeValue("gray.500", "gray.300");
  const itemHoverBg = useColorModeValue("gray.100", "gray.600");
  const multiValueBg = useColorModeValue("gray.50", "gray.700");

  // For the user text/label color if needed:
  // const userLabelColor = useColorModeValue("gray.700", "gray.200");

  // -----------------------------
  // 3) Effects
  // -----------------------------
  // Build company options
  useEffect(() => {
    if (isOpen && availableUserAndCompanyOptions.length > 0) {
      const uniqueCompanies = {};
      availableUserAndCompanyOptions.forEach((user) => {
        if (user.companyId && !uniqueCompanies[user.companyId]) {
          uniqueCompanies[user.companyId] = {
            value: user.companyId,
            label: user.companyName,
          };
        }
      });
      const newCompanyOptions = Object.values(uniqueCompanies);
      if (!isEqual(companyOptions, newCompanyOptions)) {
        setCompanyOptions(newCompanyOptions);
      }
    } else if (companyOptions.length !== 0) {
      setCompanyOptions([]);
    }
  }, [isOpen, availableUserAndCompanyOptions, companyOptions]);

  // Auto-select current user's company
  useEffect(() => {
    if (isOpen && currentUser && companyOptions.length > 0 && selectedCompanies.length === 0) {
      const currentUserCompanyOption = companyOptions.find((company) => company.value === currentUser.companyId);
      if (currentUserCompanyOption) {
        setSelectedCompanies([currentUserCompanyOption]);
      }
    }
  }, [isOpen, currentUser, companyOptions, selectedCompanies]);

  // Update member options
  useEffect(() => {
    if (isOpen && selectedCompanies.length > 0) {
      const selectedCompanyIds = selectedCompanies.map((company) => company.value);
      const filteredUsers = availableUserAndCompanyOptions.filter((user) => selectedCompanyIds.includes(user.companyId));
      const formattedUsers = filteredUsers.map((user) => ({
        value: user.value,
        label: user.value,
        user,
      }));

      if (!isEqual(memberOptions, formattedUsers)) {
        setMemberOptions(formattedUsers);
      }

      if (!selectAllUsers && selectedMembers.length === 0) {
        const currentUserOption = formattedUsers.find((u) => u.value === currentUser?._id);
        const newSelectedMembers = currentUserOption ? [currentUserOption] : [];
        if (!isEqual(selectedMembers, newSelectedMembers)) {
          setSelectedMembers(newSelectedMembers);
        }
      }
    } else {
      if (isOpen) {
        if (!isEqual(memberOptions, [])) {
          setMemberOptions([]);
        }
        if (!isEqual(selectedMembers, [])) {
          setSelectedMembers([]);
        }
      }
    }
  }, [selectedCompanies, availableUserAndCompanyOptions, currentUser, selectAllUsers, memberOptions, selectedMembers, isOpen]);

  // Handle "Select All Users"
  useEffect(() => {
    if (isOpen) {
      if (selectAllUsers) {
        if (!isEqual(selectedMembers, memberOptions)) {
          setSelectedMembers(memberOptions);
        }
      } else {
        if (selectedMembers.length === 0) {
          const currentUserOption = memberOptions.find((user) => user.value === currentUser?._id);
          const newSelectedMembers = currentUserOption ? [currentUserOption] : [];
          if (!isEqual(selectedMembers, newSelectedMembers)) {
            setSelectedMembers(newSelectedMembers);
          }
        }
      }
    }
  }, [selectAllUsers, memberOptions, currentUser, selectedMembers, isOpen]);

  // -----------------------------
  // 4) Derived values
  // -----------------------------
  const isSubmitDisabled =
    !name.trim() ||
    selectedCompanies.length === 0 ||
    (!selectAllUsers && selectedMembers.length === 0) ||
    !startDate ||
    !endDate ||
    isAvailableUserAndCompanyOptionsLoading;

  // -----------------------------
  // 5) Handlers
  // -----------------------------
  const handleSubmit = async () => {
    if (isSubmitDisabled) return;

    setIsSubmitting(true);
    const projectData = {
      name,
      startDate,
      endDate,
      status,
      priority,
      members: selectAllUsers ? memberOptions.map((user) => user.value) : selectedMembers.map((user) => user.value),
      assignedToCompanies: selectedCompanies.map((company) => company.value),
    };

    try {
      await createProject(projectData);
      onClose();
      resetForm();
    } catch (error) {
      console.error("Error creating project:", error);
      customToast({
        title: "Error Creating Project",
        description: error.message || "An error occurred.",
        status: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetForm = () => {
    setName("");
    setStartDate(null);
    setEndDate(null);
    setSelectedMembers([]);
    setSelectedCompanies([]);
    setStatus("Not Started");
    setPriority("Medium");
    setSelectAllUsers(false);
  };

  const statusOptions = [
    { value: "Not Started", label: "Not Started", color: "gray" },
    { value: "In Progress", label: "In Progress", color: "blue" },
    { value: "Completed", label: "Completed", color: "green" },
    { value: "On Hold", label: "On Hold", color: "orange" },
  ];

  const priorityOptions = [
    { value: "High", label: "High", color: "red" },
    { value: "Medium", label: "Medium", color: "yellow" },
    { value: "Low", label: "Low", color: "green" },
  ];

  // Custom user components
  const CustomUserOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <Box ref={innerRef} {...innerProps} _hover={{ bg: itemHoverBg }}>
        <HStack spacing={3} px={6} py={1}>
          <UserAvatar userIds={[data.value]} maxAvatars={1} size="sm" showInfo={true} />
        </HStack>
      </Box>
    );
  };

  const CustomUserMultiValue = (props) => {
    const { data, innerRef, innerProps, removeProps } = props;
    return (
      <Box
        ref={innerRef}
        {...innerProps}
        mb={1}
        bg={multiValueBg}
        borderRadius="xl"
        w="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px={4}
        py={1}
      >
        <HStack spacing={1}>
          <UserAvatar userIds={[data.value]} maxAvatars={1} size="sm" showInfo={true} />
        </HStack>
        <IconButton
          icon={<PiXBold fontSize="16px" />}
          colorScheme="gray"
          variant="ghost"
          size="sm"
          onClick={removeProps.onClick}
          aria-label="Remove"
        />
      </Box>
    );
  };

  const footerButtons = (
    <HStack ml="auto" spacing={3}>
      <Button variant="outline" onClick={onClose}>
        Cancel
      </Button>
      <Button colorScheme="primary" onClick={handleSubmit} isDisabled={isSubmitDisabled || isSubmitting} isLoading={isSubmitting}>
        Create Project
      </Button>
    </HStack>
  );

  // -----------------------------
  // 6) JSX
  // -----------------------------
  return (
    <ReusableModal
      isOpen={isOpen}
      onClose={onClose}
      title="Create a New Project"
      footerButtons={footerButtons}
      size="lg"
      isCentered
      scrollBehavior="inside"
    >
      <FormControl isRequired p={4} pt={2}>
        <FormLabel fontSize="md" fontWeight="500">
          Project Name
        </FormLabel>
        <Input value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter project name..." />
      </FormControl>

      <FormControl isRequired p={4}>
        <FormLabel fontSize="md" fontWeight="500">
          Project Duration
        </FormLabel>
        <HStack spacing={2}>
          <UtilsDatePicker
            hasPortal={true}
            endDate={endDate || null}
            value={startDate}
            placeholder="Start Date"
            onChange={setStartDate}
            inputVariant="default"
          />
          <PiArrowRightBold color={arrowColor} />
          <UtilsDatePicker
            hasPortal={true}
            value={endDate}
            startDate={startDate || null}
            placeholder="End Date"
            onChange={setEndDate}
            inputVariant="default"
          />
        </HStack>
      </FormControl>

      <FormControl isRequired p={4}>
        <FormLabel fontSize="md" fontWeight="500">
          Initial Project Status and Priority
        </FormLabel>
        <HStack spacing={4}>
          <TagMenu options={statusOptions} selectedValue={status} field="status" handleBlurSave={(id, field, value) => setStatus(value)} />
          <TagMenu options={priorityOptions} selectedValue={priority} field="priority" handleBlurSave={(id, field, value) => setPriority(value)} />
        </HStack>
      </FormControl>

      {/* Companies */}
      <FormControl isRequired p={4}>
        <FormLabel fontSize="md" fontWeight="500">
          Select Companies
        </FormLabel>
        <Select
          isMulti
          placeholder="Select companies..."
          options={companyOptions}
          value={selectedCompanies}
          onChange={(companies) => setSelectedCompanies(companies)}
          components={chakraComponents}
          closeMenuOnSelect={false}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            menu: (base) => ({ ...base, zIndex: 9999 }),
          }}
        />
      </FormControl>

      {/* Members */}
      <FormControl isRequired p={4}>
        {!selectAllUsers && (
          <>
            <FormLabel fontSize="md" fontWeight="500">
              Select Members
            </FormLabel>
            <Select
              isMulti
              placeholder="Select members..."
              options={memberOptions}
              value={selectedMembers}
              onChange={setSelectedMembers}
              components={{
                Option: CustomUserOption,
                MultiValue: CustomUserMultiValue,
              }}
              closeMenuOnSelect={false}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                menu: (base) => ({ ...base, zIndex: 9999 }),
              }}
              isDisabled={memberOptions.length === 0}
            />
          </>
        )}
        <Checkbox isChecked={selectAllUsers} onChange={(e) => setSelectAllUsers(e.target.checked)} mt={2}>
          Select all users from selected companies
        </Checkbox>
      </FormControl>

      {isAvailableUserAndCompanyOptionsLoading && (
        <Box display="flex" justifyContent="center" alignItems="center" py={4}>
          <Spinner />
        </Box>
      )}
    </ReusableModal>
  );
};

export default CreateProjectModal;
