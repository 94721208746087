// client/src/components/5 - General/Context/ProjectProvider.jsx

import React, { createContext, useState, useContext } from "react";
import {
  useCreateProject,
  useEditProject,
  useDeleteProject,
  useToggleArchiveProject,
  useCreateTask,
  useEditTask,
  useDeleteTask,
  useAssignMembers,
  useAddComment,
  useEditComment,
  useDeleteComment,
  useUpdateTaskOrder,
  useLikeComment,
  useEditTimeLog,
  useLogTime,
  useDeleteTimeLog,
  useAssignMembersToTask,
} from "../Hooks/useUpdateProject";
import {
  getProjectIds,
  getProjectById,
  getTaskById,
  downloadBlob,
  getTaskFileDownloadLink,
  uploadFilesToTask as apiUploadFilesToTask,
  deleteTaskFile as apiDeleteTaskFile,
} from "../../4 - API/API-Projects";
import { WorkspaceContext } from "./WorkspaceContext";
import { useQueries, useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../../queryKeys";
import useCustomToast from "../Utils/UtilsNotification";

export const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {
  const { currentWorkspace } = useContext(WorkspaceContext);
  const customToast = useCustomToast();

  // Socket.io-based mutations
  const createProjectMutation = useCreateProject();
  const editProjectMutation = useEditProject();
  const deleteProjectMutation = useDeleteProject();
  const toggleArchiveProjectMutation = useToggleArchiveProject();
  const createTaskMutation = useCreateTask();
  const editTaskMutation = useEditTask();
  const deleteTaskMutation = useDeleteTask();
  const assignMembersMutation = useAssignMembers();
  const addCommentMutation = useAddComment();
  const editCommentMutation = useEditComment();
  const deleteCommentMutation = useDeleteComment();
  const likeCommentMutation = useLikeComment();
  const updateTaskOrderMutation = useUpdateTaskOrder();
  const addTimeLogMutation = useLogTime();
  const editTimeLogMutation = useEditTimeLog();
  const assignMembersToTaskMutation = useAssignMembersToTask();
  const deleteTimeLogMutation = useDeleteTimeLog();

  // State variables
  const [currentProjectId, setCurrentProjectId] = useState(null);
  const [currentTaskId, setCurrentTaskId] = useState(null);
  const [currentSubtaskId, setCurrentSubtaskId] = useState(null);

  // Fetch active project IDs
  const {
    data: projectIds = [],
    isLoading: isProjectIdsLoading,
    error: projectIdsError,
  } = useQuery({
    queryKey: queryKeys.projectIds(currentWorkspace?._id),
    queryFn: () => getProjectIds(currentWorkspace._id),
    enabled: !!currentWorkspace,
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 30 * 60 * 1000, // 30 minutes
  });

  // Fetch multiple projects based on projectIds
  const projectQueries = useQueries({
    queries: projectIds.map((id) => ({
      queryKey: queryKeys.project(id),
      queryFn: () => getProjectById(currentWorkspace._id, id),
      enabled: !!id && !!currentWorkspace,
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
    })),
  });

  const isProjectsLoading = projectQueries.some((query) => query.isLoading);
  const projectsError = projectQueries.find((query) => query.isError)?.error;
  const projects = projectQueries.map((q) => q.data).filter(Boolean);

  // Fetch tasks associated with the current project
  const taskIds = currentProjectId
    ? (projects.find((p) => p._id === currentProjectId)?.tasks || []).map((task) => (typeof task === "object" ? task._id : task))
    : [];
  const taskQueries = useQueries({
    queries: taskIds.map((taskId) => ({
      queryKey: queryKeys.task(taskId, currentProjectId),
      queryFn: () => getTaskById(currentProjectId, taskId),
      enabled: !!taskId && !!currentProjectId,
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
    })),
  });

  const isTasksLoading = taskQueries.some((query) => query.isLoading);
  const tasksError = taskQueries.find((query) => query.isError)?.error;
  const tasks = taskQueries.map((q) => q.data).filter(Boolean);

  // Fetch subtasks associated with the current task or project
  // Corrected to properly extract subtask IDs
  const subTaskIds = currentTaskId ? (tasks.find((t) => t._id === currentTaskId)?.subtasks || []).map((subtask) => subtask) : [];

  const subtasksQueries = useQueries({
    queries: subTaskIds.map((id) => ({
      queryKey: queryKeys.subtask(id, currentProjectId),
      queryFn: () => getTaskById(currentProjectId, id), // Fetching subtask details
      enabled: !!id && !!currentProjectId,
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
    })),
  });

  const isSubtasksLoading = subtasksQueries.some((query) => query.isLoading);
  const subtasksError = subtasksQueries.find((query) => query.isError)?.error;
  const subtasks = subtasksQueries.map((q) => q.data).filter(Boolean);

  // Consolidate errors and loading states for unified error handling
  const hasError = projectIdsError || projectsError || tasksError || subtasksError;
  const isLoading = isProjectIdsLoading || isProjectsLoading || isTasksLoading || isSubtasksLoading;

  // Project Management Functions
  const createProject = async (projectData) => {
    try {
      await createProjectMutation.mutateAsync(projectData);
    } catch (error) {
      console.error("Error creating project in context:", error);
    }
  };

  const editProject = async (projectId, updates) => {
    try {
      await editProjectMutation.mutateAsync({ projectId, updates });
    } catch (error) {
      console.error("Error editing project in context:", error);
    }
  };

  const deleteProject = async (projectId) => {
    try {
      await deleteProjectMutation.mutateAsync({ projectId });
    } catch (error) {
      console.error("Error deleting project in context:", error);
    }
  };

  const toggleArchiveProject = async (projectId) => {
    try {
      await toggleArchiveProjectMutation.mutateAsync({ projectId });
    } catch (error) {
      console.error("Error toggling archive status in context:", error);
    }
  };

  // Task Management Functions
  const createTask = async (taskData) => {
    try {
      await createTaskMutation.mutateAsync(taskData);
    } catch (error) {
      console.error("Error creating task in context:", error);
    }
  };

  const editTask = async (taskId, updates) => {
    try {
      await editTaskMutation.mutateAsync({ taskId, updates });
    } catch (error) {
      console.error("Error editing task in context:", error);
    }
  };

  const deleteTask = async (taskId) => {
    try {
      await deleteTaskMutation.mutateAsync({ taskId });
    } catch (error) {
      console.error("Error deleting task in context:", error);
    }
  };

  // Member Management Function
  const assignMembersToProject = async ({ projectId, memberIds }) => {
    try {
      await assignMembersMutation.mutateAsync({ projectId, memberIds });
    } catch (error) {
      console.error("Error assigning members to project in context:", error);
    }
  };

  // New Member Management Function: Assign Users to Task
  const assignUsersToTask = async ({ taskId, userIds }) => {
    console.log("Assigning users to task:", taskId, userIds);
    try {
      await assignMembersToTaskMutation.mutateAsync({ projectId: currentProjectId, taskId: taskId, memberIds: userIds });
    } catch (error) {
      console.error("Error assigning users to task in context:", error);
    }
  };

  // Comments
  const addComment = async (taskId, commentText) => {
    try {
      await addCommentMutation.mutateAsync({ taskId, commentText });
    } catch (error) {
      console.error("Error adding comment in context:", error);
    }
  };

  const editComment = async (taskId, commentId, editedCommentText) => {
    try {
      await editCommentMutation.mutateAsync({ taskId, commentId, editedCommentText });
    } catch (error) {
      console.error("Error editing comment in context:", error);
    }
  };

  const deleteComment = async (taskId, commentId) => {
    try {
      await deleteCommentMutation.mutateAsync({ taskId, commentId });
    } catch (error) {
      console.error("Error deleting comment in context:", error);
    }
  };

  const likeComment = async (taskId, commentId) => {
    try {
      await likeCommentMutation.mutateAsync({ taskId, commentId });
    } catch (error) {
      console.error("Error liking comment in context:", error);
    }
  };

  const updateTaskOrder = async (tasks) => {
    try {
      await updateTaskOrderMutation.mutateAsync(tasks);
    } catch (error) {
      console.error("Error updating task order in context:", error);
    }
  };

  const logTime = async (taskId, timeData) => {
    const { timeSpent, description } = timeData;
    try {
      await addTimeLogMutation.mutateAsync({ taskId, timeSpent, description });
    } catch (error) {
      console.error("Error logging time in context:", error);
    }
  };

  const editTimeLog = async ({ taskId, logId, timeSpent, description }) => {
    try {
      await editTimeLogMutation.mutateAsync({ taskId, logId, timeSpent, description });
    } catch (error) {
      console.error("Error editing time log in context:", error);
    }
  };

  const deleteTimeLog = async (taskId, logId) => {
    try {
      await deleteTimeLogMutation.mutateAsync({ taskId, logId });
    } catch (error) {
      console.error("Error deleting time log in context:", error);
    }
  };

  // FILE UPLOAD
  const uploadFilesToTask = async (projectId, taskId, files) => {
    if (!projectId || !taskId) {
      console.error("Project ID and Task ID are required for file upload.");
      return;
    }
    try {
      await apiUploadFilesToTask(projectId, taskId, files);
      customToast({
        title: "File(s) Uploaded",
        description: "File(s) uploaded successfully.",
        status: "success",
      });
    } catch (error) {
      console.error("Error uploading file(s):", error);
      customToast({
        title: "Error",
        description: error.message || "Failed to upload file(s).",
        status: "error",
      });
    }
  };

  // FILE DELETE
  const deleteFileFromTask = async (projectId, taskId, fileId) => {
    if (!projectId || !taskId || !fileId) {
      console.error("Project ID, Task ID, and File ID are required for file deletion.");
      return;
    }
    try {
      await apiDeleteTaskFile(projectId, taskId, fileId);
      customToast({
        title: "File Deleted",
        description: "File deleted successfully.",
        status: "success",
      });
    } catch (error) {
      console.error("Error deleting file:", error);
      customToast({
        title: "Error",
        description: error.message || "Failed to delete file.",
        status: "error",
      });
    }
  };

  // FILE DOWNLOAD
  const downloadFileFromTask = async (projectId, taskId, backendName) => {
    try {
      const { downloadUrl, filename } = await getTaskFileDownloadLink(projectId, taskId, backendName);

      const response = await fetch(downloadUrl);
      if (!response.ok) {
        throw new Error("Failed to download file from S3.");
      }
      const blob = await response.blob();
      downloadBlob(blob, filename);
    } catch (error) {
      console.error("Error downloading file:", error);
      customToast({
        title: "Error",
        description: error.message || "Error downloading file.",
        status: "error",
      });
    }
  };

  const contextValue = {
    // States
    projects,
    tasks,
    subtasks,
    currentTaskId,
    setCurrentTaskId,
    currentProjectId,
    setCurrentProjectId,
    currentSubtaskId,
    setCurrentSubtaskId,

    // Loading and error states
    isLoading,
    hasError,
    projectIdsError,
    activeProjectsError: projectsError,
    tasksError,
    subtasksError,

    // Project Management
    createProject,
    editProject,
    deleteProject,
    toggleArchiveProject,

    // Task Management
    createTask,
    editTask,
    deleteTask,
    updateTaskOrder,

    // Comments
    addComment,
    editComment,
    deleteComment,
    likeComment,

    // Time Logs
    logTime,
    editTimeLog,
    deleteTimeLog,

    // File Management
    uploadFilesToTask,
    deleteFileFromTask,
    downloadFileFromTask,

    // Member Management
    assignMembersToProject,
    assignUsersToTask,
  };

  return <ProjectContext.Provider value={contextValue}>{children}</ProjectContext.Provider>;
};
