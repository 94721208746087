// client/src/components/Messages/Messages.jsx

import React, { useContext } from "react";
import { MessagesContext } from "../5 - General/Context/MessagesContext";
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  useColorModeValue,
  VStack,
  useBreakpointValue,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
} from "@chakra-ui/react";
import { PiPlus } from "react-icons/pi";
import { HiUser, HiUserGroup } from "react-icons/hi2";
import MessagesConversationsContainer from "./MessagesConversationsContainer";
import MessagesActiveChannel from "./MessagesActiveChannel";
import CreateMessageModal from "../3 - Modal/2 - Messages Modals/MessagesCreateMessageModal";
import DeleteChannelModal from "../3 - Modal/2 - Messages Modals/MessagesDeleteChannelModal";
import AddUserModal from "../3 - Modal/2 - Messages Modals/MessagesAddUserModal";
import ViewUsersInChannelModal from "../3 - Modal/2 - Messages Modals/MessagesViewUsersInChannelModal";
import MessagesImagePreviewModal from "../3 - Modal/2 - Messages Modals/MessagesImagePreviewModal";
import CreateOneOnOneModal from "../3 - Modal/2 - Messages Modals/MessagesCreateOneOnOneModal";

// Import WorkspaceContext to access themeColor and permissions
import { WorkspaceContext } from "../5 - General/Context/WorkspaceContext";

const Messages = () => {
  const {
    currentChannelId,
    channels,
    modals,
    setModals,
    handleCreateChannel,
    handleDeleteChannel,
    handleAddUserToChannel,
    handleRemoveUserFromChannel,
    selectedImage,
    isChannelIdsLoading,
    isLoadingChannels,
    isActiveDrawerOpen,
    setIsActiveDrawerOpen,
    activeDrawerBtnRef,
  } = useContext(MessagesContext);

  // Destructure themeColor and permission checks
  const { themeColor, canUseModule } = useContext(WorkspaceContext);

  const borderColor = useColorModeValue("gray.200", "gray.750");
  const topBarBgColor = useColorModeValue("gray.50", "gray.900");
  const textColor = useColorModeValue("black", "gray.200");

  // Determine if the device is mobile
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Flex direction="column" height="100%">
      {isLoadingChannels || isChannelIdsLoading ? (
        <Flex justify="center" align="center" height="100%">
          <VStack spacing={4}>
            <Text color={textColor}>Loading conversations...</Text>
            <Spinner size="xl" color={`${themeColor}.500`} /> {/* Dynamic color */}
          </VStack>
        </Flex>
      ) : channels.length === 0 ? (
        <Flex justify="center" width="100%">
          <Box
            p={8}
            border="1px solid"
            bg="gray.50"
            borderColor={borderColor}
            borderRadius="xl"
            textAlign="center"
            boxShadow="md"
            h="fit-content"
            width={["90%", "70%", "50%"]}
            mt="200px"
          >
            <Text fontSize="xl" fontWeight="bold">
              No conversations found.
            </Text>
            <Text fontSize="md" mt={2} color="gray.500">
              Create a new conversation to get started.
            </Text>
            {/* Only show "Create Conversation" if user canUseModule("messages") */}
            {canUseModule("messages") && (
              <Menu>
                <MenuButton mt={4} as={Button} leftIcon={<PiPlus fontSize="18px" />} fontWeight="normal" colorScheme={themeColor}>
                  Create Conversation
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={() => setModals((prev) => ({ ...prev, oneOnOne: true }))} icon={<HiUser fontSize="18px" />} fontSize="md">
                    Create a One on One Conversation
                  </MenuItem>
                  <MenuItem onClick={() => setModals((prev) => ({ ...prev, create: true }))} icon={<HiUserGroup fontSize="18px" />} fontSize="md">
                    Create a Group Conversation
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </Box>
        </Flex>
      ) : (
        <Flex direction={{ base: "column", md: "row" }} flex="1" overflow="hidden">
          {/* Conversation List */}
          <Box
            width={{ base: "100%", md: "40%" }}
            maxWidth="500px"
            overflow="hidden"
            borderRightWidth={{ base: "0", md: "1px" }}
            borderRightStyle={{ base: "none", md: "solid" }}
            borderColor={borderColor}
          >
            <MessagesConversationsContainer setIsActiveDrawerOpen={setIsActiveDrawerOpen} />
          </Box>

          {/* Active Channel for Desktop */}
          {!isMobile && (
            <Box flex="1" overflow="hidden">
              {currentChannelId ? (
                <MessagesActiveChannel />
              ) : (
                <Box
                  h="80px"
                  alignContent="center"
                  fontWeight="medium"
                  fontSize="lg"
                  bg={topBarBgColor}
                  px={8}
                  color={textColor}
                  flex="1"
                  borderBottom="1px solid"
                  borderColor={borderColor}
                >
                  Choose a conversation to start chatting
                </Box>
              )}
            </Box>
          )}
        </Flex>
      )}

      {/* Drawer for Active Channel on Mobile */}
      {isMobile && (
        <Drawer
          isOpen={isActiveDrawerOpen}
          placement="right"
          size="full"
          onClose={() => setIsActiveDrawerOpen(false)}
          finalFocusRef={activeDrawerBtnRef}
        >
          <DrawerOverlay />
          <DrawerContent p={0}>
            <DrawerBody p={0}>
              <MessagesActiveChannel onClose={() => setIsActiveDrawerOpen(false)} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}

      {/* Modals */}
      {modals.create && (
        <CreateMessageModal
          isOpen={modals.create}
          onClose={() => setModals((prevModals) => ({ ...prevModals, create: false }))}
          onCreate={(name, members) => handleCreateChannel(name, members, false)}
        />
      )}
      {modals.delete && (
        <DeleteChannelModal
          isOpen={modals.delete}
          onClose={() => setModals((prevModals) => ({ ...prevModals, delete: false }))}
          onDelete={handleDeleteChannel}
        />
      )}
      {modals.addUser && (
        <AddUserModal
          isOpen={modals.addUser}
          onClose={() => setModals((prevModals) => ({ ...prevModals, addUser: false }))}
          onAddUser={handleAddUserToChannel}
        />
      )}
      {modals.viewUsers && (
        <ViewUsersInChannelModal
          isOpen={modals.viewUsers}
          onClose={() => setModals((prevModals) => ({ ...prevModals, viewUsers: false }))}
          onRemoveUser={handleRemoveUserFromChannel}
        />
      )}
      {modals.oneOnOne && (
        <CreateOneOnOneModal
          isOpen={modals.oneOnOne}
          onClose={() => setModals((prevModals) => ({ ...modals, oneOnOne: false }))}
          onCreate={(name, members) => handleCreateChannel(name, members, true)}
        />
      )}
      {modals.imagePreview && selectedImage && (
        <MessagesImagePreviewModal
          isOpen={modals.imagePreview}
          onClose={() => setModals((prevModals) => ({ ...modals, imagePreview: false }))}
          selectedImage={selectedImage}
        />
      )}
    </Flex>
  );
};

export default Messages;
