import React from "react";
import { Menu, MenuButton, MenuList, MenuItem, Button, Tag, HStack, useColorMode } from "@chakra-ui/react";
import { PiCaretDownBold } from "react-icons/pi";
import PropTypes from "prop-types";

const TagMenu = ({ options, selectedValue = "", field, handleBlurSave, subtaskId = null, taskId = null }) => {
  // -----------------------------
  // 1) (No local state here)
  // -----------------------------

  // -----------------------------
  // 2) Color definitions
  // -----------------------------
  const { colorMode } = useColorMode();
  const isDark = colorMode === "dark";

  // For text on top of our custom backgrounds:
  // White text in light mode, darker text in dark mode.
  const tagTextColor = isDark ? "gray.800" : "white";

  /**
   * Returns a background color string that depends on the color mode.
   * Example: if "blue" is passed:
   * - in light mode => "blue.500"
   * - in dark mode  => "blue.300"
   */
  const getTagBg = (color = "gray") => {
    return isDark ? `${color}.400` : `${color}.500`;
  };

  // -----------------------------
  // 3) Derived data & handlers
  // -----------------------------
  if (!options) {
    console.error(`TagMenu for ${field} is missing 'options' prop.`);
    return null;
  }

  const selectedOption = options.find((option) => option.value === selectedValue);
  const fallbackScheme = "gray";

  // -----------------------------
  // 4) JSX
  // -----------------------------
  return (
    <Menu closeOnSelect matchWidth>
      <MenuButton
        as={Button}
        borderRadius="full"
        variant="ghost"
        p={0}
        h="fit-content"
        aria-label={`Select ${field}`}
        display="flex"
        alignItems="center"
        onClick={(e) => {
          e.stopPropagation();
        }}
        pr={2}
      >
        <HStack alignItems="center">
          <Tag
            size="md"
            borderRadius="full"
            variant="solid"
            // Use our helpers to control BG & text color
            bg={selectedOption ? getTagBg(selectedOption.color) : getTagBg(fallbackScheme)}
            color={tagTextColor}
            cursor="pointer"
          >
            {selectedValue || `Select ${field}`}
          </Tag>
          <PiCaretDownBold fontSize="18px" mr={1} />
        </HStack>
      </MenuButton>

      <MenuList>
        {options.map((option) => (
          <MenuItem
            key={option.value}
            onClick={(e) => {
              e.stopPropagation();
              handleBlurSave(subtaskId || taskId, field, option.value);
            }}
          >
            <Tag size="md" borderRadius="full" variant="solid" bg={getTagBg(option.color)} color={tagTextColor}>
              {option.label}
            </Tag>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

TagMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedValue: PropTypes.string,
  field: PropTypes.string.isRequired,
  handleBlurSave: PropTypes.func.isRequired,
  subtaskId: PropTypes.string,
  taskId: PropTypes.string,
};

export default TagMenu;
