// client/src/components/Files/Modals/FileImagePreviewModal.js

import React, { useContext, useEffect, useState } from "react";
import { Button, Image, useColorModeValue, Text, Skeleton, Box } from "@chakra-ui/react";
import { PiDownloadSimple } from "react-icons/pi";
import { downloadBlob, downloadFile } from "../../4 - API/API-Files";
import { WorkspaceContext } from "../../5 - General/Context/WorkspaceContext";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";
import ReusableModal from "../9 - General Modals/ReusableModal";

const FileImagePreviewModal = ({ isOpen, onClose, selectedImage }) => {
  const { themeColor } = useContext(WorkspaceContext); // Accessed themeColor
  const buttonColorScheme = useColorModeValue("gray", themeColor); // Uses dynamic themeColor

  const { currentWorkspace } = useContext(WorkspaceContext);
  const customToast = useCustomToast();

  const [previewUrl, setPreviewUrl] = useState(null);
  const [imageBlob, setImageBlob] = useState(null);

  const imageFilename = selectedImage?.originalFileName || "image";

  useEffect(() => {
    if (!isOpen || !selectedImage) {
      setPreviewUrl(null);
      setImageBlob(null);
      return;
    }

    let objectUrl;
    let isCancelled = false;

    const fetchImageForPreview = async () => {
      try {
        const { downloadUrl } = await downloadFile(selectedImage.backendFileName, currentWorkspace._id, selectedImage.originalFileName);

        const response = await fetch(downloadUrl);
        if (!response.ok) {
          throw new Error("Failed to fetch image for preview.");
        }
        const blob = await response.blob();
        objectUrl = URL.createObjectURL(blob);

        if (!isCancelled) {
          setPreviewUrl(objectUrl);
          setImageBlob(blob);
        }
      } catch (error) {
        if (!isCancelled) {
          console.error("Error fetching image for preview:", error);
          customToast({
            title: "Error",
            description: error.message || "Error fetching image for preview.",
            status: "error",
          });
        }
      }
    };

    fetchImageForPreview();

    return () => {
      isCancelled = true;
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [isOpen, selectedImage, currentWorkspace, customToast]);

  const handleDownloadFile = () => {
    if (!imageBlob) return;
    downloadBlob(imageBlob, imageFilename);
  };

  if (!selectedImage) return null;

  return (
    <ReusableModal
      isOpen={isOpen}
      onClose={onClose}
      title="Image Preview"
      size="lg"
      footerButtons={
        <Button
          ml="auto"
          colorScheme={buttonColorScheme} // Uses dynamic themeColor
          size="sm"
          leftIcon={<PiDownloadSimple size="16px" />}
          onClick={handleDownloadFile}
          isDisabled={!imageBlob}
        >
          Download Image
        </Button>
      }
    >
      <Box alignSelf="center" justifySelf="center">
        <Skeleton isLoaded={!!previewUrl} height="60vh" w="40vh" borderRadius="md" mb={4}>
          {previewUrl && <Image src={previewUrl} alt={imageFilename} objectFit="contain" maxH="60vh" borderRadius="md" />}
        </Skeleton>
        {!previewUrl && (
          <Text textAlign="center" color="gray.500">
            Loading image...
          </Text>
        )}
      </Box>
    </ReusableModal>
  );
};

export default FileImagePreviewModal;
