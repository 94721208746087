// src/components/SettingsSideBarLayout.js

import React, { useState, memo, useMemo, useCallback, useContext, useLayoutEffect } from "react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Flex,
  Icon,
  Text,
  VStack,
  IconButton,
  useColorModeValue,
  Tooltip,
  Link,
  Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal,
  HStack,
  useColorMode,
  Button,
  Spinner,
  Alert,
  AlertIcon,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  useBreakpointValue,
} from "@chakra-ui/react";
import UserAvatar from "../../2 - Components/Reusable/UserAvatar";
import PropTypes from "prop-types";
import CompanyAvatar from "../../2 - Components/Reusable/CompanyAvatar";
import {
  PiAlignLeft,
  PiArrowLeftBold,
  PiBell,
  PiBuildingOffice,
  PiChartLineUp,
  PiChat,
  PiInvoice,
  PiSignature,
  PiSignOut,
  PiUser,
  PiUsers,
  PiVideoConference,
  PiListBold,
  PiDotsThreeVerticalBold,
} from "react-icons/pi";
import { BsFolder, BsLayoutSidebarInset, BsLayoutSidebarInsetReverse } from "react-icons/bs";
import { CgDarkMode } from "react-icons/cg";
import { WorkspaceContext } from "../Context/WorkspaceContext";
import { useSocket } from "../Socket/SocketContext";

const SettingsSideBarLayout = ({ children }) => {
  const { permissions, currentUserId, adminCompany, isLoading, canEditModule, canAccessModule, isError, error, themeColor } =
    useContext(WorkspaceContext);
  const { socket } = useSocket();
  const navigate = useNavigate();
  const { toggleColorMode, colorMode } = useColorMode();
  const { isOpen: isDrawerOpen, onOpen, onClose } = useDisclosure();
  const [collapsed, setCollapsed] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: false });

  // For mobile, match the normal sidebar behavior by always expanding the sidebar.
  useLayoutEffect(() => {
    if (!isMobile) {
      setCollapsed(false); // Desktop: expanded sidebar
    } else {
      setCollapsed(false); // Mobile: expanded sidebar
    }
  }, [isMobile]);

  // Chakra UI color mode values
  const sidebarBgColor = useColorModeValue("gray.50", "gray.900");
  const collapseIconColor = useColorModeValue("gray.400", "gray.400");
  const borderColor = useColorModeValue("gray.200", "gray.750");
  const profileSectionHoverBg = useColorModeValue("gray.100", "gray.700");
  const textCategoryColor = useColorModeValue("gray.500", "gray.600");
  const mainContentBg = useColorModeValue("gray.100", "gray.800");

  // Use the same mobile button icon color as in your normal sidebar
  const mobileMenuButtonIcon = useColorModeValue("black", "white");

  const switchModeText = useColorModeValue("Dark Mode", "Light Mode");
  const switchModeIcon = useMemo(
    () => (colorMode === "light" ? <CgDarkMode fontSize="14px" /> : <CgDarkMode fontSize="14px" style={{ transform: "rotate(180deg)" }} />),
    [colorMode]
  );

  const toggleCollapse = useCallback(() => {
    setCollapsed((prev) => !prev);
  }, []);

  const handleGoBack = useCallback(() => {
    navigate("/dashboard");
  }, [navigate]);

  const handleToggleColorMode = useCallback(() => {
    const newTheme = colorMode === "light" ? "dark" : "light";
    toggleColorMode();
    if (socket && typeof socket.emit === "function") {
      socket.emit("updateTheme", { theme: newTheme }, (response) => {
        if (response.status === "ok") {
          console.log("Theme updated successfully via socket.");
        } else {
          console.error("Error updating theme via socket:", response.message);
        }
      });
    } else {
      console.error("Socket is not connected.");
    }
  }, [toggleColorMode, colorMode, socket]);

  const profileSection = useMemo(() => {
    return (
      <Menu>
        <MenuButton
          as={Flex}
          alignItems="center"
          cursor="pointer"
          _hover={{ bg: profileSectionHoverBg }}
          transition="background 0.3s"
          variant="ghost"
          p={1}
        >
          <HStack spacing={0}>
            <UserAvatar userIds={[currentUserId]} size="md" showInfo={!collapsed} />
            <Icon as={PiDotsThreeVerticalBold} ml="auto" boxSize="18px" display={collapsed ? "none" : "block"} />
          </HStack>
        </MenuButton>
        <Portal>
          <MenuList zIndex="1500">
            <MenuItem fontSize="md" as={RouterLink} to="/logout" icon={<PiSignOut fontSize="16px" />}>
              Logout
            </MenuItem>
            <MenuItem fontSize="md" as={RouterLink} to="/dashboard" icon={<PiArrowLeftBold fontSize="16px" />}>
              Dashboard
            </MenuItem>
            <MenuItem fontSize="md" onClick={handleToggleColorMode} icon={switchModeIcon}>
              {switchModeText}
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    );
  }, [currentUserId, collapsed, switchModeText, switchModeIcon, handleToggleColorMode, profileSectionHoverBg]);

  if (isLoading) {
    return (
      <Flex justifyContent="center" alignItems="center" height="100vh">
        <Spinner />
      </Flex>
    );
  }

  if (isError) {
    return (
      <Alert status="error" mt={4}>
        <AlertIcon />
        {error.message || "An error occurred while fetching data."}
      </Alert>
    );
  }

  if (!permissions || Object.keys(permissions).length === 0) {
    return (
      <Flex justifyContent="center" alignItems="center" height="100vh">
        <Spinner />
      </Flex>
    );
  }

  const SidebarContent = (
    <>
      {/* Top Section */}
      <Flex justifyContent="space-between" alignItems="center" p={4} borderBottom="1px solid" borderColor={borderColor} h="80px">
        <HStack spacing={2} justifyContent={collapsed ? "center" : "flex-start"} w="100%">
          <CompanyAvatar companyIds={[adminCompany?._id]} size="md" showInfo={!collapsed} />
        </HStack>
        {!isMobile && (
          <IconButton
            aria-label={collapsed ? "Expand Sidebar" : "Collapse Sidebar"}
            icon={collapsed ? <BsLayoutSidebarInsetReverse size="16px" /> : <BsLayoutSidebarInset size="16px" />}
            size="xs"
            onClick={toggleCollapse}
            variant="ghost"
            sx={{
              svg: {
                color: collapseIconColor,
              },
            }}
          />
        )}
      </Flex>

      {/* Back to Dashboard Section */}
      <Flex direction="column" align={collapsed ? "center" : "flex-start"} p={4}>
        {collapsed ? (
          <Tooltip label="Back to Dashboard" placement="right">
            <IconButton
              aria-label="Back to Dashboard"
              icon={<PiArrowLeftBold />}
              onClick={handleGoBack}
              variant="outline"
              size="sm"
              mb={2}
              sx={{
                svg: {
                  color: collapseIconColor,
                },
              }}
            />
          </Tooltip>
        ) : (
          <Button leftIcon={<PiArrowLeftBold />} onClick={handleGoBack} variant="outline" size="sm" justifyContent="flex-start" w="100%" mb={2}>
            Back to Dashboard
          </Button>
        )}
      </Flex>

      {/* Settings Navigation Links */}
      <Flex p={4} pt={2} flexDirection="column">
        <Text fontSize="xs" fontWeight="600" color={textCategoryColor} pb={2} whiteSpace="nowrap" overflow="hidden">
          Settings
        </Text>
        <VStack spacing={1} align="stretch">
          {canEditModule("user-settings") && (
            <NavItem
              to="/dashboard/settings"
              icon={PiUser}
              themeColor={themeColor}
              label="Account"
              isCollapsed={collapsed}
              onCloseDrawer={isMobile ? onClose : undefined}
              isMobile={isMobile}
            />
          )}
          {canEditModule("company-settings") && (
            <NavItem
              to="/dashboard/settings/company"
              icon={PiBuildingOffice}
              label="Company"
              themeColor={themeColor}
              isCollapsed={collapsed}
              onCloseDrawer={isMobile ? onClose : undefined}
              isMobile={isMobile}
            />
          )}
          {canAccessModule("team-settings") && (
            <NavItem
              to="/dashboard/settings/users"
              icon={PiUsers}
              themeColor={themeColor}
              label="Team"
              isCollapsed={collapsed}
              onCloseDrawer={isMobile ? onClose : undefined}
              isMobile={isMobile}
            />
          )}
          {canEditModule("notification-settings") && (
            <NavItem
              to="/dashboard/settings/notifications"
              icon={PiBell}
              themeColor={themeColor}
              label="Notifications"
              isCollapsed={collapsed}
              onCloseDrawer={isMobile ? onClose : undefined}
              isMobile={isMobile}
            />
          )}
        </VStack>
      </Flex>

      {/* Divider between Settings and Module Settings */}
      <Divider my={4} borderColor={borderColor} />

      {/* Module Settings Navigation Links */}
      <Flex p={4} pt={2} flexDirection="column">
        {[
          "messages-settings",
          "files-settings",
          "projects-settings",
          "contracts-settings",
          "billing-settings",
          "reports-settings",
          "meetings-settings",
        ].some((module) => canEditModule(module)) && (
          <>
            <Text fontSize="xs" fontWeight="bold" color={textCategoryColor} pb={2} whiteSpace="nowrap" overflow="hidden">
              Module Settings
            </Text>
            <VStack spacing={1} align="stretch">
              {canEditModule("messages-settings") && (
                <NavItem
                  to="/dashboard/settings/messages"
                  icon={PiChat}
                  themeColor={themeColor}
                  label="Messages Settings"
                  isCollapsed={collapsed}
                  onCloseDrawer={isMobile ? onClose : undefined}
                  isMobile={isMobile}
                />
              )}
              {canEditModule("files-settings") && (
                <NavItem
                  to="/dashboard/settings/files"
                  icon={BsFolder}
                  themeColor={themeColor}
                  label="Files Settings"
                  isCollapsed={collapsed}
                  onCloseDrawer={isMobile ? onClose : undefined}
                  isMobile={isMobile}
                />
              )}
              {canEditModule("projects-settings") && (
                <NavItem
                  to="/dashboard/settings/projects"
                  icon={PiAlignLeft}
                  themeColor={themeColor}
                  label="Projects Settings"
                  isCollapsed={collapsed}
                  onCloseDrawer={isMobile ? onClose : undefined}
                  isMobile={isMobile}
                />
              )}
              {canEditModule("contracts-settings") && (
                <NavItem
                  to="/dashboard/settings/contracts"
                  icon={PiSignature}
                  themeColor={themeColor}
                  label="Contracts Settings"
                  isCollapsed={collapsed}
                  onCloseDrawer={isMobile ? onClose : undefined}
                  isMobile={isMobile}
                />
              )}
              {canEditModule("billing-settings") && (
                <NavItem
                  to="/dashboard/settings/billing"
                  icon={PiInvoice}
                  themeColor={themeColor}
                  label="Billing Settings"
                  isCollapsed={collapsed}
                  onCloseDrawer={isMobile ? onClose : undefined}
                  isMobile={isMobile}
                />
              )}
              {canEditModule("reports-settings") && (
                <NavItem
                  to="/dashboard/settings/reports"
                  icon={PiChartLineUp}
                  themeColor={themeColor}
                  label="Reports Settings"
                  isCollapsed={collapsed}
                  onCloseDrawer={isMobile ? onClose : undefined}
                  isMobile={isMobile}
                />
              )}
              {canEditModule("meetings-settings") && (
                <NavItem
                  to="/dashboard/settings/meetings"
                  icon={PiVideoConference}
                  themeColor={themeColor}
                  label="Meetings Settings"
                  isCollapsed={collapsed}
                  onCloseDrawer={isMobile ? onClose : undefined}
                  isMobile={isMobile}
                />
              )}
            </VStack>
          </>
        )}
      </Flex>

      {/* Bottom Section */}
      <Box position="absolute" w="100%" bottom="0px" left="0px" borderTop="1px solid" borderColor={borderColor}>
        <Box p={3} _hover={{ bg: profileSectionHoverBg }} transition="all 0.2s">
          {profileSection}
        </Box>
      </Box>
    </>
  );

  return (
    <Flex height="100vh" flexDirection="column" overflow="hidden">
      {/* Floating Hamburger Button on Mobile */}
      {isMobile && !isDrawerOpen && (
        <IconButton
          icon={<Icon as={PiListBold} fontSize="24px" color={mobileMenuButtonIcon} />}
          variant="ghost"
          onClick={onOpen}
          aria-label="Open Sidebar"
          position="fixed"
          size="sm"
          h="50px"
          top="40px"
          left="5px"
          transform="translate(0, -50%)"
          zIndex={1000}
        />
      )}

      <Flex flex="1" overflow="hidden">
        {/* Desktop Sidebar */}
        {!isMobile && (
          <Box
            as="aside"
            overflow="hidden"
            w={collapsed ? "90px" : "260px"}
            bg={sidebarBgColor}
            display="flex"
            flexDirection="column"
            position="fixed"
            top="0"
            bottom="0"
            zIndex="100"
            transition="width 0.3s ease"
            overflowX="hidden"
            borderRight="1px solid"
            borderColor={borderColor}
          >
            {SidebarContent}
          </Box>
        )}

        {/* Mobile Drawer Sidebar */}
        {isMobile && (
          <Drawer isOpen={isDrawerOpen} placement="left" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent
              p={0}
              m={0}
              maxW="300px" // Matches normal sidebar mobile width
              bg={sidebarBgColor}
              minH="100vh"
              display="flex"
              flexDirection="column"
            >
              <DrawerCloseButton />
              <DrawerBody p={0} display="flex" flexDirection="column" height="100%">
                <Box flex="1" overflowY="auto">
                  {SidebarContent}
                </Box>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        )}

        {/* Main Content */}
        <Box
          as="main"
          flex="1"
          ml={!isMobile ? (collapsed ? "90px" : "260px") : "0"}
          bg={mainContentBg}
          transition="margin-left 0.3s ease"
          overflow="hidden"
        >
          {children}
        </Box>
      </Flex>
    </Flex>
  );
};

// NavItem Component for Sidebar Links
const NavItem = memo(({ to, icon, label, isCollapsed, badge, themeColor, onCloseDrawer, isMobile }) => {
  const { pathname } = useLocation();
  const activeBg = useColorModeValue(`${themeColor}.100`, `${themeColor}.800`);
  const activeNotifBg = useColorModeValue(`${themeColor}.200`, `${themeColor}.700`);
  const activeColor = useColorModeValue("black", "gray.100");
  const inactiveColor = useColorModeValue("gray.700", "gray.100");
  const hoverBg = useColorModeValue("gray.100", "gray.600");
  const hoverColor = useColorModeValue("black", "gray.100");

  return (
    <Flex
      direction="column"
      alignItems={isCollapsed ? "center" : "flex-start"}
      justifyContent={isCollapsed ? "center" : "flex-start"}
      _hover={pathname === to ? {} : { bg: hoverBg, color: hoverColor }}
      bg={pathname === to ? activeBg : "transparent"}
      transition="all 0.2s"
      color={pathname === to ? activeColor : inactiveColor}
      borderRadius="xl"
      cursor="pointer"
      w="100%"
      pr={2}
    >
      <Tooltip label={label} fontSize="sm" placement="right" isDisabled={!isCollapsed}>
        <Link
          as={RouterLink}
          cursor="pointer"
          to={to}
          style={{ textDecoration: "none" }}
          display="flex"
          justifyContent={isCollapsed ? "center" : "flex-start"}
          alignItems="center"
          p="5px"
          // When clicked on mobile, call onCloseDrawer to close the drawer
          onClick={() => {
            if (isMobile && onCloseDrawer) {
              onCloseDrawer();
            }
          }}
          h="30px"
          borderRadius="md"
          w="100%"
        >
          <Icon as={icon} boxSize="18px" ml={isCollapsed ? 0 : 2} />
          {!isCollapsed && (
            <>
              <Text ml={2} fontSize="sm" fontWeight="medium">
                {label}
              </Text>
              {badge !== undefined && (
                <Text bg={activeNotifBg} borderRadius="full" fontSize="sm" px={2} alignContent="center" ml="auto">
                  {badge}
                </Text>
              )}
            </>
          )}
        </Link>
      </Tooltip>
    </Flex>
  );
});

NavItem.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  badge: PropTypes.number,
  themeColor: PropTypes.string.isRequired,
  onCloseDrawer: PropTypes.func,
  isMobile: PropTypes.bool,
};

export default React.memo(SettingsSideBarLayout);
