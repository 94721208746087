// client/src/components/TimeLogSection.jsx
import React, { useState, useContext, useCallback } from "react";
import {
  Box,
  VStack,
  HStack,
  Text,
  IconButton,
  Divider,
  Textarea,
  FormControl,
  FormLabel,
  Input,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverFooter,
  PopoverHeader,
  PopoverCloseButton,
  useDisclosure,
  useColorModeValue,
  Fade,
  Stack,
  RadioGroup,
  Radio,
  Tag,
} from "@chakra-ui/react";
import UserAvatar from "../Reusable/UserAvatar";
import { ProjectContext } from "../../5 - General/Context/ProjectsContext";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";
import { formatReadableDate } from "../../5 - General/Utils/UtilsFormatData";
import { PiClockUser, PiPencilSimpleLine, PiTrash } from "react-icons/pi";
import ConfirmActionModal from "../../3 - Modal/9 - General Modals/ConfirmActionModal";

const TimeLogSection = ({ subtask, isSubtask = false }) => {
  // -----------------------------
  // 1) Context / State
  // -----------------------------
  const { tasks, logTime, editTimeLog, deleteTimeLog, currentTaskId } = useContext(ProjectContext);
  const currentTask = isSubtask ? subtask : tasks.find((t) => t._id === currentTaskId);

  const [createLog, setCreateLog] = useState({
    hours: "",
    startTime: "",
    endTime: "",
    description: "",
  });
  const [currentLog, setCurrentLog] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDeleteLogOpen, setIsDeleteLogOpen] = useState(false);
  const [createMode, setCreateMode] = useState("hours");
  const customToast = useCustomToast();

  // -----------------------------
  // 2) Color / Style variables
  // -----------------------------
  const formBorderColor = useColorModeValue("gray.200", "gray.600");
  const formBg = useColorModeValue("gray.50", "gray.800");
  const inputBg = useColorModeValue("white", "gray.700");
  const descriptionTextColor = useColorModeValue("black", "white");
  const timeLogBg = useColorModeValue("white", "gray.700");
  const timeLogBorder = useColorModeValue("gray.200", "gray.600");
  const timeLogButtonColor = useColorModeValue("primary.500", "primary.300");
  const noLogsColor = useColorModeValue("gray.500", "gray.400");

  // -----------------------------
  // 3) Handlers
  // -----------------------------
  const handleAddTimeLog = async () => {
    let timeSpent;
    const { description } = createLog;

    if (!description.trim()) {
      customToast({
        title: "Invalid input.",
        description: "'Description' is required.",
        status: "error",
      });
      return;
    }

    if (createMode === "range") {
      if (!createLog.startTime || !createLog.endTime) {
        customToast({
          title: "Invalid input.",
          description: "Both start and end times are required.",
          status: "error",
        });
        return;
      }
      const start = new Date(createLog.startTime);
      const end = new Date(createLog.endTime);
      timeSpent = (end - start) / (1000 * 60 * 60);
      if (timeSpent <= 0) {
        customToast({
          title: "Invalid input.",
          description: "End time must be after start time.",
          status: "error",
        });
        return;
      }
    } else {
      timeSpent = parseFloat(createLog.hours);
      if (isNaN(timeSpent)) {
        customToast({
          title: "Invalid input.",
          description: "'Hours' must be a number.",
          status: "error",
        });
        return;
      }
    }

    try {
      await logTime(currentTask._id, {
        timeSpent,
        description: description.trim(),
      });
      setCreateLog({ hours: "", startTime: "", endTime: "", description: "" });
    } catch (error) {
      console.error("Error adding time log:", error);
      customToast({
        title: "Error.",
        description: "There was an error adding your time log.",
        status: "error",
      });
    }
  };

  const handleEditTimeLog = async () => {
    if (!currentLog || !currentLog._id || !currentLog.description) return;
    const computedHours = parseFloat(currentLog.timeSpent);
    if (isNaN(computedHours)) return;

    try {
      await editTimeLog({
        taskId: currentTask._id,
        logId: currentLog._id,
        timeSpent: computedHours,
        description: currentLog.description.trim(),
      });
      setCurrentLog(null);
      onClose();
    } catch (error) {
      console.error("Error editing time log:", error);
      customToast({
        title: "Error.",
        description: "There was an error updating your time log.",
        status: "error",
      });
    }
  };

  const handleDeleteTimeLog = useCallback((logId) => {
    setCurrentLog(logId);
    setIsDeleteLogOpen(true);
  }, []);

  const handleDeleteTimeLogConfirm = async () => {
    try {
      await deleteTimeLog(currentTask._id, currentLog);
      setIsDeleteLogOpen(false);
      setCurrentLog(null);
    } catch (error) {
      console.error("Error deleting time log:", error);
      customToast({
        title: "Error.",
        description: "There was an error deleting your time log.",
        status: "error",
      });
    }
  };

  // -----------------------------
  // 4) JSX
  // -----------------------------
  return (
    <>
      {/* Add Time Log Form */}
      <Box mx={4} my={8} p={4} borderWidth="1px" borderColor={formBorderColor} borderRadius="md" bg={formBg}>
        <VStack spacing={4} align="stretch">
          <Text fontWeight="400">Add Time Log</Text>

          <FormControl as="fieldset">
            <FormLabel as="legend">Create Mode</FormLabel>
            <RadioGroup
              value={createMode}
              onChange={(val) => {
                setCreateMode(val);
                if (val === "range") {
                  setCreateLog((prev) => ({
                    ...prev,
                    startTime: new Date().toISOString().slice(0, 16),
                    endTime: new Date().toISOString().slice(0, 16),
                  }));
                }
              }}
            >
              <HStack spacing="24px">
                <Radio value="hours">Hours</Radio>
                <Radio value="range">Time Range</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>

          {createMode === "hours" ? (
            <FormControl isRequired>
              <FormLabel>Hours</FormLabel>
              <Input
                type="number"
                placeholder="Enter hours"
                bg={inputBg}
                value={createLog.hours}
                onChange={(e) => setCreateLog({ ...createLog, hours: e.target.value })}
                focusBorderColor="primary.400"
              />
            </FormControl>
          ) : (
            <>
              <FormControl isRequired>
                <FormLabel>Start Time</FormLabel>
                <Input
                  type="datetime-local"
                  bg={inputBg}
                  value={createLog.startTime || new Date().toISOString().slice(0, 16)}
                  onChange={(e) => setCreateLog({ ...createLog, startTime: e.target.value })}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>End Time</FormLabel>
                <Input
                  type="datetime-local"
                  bg={inputBg}
                  value={createLog.endTime || new Date().toISOString().slice(0, 16)}
                  onChange={(e) => setCreateLog({ ...createLog, endTime: e.target.value })}
                />
              </FormControl>
              {createLog.startTime && createLog.endTime && (
                <Tag w="fit-content" fontSize="md" colorScheme="gray" variant="solid">
                  Total Calculated Hours: {((new Date(createLog.endTime) - new Date(createLog.startTime)) / (1000 * 60 * 60)).toFixed(2)}
                </Tag>
              )}
            </>
          )}

          <FormControl isRequired>
            <FormLabel>Description</FormLabel>
            <Textarea
              placeholder="Enter description"
              bg={inputBg}
              value={createLog.description}
              onChange={(e) => setCreateLog({ ...createLog, description: e.target.value })}
              resize="vertical"
              focusBorderColor="primary.400"
              borderRadius="md"
            />
          </FormControl>

          <HStack justifyContent="flex-end">
            <Button
              colorScheme="primary"
              leftIcon={<PiClockUser fontSize="18px" />}
              onClick={handleAddTimeLog}
              isDisabled={
                createMode === "hours"
                  ? !createLog.hours || !createLog.description
                  : !createLog.startTime || !createLog.endTime || !createLog.description
              }
            >
              Add Time Log
            </Button>
          </HStack>
        </VStack>
      </Box>

      {/* Time Logs List */}
      <VStack spacing={6} align="stretch" mb="100px">
        {currentTask?.timeLogs?.length > 0 ? (
          currentTask.timeLogs.map((log, idx) => (
            <Box key={log._id || idx} p={4} border="1px" borderColor={timeLogBorder} borderRadius="md" bg={timeLogBg} mx={4}>
              <HStack justifyContent="space-between" mb={3}>
                <HStack spacing={4}>
                  <UserAvatar isOnClickActive={true} userIds={[log.userId]} size="sm" showInfo />
                  <VStack mx={8} align="flex-start" spacing={0}>
                    <HStack spacing={1} alignItems="center">
                      <PiClockUser fontSize="18px" color={timeLogButtonColor} />
                      <Text fontSize="md" fontWeight="600">
                        {log.timeSpent} Hours
                      </Text>
                    </HStack>
                    <Text fontSize="sm" color="gray.500">
                      {formatReadableDate(new Date(log.date)) + " at " + new Date(log.date).toLocaleTimeString()}
                    </Text>
                  </VStack>
                </HStack>
                <HStack spacing={2}>
                  <Popover
                    isOpen={currentLog?.index === idx && isOpen}
                    onOpen={() => {
                      setCurrentLog({
                        ...log,
                        index: idx,
                        startTime: "",
                        endTime: "",
                      });
                      setCreateMode("hours");
                      onOpen();
                    }}
                    onClose={() => {
                      setCurrentLog(null);
                      onClose();
                    }}
                    placement="auto"
                    closeOnBlur={false}
                  >
                    <PopoverTrigger p={0}>
                      <IconButton
                        icon={<PiPencilSimpleLine fontSize="18px" />}
                        size="sm"
                        colorScheme="primary"
                        variant="ghost"
                        aria-label="Edit Time Log"
                      />
                    </PopoverTrigger>
                    <PopoverContent p={0} borderRadius="lg" boxShadow="lg" w="500px">
                      <PopoverCloseButton />
                      <PopoverHeader fontWeight="bold" my={2} pb={4} px={4}>
                        Edit Time Log
                      </PopoverHeader>
                      <PopoverBody>
                        <Stack spacing={4} px={2} pb={2}>
                          <FormControl as="fieldset">
                            <FormLabel as="legend">Edit Mode</FormLabel>
                            <RadioGroup value={createMode} onChange={(val) => setCreateMode(val)}>
                              <HStack spacing="24px">
                                <Radio value="hours">Hours</Radio>
                                <Radio value="range">Time Range</Radio>
                              </HStack>
                            </RadioGroup>
                          </FormControl>

                          {createMode === "hours" ? (
                            <FormControl isRequired>
                              <FormLabel>Hours</FormLabel>
                              <Input
                                type="number"
                                value={currentLog?.timeSpent || ""}
                                onChange={(e) =>
                                  setCurrentLog({
                                    ...currentLog,
                                    timeSpent: e.target.value,
                                  })
                                }
                              />
                            </FormControl>
                          ) : (
                            <>
                              <FormControl isRequired>
                                <FormLabel>Start Time</FormLabel>
                                <Input
                                  type="datetime-local"
                                  value={currentLog?.startTime || new Date().toISOString().slice(0, 16)}
                                  onChange={(e) =>
                                    setCurrentLog({
                                      ...currentLog,
                                      startTime: e.target.value,
                                    })
                                  }
                                />
                              </FormControl>
                              <FormControl isRequired>
                                <FormLabel>End Time</FormLabel>
                                <Input
                                  type="datetime-local"
                                  value={currentLog?.endTime || new Date().toISOString().slice(0, 16)}
                                  onChange={(e) =>
                                    setCurrentLog({
                                      ...currentLog,
                                      endTime: e.target.value,
                                    })
                                  }
                                />
                              </FormControl>
                              {currentLog?.startTime && currentLog?.endTime && (
                                <Text>
                                  Calculated Hours: {((new Date(currentLog.endTime) - new Date(currentLog.startTime)) / (1000 * 60 * 60)).toFixed(2)}
                                </Text>
                              )}
                            </>
                          )}

                          <FormControl isRequired>
                            <FormLabel>Description</FormLabel>
                            <Textarea
                              value={currentLog?.description || ""}
                              onChange={(e) =>
                                setCurrentLog({
                                  ...currentLog,
                                  description: e.target.value,
                                })
                              }
                              borderRadius="md"
                              resize="vertical"
                              focusBorderColor="primary.500"
                              minHeight="100px"
                            />
                          </FormControl>
                        </Stack>
                      </PopoverBody>
                      <PopoverFooter display="flex" justifyContent="flex-end">
                        <Button variant="outline" mr={3} onClick={onClose}>
                          Cancel
                        </Button>
                        <Button colorScheme="primary" onClick={handleEditTimeLog}>
                          Save
                        </Button>
                      </PopoverFooter>
                    </PopoverContent>
                  </Popover>
                  <IconButton
                    icon={<PiTrash fontSize="18px" />}
                    size="sm"
                    colorScheme="gray"
                    variant="ghost"
                    aria-label="Delete Time Log"
                    onClick={() => handleDeleteTimeLog(log._id)}
                  />
                </HStack>
              </HStack>
              <Divider />
              <Fade in>
                <Box mt={3}>
                  <Text fontSize="md" color={descriptionTextColor}>
                    {log.description}
                  </Text>
                </Box>
              </Fade>
            </Box>
          ))
        ) : (
          <Box p={6} textAlign="center" flex={1}>
            <Text fontSize="md" color={noLogsColor}>
              No time logs.
            </Text>
          </Box>
        )}
      </VStack>

      {/* Confirm Delete Modal */}
      <ConfirmActionModal
        isOpen={isDeleteLogOpen}
        onClose={() => setIsDeleteLogOpen(false)}
        onConfirm={handleDeleteTimeLogConfirm}
        title="Delete Time Log"
        description="Are you sure you want to delete this time log? This action cannot be undone and will permanently remove it from the system."
        confirmText="Delete"
        cancelText="Cancel"
        isDestructive
      />
    </>
  );
};

export default TimeLogSection;
