// client/src/components/2 - Components/ProjectsViewTaskView/SubtaskSection.js
import React, { useContext, useState, useCallback } from "react";
import { Box, Text, useColorModeValue, useDisclosure, HStack, IconButton, FormControl, Input, Button } from "@chakra-ui/react";
import { formatEndDate } from "../../5 - General/Utils/UtilsFormatData";
import { ProjectContext } from "../../5 - General/Context/ProjectsContext";
import { WorkspaceContext } from "../../5 - General/Context/WorkspaceContext";
import UserAvatar from "../Reusable/UserAvatar";
import TagMenu from "../Reusable/TagMenu";
import CreateTaskModal from "../../3 - Modal/3 - Projects Modals/ProjectsCreateTaskModal";
import SubtaskDetailDrawer from "../../3 - Modal/3 - Projects Modals/ProjectsSubtaskDetailDrawer";
import { PiPencilSimpleLine } from "react-icons/pi";
import AssignTaskMembersModal from "../../3 - Modal/3 - Projects Modals/ProjectsAssignMembersToTaskModal";
import UtilsDatePicker from "../../5 - General/Utils/UtilsDatePicker";

const SubtaskSection = () => {
  // -----------------------------
  // 1) State / Context
  // -----------------------------
  const { currentTaskId, currentProjectId, tasks, subtasks, editTask, createTask, isLoading: loadingSubtasks } = useContext(ProjectContext);
  const { currentUserId } = useContext(WorkspaceContext);

  const currentTask = tasks.find((task) => task._id === currentTaskId);

  // State for showing modals and drawers
  const [isCreateSubtaskModalOpen, setIsCreateSubtaskModalOpen] = useState(false);
  const { isOpen: isSubtaskDrawerOpen, onOpen: onOpenSubtaskDrawer, onClose: onCloseSubtaskDrawer } = useDisclosure();
  const [selectedSubtask, setSelectedSubtask] = useState(null);
  const [isAssignModalOpenForSubtask, setIsAssignModalOpenForSubtask] = useState(false);
  const [selectedSubtaskForAssign, setSelectedSubtaskForAssign] = useState(null);

  // New state for inline quick creation
  const [isCreatingInline, setIsCreatingInline] = useState(false);

  // Example status/priority options
  const statusOptions = [
    { value: "Not Started", label: "Not Started", color: "gray" },
    { value: "In Progress", label: "In Progress", color: "primary" },
    { value: "Completed", label: "Completed", color: "green" },
    { value: "On Hold", label: "On Hold", color: "orange" },
  ];

  const priorityOptions = [
    { value: "High", label: "High", color: "red" },
    { value: "Medium", label: "Medium", color: "yellow" },
    { value: "Low", label: "Low", color: "green" },
  ];

  // The array of subtasks for the current task
  const currentSubtasks = subtasks; // Adjust or filter if needed

  // -----------------------------
  // 2) Color / Style variables
  // -----------------------------
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const bgColor = useColorModeValue("white", "gray.800");
  const hoverBg = useColorModeValue("gray.50", "gray.700");
  const headerBg = useColorModeValue("gray.100", "gray.700");
  const noSubtasksTextColor = useColorModeValue("gray.500", "gray.300");

  // -----------------------------
  // 3) Handlers
  // -----------------------------
  // For status/priority changes
  const onStatusChange = useCallback(
    (subtaskId, field, value) => {
      editTask(subtaskId, { [field]: value });
    },
    [editTask]
  );

  const onPriorityChange = useCallback(
    (subtaskId, field, value) => {
      editTask(subtaskId, { [field]: value });
    },
    [editTask]
  );

  // When user clicks a subtask row, open the detail drawer
  const handleOpenSubtask = useCallback(
    (subtask) => {
      setSelectedSubtask(subtask);
      onOpenSubtaskDrawer();
    },
    [onOpenSubtaskDrawer]
  );

  // Inline create
  const handleInlineSave = async (newName, newDueDate) => {
    if (!newName.trim()) return;
    const taskData = {
      projectId: currentProjectId,
      parentTask: currentTaskId,
      name: newName.trim(),
      description: "Enter subtask description...",
      status: "Not Started",
      priority: "Medium",
      endDate: newDueDate || new Date(new Date().setDate(new Date().getDate() + 15)),
      startDate: new Date(),
      tags: ["Quick Created"],
      createdBy: currentUserId,
    };
    try {
      await createTask({ projectId: currentProjectId, taskData });
      setIsCreatingInline(false);
    } catch (error) {
      console.error("Error creating subtask:", error);
    }
  };

  const handleInlineCancel = () => {
    setIsCreatingInline(false);
  };

  // Component for inline creation row
  const InlineCreateSubtaskRow = ({ onSave, onCancel }) => {
    const [newSubtaskName, setNewSubtaskName] = useState("");
    const [newSubtaskDueDate, setNewSubtaskDueDate] = useState("");

    return (
      <Box
        display="grid"
        gridTemplateColumns="4fr 2fr 2fr 2fr 2fr"
        bg={bgColor}
        borderBottom="1px solid"
        borderColor={borderColor}
        p="10px"
        alignItems="center"
      >
        {/* Subtask Name Input */}
        <Box>
          <FormControl id="subtask-name" isRequired>
            <Input placeholder="Enter subtask name" value={newSubtaskName} onChange={(e) => setNewSubtaskName(e.target.value)} w="90%" />
          </FormControl>
        </Box>

        {/* Due Date */}
        <Box>
          <FormControl id="due-date">
            <UtilsDatePicker value={newSubtaskDueDate} onChange={(date) => setNewSubtaskDueDate(date)} placeholder="Select due date" hasPortal />
          </FormControl>
        </Box>

        {/* Status (default) */}
        <Box />

        {/* Priority (default) */}
        <Box />

        {/* Save/Cancel Buttons */}
        <Box>
          <HStack spacing={2}>
            <Button
              size="xs"
              colorScheme="primary"
              onClick={() => {
                onSave(newSubtaskName, newSubtaskDueDate);
                setNewSubtaskName("");
                setNewSubtaskDueDate("");
              }}
            >
              Save
            </Button>
            <Button size="xs" variant="outline" onClick={onCancel}>
              Cancel
            </Button>
          </HStack>
        </Box>
      </Box>
    );
  };

  // -----------------------------
  // 4) JSX
  // -----------------------------
  return (
    <>
      <Box m={4} bg={bgColor} borderRadius="lg" boxShadow="base" border="1px solid" borderColor={borderColor}>
        {/* Subtask header */}
        {currentSubtasks.length > 0 && (
          <Box
            display="grid"
            gridTemplateColumns="4fr 2fr 2fr 2fr 2fr"
            bg={headerBg}
            p={4}
            borderBottom="1px solid"
            borderColor={borderColor}
            pl="40px"
          >
            <Box fontWeight="600" fontSize="16px">
              Subtasks
            </Box>
            <Box fontWeight="600" fontSize="16px">
              Due Date
            </Box>
            <Box fontWeight="600" fontSize="16px">
              Status
            </Box>
            <Box fontWeight="600" fontSize="16px">
              Priority
            </Box>
            <Box fontWeight="600" fontSize="16px">
              Assigned To
            </Box>
          </Box>
        )}

        {/* Render each subtask row */}
        {currentSubtasks.length > 0 ? (
          currentSubtasks.map((subtask) => (
            <Box
              key={subtask._id}
              display="grid"
              gridTemplateColumns="4fr 2fr 2fr 2fr 2fr"
              bg={bgColor}
              _hover={{ backgroundColor: hoverBg }}
              transition="background-color 0.2s"
              alignItems="center"
              cursor="pointer"
              borderBottom="1px solid"
              borderColor={borderColor}
              py="10px"
              pl={4}
              onClick={() => handleOpenSubtask(subtask)}
            >
              {/* Subtask name */}
              <Box display="flex" alignItems="center">
                <Box w="10px" h="30px" bg={subtask.color || "primary.500"} mr={4} borderRadius="md" />
                <Text fontWeight="400" fontSize="md" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                  {subtask.name}
                </Text>
              </Box>

              {/* Due date */}
              <Box>{subtask.endDate ? <Text fontSize="md">{formatEndDate(subtask.endDate)}</Text> : "No Due Date"}</Box>

              {/* Status */}
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <TagMenu
                  options={statusOptions}
                  selectedValue={subtask.status}
                  field="status"
                  handleBlurSave={onStatusChange}
                  subtaskId={subtask._id}
                />
              </Box>

              {/* Priority */}
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <TagMenu
                  options={priorityOptions}
                  selectedValue={subtask.priority}
                  field="priority"
                  handleBlurSave={onPriorityChange}
                  subtaskId={subtask._id}
                />
              </Box>

              {/* Assigned To */}
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <HStack spacing={2}>
                  <UserAvatar
                    userIds={subtask.assignedTo ? subtask.assignedTo.map((u) => (u._id ? u._id : u)) : []}
                    size="sm"
                    showInfo={false}
                    useGroup
                    maxAvatars={2}
                    spacing={-1}
                  />
                  <IconButton
                    icon={<PiPencilSimpleLine fontSize="18px" />}
                    aria-label="Edit assigned members"
                    size="xs"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsAssignModalOpenForSubtask(true);
                      setSelectedSubtaskForAssign(subtask);
                    }}
                  />
                </HStack>
              </Box>
            </Box>
          ))
        ) : !loadingSubtasks ? (
          <Box p={4} display="flex" justifyContent="center">
            <Text mt={4} fontSize="md" color={noSubtasksTextColor} pl={8}>
              No subtasks available.
            </Text>
          </Box>
        ) : (
          <Text mt={4} color={noSubtasksTextColor} pl={4}>
            Loading subtasks...
          </Text>
        )}

        {/* Quick Inline Create Subtask Row */}
        {isCreatingInline ? (
          <InlineCreateSubtaskRow onSave={handleInlineSave} onCancel={handleInlineCancel} />
        ) : (
          <Box
            onClick={() => setIsCreatingInline(true)}
            cursor="pointer"
            _hover={{
              backgroundColor: hoverBg,
              transition: "background-color 0.2s ease",
            }}
            py="16px"
            borderColor={borderColor}
            pl={4}
          >
            <Text color={noSubtasksTextColor} fontSize="lg" fontStyle="italic">
              Quick add a new subtask...
            </Text>
          </Box>
        )}

        {/* Full Create Subtask Modal */}
        <CreateTaskModal
          isOpen={isCreateSubtaskModalOpen}
          onClose={() => setIsCreateSubtaskModalOpen(false)}
          projectId={currentProjectId}
          parentTask={currentTask}
        />
      </Box>

      {/* Subtask Detail Drawer */}
      <SubtaskDetailDrawer isOpen={isSubtaskDrawerOpen} onClose={onCloseSubtaskDrawer} subtask={selectedSubtask} />

      {/* Assign Members Modal for Subtask */}
      {selectedSubtaskForAssign && (
        <AssignTaskMembersModal
          isOpen={isAssignModalOpenForSubtask}
          task={selectedSubtaskForAssign}
          onClose={() => {
            setIsAssignModalOpenForSubtask(false);
            setSelectedSubtaskForAssign(null);
          }}
        />
      )}
    </>
  );
};

export default SubtaskSection;
