// src/components/3 - Modal/2 - Messages Modals/AddUserModal.js

import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  VStack,
  HStack,
  Button,
  Text,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  FormLabel,
  useColorModeValue,
} from "@chakra-ui/react";
import { Select, chakraComponents } from "chakra-react-select";
import ReusableModal from "../9 - General Modals/ReusableModal";
import { MessagesContext } from "../../5 - General/Context/MessagesContext";
import { WorkspaceContext } from "../../5 - General/Context/WorkspaceContext";

const AddUserModal = ({ isOpen, onClose }) => {
  // Note the function name now matches the one in context: handleAddUsersToChannel
  const { availableUsers, isAvailableUsersLoading, availableUsersError, handleAddUsersToChannel, currentChannelId, channels } =
    useContext(MessagesContext);
  const { themeColor, currentUser } = useContext(WorkspaceContext);

  const currentChannel = channels.find((channel) => channel._id === currentChannelId);
  // Ensure currentChannel exists; otherwise, set an empty array
  const channelUsers = currentChannel ? currentChannel.members : [];

  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedUsers([]);
    }
  }, [isOpen]);

  const handleAddUsers = () => {
    const userIds = selectedUsers.map((option) => option.value);
    // Call the updated add users function from context
    handleAddUsersToChannel(userIds);
    onClose();
  };

  // Convert channel member IDs to strings for reliable comparison
  const existingUserIds = channelUsers.map((user) => user._id.toString());

  // Update options:
  // - Filter out the current user (comparing as strings)
  // - Mark an option as disabled if its value is already in the channel
  const options = availableUsers
    .filter((user) => user.value !== currentUser._id.toString())
    .map((user) => ({
      value: user.value,
      label: user.label || "N/A",
      isDisabled: existingUserIds.includes(user.value),
    }));

  const footerButtons = (
    <HStack spacing={3} w="100%" mt={3}>
      <Button variant="outline" onClick={onClose}>
        Cancel
      </Button>
      <Button ml="auto" colorScheme={themeColor} onClick={handleAddUsers} isDisabled={selectedUsers.length === 0}>
        Add Selected Users
      </Button>
    </HStack>
  );

  const textColor = useColorModeValue("black", "white");
  const selectTextColor = useColorModeValue("black", "white");
  const selectBgColor = useColorModeValue("white", "gray.900");
  const selectHoverBgColor = useColorModeValue("gray.100", "gray.600");
  const selectLightTextColor = useColorModeValue("gray.500", "gray.300");
  const selectDropdownIndicatorBgColor = useColorModeValue("gray.00", "gray.600");
  const selectMenuBgColor = useColorModeValue("white", "gray.700");

  const chakraStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : selectTextColor,
      backgroundColor: state.isSelected ? `${themeColor}.500` : selectMenuBgColor,
      "&:hover": { backgroundColor: state.isSelected ? `${themeColor}.500` : selectHoverBgColor },
      padding: "8px",
    }),
    container: (provided) => ({
      ...provided,
      backgroundColor: selectBgColor,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: selectTextColor,
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: selectMenuBgColor,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: selectTextColor,
      padding: "8px 12px",
      backgroundColor: selectDropdownIndicatorBgColor,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: selectLightTextColor,
      fontSize: "14px",
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: selectLightTextColor,
      backgroundColor: selectMenuBgColor,
    }),
  };

  const renderContent = () => {
    if (isAvailableUsersLoading) {
      return (
        <VStack spacing={4} pt={4}>
          <Spinner />
          <Text>Loading users...</Text>
        </VStack>
      );
    }

    if (availableUsersError) {
      return (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2}>Failed to load users.</AlertTitle>
          <AlertDescription>{availableUsersError.message}</AlertDescription>
        </Alert>
      );
    }

    return (
      <VStack spacing={4} pt={4}>
        <Box width="100%">
          <FormLabel>Add Users</FormLabel>
          <Select
            isMulti
            options={options}
            value={selectedUsers}
            onChange={(selected) => setSelectedUsers(selected)}
            placeholder="Search and select users..."
            components={{
              DropdownIndicator: null,
              IndicatorSeparator: null,
              MultiValueRemove: (props) => <chakraComponents.MultiValueRemove {...props}>✕</chakraComponents.MultiValueRemove>,
            }}
            closeMenuOnSelect={false}
            chakraStyles={chakraStyles}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
            isOptionDisabled={(option) => option.isDisabled}
          />
        </Box>
        {selectedUsers.length > 0 && (
          <Text fontSize="sm" color={textColor}>
            {selectedUsers.length} user{selectedUsers.length > 1 ? "s" : ""} selected
          </Text>
        )}
      </VStack>
    );
  };

  return (
    <ReusableModal isOpen={isOpen} onClose={onClose} title="Add Users to the Conversation" footerButtons={footerButtons} size="lg">
      {renderContent()}
    </ReusableModal>
  );
};

export default AddUserModal;
