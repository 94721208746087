// client/src/components/Projects/Modals/QuickCreateTaskModal.jsx

import React, { useState, useContext } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
} from "@chakra-ui/react";
import ReusableModal from "../9 - General Modals/ReusableModal";
import { ProjectContext } from "../../5 - General/Context/ProjectsContext";
import { WorkspaceContext } from "../../5 - General/Context/WorkspaceContext";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";
import UtilsDatePicker from "../../5 - General/Utils/UtilsDatePicker";

const QuickCreateTaskModal = ({ isOpen, onClose, status, priority }) => {
  // -----------------------------
  // 1) Context / State
  // -----------------------------
  const { currentProjectId, currentTaskId, createTask } = useContext(ProjectContext);
  const { currentUserId } = useContext(WorkspaceContext);
  const customToast = useCustomToast();

  const [name, setName] = useState("");
  const [dueDate, setDueDate] = useState("");

  // -----------------------------
  // 2) Color definitions
  // -----------------------------
  // Possibly used in placeholders or border colors:
  // (No direct references to "gray.50" or "gray.100" here, so you might not strictly need these.)
  // But here's an example if you want a "boxBg" or "itemHoverBg" for expansions:
  // const boxBg = useColorModeValue("gray.50", "gray.800");

  // -----------------------------
  // 3) Handlers
  // -----------------------------
  const handleSubmit = async () => {
    if (!name.trim()) {
      customToast({
        title: "Warning",
        description: "Please enter a name for the task",
        status: "warning",
        isClosable: true,
      });
      return;
    }
    const taskData = {
      projectId: currentProjectId,
      parentTask: currentTaskId || null,
      name: name.trim(),
      description: "Enter task description...",
      status: status || "Not Started",
      priority: priority || "Medium",
      endDate: dueDate || new Date(new Date().setDate(new Date().getDate() + 15)),
      startDate: new Date(),
      tags: ["Quick Created"],
      createdBy: currentUserId || null,
    };
    console.log(taskData);
    await createTask({
      projectId: currentProjectId,
      taskData,
    });
    setName("");
    setDueDate("");
    onClose();
  };

  const handleClose = () => {
    setName("");
    setDueDate("");
    onClose();
  };

  // -----------------------------
  // 4) JSX
  // -----------------------------
  return (
    <ReusableModal
      isOpen={isOpen}
      onClose={handleClose}
      title="Quick Create New Task"
      footerButtons={
        <>
          <Button variant="outline" mr={3} onClick={handleClose}>
            Cancel
          </Button>
          <Button colorScheme="primary" onClick={handleSubmit}>
            Create Task
          </Button>
        </>
      }
    >
      <FormControl id="task-name" isRequired mb={4}>
        <FormLabel>Task Name</FormLabel>
        <Input value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter task name" />
      </FormControl>
      <FormControl id="due-date">
        <FormLabel>Due Date (optional)</FormLabel>
        <UtilsDatePicker
          value={dueDate}
          onChange={(date) => setDueDate(date)}
          fontSize="md"
          fontWeight="normal"
          placeholder="Select due date"
          startDate={new Date()}
          hasPortal
        />
      </FormControl>
    </ReusableModal>
  );
};

export default QuickCreateTaskModal;
