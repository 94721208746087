// client/src/components/2 - Components/Reusable/TableConfig.js

import React from "react";
import { Box, Button, HStack, Tag, Text } from "@chakra-ui/react";
import UserAvatar from "./UserAvatar";
import CompanyAvatar from "./CompanyAvatar";
import { formatReadableDate, formatTimestamp } from "../../5 - General/Utils/UtilsFormatData";

// Helper functions for rendering data
const renderDateTag = (value) => (
  <Tag size="sm" colorScheme="gray">
    {formatReadableDate(value)}
  </Tag>
);

const renderStatusTag = (status) => {
  const colorScheme = status === "Active" ? "green" : "red";
  return (
    <Tag size="sm" colorScheme={colorScheme}>
      {status}
    </Tag>
  );
};

// Customers table configuration
const customerColumns = () => [
  {
    accessor: "userDetails",
    label: "User Details",
    render: (value, row) => (
      <HStack spacing={4}>
        <UserAvatar userIds={[row._id]} size="sm" showInfo={false} isOnClickActive={true} />
        <Box>
          <Text fontWeight="600">{`${row.firstName} ${row.lastName}`}</Text>
          <Text fontSize="sm" color="gray.500">
            {row.email}
          </Text>
        </Box>
      </HStack>
    ),
    defaultVisible: true,
  },
  {
    accessor: "phoneNumber",
    label: "Phone",
    render: (value) => (
      <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" maxWidth="200px" fontSize="sm">
        {value || "N/A"}
      </Text>
    ),
    defaultVisible: true,
  },
  {
    accessor: "role",
    label: "Role",
    render: (value) => (
      <Tag size="sm" colorScheme="primary">
        {value}
      </Tag>
    ),
    defaultVisible: true,
  },
  {
    accessor: "status",
    label: "Status",
    render: renderStatusTag,
    defaultVisible: true,
  },
  {
    accessor: "companyId",
    label: "Company",
    render: (value) => <Text fontWeight="500">{value?.companyName || "N/A"}</Text>,
    defaultVisible: true,
  },
  {
    accessor: "lastLoggedIn",
    label: "Last Login",
    render: (value) => (
      <Tag fontSize="sm" colorScheme="gray">
        {value ? formatTimestamp(value) : "Never"}
      </Tag>
    ),
    defaultVisible: true,
  },
  {
    accessor: "createdAt",
    label: "Date Joined",
    render: renderDateTag,
    defaultVisible: true,
  },
];

// Companies table configuration
const companyColumns = (themeColor) => [
  {
    accessor: "companyName",
    label: "Company Name",
    render: (value, row) => (
      <HStack spacing={4}>
        <CompanyAvatar companyIds={[row._id]} size="sm" showInfo={true} />
      </HStack>
    ),
    defaultVisible: true,
  },
  {
    accessor: "companyRegistrationNumber",
    label: "Registration Number",
    render: (value) => <Text fontSize="sm">{value || "N/A"}</Text>,
    defaultVisible: true,
  },
  {
    accessor: "companyRole",
    label: "Role",
    render: (value) => (
      <Tag size="sm" colorScheme="blue">
        {value}
      </Tag>
    ),
    defaultVisible: true,
  },
  {
    accessor: "createdBy",
    label: "Created By",
    render: (value) => (
      <Text fontSize="sm" color="gray.500">
        {value?.firstName && value?.lastName ? `${value.firstName} ${value.lastName}` : "N/A"}
      </Text>
    ),
    defaultVisible: true,
  },
  {
    accessor: "createdAt",
    label: "Creation Date",
    render: renderDateTag,
    defaultVisible: true,
  },
];

// Main Table Configurations
export const tableConfigs = {
  CustomersTable: ({
    onEdit,
    onDelete,
    currentPage,
    totalPages,
    onPageChange,
    onSearchChange,
    searchValue,
    maxPerPage,
    totalItems,
    searchColumns,
    onSearchColumnsChange,
    isLoading,
    isError,
    error,
    themeColor, // Added themeColor
  }) => ({
    columns: customerColumns(themeColor),
    actionColumn: {
      label: "Actions",
      render: (row) => (
        <HStack spacing={2} justify="flex-end">
          <Button size="sm" variant="outline" colorScheme={themeColor} onClick={() => onEdit(row)}>
            Edit
          </Button>
          <Button size="sm" variant="outline" colorScheme="red" onClick={() => onDelete(row._id)}>
            Delete
          </Button>
        </HStack>
      ),
    },
    headerStyles: {
      h: "50px",
    },
    headerCellStyles: {
      fontWeight: "bold",
      fontSize: "14px",
      textTransform: "capitalize",
      borderBottom: "1px solid",
      transition: "background-color 0.2s",
    },
    rowStyles: {
      transition: "background-color 0.2s",
    },
    cellStyles: {
      fontSize: "14px",
    },
    // Pass pagination and search props
    currentPage,
    totalPages,
    onPageChange,
    onSearchChange,
    searchValue,
    maxHeight: "calc(100vh - 300px)",
    searchColumns,
    onSearchColumnsChange,
    maxPerPage,
    totalItems,
    isLoading,
    isError,
    error,
  }),

  CustomersCompanyTable: ({
    onEdit,
    onDelete,
    currentPage,
    totalPages,
    onPageChange,
    onSearchChange,
    searchValue,
    maxPerPage,
    totalItems,
    searchColumns,
    onSearchColumnsChange,
    isLoading,
    isError,
    error,
    themeColor, // Added themeColor
  }) => ({
    columns: companyColumns(themeColor),
    actionColumn: {
      label: "Actions",
      render: (row) => (
        <HStack spacing={2} justify="flex-end">
          <Button size="sm" variant="outline" colorScheme={themeColor} onClick={() => onEdit(row)}>
            Edit
          </Button>
          <Button size="sm" variant="outline" colorScheme="red" onClick={() => onDelete(row._id)}>
            Delete
          </Button>
        </HStack>
      ),
    },
    headerStyles: {
      h: "50px",
    },
    headerCellStyles: {
      fontWeight: "bold",
      fontSize: "14px",
      textTransform: "capitalize",
      borderBottom: "1px solid",
      transition: "background-color 0.2s",
    },
    rowStyles: {
      transition: "background-color 0.2s",
    },
    cellStyles: {
      fontSize: "14px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "200px",
    },
    // Pass pagination and search props
    currentPage,
    totalPages,
    onPageChange,
    onSearchChange,
    searchValue,
    maxHeight: "calc(100vh - 300px)",
    searchColumns,
    onSearchColumnsChange,
    maxPerPage,
    totalItems,
    isLoading,
    isError,
    error,
  }),
};

export default tableConfigs;
