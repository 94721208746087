// src/components/Reusable/GanttTaskBar.js
import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import { Box, Text, Tooltip } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { GanttContext } from "./GanttContext";
import dayjs from "dayjs";
import { isColorLight } from "../../5 - General/Utils/UtilsTextContrast";

const MotionBox = motion.create(Box);

// ------------------------------
// (Keep the StartDependency & EndDependency components as before)
// ------------------------------
const StartDependency = ({ onLinkEnd }) => (
  <Tooltip label="Complete Dependency Linking" hasArrow>
    <Box
      as="button"
      size="xs"
      width="10px"
      height="10px"
      bg="gray.400"
      borderRadius="50%"
      onClick={(e) => {
        e.stopPropagation();
        onLinkEnd();
      }}
      aria-label="Complete Dependency Linking"
      mr="10px"
    />
  </Tooltip>
);

const EndDependency = ({ onLinkStart }) => (
  <Tooltip label="Initiate Dependency Linking" hasArrow>
    <Box
      as="button"
      size="sm"
      width="10px"
      height="10px"
      bg="gray.400"
      borderRadius="full"
      onClick={(e) => {
        e.stopPropagation();
        onLinkStart();
      }}
      aria-label="Initiate Dependency Linking"
      ml="10px"
    />
  </Tooltip>
);

// ======================
// StartDateHandle Component
// ======================
const StartDateHandle = ({
  onResizeStart,
  dayWidth,
  setLocalLeft,
  setLocalWidth,
  disableTextSelection,
  startDragging,
  endDragging,
  currentLeft,
  currentWidth,
  onDragStart,
  onDragEnd,
  handleDayHover,
  task,
  chartRef,
}) => {
  const handleRef = useRef(null);
  const initialLeftRef = useRef(0);
  const initialXRef = useRef(0);
  const movementRef = useRef(0);

  const handleMouseMove = useCallback(
    (e) => {
      if (!chartRef.current) {
        console.warn("chartRef.current is null in StartDateHandle handleMouseMove");
        return;
      }
      const chartRect = chartRef.current.getBoundingClientRect();
      const offsetX = e.clientX - chartRect.left + chartRef.current.scrollLeft;
      // Snap offset to day boundaries:
      const snappedOffset = Math.round(offsetX / dayWidth) * dayWidth;
      movementRef.current = snappedOffset; // update for mouseUp

      const daysMoved = Math.round(snappedOffset / dayWidth);
      const newDate = dayjs(task.startDate).add(daysMoved, "day");
      handleDayHover(newDate);

      setLocalLeft(initialLeftRef.current + snappedOffset);
      setLocalWidth(currentWidth - snappedOffset);
    },
    [setLocalLeft, setLocalWidth, currentWidth, dayWidth, handleDayHover, task.startDate, chartRef]
  );

  const handleMouseUp = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      endDragging();
      disableTextSelection(false);

      const daysChanged = Math.round(movementRef.current / dayWidth);
      if (daysChanged !== 0) {
        onResizeStart(daysChanged); // task._id is already bound
      }

      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);

      movementRef.current = 0;
      onDragEnd();
    },
    [dayWidth, onResizeStart, endDragging, disableTextSelection, handleMouseMove, onDragEnd]
  );

  const handleMouseDown = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      startDragging();
      onDragStart();
      initialXRef.current = e.clientX;
      initialLeftRef.current = currentLeft;
      disableTextSelection(true);

      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    },
    [startDragging, disableTextSelection, handleMouseMove, handleMouseUp, currentLeft, onDragStart]
  );

  return (
    <Tooltip label="Drag to adjust start date" hasArrow>
      <Box
        ref={handleRef}
        width="10px"
        height="40px"
        borderRadius="10px 0 0 10px"
        bg="gray.300"
        cursor="ew-resize"
        onMouseDown={handleMouseDown}
        aria-label="Start Date Handle"
      />
    </Tooltip>
  );
};

// ======================
// EndDateHandle Component
// ======================
const EndDateHandle = ({
  onResizeEnd,
  dayWidth,
  setLocalWidth,
  disableTextSelection,
  startDragging,
  endDragging,
  currentWidth,
  onDragStart,
  onDragEnd,
  handleDayHover,
  task,
  chartRef,
  setIsResizing, // new prop passed from parent
}) => {
  const handleRef = useRef(null);
  const initialWidthRef = useRef(0);
  const initialXRef = useRef(0);
  const movementRef = useRef(0);

  const handleMouseMove = useCallback(
    (e) => {
      if (!chartRef.current) return;
      const chartRect = chartRef.current.getBoundingClientRect();
      const offsetX = e.clientX - chartRect.left + chartRef.current.scrollLeft;
      const deltaX = offsetX - initialXRef.current;
      const snappedDeltaX = Math.round(deltaX / dayWidth) * dayWidth;
      movementRef.current = snappedDeltaX;

      const newWidth = initialWidthRef.current + snappedDeltaX;
      setLocalWidth(Math.max(newWidth, dayWidth));

      // Update the hovered day indicator (optional)
      const daysMoved = movementRef.current / dayWidth;
      const newDate = dayjs(task.endDate).add(daysMoved, "day");
      handleDayHover(newDate);
    },
    [setLocalWidth, dayWidth, handleDayHover, task.endDate, chartRef]
  );

  const handleMouseUp = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      endDragging();
      disableTextSelection(false);

      // Use Math.round to snap only if the drag exceeds half a day:
      const daysChanged = Math.round(movementRef.current / dayWidth);
      if (daysChanged !== 0) {
        onResizeEnd(daysChanged);
      }

      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
      movementRef.current = 0;
      onDragEnd();
      setIsResizing(false);
    },
    [dayWidth, onResizeEnd, endDragging, disableTextSelection, handleMouseMove, onDragEnd, setIsResizing]
  );

  const handleMouseDown = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      startDragging();
      onDragStart();

      // Turn on the resizing indicator:
      setIsResizing(true);

      const chartRect = chartRef.current.getBoundingClientRect();
      const offsetX = e.clientX - chartRect.left + chartRef.current.scrollLeft;
      // Removed the -20 offset so that we work in the correct coordinate space.
      initialXRef.current = offsetX;
      initialWidthRef.current = currentWidth;
      disableTextSelection(true);

      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    },
    [startDragging, disableTextSelection, handleMouseMove, handleMouseUp, currentWidth, onDragStart, chartRef, setIsResizing]
  );

  return (
    <Tooltip label="Drag to adjust end date" hasArrow>
      <Box
        ref={handleRef}
        width="10px"
        height="40px"
        borderRadius="0 10px 10px 0"
        bg="gray.300"
        cursor="ew-resize"
        onMouseDown={handleMouseDown}
        aria-label="End Date Handle"
      />
    </Tooltip>
  );
};

// ------------------------------
// Updated MainBox Component (for moving the entire task bar)
// (No changes related to the border highlight here)
// ------------------------------
const MainBox = ({
  task,
  isSelected,
  onDrag,
  dayWidth,
  setLocalLeft,
  disableTextSelection,
  startDragging,
  endDragging,
  currentLeft,
  onDragStart,
  onDragEnd,
  handleDayHover,
  chartRef,
}) => {
  const handleRef = useRef(null);
  const initialLeftRef = useRef(0);
  const initialXRef = useRef(0);
  const movementRef = useRef(0);

  const handleMouseMove = useCallback(
    (e) => {
      if (!chartRef.current) {
        console.warn("chartRef.current is null in MainBox handleMouseMove");
        return;
      }
      const chartRect = chartRef.current.getBoundingClientRect();
      const offsetX = e.clientX - chartRect.left + chartRef.current.scrollLeft;
      // Adjust by adding 20 so that we measure against the true task.left:
      const adjustedOffset = offsetX + 20;
      const deltaX = adjustedOffset - initialXRef.current;
      const snappedDelta = Math.round(deltaX / dayWidth) * dayWidth;
      movementRef.current = snappedDelta;
      setLocalLeft(initialLeftRef.current + snappedDelta);

      const daysMoved = snappedDelta / dayWidth;
      const newDate = dayjs(task.startDate).add(daysMoved, "day");
      handleDayHover(newDate);
    },
    [setLocalLeft, dayWidth, handleDayHover, task.startDate, chartRef]
  );

  const handleMouseUp = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      endDragging();
      disableTextSelection(false);

      const daysMoved = movementRef.current / dayWidth;
      if (daysMoved !== 0) {
        onDrag(daysMoved);
      }

      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
      movementRef.current = 0;
      onDragEnd();
    },
    [dayWidth, onDrag, endDragging, disableTextSelection, handleMouseMove, onDragEnd]
  );

  const handleMouseDown = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      startDragging();
      onDragStart();

      const chartRect = chartRef.current.getBoundingClientRect();
      const offsetX = e.clientX - chartRect.left + chartRef.current.scrollLeft;
      // Adjust initial value by adding 20 so that we work in the same coordinate space as task.left:
      initialXRef.current = offsetX + 20;
      initialLeftRef.current = currentLeft;
      disableTextSelection(true);

      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    },
    [startDragging, disableTextSelection, handleMouseMove, handleMouseUp, currentLeft, onDragStart, chartRef]
  );

  return (
    <Tooltip label="Drag to move task" hasArrow>
      <Box
        ref={handleRef}
        flex="1"
        bg={isSelected ? "#bee3f8" : task.color || "white"}
        cursor="move"
        h="40px"
        onMouseDown={handleMouseDown}
        aria-label="Main Task Bar"
        position="relative"
      >
        <Text color={task.color ? (isColorLight(task.color) ? "black" : "white") : "inherit"} fontSize="sm" fontWeight="medium" isTruncated maxWidth="150px" p={2}>
          {task.name}
        </Text>
        <Box
          position="absolute"
          bottom="0"
          left="0"
          width={`${task.progress}%`}
          height="4px"
          bg="rgba(49, 130, 206, 0.5)"
          borderRadius="0 4px 4px 0"
        />
      </Box>
    </Tooltip>
  );
};

// ------------------------------
// Updated GanttTaskBar Component
// ------------------------------
const GanttTaskBar = ({ task, isSelected }) => {
  const {
    onDrag,
    onResizeStart,
    onResizeEnd,
    dayWidth,
    rowHeight,
    handleLinkStart,
    handleLinkEnd,
    onDragStart: contextOnDragStart,
    onDragEnd: contextOnDragEnd,
    handleDayHover,
    chartRef,
  } = useContext(GanttContext);

  const [localLeft, setLocalLeft] = useState(task.left);
  const [localWidth, setLocalWidth] = useState(task.width);
  const [isDragging, setIsDragging] = useState(false);
  // New state for tracking whether we’re resizing (using the end handle)
  const [isResizing, setIsResizing] = useState(false);

  const disableTextSelection = useCallback((disable) => {
    document.body.style.userSelect = disable ? "none" : "auto";
  }, []);

  const startDraggingInternal = useCallback(() => setIsDragging(true), []);
  const endDraggingInternal = useCallback(() => setIsDragging(false), []);

  useEffect(() => {
    if (!isDragging) {
      setLocalLeft(task.left);
      setLocalWidth(task.width);
    }
  }, [task.left, task.width, isDragging]);

  return (
    <MotionBox
      position="absolute"
      top={`${task.top}px`}
      left={`${localLeft - 20}px`}
      width={`${localWidth + 40}px`}
      height={`${rowHeight}px`}
      minWidth={`${dayWidth * 2}px`} // Ensure a minimum width
      display="flex"
      alignItems="center"
      zIndex={isSelected ? 2 : 1}
      style={{ transition: isDragging ? "none" : "left 0.2s, width 0.2s" }}
      pointerEvents="auto"
    >
      {/* Start Dependency Dot */}
      <StartDependency onLinkEnd={() => handleLinkEnd(task._id, "start")} />

      {/* Start Date Handle */}
      <StartDateHandle
        onResizeStart={(daysChanged) => onResizeStart(task._id, daysChanged)}
        dayWidth={dayWidth}
        setLocalLeft={setLocalLeft}
        setLocalWidth={setLocalWidth}
        disableTextSelection={disableTextSelection}
        startDragging={startDraggingInternal}
        endDragging={endDraggingInternal}
        currentLeft={localLeft}
        currentWidth={localWidth}
        onDragStart={contextOnDragStart}
        onDragEnd={contextOnDragEnd}
        handleDayHover={handleDayHover}
        task={task}
        chartRef={chartRef}
      />

      {/* Wrap MainBox in a relative container so we can overlay the right-border highlight */}
      <Box position="relative" flex="1">
        <MainBox
          task={{ ...task, progress: task.progress || 0 }}
          isSelected={isSelected}
          onDrag={(daysMoved) => onDrag(task._id, daysMoved)}
          dayWidth={dayWidth}
          setLocalLeft={setLocalLeft}
          disableTextSelection={disableTextSelection}
          startDragging={startDraggingInternal}
          endDragging={endDraggingInternal}
          currentLeft={localLeft}
          onDragStart={contextOnDragStart}
          onDragEnd={contextOnDragEnd}
          handleDayHover={handleDayHover}
          chartRef={chartRef}
        />
        {isResizing && (
          // This vertical box highlights the right border of the main task area
          <Box position="absolute" top="0" right="0" height="100%" width="3px" bg="primary.500" />
        )}
      </Box>

      {/* End Date Handle with resizing indicator support */}
      <EndDateHandle
        onResizeEnd={(daysChanged) => onResizeEnd(task._id, daysChanged)}
        dayWidth={dayWidth}
        setLocalWidth={setLocalWidth}
        disableTextSelection={disableTextSelection}
        startDragging={startDraggingInternal}
        endDragging={endDraggingInternal}
        currentWidth={localWidth}
        onDragStart={contextOnDragStart}
        onDragEnd={contextOnDragEnd}
        handleDayHover={handleDayHover}
        task={task}
        chartRef={chartRef}
        setIsResizing={setIsResizing} // pass our setter to toggle the indicator
      />

      {/* End Dependency Dot */}
      <EndDependency onLinkStart={() => handleLinkStart(task._id, "end")} />
    </MotionBox>
  );
};

export default GanttTaskBar;
