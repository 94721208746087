// src/components/ProjectsTasksGanttChart.js

import React, { useContext, useEffect, useState, useCallback } from "react";
import { Box, Spinner, Text } from "@chakra-ui/react";
import { ProjectContext } from "../5 - General/Context/ProjectsContext";
import "@fontsource-variable/inter";
import GanttChart from "./Reusable/GanttChart";
import EditTaskNameModal from "../3 - Modal/3 - Projects Modals/ProjectsEditTaskModal";

const ProjectsTasksGanttChart = ({ onTaskClick }) => {
  // -----------------------------
  // 1) State / Context
  // -----------------------------
  const { currentProjectId, projects, tasks, subtasks, editTask, loadingTasks } = useContext(ProjectContext);

  const [ganttTasks, setGanttTasks] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [taskToEdit, setTaskToEdit] = useState(null);

  const currentProject = projects.find((project) => project._id === currentProjectId);

  // -----------------------------
  // 2) (No specific color variables needed here)
  // -----------------------------

  // -----------------------------
  // 3) Transform & Effects
  // -----------------------------
  const transformTasks = useCallback(() => {
    return (tasks || []).map((task) => ({
      _id: task._id.toString(),
      name: task.name,
      startDate: task.startDate,
      endDate: task.endDate,
      progress: task.progress || 0,
      dependencies: (task.dependencies || []).map((dep) => (typeof dep === "string" ? dep : dep._id.toString())),
      color: task.color || "#3182CE",
      subtasks: task.subtasks || [],
      projectId: task.projectId,
    }));
  }, [tasks]);

  useEffect(() => {
    setGanttTasks(transformTasks());
  }, [transformTasks]);

  // Handle updates
  const handleTaskUpdate = async (taskId, updatedFields) => {
    try {
      const updatedTask = await editTask(taskId, updatedFields);
      return updatedTask;
    } catch (error) {
      console.error("Error updating task:", error);
      throw error;
    }
  };

  // Double click => Edit
  const handleDoubleClick = (task) => {
    setTaskToEdit(task);
    setIsEditModalOpen(true);
  };

  // Single click => Select
  const handleTaskSelect = (taskId) => {
    onTaskClick(taskId);
  };

  // Delete tasks
  const handleTasksDelete = (taskIds) => {
    console.log("Deleting tasks:", taskIds);
  };

  // -----------------------------
  // 4) JSX
  // -----------------------------
  return (
    <Box>
      {loadingTasks ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="600px">
          <Spinner size="xl" />
        </Box>
      ) : currentProject ? (
        <Box mt={8}>
          <GanttChart
            projects={currentProject ? [currentProject] : []}
            tasks={ganttTasks}
            subtasks={subtasks}
            onTaskDoubleClick={handleDoubleClick}
            onTaskSelect={handleTaskSelect}
            onTasksDelete={handleTasksDelete}
            onTaskUpdate={handleTaskUpdate}
            height={600}
          />
        </Box>
      ) : (
        <Box p={4} textAlign="center">
          <Text>No project selected to display tasks.</Text>
        </Box>
      )}

      {/* Edit Task Modal */}
      {taskToEdit && (
        <EditTaskNameModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          task={taskToEdit}
          onSave={(updatedTask) => {
            editTask(updatedTask._id, {
              name: updatedTask.name,
              __v: updatedTask.__v,
            });
            setIsEditModalOpen(false);
          }}
        />
      )}
    </Box>
  );
};

export default ProjectsTasksGanttChart;
