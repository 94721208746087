// client/src/components/Reusable/ProjectEditableName.js
import React, { useContext, useCallback } from "react";
import {
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  IconButton,
  Input,
  ButtonGroup,
  Tooltip,
  useEditableControls,
  useColorModeValue,
} from "@chakra-ui/react";
import { PiCheckBold, PiXBold } from "react-icons/pi";
import { ProjectContext } from "../../5 - General/Context/ProjectsContext";

const ProjectEditableName = () => {
  const { currentProjectId, projects, editProject } = useContext(ProjectContext);
  const project = projects.find((p) => p._id === currentProjectId);
  // Define a hover background color that adapts to light/dark mode.
  const previewHoverBg = useColorModeValue("gray.100", "gray.600");

  // Callback to handle the project name change.
  const handleProjectChange = useCallback(
    (nextValue) => {
      if (nextValue && project) {
        editProject(project._id, { name: nextValue });
      }
    },
    [project, editProject]
  );

  // Custom editable controls.
  function EditableControls() {
    const { isEditing, getSubmitButtonProps, getCancelButtonProps } = useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="end" size="sm" spacing={2}>
        <IconButton icon={<PiCheckBold fontSize="18px" />} {...getSubmitButtonProps()} aria-label="Submit" />
        <IconButton icon={<PiXBold fontSize="18px" />} {...getCancelButtonProps()} aria-label="Cancel" />
      </ButtonGroup>
    ) : null;
  }

  if (!project) {
    return null;
  }

  return (
    <Editable defaultValue={project.name} isPreviewFocusable={true} selectAllOnFocus={false} onSubmit={handleProjectChange}>
      <Tooltip label="Click to edit project name" shouldWrapChildren={true}>
        <Flex align="center" direction={["column", "row"]} w="100%">
          <EditablePreview
            py={2}
            px={4}
            borderRadius="xl"
            fontSize={["2xl", "3xl"]}
            fontWeight="700"
            _hover={{ background: previewHoverBg }}
            flex="1"
          />
          <Flex h="fit-content" align="center" mt={["2", 0]}>
            <Input py={2} px={4} mr={2} as={EditableInput} fontSize={["2xl", "3xl"]} fontWeight="700" />
            <EditableControls />
          </Flex>
        </Flex>
      </Tooltip>
    </Editable>
  );
};

export default ProjectEditableName;
