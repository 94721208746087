// client/src/components/4 - API/API-Projects.js

import { fetchData, APIError } from "./FetchData";
const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;

/**
 * Fetch all projects for a company
 * GET /api/v1/projects/:companyId/projects
 */
export const getProjectIds = async (companyId) => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/${companyId}/projects`);
  return data.projectIds;
};

/**
 * Fetch archived projects for a company
 * GET /api/v1/projects/:companyId/projects/archived
 */
export const fetchArchivedProjects = async (companyId) => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/${companyId}/projects/archived`);
  return data.archivedProjectIds;
};

/**
 * Fetch a specific project by ID
 * GET /api/v1/projects/:companyId/projects/:projectId
 */
export const getProjectById = async (companyId, projectId) => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/${companyId}/projects/${projectId}`);
  return data.project;
};

/**
 * Fetch a specific task by ID
 * GET /api/v1/projects/:projectId/tasks/:taskId
 */
export const getTaskById = async (projectId, taskId) => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/${projectId}/tasks/${taskId}`);
  return data.task;
};

/**
 * Fetch all available users and their companies for a specific user
 * GET /api/v1/projects/:userId/get-options-for-user
 */
export const getAvailableUserAndCompanyOptions = async (userId) => {
  if (!userId) throw new Error("User ID is required to fetch available options.");
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/${userId}/get-options-for-user`);
  return data; // Ensure that backend sends the array directly
};

/**
 * Upload multiple files to a specific task under a project
 * POST /api/v1/projects/:projectId/tasks/:taskId/upload
 */
export const uploadFilesToTask = async (projectId, taskId, files) => {
  const formData = new FormData();
  files.forEach((files) => {
    formData.append("files", files);
  });

  const response = await fetch(`${API_BASE_URL}/api/v1/projects/${projectId}/tasks/${taskId}/upload`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: formData,
  });

  if (!response.ok) {
    throw new Error("Error uploading files");
  }

  const data = await response.json();
  return data.files; // Adjust based on your actual backend response structure
};

/**
 * Delete a file from a task
 * DELETE /api/v1/projects/:projectId/tasks/:taskId/file/:fileId
 */
export const deleteTaskFile = async (projectId, taskId, fileId) => {
  const url = `${API_BASE_URL}/api/v1/projects/${projectId}/tasks/${taskId}/file/${fileId}`;
  return fetchData(url, "DELETE", null, true);
};

/**
 * Get a presigned URL to download a file from a task
 * GET /api/v1/projects/:projectId/tasks/:taskId/download/:filename
 */
export const getTaskFileDownloadLink = async (projectId, taskId, backendName) => {
  const fullUrl = `${API_BASE_URL}/api/v1/projects/${projectId}/tasks/${taskId}/download/${backendName}`;

  const response = await fetch(fullUrl, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (!response.ok) {
    const errorData = await response.json().catch(() => null);
    const errorMessage = errorData?.message || "Failed to get download link.";
    throw new APIError(errorMessage, response.status, errorData);
  }

  const data = await response.json(); // Expect { status, downloadUrl, filename }
  if (data.status !== "ok") {
    throw new APIError(data.message || "Failed to get download link.", response.status, data);
  }
  return data; // { downloadUrl, filename }
};

/**
 * Trigger a blob download with a given filename
 */
export const downloadBlob = (blob, filename) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};
