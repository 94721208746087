// client/src/components/2 - Components/Reusable/ModularTable.js

import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  VStack,
  Box,
  Text,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
  Button,
  useColorModeValue,
  Spinner,
  useBreakpointValue,
  ButtonGroup,
} from "@chakra-ui/react";
import { PiCaretDownBold } from "react-icons/pi";
import { motion, AnimatePresence } from "framer-motion";

const MotionTr = motion(Tr);

const ModularTable = ({
  columns,
  data,
  actionColumn,
  headerStyles = {},
  rowStyles = {},
  cellStyles = {},
  headerCellStyles = {},
  currentPage,
  totalPages,
  maxPerPage,
  totalItems,
  onPageChange,
  searchValue,
  onSearchChange,
  searchColumns,
  allowSearch = true,
  onSearchColumnsChange,
  maxHeight,
  isLoading,
  isError,
  error,
}) => {
  const colors = {
    borderColor: useColorModeValue("gray.200", "gray.600"),
    bgColor: useColorModeValue("white", "gray.800"),
    headerBgColor: useColorModeValue("gray.50", "gray.900"),
    rowHoverBgColor: useColorModeValue("gray.100", "gray.700"),
    textColor: useColorModeValue("gray.700", "gray.100"),
    inputBgColor: useColorModeValue("white", "gray.800"),
    inputPlaceholderColor: useColorModeValue("gray.500", "gray.500"),
    paginationButtonBg: useColorModeValue("white", "gray.800"),
    paginationButtonHoverBg: useColorModeValue("gray.100", "gray.700"),
    noDataTextColor: useColorModeValue("gray.500", "gray.400"),
    errorTextColor: useColorModeValue("red.500", "red.300"),
    spinnerColor: useColorModeValue("gray.500", "gray.300"),
    menuButtonHoverBg: useColorModeValue("gray.100", "gray.700"),
    menuListBgColor: useColorModeValue("white", "gray.800"),
    menuItemHoverBg: useColorModeValue("gray.100", "gray.700"),
  };

  const isMobile = useBreakpointValue({ base: true, md: false });
  const searchInputWidth = useBreakpointValue({ base: "100%", md: "250px" });
  const controlsFlexDirection = useBreakpointValue({ base: "column", md: "row" });
  const paginationFlexDirection = useBreakpointValue({ base: "column", md: "row" });
  const paginationAlign = useBreakpointValue({ base: "stretch", md: "flex-end" });
  const paginationButtonSize = useBreakpointValue({ base: "sm", md: "sm" });

  const initialVisibleColumns = React.useMemo(
    () =>
      columns.reduce((acc, col) => {
        acc[col.accessor] = col.defaultVisible !== false;
        return acc;
      }, {}),
    [columns]
  );

  const [visibleColumns, setVisibleColumns] = React.useState(initialVisibleColumns);

  const handleColumnChange = (column) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };

  return (
    <Box
      border="1px solid"
      borderColor={colors.borderColor}
      borderRadius="md"
      p={4}
      bg={colors.bgColor}
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      maxH={maxHeight || "100%"}
    >
      <VStack spacing={4} align="stretch" mb={4} flexDirection={controlsFlexDirection}>
        {allowSearch && (
          <HStack spacing={4} width="100%" flexDirection={isMobile ? "column" : "row"}>
            <Input
              placeholder="Search..."
              value={searchValue}
              onChange={(e) => onSearchChange(e.target.value)}
              width={searchInputWidth}
              bg={colors.inputBgColor}
              color={colors.textColor}
              _placeholder={{ color: colors.inputPlaceholderColor }}
            />
            {!isMobile && (
              <HStack spacing={4}>
                <Menu closeOnSelect={false}>
                  <MenuButton as={Button} rightIcon={<PiCaretDownBold fontSize="18px" />} _hover={{ bg: colors.menuButtonHoverBg }}>
                    Search Columns
                  </MenuButton>
                  <MenuList bg={colors.menuListBgColor}>
                    <MenuOptionGroup title="Search by :" type="checkbox" value={searchColumns} onChange={(values) => onSearchColumnsChange(values)}>
                      {columns.map((col) => (
                        <MenuItemOption key={col.accessor} fontSize="md" value={col.accessor} _hover={{ bg: colors.menuItemHoverBg }}>
                          {col.label}
                        </MenuItemOption>
                      ))}
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
                <Menu closeOnSelect={false}>
                  <MenuButton as={Button} rightIcon={<PiCaretDownBold fontSize="18px" />} _hover={{ bg: colors.menuButtonHoverBg }}>
                    Hide Columns
                  </MenuButton>
                  <MenuList bg={colors.menuListBgColor}>
                    <MenuOptionGroup title="Hide Columns" type="checkbox">
                      {columns.map((col) => (
                        <MenuItemOption
                          key={col.accessor}
                          fontSize="md"
                          value={col.accessor}
                          isChecked={visibleColumns[col.accessor]}
                          onClick={() => handleColumnChange(col.accessor)}
                          _hover={{ bg: colors.menuItemHoverBg }}
                        >
                          {col.label}
                        </MenuItemOption>
                      ))}
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              </HStack>
            )}
          </HStack>
        )}
      </VStack>

      <Box flex="1" overflowX="auto" overflowY="scroll">
        <Table variant="simple" width="100%" size="sm">
          <Thead position="sticky" top={0} bg={colors.headerBgColor} zIndex={1} {...headerStyles}>
            <Tr>
              {columns.map(
                (col) =>
                  visibleColumns[col.accessor] && (
                    <Th key={col.accessor} color={colors.textColor} {...headerCellStyles}>
                      <HStack justify="space-between">
                        <Text fontSize={isMobile ? "sm" : "md"}>{col.label}</Text>
                      </HStack>
                    </Th>
                  )
              )}
              {actionColumn && (
                <Th textAlign="right" color={colors.textColor} {...headerCellStyles}>
                  {actionColumn.label || "Actions"}
                </Th>
              )}
            </Tr>
          </Thead>
          <Tbody>
            {isLoading ? (
              <Tr>
                <Td bg={colors.bgColor} colSpan={columns.length + (actionColumn ? 1 : 0)} rowSpan={maxPerPage}>
                  <Box display="flex" justifyContent="center" alignItems="center" py={4}>
                    <Spinner color={colors.spinnerColor} />
                  </Box>
                </Td>
              </Tr>
            ) : isError ? (
              <Tr>
                <Td bg={colors.bgColor} colSpan={columns.length + (actionColumn ? 1 : 0)} rowSpan={maxPerPage}>
                  <Text textAlign="center" color={colors.errorTextColor}>
                    {error || "An error occurred."}
                  </Text>
                </Td>
              </Tr>
            ) : data && data.length > 0 ? (
              <AnimatePresence>
                {data.map((row) => (
                  <MotionTr
                    key={row._id || row.id || `${row.firstName}-${row.lastName}-${row.email}`}
                    _hover={{ bg: colors.rowHoverBgColor }}
                    cursor="pointer"
                    {...rowStyles}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.3 }}
                    layout
                  >
                    {columns.map(
                      (col) =>
                        visibleColumns[col.accessor] && (
                          <Td key={col.accessor} color={colors.textColor} {...cellStyles}>
                            <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" fontSize={isMobile ? "sm" : "md"}>
                              {col.render ? col.render(row[col.accessor], row) : row[col.accessor]}
                            </Text>
                          </Td>
                        )
                    )}
                    {actionColumn && (
                      <Td textAlign="right" {...cellStyles}>
                        {actionColumn.render(row)}
                      </Td>
                    )}
                  </MotionTr>
                ))}
              </AnimatePresence>
            ) : (
              <Tr>
                <Td colSpan={columns.length + (actionColumn ? 1 : 0)} bg={colors.bgColor} height="100px">
                  <Text textAlign="center" color={colors.noDataTextColor}>
                    No data available.
                  </Text>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>

      {typeof currentPage === "number" && typeof totalItems === "number" && typeof totalPages === "number" && typeof maxPerPage === "number" && (
        <VStack spacing={4} align="stretch" mt={4} flexDirection={paginationFlexDirection}>
          <Text w="30%" fontSize={isMobile ? "sm" : "md"}>
            Showing {totalItems === 0 ? 0 : (currentPage - 1) * maxPerPage + 1} - {Math.min(currentPage * maxPerPage, totalItems)} of {totalItems}{" "}
            items
          </Text>
          <HStack spacing={2} justify={paginationAlign} width="100%" flexDirection={isMobile ? "column" : "row"}>
            <ButtonGroup isAttached>
              <Button
                size={paginationButtonSize}
                variant="outline"
                onClick={() => onPageChange(currentPage - 1)}
                isDisabled={currentPage === 1 || totalPages === undefined}
                bg={colors.paginationButtonBg}
                _hover={{ bg: colors.paginationButtonHoverBg }}
                width="auto"
              >
                Previous
              </Button>
              <Button bg={colors.paginationButtonBg} variant="outline" _hover={{ bg: "transparent" }} cursor="default" size={paginationButtonSize}>
                Page {currentPage} of {totalPages}
              </Button>
              <Button
                size={paginationButtonSize}
                variant="outline"
                onClick={() => onPageChange(currentPage + 1)}
                isDisabled={currentPage === totalPages}
                bg={colors.paginationButtonBg}
                _hover={{ bg: colors.paginationButtonHoverBg }}
                width="auto"
              >
                Next
              </Button>
            </ButtonGroup>
          </HStack>
        </VStack>
      )}
    </Box>
  );
};

export default React.memo(ModularTable);
