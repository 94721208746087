import React from "react";
import {
  Box,
  Heading,
  Text,
  Center,
  Icon,
  Button,
  SimpleGrid,
  Divider,
  useColorModeValue,
} from "@chakra-ui/react";
import { PiSignature } from "react-icons/pi";

const Contracts = () => {
  // -----------------------------
  // 1) (No local state)
  // -----------------------------

  // -----------------------------
  // 2) Color definitions
  // -----------------------------
  const containerBg = useColorModeValue("gray.50", "gray.800");
  const headingColor = useColorModeValue("primary.700", "primary.500");
  const iconColor = useColorModeValue("primary.500", "primary.500");
  const textColor = useColorModeValue("gray.600", "gray.200");
  const subHeadingColor = useColorModeValue("primary.600", "primary.500");
  const dividerColor = useColorModeValue("gray.200", "gray.600");

  // -----------------------------
  // 3) JSX
  // -----------------------------
  return (
    <Box
      bg={containerBg}
      w="100%"
      minH="100vh"
      p={5}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Center w="100%" flexDirection="column">
        <Icon as={PiSignature} boxSize={20} color={iconColor} mb={4} />
        <Heading as="h1" size="2xl" color={headingColor} textAlign="center">
          Contracts Module Coming Soon
        </Heading>
        <Text
          fontSize="lg"
          color={textColor}
          textAlign="center"
          maxW="800px"
          mt={4}
        >
          Our new Contracts Module is on its way! Soon, you&apos;ll be able to
          manage all your contracts and agreements directly within your client
          portal.
        </Text>
        <Button colorScheme="primary" size="lg" mt={6} disabled>
          Notify Me When Available
        </Button>
      </Center>

      <Divider my={10} borderColor={dividerColor} />

      {/* Feature Highlights */}
      <Box maxW="800px" mx="auto">
        <Heading
          as="h2"
          size="lg"
          color={subHeadingColor}
          mb={4}
          textAlign="center"
        >
          Upcoming Features
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
          <Box>
            <Heading as="h3" size="md" mb={2} color={textColor}>
              ✍️ E-Signatures
            </Heading>
            <Text color={textColor}>
              Sign contracts electronically with legally binding e-signatures,
              streamlining the agreement process.
            </Text>
          </Box>
          <Box>
            <Heading as="h3" size="md" mb={2} color={textColor}>
              📄 Template Library
            </Heading>
            <Text color={textColor}>
              Access a library of customizable contract templates to save time
              and maintain consistency.
            </Text>
          </Box>
          <Box>
            <Heading as="h3" size="md" mb={2} color={textColor}>
              🔒 Secure Storage
            </Heading>
            <Text color={textColor}>
              Store all your contracts securely with encrypted storage and
              role-based access controls.
            </Text>
          </Box>
          <Box>
            <Heading as="h3" size="md" mb={2} color={textColor}>
              🛎️ Renewal Reminders
            </Heading>
            <Text color={textColor}>
              Receive automatic reminders for contract renewals, expirations,
              or required actions.
            </Text>
          </Box>
        </SimpleGrid>
      </Box>
      <Box w="10px" h="100px" />
    </Box>
  );
};

export default Contracts;
