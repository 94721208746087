// TableConfigTeamSettings.js

import React from "react";
import {
  Button,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { PiCaretDownBold, PiTrash } from "react-icons/pi";
import { formatReadableDate, formatTimestamp } from "../../5 - General/Utils/UtilsFormatData";
import UserAvatar from "./UserAvatar";
import { Select } from "chakra-react-select";

// New component to customize the Select component for dark mode.
const UserRoleSelect = ({ user, selectedRole, isDisabled, handleChangeRole }) => {
  const selectTextColor = useColorModeValue("black", "white");
  const selectBgColor = useColorModeValue("white", "gray.900");
  const selectHoverBgColor = useColorModeValue("gray.100", "gray.600");
  const selectLightTextColor = useColorModeValue("gray.500", "gray.500");
  const selectDropdownIndicatorBgColor = useColorModeValue("gray.00", "gray.600");
  const selectMenuBgColor = useColorModeValue("white", "gray.700");

  const chakraStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : selectTextColor,
      backgroundColor: state.isSelected ? `primary.500` : selectMenuBgColor,
      "&:hover": { backgroundColor: state.isSelected ? `primary.500` : selectHoverBgColor },
      fontSize: "xs",
    }),
    container: (provided) => ({
      ...provided,
      backgroundColor: selectBgColor,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: selectTextColor,
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: selectMenuBgColor,
      zIndex: 9999,
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: selectTextColor,
      backgroundColor: selectDropdownIndicatorBgColor,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: selectLightTextColor,
      fontSize: "md",
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: selectLightTextColor,
      backgroundColor: selectMenuBgColor,
    }),
    multiValue: (provided) => ({
      ...provided,
      color: selectTextColor,
      borderRadius: "full",
      fontSize: "sm",
      margin: "2px",
    }),
  };

  const roleOptions = [
    { value: "Admin", label: "Admin" },
    { value: "Editor", label: "Editor" },
    { value: "Customer", label: "Customer" },
  ];

  return (
    <Select
      size="sm"
      value={selectedRole}
      onChange={(option) => handleChangeRole(user._id, option.value)}
      options={roleOptions}
      placeholder="Select role"
      isClearable={false}
      isSearchable={false}
      isDisabled={isDisabled}
      menuPortalTarget={document.body}
      chakraStyles={chakraStyles}
    />
  );
};

// Extracted component for user details that adjusts colors based on color mode.
const UserDetailsCell = ({ user, fontSize = "md" }) => {
  const textColor = useColorModeValue("gray.700", "gray.200");
  const textLightColor = useColorModeValue("gray.500", "gray.400");

  return (
    <HStack spacing={4}>
      <UserAvatar isOnClickActive userIds={[user.id || user._id]} size="sm" showInfo={false} />
      <div>
        <Text fontWeight="600" fontSize={fontSize} color={textColor}>
          {user.firstName} {user.lastName}
        </Text>
        <Text fontSize="xs" color={textLightColor}>
          {user.email}
        </Text>
      </div>
    </HStack>
  );
};

const teamSettingsColumns = ({ handleChangeRole, currentUserRole, canModifyUser, currentUserId, teamMembers }) => {
  const desktopColumns = [
    {
      accessor: "userDetails",
      label: "User Details",
      render: (value, user) => <UserDetailsCell user={user} fontSize="md" />,
      defaultVisible: true,
    },
    {
      accessor: "createdAt",
      label: "Date Joined",
      render: (value) => (
        <Button size="sm" variant="ghost" _hover={{ bg: null }}>
          {formatReadableDate(value)}
        </Button>
      ),
      defaultVisible: true,
    },
    {
      accessor: "role",
      label: "User Role",
      render: (value, user) => {
        if (currentUserRole === "Customer") {
          return (
            <Button size="xs" variant="outline" _hover={{ bg: null }}>
              {user.role}
            </Button>
          );
        }
        const selectedRole = { value: user.role, label: user.role };
        const isDisabled = !canModifyUser(currentUserRole, user.role, currentUserId, user._id, teamMembers);

        return isDisabled ? (
          <Button size="xs" variant="outline" _hover={{ bg: null }}>
            {user.role}
          </Button>
        ) : (
          <UserRoleSelect user={user} selectedRole={selectedRole} isDisabled={isDisabled} handleChangeRole={handleChangeRole} />
        );
      },
      defaultVisible: true,
    },
    {
      accessor: "moreInfoMenu",
      label: "More Info",
      render: (value, user) => {
        return (
          <Menu>
            <MenuButton as={Button} variant="outline" aria-label="User Info" rightIcon={<PiCaretDownBold />} size="xs">
              See more
            </MenuButton>
            <MenuList>
              <MenuItem _hover={{ bg: "transparent" }} _focus={{ bg: "transparent" }}>
                <Grid templateColumns="100px 1fr" gap={2}>
                  <GridItem alignContent="center">
                    <Text fontSize="md">Last Login:</Text>
                  </GridItem>
                  <GridItem>
                    <Tag size="xs" fontSize="sm" colorScheme={user.lastLoggedIn ? "gray" : "red"}>
                      {user.lastLoggedIn ? formatTimestamp(user.lastLoggedIn) : "Never"}
                    </Tag>
                  </GridItem>
                </Grid>
              </MenuItem>
              <MenuItem _hover={{ bg: "transparent" }} _focus={{ bg: "transparent" }}>
                <Grid templateColumns="100px 1fr" gap={2}>
                  <GridItem alignContent="center">
                    <Text fontSize="md">Status:</Text>
                  </GridItem>
                  <GridItem>
                    <Tag fontSize="sm" size="xs" colorScheme={user.status === "Deactivated" ? "red" : "green"}>
                      {user.status === "Deactivated" ? "Deactivated" : "Active"}
                    </Tag>
                  </GridItem>
                </Grid>
              </MenuItem>
              <MenuItem _hover={{ bg: "transparent" }} _focus={{ bg: "transparent" }}>
                <Grid templateColumns="100px 1fr" gap={2}>
                  <GridItem alignContent="center">
                    <Text fontSize="md">Email:</Text>
                  </GridItem>
                  <GridItem>
                    <Tag fontSize="sm" size="xs" colorScheme={user.isVerified ? "green" : "red"}>
                      {user.isVerified ? "Verified" : "Not Verified"}
                    </Tag>
                  </GridItem>
                </Grid>
              </MenuItem>
              <MenuItem _hover={{ bg: "transparent" }} _focus={{ bg: "transparent" }}>
                <Grid templateColumns="100px 1fr" gap={2}>
                  <GridItem alignContent="center">
                    <Text fontSize="md">Profile:</Text>
                  </GridItem>
                  <GridItem>
                    <Tag fontSize="sm" size="xs" colorScheme={user.profileCompleted ? "green" : "red"}>
                      {user.profileCompleted ? "Complete" : "Incomplete Profile"}
                    </Tag>
                  </GridItem>
                </Grid>
              </MenuItem>
            </MenuList>
          </Menu>
        );
      },
      defaultVisible: true,
    },
  ];

  const mobileColumns = [
    {
      accessor: "userDetails",
      label: "User Details",
      render: (value, user) => <UserDetailsCell user={user} fontSize="sm" />,
      defaultVisible: true,
    },
  ];

  return { desktopColumns, mobileColumns };
};

const actionColumn = ({
  handleModifyRights,
  handleDeactivateUser,
  handleResendInvitation,
  handleSendPasswordReset,
  handleActivateUser,
  handleDeleteUser,
  currentUserRole,
  canModifyUser,
  isMobile,
  currentUserId,
  teamMembers,
  onOpenModal,
}) => {
  if (isMobile) {
    return {
      label: "Actions",
      render: (user) => (
        <HStack w="100%" justify="end" spacing={2}>
          <Button size="xs" variant="outline" onClick={() => onOpenModal(user)}>
            Actions
          </Button>
        </HStack>
      ),
    };
  } else {
    return {
      label: "Actions",
      render: (user) => {
        const isDisabled = !canModifyUser(currentUserRole, user.role, currentUserId, user._id, teamMembers);
        const isDisabledForAdmins = user.role === "Admin";
        return (
          <HStack w="100%" justify="end" spacing={2}>
            {!user.isVerified && (
              <Tooltip label="User must verify email to access the platform. This will resend the invitation email to the user.">
                <Button
                  size="xs"
                  variant="outline"
                  colorScheme="blue"
                  onClick={() => handleResendInvitation(user)}
                  aria-label={`Resend invitation to ${user.firstName}`}
                  isDisabled={isDisabled}
                >
                  Resend Invitation
                </Button>
              </Tooltip>
            )}
            {user.isVerified && (
              <Button
                size="xs"
                variant="outline"
                colorScheme="gray"
                onClick={() => handleSendPasswordReset(user)}
                aria-label={`Send password reset to ${user.firstName}`}
                isDisabled={isDisabled}
              >
                Send Password Reset
              </Button>
            )}
            <Tooltip label="Modify this user's rights to individual modules">
              <Button
                size="xs"
                variant="outline"
                onClick={() => handleModifyRights(user)}
                aria-label={`Modify rights for ${user.firstName}`}
                isDisabled={isDisabled}
              >
                Rights
              </Button>
            </Tooltip>
            {user.status !== "Deactivated" ? (
              <Tooltip label="Suspend user account">
                <Button
                  size="xs"
                  colorScheme="gray"
                  variant="outline"
                  onClick={() => handleDeactivateUser(user)}
                  aria-label={`Deactivate ${user.firstName}`}
                  isDisabled={isDisabled || isDisabledForAdmins}
                >
                  Suspend
                </Button>
              </Tooltip>
            ) : (
              <Tooltip label="Reactivate User Account">
                <Button
                  size="xs"
                  variant="outline"
                  colorScheme="green"
                  onClick={() => handleActivateUser(user)}
                  aria-label={`Reactivate ${user.firstName}`}
                  isDisabled={isDisabled}
                >
                  Reactivate
                </Button>
              </Tooltip>
            )}
            <Tooltip label={user.status === "Deactivated" ? "Delete user account" : "User account must be suspended to delete"}>
              <IconButton
                icon={<PiTrash fontSize="20px" />}
                size="xs"
                variant="outline"
                colorScheme="red"
                onClick={() => handleDeleteUser(user)}
                aria-label={`Delete ${user.firstName}`}
                isDisabled={isDisabled || user.status !== "Deactivated"}
              />
            </Tooltip>
          </HStack>
        );
      },
    };
  }
};

export const tableConfigs = {
  teamSettings: ({
    handleModifyRights,
    handleChangeRole,
    handleDeactivateUser,
    handleResendInvitation,
    handleSendPasswordReset,
    handleActivateUser,
    handleDeleteUser,
    currentUserRole,
    canModifyUser,
    isMobile,
    handleOpenModal,
    currentUserId,
    teamMembers,
  }) => {
    const { desktopColumns, mobileColumns } = teamSettingsColumns({
      handleChangeRole,
      currentUserRole,
      canModifyUser,
      currentUserId,
      teamMembers,
    });

    return {
      desktopColumns,
      mobileColumns,
      actionColumn: actionColumn({
        handleModifyRights,
        handleChangeRole,
        handleDeactivateUser,
        handleResendInvitation,
        handleSendPasswordReset,
        handleActivateUser,
        handleDeleteUser,
        currentUserRole,
        canModifyUser,
        isMobile,
        onOpenModal: handleOpenModal,
        currentUserId,
        teamMembers,
      }),
      headerStyles: {
        h: "50px",
      },
      headerCellStyles: {
        fontWeight: "bold",
        fontSize: "14px",
        textTransform: "capitalize",
        borderBottom: "1px solid",
        transition: "background-color 0.2s",
      },
      rowStyles: {
        transition: "background-color 0.2s",
      },
      cellStyles: {
        fontSize: "14px",
      },
    };
  },
};

export default tableConfigs;
