// src/components/3 - Modal/2 - Messages Modals/MessagesViewUsersInChannelModal.js

import React, { useState, useContext } from "react";
import {
  Box,
  Flex,
  Grid,
  Input,
  useColorModeValue,
  Button,
  IconButton,
} from "@chakra-ui/react";
import { PiTrash } from "react-icons/pi";
import UserAvatar from "../../2 - Components/Reusable/UserAvatar";
import { MessagesContext } from "../../5 - General/Context/MessagesContext";
import ReusableModal from "../../3 - Modal/9 - General Modals/ReusableModal";
import ConfirmActionModal from "../../3 - Modal/9 - General Modals/ConfirmActionModal";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";

const ViewUsersInChannelModal = ({ isOpen, onClose }) => {
  const { channels, currentChannelId, handleRemoveUserFromChannel } = useContext(MessagesContext);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const currentChannel = channels.find((channel) => channel._id === currentChannelId);

  const channelUsers = currentChannel.members;
  const filteredUsers = channelUsers.filter(
    (user) =>
      `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const borderColor = useColorModeValue("gray.200", "gray.750");
  const userCardBg = useColorModeValue("white", "gray.700");
  const customToast = useCustomToast();

  const handleRemoveUserClick = (userId) => {
    if (channelUsers.length <= 3) {
      customToast({
        status: "warning",
        title: "Cannot Remove User",
        description: "A channel must have at least 3 users. Delete the channel or add more users.",
      });
    } else {
      setSelectedUser(userId);
    }
  };

  const handleConfirmRemove = () => {
    handleRemoveUserFromChannel(selectedUser);
    setSelectedUser(null);
  };

  return (
    <>
      <ReusableModal
        isOpen={isOpen}
        onClose={onClose}
        title="Users in the Conversation"
        footerButtons={
          <Button ml="auto" onClick={onClose}>
            Close
          </Button>
        }
        size="lg"
        isCentered
        scrollBehavior="inside"
      >
        <Box pr={8} pl={4} pt={4} justifyContent="start">
          <Input
            placeholder="Search users or companies..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            mb={4}
          />
        </Box>
        <Grid
          templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
          gap={2}
          sx={{
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: useColorModeValue("gray.300", "gray.600"),
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: useColorModeValue("gray.400", "gray.700"),
            },
          }}
        >
          {filteredUsers.map((user) => (
            <Flex
              key={user._id}
              alignItems="center"
              justify="space-between"
              p={2}
              borderRadius="md"
              borderWidth="1px"
              borderColor={borderColor}
              minWidth="300px"
              bg={userCardBg}
            >
              <UserAvatar userIds={[user._id]} mr={3} size="sm" />
              <IconButton
                aria-label="Remove User"
                icon={<PiTrash />}
                onClick={() => handleRemoveUserClick(user._id)}
                variant="ghost"
              />
            </Flex>
          ))}
        </Grid>
      </ReusableModal>

      <ConfirmActionModal
        isOpen={Boolean(selectedUser)}
        onClose={() => setSelectedUser(null)}
        onConfirm={handleConfirmRemove}
        title="Confirm Removal from Channel"
        description="Are you sure you want to remove this user from the channel?"
        confirmText="Remove"
        cancelText="Cancel"
        isDestructive
      />
    </>
  );
};

export default ViewUsersInChannelModal;
