// client/src/components/3 - Modal/3 - Projects Modals/ProjectsSubtaskDetailDrawer.js

import React, { useState, useContext, useEffect } from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Box,
  Text,
  useColorModeValue,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  VStack,
  Editable,
  EditablePreview,
  EditableInput,
  Divider,
  Grid,
  GridItem,
  HStack,
  Button,
  Badge,
} from "@chakra-ui/react";
import { PiArticle, PiPlus, PiChats, PiFolders, PiClockUser } from "react-icons/pi";
import UserAvatar from "../../2 - Components/Reusable/UserAvatar";
import { ProjectContext } from "../../5 - General/Context/ProjectsContext";
import CommentsSection from "../../2 - Components/ProjectsViewTaskView/ProjectsTaskViewTabsComments";
import TimeLogsSection from "../../2 - Components/ProjectsViewTaskView/ProjectsTaskViewTabsTimeLogs";
import FilesSection from "../../2 - Components/ProjectsViewTaskView/ProjectsTaskViewTabsFiles";
import UtilsDatePicker from "../../5 - General/Utils/UtilsDatePicker";
import TagMenu from "../../2 - Components/Reusable/TagMenu";
import DescriptionSection from "../../2 - Components/ProjectsViewTaskView/ProjectsTaskViewTabsDescription";
import AssignTaskMembersModal from "./ProjectsAssignMembersToTaskModal";

/** A sub-component for the subtabs (Description, Comments, etc.). */
const SubTaskTabs = ({ subtask, onUpdate }) => {
  const tabTextColor = useColorModeValue("gray.500", "gray.300");
  const [subTaskData, setSubTaskData] = useState({
    name: subtask?.name || "",
    status: subtask?.status || "",
    priority: subtask?.priority || "",
    endDate: subtask?.endDate ? subtask.endDate.slice(0, 10) : "",
    assignedTo: subtask?.assignedTo ? subtask.assignedTo.join(", ") : "",
    description: subtask?.description || "",
  });

  // Track the selected tab index
  const [tabIndex, setTabIndex] = useState(0);

  if (!subtask) return null;

  const tabStyles = {
    color: tabTextColor,
    fontWeight: "600",
    fontSize: "lg",
    borderBottom: "3px solid",
    borderColor: "transparent",
    mx: 2,
    _selected: {
      color: "primary.500",
      borderColor: "primary.500",
    },
  };

  const tabPanelStyles = {
    p: 0,
    m: 0,
    flex: 1,
    overflowY: "auto",
  };

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  return (
    <>
      <Divider mt={4} mb={4} />
      <Tabs variant="unstyled" display="flex" flexDirection="column" isLazy index={tabIndex} onChange={handleTabsChange}>
        <TabList mb={1}>
          <Tab {...tabStyles}>
            <HStack spacing={2} align="center">
              <PiArticle fontSize="16px" />
              <Text fontWeight="500" fontSize="14px">
                Description
              </Text>
            </HStack>
          </Tab>
          <Tab {...tabStyles}>
            <HStack spacing={2} align="center">
              <PiChats fontSize="16px" />
              <Text fontWeight="500" fontSize="14px">
                Comments
              </Text>
              <Badge py={1} px={2} colorScheme={tabIndex === 1 ? "primary" : "primary"} borderRadius="8px">
                {subtask.comments ? subtask.comments.length : 0}
              </Badge>
            </HStack>
          </Tab>
          <Tab {...tabStyles}>
            <HStack spacing={2} align="center">
              <PiFolders fontSize="16px" />
              <Text fontWeight="500" fontSize="14px">
                Files
              </Text>
              <Badge py={1} px={2} colorScheme={tabIndex === 2 ? "primary" : "primary"} borderRadius="8px">
                {subtask.files ? subtask.files.length : 0}
              </Badge>
            </HStack>
          </Tab>
          <Tab {...tabStyles}>
            <HStack spacing={2} align="center">
              <PiClockUser fontSize="16px" />
              <Text fontWeight="500" fontSize="14px">
                Time Logs
              </Text>
              <Badge py={1} px={2} colorScheme={tabIndex === 3 ? "primary" : "primary"} borderRadius="8px">
                {subtask.timeLogs ? subtask.timeLogs.length : 0}
              </Badge>
            </HStack>
          </Tab>
        </TabList>
        <TabPanels>
          {/* Description Tab */}
          <TabPanel {...tabPanelStyles}>
            <VStack spacing={4} align="stretch">
              <DescriptionSection
                initialContent={subTaskData.description}
                subtask={subtask}
                isSubtask={true}
                onSave={(newValue) => {
                  setSubTaskData((prev) => ({ ...prev, description: newValue }));
                  onUpdate("description", newValue);
                }}
              />
            </VStack>
          </TabPanel>
          {/* Comments Tab */}
          <TabPanel {...tabPanelStyles}>
            <CommentsSection subtask={subtask} isSubtask={true} />
          </TabPanel>
          {/* Files Tab */}
          <TabPanel {...tabPanelStyles}>
            <FilesSection subtask={subtask} isSubtask={true} />
          </TabPanel>
          {/* Time Logs Tab */}
          <TabPanel {...tabPanelStyles}>
            <TimeLogsSection subtask={subtask} isSubtask={true} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

const SubtaskDetailDrawer = ({ isOpen, onClose, subtask }) => {
  // -----------------------------
  // 1) State / Context
  // -----------------------------
  const { subtasks, editTask } = useContext(ProjectContext);
  const currentSubtask = subtasks.find((t) => t?._id === subtask?._id);

  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [editableData, setEditableData] = useState({
    name: currentSubtask?.name || "",
    status: currentSubtask?.status || "",
    priority: currentSubtask?.priority || "",
    startDate: currentSubtask?.startDate ? currentSubtask.startDate.slice(0, 10) : "",
    endDate: currentSubtask?.endDate ? currentSubtask.endDate.slice(0, 10) : "",
    assignedTo: currentSubtask?.assignedTo ? currentSubtask.assignedTo.join(", ") : "",
    description: currentSubtask?.description || "",
  });

  // -----------------------------
  // 2) Color definitions
  // -----------------------------
  const drawerBg = useColorModeValue("white", "gray.800");
  const labelColor = useColorModeValue("gray.500", "gray.400");
  // We can keep the overlay as "blackAlpha.300" or define a color-mode-based overlay if desired.

  // -----------------------------
  // 3) Effects
  // -----------------------------
  useEffect(() => {
    setEditableData({
      name: currentSubtask?.name || "",
      status: currentSubtask?.status || "",
      priority: currentSubtask?.priority || "",
      startDate: currentSubtask?.startDate ? currentSubtask.startDate.slice(0, 10) : "",
      endDate: currentSubtask?.endDate ? currentSubtask.endDate.slice(0, 10) : "",
      assignedTo: currentSubtask?.assignedTo ? currentSubtask.assignedTo.join(", ") : "",
      description: currentSubtask?.description || "",
    });
  }, [currentSubtask]);

  // -----------------------------
  // 4) Data & Handlers
  // -----------------------------
  const assignedTo = currentSubtask?.assignedTo.map((user) => user?._id);

  // Status & Priority Options
  const statusOptions = [
    { value: "Not Started", label: "Not Started", color: "gray" },
    { value: "In Progress", label: "In Progress", color: "blue" },
    { value: "Completed", label: "Completed", color: "green" },
    { value: "On Hold", label: "On Hold", color: "orange" },
  ];

  const priorityOptions = [
    { value: "Low", label: "Low", color: "green" },
    { value: "Medium", label: "Medium", color: "yellow" },
    { value: "High", label: "High", color: "red" },
  ];

  const handleUpdate = (field, value) => {
    let updatedValue = value;
    if (field === "startDate" || field === "endDate") {
      updatedValue = new Date(value);
    }
    editTask(currentSubtask._id, { [field]: updatedValue });
  };

  const handleSubtaskChange = (field, value) => {
    if (!field || value === undefined) {
      return;
    }

    if (field === "startDate" || field === "endDate") {
      const currentDate = currentSubtask[field] ? new Date(currentSubtask[field]).getTime() : null;
      const newDate = new Date(value).getTime();

      if (currentDate !== null && currentDate === newDate) {
        return;
      }
    } else if (currentSubtask[field] === value) {
      return;
    }

    editTask(currentSubtask._id, { [field]: value });
  };

  // -----------------------------
  // 5) JSX
  // -----------------------------
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay bg="blackAlpha.300" />
      <DrawerContent bg={drawerBg} borderRadius={{ base: "none", md: "xl" }} boxShadow="2xl" p={4}>
        <DrawerCloseButton mt={2} mr={2} size="lg" />
        <DrawerHeader m={0} p={0}>
          <Editable m={4} defaultValue={currentSubtask?.name} onSubmit={(value) => editTask(currentSubtask?._id, { name: value })}>
            <EditablePreview fontSize="2xl" fontWeight="bold" />
            <EditableInput />
          </Editable>
        </DrawerHeader>

        <DrawerBody m={0} p={0}>
          <Box mx={4} mt={4}>
            <Grid templateColumns="100px 1fr" gap={6} alignItems="center" gridAutoRows="minmax(30px, auto)">
              {/* Status */}
              <GridItem>
                <Text fontSize="sm" color={labelColor} textTransform="uppercase" letterSpacing="wide">
                  Status
                </Text>
              </GridItem>
              <GridItem>
                <TagMenu
                  options={statusOptions}
                  selectedValue={editableData.status}
                  field="status"
                  handleBlurSave={(id, field, value) => {
                    handleSubtaskChange("status", value);
                    editTask(currentSubtask?._id, { status: value });
                  }}
                  subtaskId={currentSubtask?._id}
                />
              </GridItem>

              {/* Priority */}
              <GridItem>
                <Text fontSize="sm" color={labelColor} textTransform="uppercase" letterSpacing="wide">
                  Priority
                </Text>
              </GridItem>
              <GridItem>
                <TagMenu
                  options={priorityOptions}
                  selectedValue={editableData.priority}
                  field="priority"
                  handleBlurSave={(id, field, value) => {
                    handleSubtaskChange("priority", value);
                    editTask(currentSubtask?._id, { priority: value });
                  }}
                  subtaskId={currentSubtask?._id}
                />
              </GridItem>

              {/* Start Date */}
              <GridItem>
                <Text fontSize="sm" color={labelColor} textTransform="uppercase" letterSpacing="wide">
                  Start Date
                </Text>
              </GridItem>
              <GridItem>
                <UtilsDatePicker
                  hasEditButton
                  value={editableData.startDate}
                  inputVariant="editable"
                  onChange={(date) => handleUpdate("startDate", date)}
                />
              </GridItem>

              {/* Due Date */}
              <GridItem>
                <Text fontSize="sm" color={labelColor} textTransform="uppercase" letterSpacing="wide">
                  Due Date
                </Text>
              </GridItem>
              <GridItem>
                <UtilsDatePicker
                  hasEditButton
                  value={editableData.endDate}
                  inputVariant="editable"
                  onChange={(date) => handleUpdate("endDate", date)}
                />
              </GridItem>

              {/* Assigned To */}
              <GridItem>
                <Text fontSize="sm" color={labelColor} textTransform="uppercase" letterSpacing="wide">
                  Assigned
                </Text>
              </GridItem>
              <GridItem>
                <HStack spacing={3}>
                  {currentSubtask?.assignedTo && assignedTo?.length > 0 ? (
                    <UserAvatar userIds={assignedTo} size="sm" showInfo={false} useGroup={true} maxAvatars={6} spacing={-2} />
                  ) : (
                    <Text fontSize="md" color={labelColor}>
                      No members assigned.
                    </Text>
                  )}
                  <Button
                    leftIcon={<PiPlus fontSize="12px" />}
                    size="xs"
                    colorScheme="primary"
                    variant="outline"
                    onClick={() => setIsAssignModalOpen(true)}
                  >
                    Assign
                  </Button>
                </HStack>
              </GridItem>
            </Grid>
          </Box>

          {/* SubTask Tabs */}
          <SubTaskTabs subtask={currentSubtask} onUpdate={handleUpdate} />
        </DrawerBody>

        {/* Assign Task Members Modal */}
        <AssignTaskMembersModal isOpen={isAssignModalOpen} onClose={() => setIsAssignModalOpen(false)} isSubTask={true} subtask={currentSubtask} />
      </DrawerContent>
    </Drawer>
  );
};

export default SubtaskDetailDrawer;
