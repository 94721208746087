// client/src/components/2 - Components/ProjectsViewTaskView/ProjectsTaskViewTabsFiles.js
import React, { useState, useContext, useCallback, useMemo } from "react";
import { Box, HStack, SimpleGrid, Input, Flex, Button, IconButton, useColorModeValue, Text, Spinner } from "@chakra-ui/react";
import { PiDownloadSimple, PiTrash } from "react-icons/pi";
import FileCard from "../../5 - General/Utils/UtilsFileCard";
import { useDropzone } from "react-dropzone";
import UserAvatar from "../Reusable/UserAvatar";
import { ProjectContext } from "../../5 - General/Context/ProjectsContext";
import { WorkspaceContext } from "../../5 - General/Context/WorkspaceContext";
import ConfirmActionModal from "../../3 - Modal/9 - General Modals/ConfirmActionModal";

const FileSection = ({ subtask, isSubtask = false }) => {
  // -----------------------------
  // 1) Context / State
  // -----------------------------
  const { currentUser } = useContext(WorkspaceContext);
  const { currentProjectId, currentTaskId, tasks, uploadFilesToTask, deleteFileFromTask, downloadFileFromTask } = useContext(ProjectContext);

  const [fileSearchQuery, setFileSearchQuery] = useState("");
  const [fileToDelete, setFileToDelete] = useState(null);
  const [isDeleteFileOpen, setIsDeleteFileOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const currentTask = useMemo(
    () => (isSubtask ? subtask : tasks.find((t) => t._id === currentTaskId) || {}),
    [isSubtask, subtask, tasks, currentTaskId]
  );

  // -----------------------------
  // 2) Color / Style variables
  // -----------------------------
  const containerBorderColor = useColorModeValue("gray.200", "gray.600");
  const containerBg = useColorModeValue("gray.50", "gray.900");
  const inputBgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const dragAreaTextColor = useColorModeValue("gray.700", "gray.200");
  const overlayBg = useColorModeValue("rgba(255,255,255,0.7)", "rgba(0,0,0,0.5)");
  const noFilesColor = useColorModeValue("gray.500", "gray.400");

  // -----------------------------
  // 3) Handlers
  // -----------------------------
  const handleFileUpload = useCallback(
    async (acceptedFiles) => {
      if (!currentProjectId || !currentTask._id) {
        console.error("No currentProjectId or currentTask available.");
        return;
      }
      try {
        setIsUploading(true);
        await uploadFilesToTask(currentProjectId, currentTask._id, acceptedFiles);
      } catch (error) {
        console.error("Error uploading files:", error);
      } finally {
        setIsUploading(false);
      }
    },
    [uploadFilesToTask, currentProjectId, currentTask]
  );

  const handleDeleteFile = useCallback((file) => {
    setFileToDelete(file);
    setIsDeleteFileOpen(true);
  }, []);

  const confirmDeleteFile = useCallback(async () => {
    if (!fileToDelete || !currentProjectId || !currentTask._id) return;
    try {
      await deleteFileFromTask(currentProjectId, currentTask._id, fileToDelete._id);
      setIsDeleteFileOpen(false);
      setFileToDelete(null);
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  }, [deleteFileFromTask, currentProjectId, currentTask, fileToDelete]);

  const handleDownloadFile = useCallback(
    (file) => {
      if (!currentProjectId || !currentTask._id) return;
      downloadFileFromTask(currentProjectId, currentTask._id, file.backendName);
    },
    [downloadFileFromTask, currentProjectId, currentTask]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleFileUpload,
  });

  // Compute filtered files
  const safeFileSearchQuery = fileSearchQuery.toLowerCase();
  const taskFiles = currentTask.files || [];
  const filteredFiles = taskFiles.filter((file) => file.originalName?.toLowerCase().includes(safeFileSearchQuery));

  // -----------------------------
  // 4) JSX
  // -----------------------------
  return (
    <Flex flexDir="column" w="100%" pb={2} position="relative">
      {/* File Upload & Search */}
      <Flex mx={4} my={8} p={4} borderWidth="1px" borderColor={containerBorderColor} borderRadius="md" bg={containerBg} flexDir="column">
        <Text fontWeight="400">Shared Files</Text>
        <Input bg={inputBgColor} placeholder="Search files..." value={fileSearchQuery} onChange={(e) => setFileSearchQuery(e.target.value)} my={4} />

        <Box
          p={4}
          border="1px solid"
          borderColor={borderColor}
          bg={inputBgColor}
          borderRadius="md"
          textAlign="center"
          cursor="pointer"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <Text fontSize="lg" color={dragAreaTextColor}>
              Drop here to upload
            </Text>
          ) : (
            <Text fontSize="lg" color={dragAreaTextColor}>
              Drag and drop or click to select files
            </Text>
          )}
        </Box>
      </Flex>

      {/* Files List */}
      <SimpleGrid columns={[1, 1]} spacing={4} mx={4} mb="100px">
        {filteredFiles.length > 0 ? (
          filteredFiles.map((file, idx) => (
            <HStack
              key={file._id || idx}
              p={2}
              boxShadow="base"
              border="1px solid"
              borderColor={borderColor}
              borderRadius="md"
              justifyContent="space-between"
              alignItems="center"
            >
              <HStack w="60%" alignItems="center">
                <FileCard
                  file={{
                    name: file.originalName,
                    size: file.size,
                    type: file.mimeType,
                  }}
                />
                <UserAvatar isOnClickActive={true} userIds={[file.uploadedBy]} size="sm" showInfo={true} />
              </HStack>
              <HStack spacing={2}>
                <Button leftIcon={<PiDownloadSimple fontSize="18px" />} size="sm" variant="outline" onClick={() => handleDownloadFile(file)}>
                  Download
                </Button>
                {file.uploadedBy === currentUser._id && (
                  <IconButton
                    icon={<PiTrash fontSize="18px" />}
                    variant="outline"
                    size="sm"
                    color="red.600"
                    onClick={() => handleDeleteFile(file)}
                    aria-label="Delete file"
                  />
                )}
              </HStack>
            </HStack>
          ))
        ) : (
          <Text textAlign="center" fontSize="md" color={noFilesColor}>
            No files available.
          </Text>
        )}
      </SimpleGrid>

      {/* Upload Indicator */}
      {isUploading && (
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg={overlayBg}
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex={10}
        >
          <Spinner size="xl" />
        </Box>
      )}

      {/* Confirm Deletion Modal */}
      <ConfirmActionModal
        isOpen={isDeleteFileOpen}
        onClose={() => setIsDeleteFileOpen(false)}
        onConfirm={confirmDeleteFile}
        title="Delete File"
        description="Are you sure you want to delete this file? This action cannot be undone and will permanently remove the file from the system."
        confirmText="Delete"
        cancelText="Cancel"
        isDestructive={true}
      />
    </Flex>
  );
};

export default FileSection;
