// src/components/3 - Modal/2 - Messages Modals/CreateOneOnOneModal.js

import React, { useState, useContext, useEffect, useMemo } from "react";
import { Box, VStack, Button, Text, Spinner, Alert, AlertIcon, AlertTitle, AlertDescription, useColorModeValue } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import ReusableModal from "../9 - General Modals/ReusableModal";
import { MessagesContext } from "../../5 - General/Context/MessagesContext";
import { WorkspaceContext } from "../../5 - General/Context/WorkspaceContext"; // Imported WorkspaceContext

const CreateOneOnOneModal = ({ isOpen, onClose }) => {
  const { currentUser, availableUsers, isAvailableUsersLoading, availableUsersError, handleCreateChannel, channels } = useContext(MessagesContext);
  const { themeColor } = useContext(WorkspaceContext); // Accessed themeColor
  const [selectedUser, setSelectedUser] = useState(null);

  // Colors for the select component
  const selectTextColor = useColorModeValue("black", "white");
  const selectBgColor = useColorModeValue("white", "gray.900");
  const selectHoverBgColor = useColorModeValue("gray.100", "gray.600");
  const selectLightTextColor = useColorModeValue("gray.500", "gray.300");
  const selectMenuBgColor = useColorModeValue("white", "gray.700");

  // Adjust chakra-react-select styles to indicate disabled options
  const chakraStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? "gray.400" : state.isSelected ? "white" : selectTextColor,
      backgroundColor: state.isSelected ? `${themeColor}.500` : selectMenuBgColor,
      "&:hover": {
        backgroundColor: state.isDisabled ? selectMenuBgColor : selectHoverBgColor,
      },
      padding: "8px",
      cursor: state.isDisabled ? "not-allowed" : "default",
    }),
    container: (provided) => ({
      ...provided,
      backgroundColor: selectBgColor,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: selectTextColor,
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: selectMenuBgColor,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: selectTextColor,
      padding: "8px 12px",
      bg: selectHoverBgColor,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: selectLightTextColor,
      fontSize: "14px",
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: selectLightTextColor,
      backgroundColor: selectMenuBgColor,
    }),
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedUser(null);
    }
  }, [isOpen]);

  // Get all existing 1-on-1 channels where the current user is a member
  const existingOneOnOneChannels = useMemo(() => {
    return channels.filter((channel) => channel.conversationType === "1on1" && channel.members.some((member) => member._id === currentUser._id));
  }, [channels, currentUser._id]);

  console.log(existingOneOnOneChannels);

  // Helper function to check if a conversation with the given user already exists
  const isUserAlreadyConnected = (userId) => {
    return existingOneOnOneChannels.some((channel) => channel.members.some((member) => member._id === userId));
  };

  // Footer buttons for the modal
  const footerButtons = (
    <>
      <Button onClick={onClose} variant="outline" colorScheme="gray">
        Cancel
      </Button>
      {availableUsers.length === 0 || availableUsersError || isAvailableUsersLoading ? null : (
        <Button
          colorScheme={themeColor} // Use themeColor here
          onClick={() => {
            if (selectedUser) {
              const conversationName = `${selectedUser.firstName} ${selectedUser.lastName}`;
              const memberIds = [selectedUser.value, currentUser._id];
              // Pass the conversation name and member IDs to the create channel handler.
              // The third parameter (e.g., `true`) may be used by your handler for one-on-one channels.
              handleCreateChannel(conversationName, memberIds, true);
              onClose();
            }
          }}
          disabled={!selectedUser}
        >
          Create
        </Button>
      )}
    </>
  );

  // Render the modal content based on loading/error status
  const renderContent = () => {
    if (isAvailableUsersLoading) {
      return (
        <VStack spacing={4} pt={4}>
          <Spinner />
          <Text>Loading users...</Text>
        </VStack>
      );
    }

    if (availableUsersError) {
      return (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2}>Failed to load users.</AlertTitle>
          <AlertDescription>{availableUsersError.message}</AlertDescription>
        </Alert>
      );
    }

    if (availableUsers.length === 0) {
      return (
        <Alert status="info">
          <VStack align="start">
            <AlertTitle mr={2}>No users available.</AlertTitle>
            <AlertDescription>
              There are no users available to create a conversation. Ask an Editor or an Admin to create it for you.
            </AlertDescription>
          </VStack>
        </Alert>
      );
    }

    // Create options for the select component, disabling users with an existing conversation
    const options = availableUsers
      .filter((user) => user.value !== currentUser._id)
      .map((user) => ({
        value: user.value,
        label: user.label,
        firstName: user.firstName,
        lastName: user.lastName,
        isDisabled: isUserAlreadyConnected(user.value),
      }));

    return (
      <VStack spacing={4} pt={4}>
        <Box width="100%">
          <Select
            options={options}
            value={selectedUser}
            onChange={(selected) => setSelectedUser(selected)}
            placeholder="Select a user..."
            isClearable
            isSearchable
            chakraStyles={chakraStyles}
            menuPortalTarget={document.body}
            isOptionDisabled={(option) => option.isDisabled}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
          />
        </Box>
        {selectedUser && selectedUser.isDisabled && <Text color="red.500">You already have a conversation with this user.</Text>}
      </VStack>
    );
  };

  return (
    <ReusableModal isOpen={isOpen} onClose={onClose} title="Create a One-on-One Conversation" footerButtons={footerButtons}>
      {renderContent()}
    </ReusableModal>
  );
};

export default CreateOneOnOneModal;
