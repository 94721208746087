// src/components/Reusable/UserAvatar.jsx
import React, { useContext, useState, lazy, Suspense } from "react";
import { Avatar, AvatarBadge, Box, Flex, Text, Tooltip, Spinner } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useUserOnlineStatusSocketQuery } from "../../5 - General/Hooks/useUserOnlineStatusSocketQuery";
import { WorkspaceContext } from "../../5 - General/Context/WorkspaceContext";
import { PiUser } from "react-icons/pi";

// Lazy load the drawer component for user details.
const LazyUserDetailsDrawer = lazy(
  () => import("../../3 - Modal/9 - General Modals/UserDetailsDrawer") // adjust the path as needed
);

const UserAvatar = ({
  userIds = [],
  size = "md",
  showInfo = true,
  maxAvatars = 2,
  borderRadius = "full",
  bg = "transparent",
  spacing = -5,
  withTooltip = true,
  alignSelf = "",
  mr = 0,
  ml = 0,
  isOnClickActive = false,
}) => {
  const sortedUserIds = [...userIds].sort();
  const { data: usersData, isLoading, error } = useUserOnlineStatusSocketQuery(sortedUserIds);
  const { themeColor } = useContext(WorkspaceContext);
  const [drawerOpen, setDrawerOpen] = useState(false);

  if (!userIds || userIds.length === 0) {
    return null;
  }

  if (isLoading) {
    return <Spinner size={size} />;
  }

  if (error) {
    return (
      <Box mt={4}>
        <Text color="red.500">{error.message || "An error occurred while fetching user data."}</Text>
      </Box>
    );
  }

  // When isOnClickActive is true, clicking the avatar opens the drawer.
  const handleAvatarClick = (e) => {
    e.stopPropagation();
    if (isOnClickActive) {
      setDrawerOpen(true);
    }
  };

  // Render a single user's avatar.
  const renderAvatar = (userId) => {
    const user = usersData[userId];
    if (!user) {
      return <Avatar size={size} borderRadius={borderRadius} key={userId} />;
    }

    const { firstName, lastName, profilePictureUrl, companyName, isOnline } = user;
    const avatarSrc = profilePictureUrl || "";

    const avatarElement = (
      <Avatar
        mr={`${mr}px`}
        ml={`${ml}px`}
        bg={`${themeColor}.200`}
        color={`${themeColor}.700`}
        alignSelf={alignSelf}
        size={size}
        name={`${firstName} ${lastName}`}
        src={avatarSrc}
        borderRadius={borderRadius}
        key={userId}
      >
        {isOnline ? <AvatarBadge boxSize="0.90em" bg="green.400" /> : <AvatarBadge boxSize="0.90em" bg="gray.300" />}
      </Avatar>
    );

    return withTooltip ? (
      <Tooltip
        hasArrow
        label={`${firstName} ${lastName} - ${companyName || "No Company"} - ${isOnline ? "Online" : "Offline"}`}
        aria-label={`${firstName} ${lastName} Tooltip`}
        key={userId}
      >
        {avatarElement}
      </Tooltip>
    ) : (
      avatarElement
    );
  };

  // Determine how many avatars to display.
  const displayUsers = userIds.slice(0, maxAvatars);
  const extraUsers = userIds.length - maxAvatars;

  const renderExtraAvatar = () => {
    if (extraUsers <= 0) return null;
    const avatarLabel = `+${extraUsers}`;
    const allUsers = userIds.map((userId) => {
      const user = usersData[userId] || {};
      const { firstName, lastName, companyName, isOnline } = user;
      return `${firstName} ${lastName} - ${companyName || "No Company"} - ${isOnline ? "Online" : "Offline"}`;
    });
    return (
      <Tooltip
        hasArrow
        label={
          <Box>
            {allUsers.map((userInfo, index) => (
              <Text key={index} fontSize="sm">
                {userInfo}
              </Text>
            ))}
          </Box>
        }
        aria-label="Extra Users Tooltip"
        key="extra-users-tooltip"
      >
          <Avatar
          size={size}
          bg="gray.300"
          borderRadius={borderRadius}
          cursor="pointer"
          overflow="hidden"
          icon={<PiUser fontSize="0" />}
        >
          <Text fontSize={size} fontWeight='600' color="gray.600">
            {avatarLabel}
          </Text>
        </Avatar>
      </Tooltip>
    );
  };

  return (
    <Box onClick={handleAvatarClick} cursor={isOnClickActive ? "pointer" : "default"}>
      <Flex align="center" mr={1} bg={bg}>
        <Box position="relative" display="flex">
          {displayUsers.map((userId, index) => (
            <Box key={`${userId}-avatar`} position="relative" marginLeft={index === 0 ? 0 : `${spacing}px`} zIndex={index}>
              {renderAvatar(userId)}
            </Box>
          ))}
          {extraUsers > 0 && (
            <Box position="relative" marginLeft={`${spacing}px`} zIndex={0}>
              {renderExtraAvatar()}
            </Box>
          )}
        </Box>
        {showInfo && userIds.length === 1 && (
          <Box ml={3}>
            <Text fontWeight="600" fontSize="md" whiteSpace="nowrap" overflow="hidden">
              {usersData[userIds[0]]?.firstName} {usersData[userIds[0]]?.lastName}
            </Text>
            {usersData[userIds[0]]?.companyName && (
              <Text fontSize="xs" color="gray.500" whiteSpace="nowrap" overflow="hidden">
                {usersData[userIds[0]]?.companyName}
              </Text>
            )}
          </Box>
        )}
      </Flex>
      {isOnClickActive && (
        <Suspense fallback={null}>
          <LazyUserDetailsDrawer userId={userIds[0]} isOpen={drawerOpen} onClose={() => setDrawerOpen(false)} />
        </Suspense>
      )}
    </Box>
  );
};

UserAvatar.propTypes = {
  userIds: PropTypes.arrayOf(PropTypes.string),
  size: PropTypes.string,
  showInfo: PropTypes.bool,
  maxAvatars: PropTypes.number,
  borderRadius: PropTypes.string,
  bg: PropTypes.string,
  spacing: PropTypes.number,
  withTooltip: PropTypes.bool,
  alignSelf: PropTypes.string,
  mr: PropTypes.number,
  ml: PropTypes.number,
  isOnClickActive: PropTypes.bool,
};

export default UserAvatar;