// client/src/components/2 - Components/ProjectsViewTaskView/ProjectsTaskViewDetails.js
import React, { useState, useEffect, useContext } from "react";
import { Box, Divider, Grid, Heading, HStack, Switch, Text, VStack, useColorModeValue, Tooltip, IconButton } from "@chakra-ui/react";
import UserAvatar from "../Reusable/UserAvatar";
import UtilsDatePicker from "../../5 - General/Utils/UtilsDatePicker";
import { CgSpinner } from "react-icons/cg";
import { PiBell, PiPencilSimpleLine } from "react-icons/pi";
import { ProjectContext } from "../../5 - General/Context/ProjectsContext";
import AssignTaskMembersModal from "../../3 - Modal/3 - Projects Modals/ProjectsAssignMembersToTaskModal";

const TaskViewDetails = ({ task, handleTaskChange }) => {
  // -----------------------------
  // 1) Context / State
  // -----------------------------
  const { subtasks } = useContext(ProjectContext);
  const taskSubtasks = subtasks.filter((subtask) => subtask.parentTask?.toString() === task._id.toString());

  const [startDate, setStartDate] = useState(task.startDate);
  const [endDate, setEndDate] = useState(task.endDate);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);

  useEffect(() => {
    setStartDate(task.startDate);
    setEndDate(task.endDate);
  }, [task.startDate, task.endDate]);

  // -----------------------------
  // 2) Color / Style variables
  // -----------------------------
  const headingColor = useColorModeValue("gray.700", "gray.200");
  const labelColor = useColorModeValue("gray.500", "gray.400");
  const datePickerHoverBg = useColorModeValue("gray.50", "gray.700");
  const dividerColor = useColorModeValue("gray.200", "gray.600");
  const statsCardBg = useColorModeValue("white", "gray.800");
  const statsCardBorder = useColorModeValue("gray.200", "gray.700");
  const progressBarBg = useColorModeValue("gray.200", "gray.700");
  const emptyProgressBg = useColorModeValue("gray.400", "gray.600");

  // -----------------------------
  // 3) Calculations / Data
  // -----------------------------
  // Calculate days remaining
  const daysRemaining = task.endDate ? Math.ceil((new Date(task.endDate) - new Date()) / (1000 * 60 * 60 * 24)) : null;

  // Calculate progress based on subtasks
  const totalSubtasks = taskSubtasks.length || 1;
  const statusCounts = {
    Completed: taskSubtasks.filter((st) => st.status === "Completed").length,
    "In Progress": taskSubtasks.filter((st) => st.status === "In Progress").length,
    "Not Started": taskSubtasks.filter((st) => st.status === "Not Started").length,
    "On Hold": taskSubtasks.filter((st) => st.status === "On Hold").length,
  };

  const statusPercentages = {
    Completed: (statusCounts["Completed"] / totalSubtasks) * 100,
    "In Progress": (statusCounts["In Progress"] / totalSubtasks) * 100,
    "Not Started": (statusCounts["Not Started"] / totalSubtasks) * 100,
    "On Hold": (statusCounts["On Hold"] / totalSubtasks) * 100,
  };

  // Get assigned user IDs from task.assignedTo
  const assignedTo = task.assignedTo.map((user) => user._id);

  // Define colors for progress bar segments
  const statusColors = {
    Completed: "green.500",
    "In Progress": "primary.500",
    "Not Started": useColorModeValue("gray.300", "gray.600"),
    "On Hold": "orange.500",
  };

  // -----------------------------
  // 4) JSX
  // -----------------------------
  return (
    <Box flex="none" overflow="hidden" pt={4} mt={2}>
      <Heading fontSize="xl" fontWeight="600" mb={6} color={headingColor}>
        Task Details
      </Heading>

      {/* Task Details Grid */}
      <Grid templateColumns={{ base: "1fr", md: "120px 1fr" }} gap={4} alignItems="center">
        {/* Assigned To */}
        <Text fontWeight={500} fontSize="sm" color={labelColor} textAlign="left" minWidth="120px">
          Assigned to:
        </Text>
        <HStack spacing={2}>
          <UserAvatar showInfo={false} userIds={assignedTo} size="sm" maxAvatars={10} />
          <Tooltip label="Edit assigned users">
            <Box>
              <IconButton
                icon={<PiPencilSimpleLine fontSize="18px" />}
                variant="ghost"
                size="xs"
                aria-label="Edit assigned users"
                onClick={() => setIsAssignModalOpen(true)}
              />
            </Box>
          </Tooltip>
        </HStack>

        {/* Start Date */}
        <Text fontWeight={500} fontSize="sm" color={labelColor} textAlign="left" minWidth="120px">
          Start Date:
        </Text>
        <Box _hover={{ bg: datePickerHoverBg }} transition="all 0.2s" borderRadius="md">
          <UtilsDatePicker
            inputVariant="editable"
            value={startDate}
            onChange={(date) => {
              setStartDate(date);
              handleTaskChange("startDate", date);
            }}
            fontWeight={500}
            endDate={endDate || null}
            fontSize="sm"
          />
        </Box>

        {/* Due Date */}
        <Text fontWeight={500} fontSize="sm" color={labelColor} textAlign="left" minWidth="120px">
          Due Date:
        </Text>
        <Box _hover={{ bg: datePickerHoverBg }} transition="all 0.2s" borderRadius="md">
          <UtilsDatePicker
            inputVariant="editable"
            value={endDate}
            onChange={(date) => {
              setEndDate(date);
              handleTaskChange("endDate", date);
            }}
            fontWeight={500}
            startDate={startDate || null}
            fontSize="sm"
          />
        </Box>
      </Grid>

      <Divider my={4} borderColor={dividerColor} />

      <VStack align="stretch" spacing={6} mt={4}>
        {/* Statistics Section */}
        <Heading fontSize="xl" fontWeight="600" color={headingColor}>
          Statistics
        </Heading>

        {/* Time Remaining Box */}
        <Box p={4} borderRadius="md" border="1px solid" borderColor={statsCardBorder} bg={statsCardBg} mr="50px">
          <HStack justify="space-between" mb={2}>
            <HStack spacing={2}>
              <PiBell fontSize="18px" />
              <Text fontSize="md" fontWeight="600">
                Time Remaining
              </Text>
            </HStack>
            <Text fontSize="md" fontWeight="600" color={daysRemaining > 0 ? "green.500" : "red.500"}>
              {daysRemaining > 0 ? `${daysRemaining} days` : "Overdue"}
            </Text>
          </HStack>
          <Box bg={useColorModeValue("gray.50", "gray.700")} p={2} borderRadius="md">
            <HStack justify="space-between">
              <Text fontSize="sm" fontWeight="500" color={labelColor}>
                {daysRemaining > 0 ? "Activate" : "Deactivate"} Reminders
              </Text>
              <Switch size="md" colorScheme="primary" />
            </HStack>
          </Box>
        </Box>

        {/* Custom Progress Bar with Tooltips */}
        <Box p={4} borderRadius="md" border="1px solid" borderColor={statsCardBorder} bg={statsCardBg} mr="50px">
          <HStack justify="space-between" mb={2}>
            <HStack spacing={2}>
              <CgSpinner fontSize="18px" />
              <Text fontSize="md" fontWeight="600">
                Progress
              </Text>
            </HStack>
            <Text fontSize="md" fontWeight="600" color={labelColor}>
              {((statusCounts["Completed"] / totalSubtasks) * 100).toFixed(2)}%
            </Text>
          </HStack>
          <Box width="100%" display="flex" height="20px" borderRadius="full" overflow="hidden" bg={progressBarBg}>
            {taskSubtasks.length > 0 ? (
              ["Completed", "In Progress", "Not Started", "On Hold"].map((status) => {
                const percentage = statusPercentages[status];
                if (percentage > 0) {
                  return (
                    <Tooltip
                      key={status}
                      label={`${status}: ${statusCounts[status]} (${percentage.toFixed(2)}%)`}
                      aria-label={`${status} tooltip`}
                      placement="bottom"
                      hasArrow
                    >
                      <Box width={`${percentage}%`} bg={statusColors[status]} transition="width 0.3s ease-in-out" cursor="pointer" />
                    </Tooltip>
                  );
                }
                return null;
              })
            ) : (
              // Fallback: default empty bar when no subtasks
              <Box width="100%" bg={emptyProgressBg} />
            )}
          </Box>
        </Box>

        <Divider borderColor={dividerColor} />

        {/* Created By */}
        <Box>
          <Heading fontSize="xl" fontWeight="600" mb={6} color={headingColor}>
            Created By
          </Heading>
          <VStack mx={4} align="stretch" spacing={2} mt={2}>
            <UserAvatar isOnClickActive={true} userIds={[task.createdBy]} size="md" showInfo={true} withTooltip={false} />
          </VStack>
        </Box>
      </VStack>

      {/* Assign Members Modal */}
      <AssignTaskMembersModal isOpen={isAssignModalOpen} task={task} onClose={() => setIsAssignModalOpen(false)} />
    </Box>
  );
};

export default TaskViewDetails;
