// client/src/components/4 - API/API-Files.js

import { fetchData, APIError } from "./FetchData";
const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;

/**
 * Function to trigger download of a blob
 * @param {Blob} blob - The blob data to download
 * @param {string} filename - The desired filename for the downloaded file
 */
export const downloadBlob = (blob, filename) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

/**
 * Function to download a single file via S3 pre-signed URL
 * @param {string} backendFileName - The backend file name (S3 object key)
 * @param {string} companyId - The company ID associated with the file
 */
export const downloadFile = async (backendFileName, companyId, originalFileName) => {
  const fullUrl = `${API_BASE_URL}/api/v1/files/uploads/files/${companyId}/${backendFileName}/download`;
  try {
    const response = await fetch(fullUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      const errorMessage = errorData?.message || "Failed to download file.";
      throw new APIError(errorMessage, response.status, errorData);
    }

    const data = await response.json();
    if (data.status === "ok") {
      const downloadUrl = data.downloadUrl;
      const filename = data.filename || originalFileName || "download";
      return { downloadUrl, filename };
    } else {
      throw new APIError(data.message || "Failed to download file.", response.status, data);
    }
  } catch (error) {
    if (error instanceof APIError) {
      throw error;
    } else {
      throw new APIError(error.message || "An unexpected error occurred.", 500, null);
    }
  }
};

/**
 * Function to download a single file using a direct download link
 * @param {string} downloadLink - The direct download link (pre-signed URL)
 */
export const downloadFileWithLink = async (downloadLink) => {
  try {
    // No need to fetch the download link; directly trigger the download
    window.location.href = downloadLink;
  } catch (error) {
    throw new APIError(error.message || "An unexpected error occurred.", 500, null);
  }
};

/**
 * Function to fetch all companies
 */
export const fetchCompanies = async () => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/get-all-companies`, "GET", null, true);
  return data.companies;
};

/**
 * Function to fetch files and folders for a company, optionally within a specific folder
 * @param {string} companyId - The company ID
 * @param {string|null} folderId - The parent folder ID (optional)
 */
export const fetchFilesAndFolders = async (companyId, folderId = null) => {
  let url = `${API_BASE_URL}/api/v1/files/${companyId}/get-files-and-folders`;
  if (folderId) {
    const params = new URLSearchParams({ parentFolder: folderId });
    url += `?${params.toString()}`;
  }
  const data = await fetchData(url, "GET", null, true);
  return data;
};

/**
 * Function to upload a file to S3 via the backend
 * @param {File} file - The file object to upload
 * @param {string} companyId - The company ID
 * @param {string|null} parentFolderId - The parent folder ID (optional)
 */
export const uploadFile = async (file, companyId, parentFolderId = null) => {
  const formData = new FormData();
  formData.append("file", file);
  if (parentFolderId) {
    formData.append("parentFolderId", parentFolderId);
  }
  const data = await fetchData(`${API_BASE_URL}/api/v1/files/${companyId}/upload`, "POST", formData, true);
  return data;
};

/**
 * Function to search for files within a company and optional parent folder
 * @param {string} companyId - The company ID
 * @param {string} query - The search query
 * @param {string|null} parentFolderId - The parent folder ID (optional)
 */
export const searchFiles = async (companyId, query, parentFolderId = null) => {
  const encodedQuery = encodeURIComponent(query.trim());
  let url = `${API_BASE_URL}/api/v1/files/${companyId}/search?q=${encodedQuery}`;
  if (parentFolderId) {
    const params = new URLSearchParams({ parentFolderId });
    url += `&${params.toString()}`;
  }

  const data = await fetchData(url, "GET", null, true);
  return data;
};

export const duplicateFile = async (fileId, companyId, parentFolderId = null) => {
  const body = { fileId, parentFolderId };
  const data = await fetchData(`${API_BASE_URL}/api/v1/files/${companyId}/duplicate`, "POST", body, false);
  return data.file;
};
