// client/src/components/5 - General/Context/FilesProvider.jsx

import React, { createContext, useState, useEffect, useCallback, useMemo, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom"; // Import React Router hooks
import { fetchFilesAndFolders, downloadFile, searchFiles, downloadBlob } from "../../4 - API/API-Files";
import { useCreateFolder, useDeleteFile, useRenameFile, useCreateLink, useMoveFiles, useUploadFile } from "../Hooks/useUpdateFile";
import useCustomToast from "../Utils/UtilsNotification";
import debounce from "lodash.debounce";
import ImportProgressModal from "../../3 - Modal/4 - Files Modals/FileImportProgressModal";
import { WorkspaceContext } from "./WorkspaceContext";
import { useDisclosure } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../../queryKeys";

export const FilesContext = createContext();

export const FilesProvider = ({ children }) => {
  const { currentWorkspace } = useContext(WorkspaceContext);
  const navigate = useNavigate(); // Initialize useNavigate
  const { folderId } = useParams(); // Access folderId from URL

  const createFolderMutation = useCreateFolder();
  const deleteFileMutation = useDeleteFile();
  const uploadFileMutation = useUploadFile();
  const renameFileMutation = useRenameFile();
  const createLinkMutation = useCreateLink();
  const moveFilesMutation = useMoveFiles();
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [currentFolderId, setCurrentFolderId] = useState(folderId || null); // Initialize from URL
  const [folderPath, setFolderPath] = useState([]);
  const [sortField, setSortField] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");
  const [fileToRename, setFileToRename] = useState(null);
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  // States for Modals
  const { isOpen: isRenameModalOpen, onOpen: onOpenRenameModal, onClose: onCloseRenameModal } = useDisclosure();
  const { isOpen: isDeleteConfirmationModalOpen, onOpen: onOpenDeleteConfirmationModal, onClose: onCloseDeleteConfirmationModal } = useDisclosure();
  const { isOpen: isCreateFolderModalOpen, onOpen: onOpenCreateFolderModal, onClose: onCloseCreateFolderModal } = useDisclosure();
  const { isOpen: isCreateLinkModalOpen, onOpen: onOpenCreateLinkModal, onClose: onCloseCreateLinkModal } = useDisclosure();
  const { isOpen: isImportModalOpen, onOpen: onOpenImportModal, onClose: onCloseImportModal } = useDisclosure();
  const { isOpen: isImagePreviewModalOpen, onOpen: onOpenImagePreviewModal, onClose: onCloseImagePreviewModal } = useDisclosure();

  // States for Search Suggestions
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isSuggestionsOpen, setIsSuggestionsOpen] = useState(false);
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
  const [totalSearchMatches, setTotalSearchMatches] = useState(0);

  // States for Import Progress
  const [isImportProgressOpen, setIsImportProgressOpen] = useState(false);
  const [importProgress, setImportProgress] = useState(0);
  const [importStatusMessage, setImportStatusMessage] = useState("");
  const customToast = useCustomToast();

  const {
    data,
    isLoading: isFilesLoading,
    error: filesError,
  } = useQuery({
    queryKey: queryKeys.files(currentWorkspace?._id, currentFolderId),
    queryFn: () => fetchFilesAndFolders(currentWorkspace._id, currentFolderId),
    enabled: Boolean(currentWorkspace),
  });

  // Destructure data to get files and backendFolderPath
  const files = useMemo(() => data?.filesAndFolders || [], [data?.filesAndFolders]);
  const backendFolderPath = useMemo(() => data?.folderPath || [], [data?.folderPath]);

  // Update folderPath when backendFolderPath changes
  useEffect(() => {
    if (currentFolderId) {
      // Prepend 'Root' to the folderPath
      setFolderPath([{ _id: null, name: currentWorkspace?.companyName }, ...backendFolderPath]);
    } else {
      // At root, only 'Root' is present
      setFolderPath([{ _id: null, name: currentWorkspace?.companyName }]);
    }
  }, [backendFolderPath, currentFolderId, currentWorkspace]);

  const sortFiles = useCallback(
    (files) => {
      if (!Array.isArray(files)) return [];
      return [...files].sort((a, b) => {
        let aVal, bVal;
        switch (sortField) {
          case "name":
            aVal = a.originalFileName.toLowerCase();
            bVal = b.originalFileName.toLowerCase();
            break;
          case "date":
            aVal = new Date(a.createdAt);
            bVal = new Date(b.createdAt);
            break;
          case "creator":
            aVal = a.uploadedBy.firstName.toLowerCase();
            bVal = b.uploadedBy.firstName.toLowerCase();
            break;
          default:
            return 0;
        }
        return sortDirection === "asc" ? (aVal > bVal ? 1 : -1) : aVal < bVal ? 1 : -1;
      });
    },
    [sortField, sortDirection]
  );

  const sortedFiles = useMemo(() => sortFiles(files), [files, sortFiles]);

  // Update selected company when current workspace changes
  useEffect(() => {
    if (currentWorkspace) {
      // If folderId is present in URL, set it; otherwise, reset to root
      if (folderId) {
        setCurrentFolderId(folderId);
      } else {
        setCurrentFolderId(null);
        setFolderPath([]);
      }
      setSelectedItems(new Set());
      setIsAllSelected(false);
      setSearchTerm("");
      setSuggestions([]);
      setIsSuggestionsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorkspace, folderId]);

  // Update folderPath when backendFolderPath changes
  useEffect(() => {
    setFolderPath(backendFolderPath);
  }, [backendFolderPath]);

  // Sorting Handlers
  const updateSortField = (field) => {
    setSortField(field);
  };

  const updateSortDirection = (direction) => {
    setSortDirection(direction);
  };

  // Navigate to a new folder and update URL
  const navigateToFolder = (folderId, folderName) => {
    setCurrentFolderId(folderId);
    // Update the URL
    if (folderId) {
      navigate(`/dashboard/files/${folderId}`);
    } else {
      navigate(`/dashboard/files`);
    }
    // Update folderPath is handled by the useEffect fetching data
  };

  // Folder Click Handler
  const handleFolderClick = (folderId, folderName) => {
    navigateToFolder(folderId, folderName);
    setSelectedItems(new Set());
    setIsAllSelected(false);
    setSearchTerm("");
    setSuggestions([]);
    setIsSuggestionsOpen(false);
  };

  // Breadcrumb Click Handler
  const handleBreadcrumbClick = (index) => {
    if (index === -1) {
      // Navigate to root
      navigateToFolder(null, "Root");
    } else {
      const targetFolder = folderPath[index];
      navigateToFolder(targetFolder._id, targetFolder.name);
    }

    // Reset selections and search states
    setSelectedItems(new Set());
    setIsAllSelected(false);
    setSearchTerm("");
    setSuggestions([]);
    setIsSuggestionsOpen(false);
  };

  // Function to go back one folder
  const handleGoBack = () => {
    if (folderPath.length > 1) {
      handleBreadcrumbClick(folderPath.length - 2);
    } else {
      handleBreadcrumbClick(-1); // Navigate to root
    }
  };

  // File Selection Handler
  const handleFileSelect = (fileId) => {
    setSelectedItems((prev) => {
      const newSelected = new Set(prev);
      if (newSelected.has(fileId)) {
        newSelected.delete(fileId);
      } else {
        newSelected.add(fileId);
      }
      // Update isAllSelected based on the new selection
      setIsAllSelected(newSelected.size === files.length);
      return newSelected;
    });
  };

  // Select All Handler
  const handleSelectAll = () => {
    if (selectedItems.size > 0) {
      // If any items are selected, deselect all
      setSelectedItems(new Set());
      setIsAllSelected(false);
    } else {
      // If no items are selected, select all
      const allIds = files.map((file) => file._id);
      setSelectedItems(new Set(allIds));
      setIsAllSelected(true);
    }
  };

  // Mass Delete Handler
  const handleMassDelete = () => {
    if (selectedItems.size === 0) return;
    handleDeleteConfirmationModalOpen(Array.from(selectedItems));
  };

  // Mass Delete Confirmation Modal Open Handler
  const handleDeleteConfirmationModalOpen = (fileIds) => {
    // Filter and set filesToDelete as an array of file objects
    const filesToDelete = files.filter((file) => fileIds.includes(file._id));
    setFilesToDelete(filesToDelete);
    onOpenDeleteConfirmationModal();
  };

  // Delete Confirmation Handler
  const handleDeleteConfirm = async () => {
    if (filesToDelete.length === 0) return;
    try {
      // Extract file IDs from the filesToDelete array and convert to strings
      const fileIds = filesToDelete.map((file) => file._id.toString());
      await deleteFileMutation.mutateAsync({
        fileIds,
        companyId: currentWorkspace._id,
      });
      // Optionally, refetch files after deletion
    } catch (error) {
      console.error("Error deleting files:", error);
    } finally {
      setFilesToDelete([]);
      onCloseDeleteConfirmationModal();
    }
  };

  // Create Folder Handler
  const handleCreateFolder = async (folderName) => {
    if (!folderName.trim()) return;
    try {
      await createFolderMutation.mutateAsync({
        folderName,
        companyId: currentWorkspace._id,
        parentFolderId: currentFolderId,
      });
    } catch (error) {
      console.error("Error creating folder:", error);
    }
  };

  // Link Creation Handler
  const handleCreateLink = async (linkName, linkUrl) => {
    if (!linkName.trim() || !linkUrl.trim()) return;
    try {
      await createLinkMutation.mutateAsync({
        linkName,
        linkUrl,
        companyId: currentWorkspace._id,
        parentFolderId: currentFolderId,
      });
    } catch (error) {
      console.error("Error creating link:", error);
    }
  };

  // File Upload Handler
  const handleFileUpload = async (file) => {
    try {
      if (!file) return;
      await uploadFileMutation.mutateAsync({
        file,
        companyId: currentWorkspace._id,
        parentFolderId: currentFolderId,
      });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  // Rename Handler
  const handleRenameSubmit = async (newName) => {
    if (!fileToRename || !newName.trim()) return;
    try {
      await renameFileMutation.mutateAsync({
        itemId: fileToRename,
        newName,
      });
    } catch (error) {
      console.error("Error renaming file:", error);
    }
  };

  // Download File Handler
  const handleDownloadFile = async (file) => {
    try {
      const { downloadUrl, filename } = await downloadFile(file.backendFileName, currentWorkspace._id, file.originalFileName);

      const response = await fetch(downloadUrl, {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error("Failed to download file.");
      }

      const blob = await response.blob();
      downloadBlob(blob, filename);
    } catch (error) {
      console.error("Error downloading file:", error);
      customToast({
        title: "Error",
        description: error.message || "Error downloading file.",
        status: "error",
      });
    }
  };

  // Debounced fetch for search suggestions
  const fetchSearchSuggestions = useMemo(
    () =>
      debounce(async (query) => {
        if (!query) {
          setSuggestions([]);
          setTotalSearchMatches(0);
          setIsSuggestionsOpen(false);
          setIsLoadingSuggestions(false);
          return;
        }
        setIsLoadingSuggestions(true);

        try {
          // Now searchFiles returns { status, totalMatches, results }
          const data = await searchFiles(currentWorkspace._id, query);
          if (data.status === "ok") {
            setSuggestions(data.results);
            setTotalSearchMatches(data.totalMatches);
            setIsSuggestionsOpen(true);
          } else {
            setSuggestions([]);
            setTotalSearchMatches(0);
            setIsSuggestionsOpen(false);
          }
        } catch (error) {
          console.error("Error fetching search suggestions:", error);
          setSuggestions([]);
          setTotalSearchMatches(0);
          setIsSuggestionsOpen(false);
        } finally {
          setIsLoadingSuggestions(false);
        }
      }, 300),
    [currentWorkspace]
  );

  // On unmount, cancel the debounce
  useEffect(() => {
    return () => {
      fetchSearchSuggestions.cancel();
    };
  }, [fetchSearchSuggestions]);

  // Every time searchTerm changes, fetch suggestions
  useEffect(() => {
    if (currentWorkspace) {
      fetchSearchSuggestions(searchTerm);
    }
  }, [searchTerm, fetchSearchSuggestions, currentWorkspace]);

  // Select Suggestion Handler
  const handleSelectSuggestion = async (file) => {
    if (!file) return;

    // Navigate to the parent folder
    const parentFolder = file.parentFolder; // Assuming parentFolder is populated
    const parentFolderId = parentFolder ? parentFolder._id : null;

    navigateToFolder(parentFolderId, parentFolder ? parentFolder.originalFileName : "Root");

    // Select the file, close suggestions, and reset search term
    setSelectedItems(new Set([file._id]));
    setIsSuggestionsOpen(false);
    setSuggestions([]);
    setSearchTerm("");
  };

  // Set the file to rename and open the modal
  const handleRenameModalOpen = (fileId) => {
    setFileToRename(fileId);
    onOpenRenameModal();
  };

  // Open Create Folder Modal
  const handleCreateFolderModalOpen = () => {
    onOpenCreateFolderModal();
  };

  // Open Create Link Modal
  const handleCreateLinkModalOpen = () => {
    onOpenCreateLinkModal();
  };

  // Open Import Modal
  const handleImportModalOpen = () => {
    onOpenImportModal();
  };

  // Set the selected image and open the modal
  const handleImagePreviewModalOpen = (file) => {
    setSelectedImage({
      ...file,
      downloadUrl: file.downloadUrl,
    });
    onOpenImagePreviewModal();
  };

  // Import folder with its structure handler
  const handleImportFolder = async (files) => {
    // Check if there are any files in the selected folder
    if (files.length === 0) {
      customToast({
        title: "No Files Selected",
        description: "Please select a folder containing files to import.",
        status: "warning",
      });
      return;
    }

    // Calculate total size of selected folder and check limits
    const totalSize = files.reduce((acc, file) => acc + file.size, 0);
    const MAX_ITEMS = 50;
    const MAX_SIZE_MB = 2000; // 2GB
    const totalSizeMB = totalSize / (1024 * 1024);
    if (totalSizeMB > MAX_SIZE_MB) {
      customToast({
        title: "Import Aborted",
        description: `The selected folder exceeds the maximum allowed size of ${MAX_SIZE_MB}MB.`,
        status: "error",
      });
      return;
    } else if (files?.length > MAX_ITEMS) {
      customToast({
        title: "Import Aborted",
        description: `The selected folder exceeds the maximum allowed items of ${MAX_ITEMS}.`,
        status: "error",
      });
      return;
    }

    // Start Import Process
    setIsImportProgressOpen(true);
    setImportProgress(0);
    setImportStatusMessage("Initializing import...");

    try {
      // Create the main folder
      const mainFolderName = extractMainFolderName(files);
      setImportStatusMessage(`Creating main folder: ${mainFolderName}`);
      const mainFolder = await createFolderMutation.mutateAsync({
        folderName: mainFolderName,
        companyId: currentWorkspace._id,
        parentFolderId: currentFolderId,
      });
      setImportProgress((prev) => prev + 5);

      // Parse folder structure from selected folder
      const folderStructure = parseFolderStructure(files, mainFolderName);

      // Recursively create folders and upload files
      const totalOperations = countTotalOperations(folderStructure);
      let completedOperations = 0;

      await importFolderStructure(folderStructure, mainFolder._id, () => {
        completedOperations += 1;
        const progress = Math.round((completedOperations / totalOperations) * 100);
        setImportProgress(progress);
        setImportStatusMessage(`Importing... (${completedOperations}/${totalOperations})`);
      });

      setImportProgress(100);
      setImportStatusMessage("Import completed successfully!");

      // Navigate to the newly created main folder
      navigateToFolder(mainFolder._id, mainFolder.originalFileName);

      // Close the progress modal after a short delay
      setTimeout(() => {
        setIsImportProgressOpen(false);
      }, 1000);
    } catch (error) {
      console.error("Error importing folder:", error);
      customToast({
        title: "Import Failed",
        description: error.message || "An error occurred during folder import.",
        status: "error",
      });
      onCloseImportModal();
    }
  };

  const extractMainFolderName = (files) => {
    if (files.length === 0) return "Imported Folder";
    const firstFilePath = files[0].webkitRelativePath || files[0].name;
    const mainFolder = firstFilePath.split("/")[0];
    return mainFolder || "Imported Folder";
  };

  const parseFolderStructure = (files, mainFolderName) => {
    if (!files || typeof files.forEach !== "function") {
      console.error("Invalid files input:", files);
      return {};
    }

    const structure = {};

    files.forEach((file) => {
      const relativePath = file.webkitRelativePath || file.name;
      const pathSegments = relativePath.split("/").filter((seg) => seg !== mainFolderName);
      let currentLevel = structure;

      // Traverse the path segments to create the folder structure
      pathSegments.forEach((segment, index) => {
        if (index === pathSegments.length - 1) {
          // It's a file
          if (!currentLevel.files) currentLevel.files = [];
          currentLevel.files.push(file);
        } else {
          // It's a folder
          if (!currentLevel.folders) currentLevel.folders = {};
          if (!currentLevel.folders[segment]) {
            currentLevel.folders[segment] = {};
          }
          currentLevel = currentLevel.folders[segment];
        }
      });
    });

    return structure;
  };

  const countTotalOperations = (structure) => {
    let count = 1; // Main folder is always created (set to 1)
    const traverse = (node) => {
      if (node.folders) {
        Object.values(node.folders).forEach((folder) => {
          count += 1; // Folder creation (each folder is an operation)
          traverse(folder);
        });
      }
      if (node.files) {
        count += node.files.length; // File uploads (each file is an operation)
      }
    };
    traverse(structure);
    return count;
  };

  const importFolderStructure = async (structure, parentFolderId, onProgress) => {
    const traverse = async (node, currentParentId) => {
      if (node.folders) {
        for (const [folderName, folderNode] of Object.entries(node.folders)) {
          try {
            const newFolder = await createFolderMutation.mutateAsync({
              folderName: folderName,
              companyId: currentWorkspace._id,
              parentFolderId: currentParentId,
            });
            onProgress();
            await traverse(folderNode, newFolder._id);
          } catch (error) {
            console.error("Error creating folder:", error);
          }
        }
      }
      if (node.files) {
        for (const file of node.files) {
          try {
            await uploadFileMutation.mutateAsync({
              file,
              selectedUserIds: [],
              companyId: currentWorkspace._id,
              parentFolderId: currentParentId,
            });
          } catch (error) {
            console.error("Error uploading file:", error);
          }
          onProgress();
        }
      }
    };

    await traverse(structure, parentFolderId);
  };

  const moveItems = async (fileIds, newParentFolderId) => {
    try {
      await moveFilesMutation.mutateAsync({
        fileIds: fileIds,
        newParentFolderId: newParentFolderId,
        companyId: currentWorkspace._id,
      });
      setSelectedItems(new Set());
      setIsAllSelected(false);
      // Optionally, refetch files after moving
    } catch (error) {
      console.error("Error moving files:", error);
    }
  };

  return (
    <FilesContext.Provider
      value={{
        currentWorkspace,
        files: sortedFiles,
        selectedItems,
        isAllSelected,
        currentFolderId,
        folderPath,
        sortField,
        sortDirection,
        isLoadingSuggestions,
        suggestions,
        searchTerm,
        isSuggestionsOpen,
        isFilesLoading,
        filesError,
        totalSearchMatches,
        handleCreateFolder,
        handleDeleteConfirm,
        handleRenameSubmit,
        handleCreateLink,
        updateSortField,
        updateSortDirection,
        handleFolderClick,
        handleBreadcrumbClick,
        handleFileSelect,
        handleSelectAll,
        handleMassDelete,
        handleFileUpload,
        handleDownloadFile,
        setSearchTerm,
        setIsSuggestionsOpen,
        handleSelectSuggestion,
        setSelectedItems,
        moveItems,
        handleGoBack,

        // Modal Controls
        isRenameModalOpen,
        onOpenRenameModal,
        handleRenameModalOpen,
        onCloseRenameModal,
        fileToRename,

        isDeleteConfirmationModalOpen,
        onOpenDeleteConfirmationModal,
        handleDeleteConfirmationModalOpen,
        onCloseDeleteConfirmationModal,
        filesToDelete,

        isCreateFolderModalOpen,
        onOpenCreateFolderModal,
        handleCreateFolderModalOpen,
        onCloseCreateFolderModal,

        isCreateLinkModalOpen,
        onOpenCreateLinkModal,
        handleCreateLinkModalOpen,
        onCloseCreateLinkModal,

        isImportModalOpen,
        onOpenImportModal,
        handleImportModalOpen,
        onCloseImportModal,

        isImagePreviewModalOpen,
        onOpenImagePreviewModal,
        handleImagePreviewModalOpen,
        onCloseImagePreviewModal,
        setSelectedImage,
        selectedImage,

        // Import Folder Controls
        handleImportFolder,

        // Import Progress Modal Controls
        isImportProgressOpen,
        setIsImportProgressOpen,
        importProgress,
        importStatusMessage,
      }}
    >
      {children}
      {/* Import Progress Modal */}
      <ImportProgressModal isOpen={isImportProgressOpen} onClose={() => {}} progress={importProgress} statusMessage={importStatusMessage} />
    </FilesContext.Provider>
  );
};
