// client/src/components/Files/FilesList.jsx

import React, { useContext, useEffect, useRef, memo, useCallback, useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Tooltip,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  HStack,
  Input,
  Button,
  Flex,
  PopoverContent,
  PopoverBody,
  Popover,
  Portal,
  VStack,
  Spinner,
  useOutsideClick,
  InputGroup,
  InputLeftElement,
  PopoverAnchor,
  useColorModeValue,
  useBreakpointValue,
  Divider,
} from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import {
  PiDotsThreeBold,
  PiDownloadSimple,
  PiTrash,
  PiEye,
  PiPencilSimpleLine,
  PiCheck,
  PiSortAscending,
  PiSortDescending,
  PiArrowsDownUp,
  PiLink,
  PiDotsSixVertical,
  PiPlus,
  PiMagnifyingGlass,
  PiFolderSimple,
  PiCopy,
  PiSelection,
  PiSelectionSlash,
  PiClipboard,
} from "react-icons/pi";
import { BsFileEarmarkPlus, BsFolder, BsFolderPlus } from "react-icons/bs";
import { ArrowBackIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { FilesContext } from "../5 - General/Context/FilesContext";
import UserAvatar from "./Reusable/UserAvatar";
import { renderFileIcon } from "../5 - General/Utils/UtilsFileIcon";
import { formatFileSize } from "../5 - General/Utils/UtilsFormatData";
import { useDrag, useDrop, useDragLayer } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import useCustomToast from "../5 - General/Utils/UtilsNotification";
import { WorkspaceContext } from "../5 - General/Context/WorkspaceContext";
import { duplicateFile as duplicateFileAPI } from "../4 - API/API-Files";

// For row animations
const MotionTr = memo(motion.create(Tr));

// DnD item type
const ItemTypes = {
  FILE: "file",
};

const FilesList = () => {
  const {
    files,
    selectedItems,
    handleFileSelect,
    handleSelectAll,
    handleDownloadFile,
    sortField,
    updateSortField,
    totalSearchMatches,
    sortDirection,
    updateSortDirection,
    handleFolderClick,
    currentWorkspace,
    searchTerm,
    setSearchTerm,
    suggestions,
    handleSelectSuggestion,
    isFilesLoading,
    isLoadingSuggestions,
    handleRenameModalOpen,
    handleDeleteConfirmationModalOpen,
    currentFolderId,
    handleImportFolder,
    handleMassDelete,
    onOpenImportModal,
    onOpenCreateLinkModal,
    onOpenCreateFolderModal,
    handleImagePreviewModalOpen,
    setSelectedItems,
    handleFileUpload,
    handleGoBack,
  } = useContext(FilesContext);
  const { themeColor } = useContext(WorkspaceContext);

  const [selectionStart, setSelectionStart] = useState(null);
  const [selectionBox, setSelectionBox] = useState({
    visible: false,
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [contextMenu, setContextMenu] = useState({
    isOpen: false,
    x: 0,
    y: 0,
    file: null,
  });
  const inputRef = useRef(null);
  const popoverRef = useRef(null);
  const contextMenuRef = useRef(null);
  const folderInputRef = useRef(null);

  const [globalContextMenu, setGlobalContextMenu] = useState({
    isOpen: false,
    x: 0,
    y: 0,
  });

  // State to handle local-file dragging (outside of react-dnd) + overlay
  const [isDraggingLocalFiles, setIsDraggingLocalFiles] = useState(false);

  // Detect mobile vs. desktop
  const isMobile = useBreakpointValue({ base: true, md: false });

  // Use Chakra color modes
  const bgHover = useColorModeValue(`gray.100`, `gray.700`);
  const bgHoverIsSelected = useColorModeValue(`${themeColor}.200`, `${themeColor}.900`);
  const bgDropTarget = useColorModeValue(`${themeColor}.300`, `${themeColor}.700`);
  const textColor = useColorModeValue("black", "white");
  const textLightColor = useColorModeValue("gray.600", "gray.400");
  const menuContextBgColor = useColorModeValue("white", "gray.700");
  const tableHeaderBgColor = useColorModeValue(`gray.50`, `gray.700`);
  const spinnerColor = useColorModeValue(`${themeColor}.500`, `${themeColor}.300`);
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const noFileAndFolderFoundBgColor = useColorModeValue(`gray.50`, `gray.700`);
  const folderColor = useColorModeValue(`${themeColor}.500`, `${themeColor}.300`);

  const customToast = useCustomToast();

  // === Drag & Drop from the local file system (NOT react-dnd for rearranging) ===

  // If the user drags from their OS, we check the event’s `dataTransfer.types`.
  // If it includes 'Files', we show an overlay. We also allow default so the drop can happen.
  const handleWindowDragOver = useCallback((e) => {
    // Only show overlay if the user is dragging actual files from their OS
    if (e.dataTransfer?.types?.includes("Files")) {
      e.preventDefault();
      setIsDraggingLocalFiles(true);
    }
  }, []);

  const handleWindowDragLeave = useCallback((e) => {
    // Only hide overlay if this is indeed from a file drag
    if (e.dataTransfer?.types?.includes("Files")) {
      // Sometimes dragleave can be triggered multiple times or from children,
      // so check relatedTarget if needed. But simplest approach:
      setIsDraggingLocalFiles(false);
    }
  }, []);

  const handleWindowDrop = useCallback(
    async (e) => {
      if (e.dataTransfer?.types?.includes("Files")) {
        e.preventDefault();
        setIsDraggingLocalFiles(false);

        const droppedFiles = Array.from(e.dataTransfer.files);
        if (!droppedFiles.length) return;

        // 5-file limit
        if (droppedFiles.length > 5) {
          customToast({
            title: "File Limit Exceeded",
            description: "You can only upload up to 5 files at once.",
            status: "warning",
          });
          return;
        }

        // Upload each dropped file using existing context method
        for (const file of droppedFiles) {
          try {
            await handleFileUpload(file);
          } catch (error) {
            console.error("Error uploading file:", error);
            customToast({
              title: "Upload Error",
              description: `Could not upload "${file.name}".`,
              status: "error",
            });
          }
        }
      }
    },
    [handleFileUpload, customToast]
  );

  // Attach window-level event listeners for OS-file dragging
  useEffect(() => {
    window.addEventListener("dragover", handleWindowDragOver);
    window.addEventListener("dragleave", handleWindowDragLeave);
    window.addEventListener("drop", handleWindowDrop);

    return () => {
      window.removeEventListener("dragover", handleWindowDragOver);
      window.removeEventListener("dragleave", handleWindowDragLeave);
      window.removeEventListener("drop", handleWindowDrop);
    };
  }, [handleWindowDragOver, handleWindowDragLeave, handleWindowDrop]);

  // Show a “Drop to Upload” overlay if isDraggingLocalFiles is true
  const dropOverlay = isDraggingLocalFiles && (
    <Box
      position="fixed"
      top={0}
      left={0}
      width="100vw"
      height="100vh"
      bg="rgba(0,0,0,0.5)"
      zIndex={2000}
      display="flex"
      alignItems="center"
      justifyContent="center"
      pointerEvents="none"
    >
      <Text color="white" fontSize="2xl" fontWeight="bold" userSelect="none">
        Drop to upload (Max 5)
      </Text>
    </Box>
  );

  // === END drag & drop from local file system ===

  // ============== Selection Box Code =================

  const handleMouseDown = (e) => {
    // Only proceed on left-click
    if (e.button !== 0) return;

    // If clicking on an existing file row, let that row handle its click
    if (e.target.closest("tr")) return;

    // If clicking inside the global context menu, do nothing
    if (e.target.closest("[data-global-context-menu]")) return;

    // If clicking inside the context menu, do nothing
    if (e.target.closest("[data-context-menu]")) return;

    // Clear any previous selection before starting a new one
    setSelectedItems(new Set());

    // Record where the selection started and show the selection box
    setSelectionStart({ x: e.clientX, y: e.clientY });
    setSelectionBox({
      visible: true,
      x: e.clientX,
      y: e.clientY,
      width: 0,
      height: 0,
    });
  };

  const handleMouseMove = (e) => {
    if (!selectionStart) return;

    const currentX = e.clientX;
    const currentY = e.clientY;
    const x = Math.min(currentX, selectionStart.x);
    const y = Math.min(currentY, selectionStart.y);
    const width = Math.abs(currentX - selectionStart.x);
    const height = Math.abs(currentY - selectionStart.y);

    setSelectionBox({ visible: true, x, y, width, height });
  };

  const handleMouseUp = (e) => {
    if (!selectionStart) return;

    // If the selection box is very small, treat it as a click in the background and clear selection
    if (selectionBox.width < 5 && selectionBox.height < 5) {
      setSelectedItems(new Set());
    } else {
      // The selection rectangle in viewport (client) coordinates:
      const selectionRect = {
        left: selectionBox.x,
        top: selectionBox.y,
        right: selectionBox.x + selectionBox.width,
        bottom: selectionBox.y + selectionBox.height,
      };

      // Create a new selection based on which file rows intersect the selection rectangle.
      // (Each row is assumed to have an id in the format "file-row-<file._id>")
      const newSelected = new Set();
      document.querySelectorAll("tr[id^='file-row-']").forEach((row) => {
        const rect = row.getBoundingClientRect();
        // Check for intersection between the row and the selection rectangle.
        if (
          rect.left < selectionRect.right &&
          rect.right > selectionRect.left &&
          rect.top < selectionRect.bottom &&
          rect.bottom > selectionRect.top
        ) {
          const fileId = row.id.replace("file-row-", "");
          newSelected.add(fileId);
        }
      });
      setSelectedItems(newSelected);
    }

    // Clear the selection rectangle state.
    setSelectionStart(null);
    setSelectionBox({ visible: false, x: 0, y: 0, width: 0, height: 0 });
  };

  // ============== File Type Filter / Sorting Code (unchanged) ==============

  const getFileType = useCallback((file) => {
    if (file.type === "folder") {
      return "Folders";
    } else if (file.type === "link") {
      return "Links";
    } else {
      return file.originalFileName.split(".").pop().toLowerCase();
    }
  }, []);

  const availableFileTypes = React.useMemo(() => {
    const extensionsSet = new Set();
    let hasLink = false;
    files?.forEach((file) => {
      if (file.type === "file") {
        const ext = file.originalFileName.split(".").pop().toLowerCase();
        extensionsSet.add(ext);
      } else if (file.type === "link") {
        hasLink = true;
      }
    });
    return ["Folders", ...(hasLink ? ["Links"] : []), ...Array.from(extensionsSet)];
  }, [files]);

  const [selectedFileTypes, setSelectedFileTypes] = useState(new Set(availableFileTypes));
  useEffect(() => {
    setSelectedFileTypes((prevSelectedTypes) => {
      const updatedSet = new Set(prevSelectedTypes);
      availableFileTypes.forEach((type) => {
        if (!updatedSet.has(type)) {
          updatedSet.add(type);
        }
      });
      return updatedSet;
    });
  }, [availableFileTypes]);

  const selectedFileTypesArray = Array.from(selectedFileTypes);
  let selectedFileTypeTitle;
  if (selectedFileTypes.size === availableFileTypes.length) {
    selectedFileTypeTitle = "All";
  } else if (selectedFileTypes.size === 1) {
    selectedFileTypeTitle = selectedFileTypesArray[0];
  } else {
    selectedFileTypeTitle = "Few";
  }

  const sortFilesFunction = useCallback(() => {
    if (!Array.isArray(files)) return [];
    const sorted = [...files];

    // Filter / sort by file type selection first
    sorted.sort((a, b) => {
      const aType = getFileType(a);
      const bType = getFileType(b);
      const aMatches = selectedFileTypes.has(aType);
      const bMatches = selectedFileTypes.has(bType);

      if (aMatches && !bMatches) return -1;
      if (!aMatches && bMatches) return 1;

      // Then sort folders before files
      if (a.type === "folder" && b.type !== "folder") return -1;
      if (a.type !== "folder" && b.type === "folder") return 1;

      let aVal, bVal;
      switch (sortField) {
        case "name":
          aVal = a.originalFileName.toLowerCase();
          bVal = b.originalFileName.toLowerCase();
          break;
        case "date":
          aVal = new Date(a.createdAt);
          bVal = new Date(b.createdAt);
          break;
        case "creator":
          aVal = a.uploadedBy.firstName.toLowerCase();
          bVal = b.uploadedBy.firstName.toLowerCase();
          break;
        default:
          return 0;
      }
      if (sortDirection === "asc") {
        return aVal > bVal ? 1 : aVal < bVal ? -1 : 0;
      } else if (sortDirection === "desc") {
        return aVal < bVal ? 1 : aVal > bVal ? -1 : 0;
      } else {
        return 0;
      }
    });

    return sorted;
  }, [files, getFileType, selectedFileTypes, sortField, sortDirection]);

  const handleSort = (field) => {
    if (sortField === field) {
      if (sortDirection === "asc") {
        updateSortDirection("desc");
      } else if (sortDirection === "desc") {
        updateSortField(null);
        updateSortDirection(null);
      } else {
        updateSortDirection("asc");
      }
    } else {
      updateSortField(field);
      updateSortDirection("asc");
    }
  };

  const isImageFile = (fileName) => {
    const extensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"];
    const ext = fileName.split(".").pop().toLowerCase();
    return extensions.includes(ext);
  };

  // Single/Double click handling
  const clickTimeout = useRef(null);

  const handleRowClick = (e, file) => {
    e.stopPropagation();

    if (isMobile && file.type === "folder") {
      // On mobile: single tap => open folder
      handleFolderClick(file._id, file.originalFileName);
      return;
    }

    // Desktop: select file/folder on single click
    if (file.type === "folder") {
      if (clickTimeout.current) {
        clearTimeout(clickTimeout.current);
        clickTimeout.current = null;
      }
      clickTimeout.current = setTimeout(() => {
        handleFileSelect(file._id);
        clickTimeout.current = null;
      }, 250);
    } else {
      handleFileSelect(file._id);
    }
  };

  const handleRowDoubleClick = (e, file) => {
    e.preventDefault();
    e.stopPropagation();
    // Desktop double-click => open folder or download/preview
    if (clickTimeout.current) {
      clearTimeout(clickTimeout.current);
      clickTimeout.current = null;
    }
    if (file.type === "folder") {
      handleFolderClick(file._id, file.originalFileName);
    } else if (file.type === "link") {
      window.open(file.url, "_blank");
    } else if (file.type === "file") {
      if (isImageFile(file.originalFileName)) {
        handleImagePreviewModalOpen(file);
      } else {
        handleDownloadFile(file);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (clickTimeout.current) {
        clearTimeout(clickTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    if (selectedItems.size === 1) {
      const selectedId = Array.from(selectedItems)[0];
      const selectedRow = document.getElementById(`file-row-${selectedId}`);
      if (selectedRow) {
        selectedRow.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [selectedItems]);

  // Hidden <input> for folder import
  const triggerFolderInput = () => {
    if (folderInputRef.current) {
      folderInputRef.current.click();
    }
  };

  const onFolderSelected = (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      handleImportFolder(files);
    }
    event.target.value = null;
  };

  // Right-click context menu
  useOutsideClick({
    ref: contextMenuRef,
    handler: () => {
      if (contextMenu.isOpen) {
        setContextMenu({ ...contextMenu, isOpen: false });
      }
    },
  });

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape" && contextMenu.isOpen) {
        setContextMenu({ ...contextMenu, isOpen: false });
      }
    };
    document.addEventListener("keydown", handleEsc);
    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, [contextMenu]);

  const handleGlobalContextMenu = (e) => {
    if (!e.target.closest("tr")) {
      e.preventDefault();
      setGlobalContextMenu({ isOpen: true, x: e.clientX, y: e.clientY });
    }
  };

  // Sorting icon
  const renderSortIcon = (field) => {
    if (sortField !== field) {
      return <PiArrowsDownUp fontSize="18px" style={{ marginLeft: "4px" }} />;
    }
    if (sortDirection === "asc") {
      return <PiSortAscending fontSize="18px" style={{ marginLeft: "4px" }} />;
    }
    if (sortDirection === "desc") {
      return <PiSortDescending fontSize="18px" style={{ marginLeft: "4px" }} />;
    }
    return <PiArrowsDownUp fontSize="18px" style={{ marginLeft: "4px" }} />;
  };

  // Custom drag layer for rearranging files in-app
  const CustomDragLayer = () => {
    const { isDragging, currentOffset, item } = useDragLayer((monitor) => ({
      item: monitor.getItem(),
      isDragging: monitor.isDragging(),
      currentOffset: monitor.getSourceClientOffset(),
    }));

    const bgColor = useColorModeValue("white", "gray.900");

    if (!isDragging || !currentOffset || !item) {
      return null;
    }

    const style = {
      position: "fixed",
      pointerEvents: "none",
      left: currentOffset.x,
      top: currentOffset.y,
      transform: "translate(200%, 50%)",
      zIndex: 1000,
    };

    return (
      <div style={style}>
        <Box bg={bgColor} p={2} borderRadius="md" boxShadow="md">
          <Text>
            {item.files.length} item{item.files.length > 1 ? "s" : ""} selected
          </Text>
        </Box>
      </div>
    );
  };

  // ============ RENDER ============

  return (
    <>
      {/* Overlay for dropping local files (only shown if isDraggingLocalFiles) */}
      {dropOverlay}

      {/* Hidden Folder Input for Importing Folders */}
      <input
        type="file"
        webkitdirectory="true"
        directory="true"
        multiple
        ref={folderInputRef}
        style={{ display: "none" }}
        onChange={onFolderSelected}
      />

      <Box
        p={4}
        height="calc(100vh - 80px)"
        display="flex"
        flexDirection="column"
        position="relative" // so the selection div can be positioned absolutely
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onContextMenu={handleGlobalContextMenu}
      >
        {/* Header */}
        <Flex justify="space-between" align="center" mb={4} px={isMobile ? 0 : 4} overflowY="hidden">
          {/* Search Bar with Auto-Suggestions */}
          {currentWorkspace && (
            <Box position="relative" width={isMobile ? "250px" : "500px"}>
              <Popover
                isOpen={isPopoverOpen}
                onOpen={() => setIsPopoverOpen(true)}
                onClose={() => setIsPopoverOpen(false)}
                placement="bottom-start"
                matchWidth
                autoFocus={false}
              >
                <PopoverAnchor>
                  <HStack spacing={2} p={1}>
                    <InputGroup maxW="500px">
                      <InputLeftElement pointerEvents="none">
                        <PiMagnifyingGlass fontSize="18px" color="gray.400" />
                      </InputLeftElement>
                      <Input
                        ref={inputRef}
                        placeholder="Search files..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        variant="outline"
                        onFocus={() => setIsPopoverOpen(true)}
                        userSelect="none"
                      />
                    </InputGroup>
                    {searchTerm && (
                      <Button
                        size="sm"
                        onClick={() => {
                          setSearchTerm("");
                          setIsPopoverOpen(false);
                        }}
                        userSelect="none"
                      >
                        Clear
                      </Button>
                    )}
                  </HStack>
                </PopoverAnchor>
                <Portal>
                  <PopoverContent ref={popoverRef} maxH="500px" width="100%" overflowY="auto" zIndex={10} borderRadius="md">
                    <PopoverBody>
                      {!searchTerm ? (
                        <Box p={2}>
                          <Text color={textLightColor} userSelect="none">
                            Search for a file...
                          </Text>
                        </Box>
                      ) : isLoadingSuggestions ? (
                        <Flex justify="center" align="center" py={4}>
                          <Spinner size="md" color={spinnerColor} />
                        </Flex>
                      ) : suggestions.length > 0 ? (
                        <>
                          <Text my={2} mb={4} color={textLightColor} userSelect="none">
                            Found {totalSearchMatches} matching file
                            {totalSearchMatches !== 1 ? "s" : ""}.
                            {totalSearchMatches > suggestions.length ? ` Showing the first ${suggestions.length}.` : null}
                          </Text>

                          {suggestions.map((file) => (
                            <Tooltip
                              key={file._id}
                              label={`Click to navigate to the ${file.type}: ${file.originalFileName}`}
                              placement="right"
                              hasArrow
                            >
                              <Box
                                onClick={() => handleSelectSuggestion(file)}
                                cursor="pointer"
                                p={2}
                                _hover={{ bg: bgHover }}
                                borderRadius="md"
                                m={1}
                                border="1px solid"
                                borderColor={borderColor}
                                display="flex"
                                alignItems="center"
                              >
                                <Text whiteSpace="nowrap" fontSize="md" isTruncated userSelect="none">
                                  {file.originalFileName}
                                </Text>
                              </Box>
                            </Tooltip>
                          ))}
                        </>
                      ) : (
                        <Box p={2}>
                          <Text color={textLightColor} userSelect="none">
                            No results found.
                          </Text>
                        </Box>
                      )}
                    </PopoverBody>
                  </PopoverContent>
                </Portal>
              </Popover>
            </Box>
          )}

          {/* "Select All" button */}
          <HStack spacing={2}>
            <Button size="xs" onClick={() => handleSelectAll()} userSelect="none">
              {selectedItems.size > 0 ? "Deselect All" : "Select All"}
            </Button>
          </HStack>
        </Flex>

        {/* If no files/folders */}
        {isFilesLoading ? (
          // Show spinner while loading initial data
          <Flex justify="center" align="center" mt={8} flex="1">
            <Spinner size="xl" color={spinnerColor} />
          </Flex>
        ) : files.length === 0 ? (
          currentFolderId === null ? (
            <Flex justify="center" align="start" mt={8} flex="1">
              <Box
                p={8}
                border="1px solid"
                bg={noFileAndFolderFoundBgColor}
                borderColor={borderColor}
                borderRadius="xl"
                textAlign="center"
                boxShadow="md"
                h="fit-content"
                width={["90%", "70%", "50%"]}
              >
                <Text fontSize="lg" mb={2} fontWeight="bold" color={textColor}>
                  No files or folders found for {currentWorkspace?.companyName || "this company"}.
                </Text>
                <Text mb={4} fontSize="md" color="gray.500">
                  Add files and folders to get started.
                </Text>
                {currentWorkspace && (
                  <Menu>
                    <MenuButton as={Button} leftIcon={<PiPlus fontSize="18px" />} fontSize="sm" fontWeight="normal" colorScheme={themeColor}>
                      Add New
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={() => onOpenImportModal()} icon={<BsFileEarmarkPlus fontSize="18px" />} fontSize="sm">
                        Import a File
                      </MenuItem>
                      <MenuItem onClick={triggerFolderInput} icon={<BsFolderPlus fontSize="18px" />} fontSize="sm">
                        Import a Folder
                      </MenuItem>
                      <MenuItem onClick={() => onOpenCreateLinkModal()} icon={<PiLink fontSize="18px" />} fontSize="sm">
                        Add a Link
                      </MenuItem>
                      <MenuDivider />
                      <MenuItem onClick={() => onOpenCreateFolderModal()} icon={<PiFolderSimple fontSize="18px" />} fontSize="sm">
                        Create a Folder
                      </MenuItem>
                    </MenuList>
                  </Menu>
                )}
              </Box>
            </Flex>
          ) : (
            <Flex justify="center" align="start" mt={8} flex="1">
              <Box
                p={8}
                border="1px solid"
                bg={noFileAndFolderFoundBgColor}
                borderColor={borderColor}
                borderRadius="xl"
                textAlign="center"
                boxShadow="md"
                h="fit-content"
                width={["90%", "70%", "50%"]}
              >
                <Text fontSize="lg" mb={2} fontWeight="bold" color={textColor}>
                  This folder is empty.
                </Text>
                <Text mb={4} fontSize="md" color="gray.500">
                  Add files or folders to get started.
                </Text>
                <Button mr={2} leftIcon={<ArrowBackIcon />} onClick={() => handleGoBack()} size="xs">
                  Back
                </Button>
                <Menu>
                  <MenuButton as={Button} leftIcon={<PiPlus fontSize="18px" />} colorScheme={themeColor} size="xs">
                    Add New
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => onOpenImportModal()} icon={<BsFileEarmarkPlus fontSize="18px" />} fontSize="sm">
                      Import a File
                    </MenuItem>
                    <MenuItem onClick={triggerFolderInput} icon={<BsFolderPlus fontSize="18px" />} fontSize="sm">
                      Import a Folder
                    </MenuItem>
                    <MenuItem onClick={() => onOpenCreateLinkModal()} icon={<PiLink fontSize="18px" />} fontSize="sm">
                      Add a Link
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem onClick={() => onOpenCreateFolderModal()} icon={<PiFolderSimple fontSize="18px" />} fontSize="sm">
                      Create a Folder
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box>
            </Flex>
          )
        ) : (
          <Box flex="1" overflowY="scroll" css={{ "&::-webkit-scrollbar": { display: "none" }, msOverflowStyle: "none", scrollbarWidth: "none" }}>
            <Table variant="unstyled" width="100%">
              <Thead>
                <Tr position="sticky" zIndex={10} top="0" bg={tableHeaderBgColor} borderBottom="1px solid" borderColor={borderColor}>
                  <Th
                    textTransform="capitalize"
                    fontSize="md"
                    pr={1}
                    w={isMobile ? "80px" : "120px"}
                    fontWeight="600"
                    cursor="pointer"
                    userSelect="none"
                  >
                    <Flex align="center" width="100%">
                      <Menu closeOnSelect={false}>
                        <MenuButton as={Box} display="flex" alignItems="center" cursor="pointer">
                          {selectedFileTypeTitle}
                          <ChevronDownIcon ml={1} />
                        </MenuButton>
                        <MenuList>
                          <MenuItem
                            onClick={() => {
                              setSelectedFileTypes(new Set(availableFileTypes));
                            }}
                          >
                            <HStack justifyContent="space-between" w="100%">
                              <Text textTransform="capitalize" fontSize="md" fontWeight="400">
                                All Files and Folders
                              </Text>
                              {selectedFileTypes.size === availableFileTypes.length && <PiCheck color={`${themeColor}.500`} />}
                            </HStack>
                          </MenuItem>
                          <MenuDivider />
                          {availableFileTypes.map((type) => (
                            <MenuItem
                              key={type}
                              onClick={() => {
                                setSelectedFileTypes((prev) => {
                                  if (prev.size === availableFileTypes.length) {
                                    return new Set([type]);
                                  } else {
                                    const newSet = new Set(prev);
                                    if (newSet.has(type)) {
                                      newSet.delete(type);
                                    } else {
                                      newSet.add(type);
                                    }
                                    return newSet;
                                  }
                                });
                              }}
                            >
                              <HStack justifyContent="space-between" w="100%">
                                <Text textTransform="capitalize" fontSize="md" fontWeight="400">
                                  {type}
                                </Text>
                                {selectedFileTypes.has(type) && <PiCheck color={`${themeColor}.500`} />}
                              </HStack>
                            </MenuItem>
                          ))}
                        </MenuList>
                      </Menu>
                    </Flex>
                  </Th>
                  <Th
                    _hover={{ bg: bgHover }}
                    textTransform="capitalize"
                    transition="all 0.2s"
                    fontSize="md"
                    fontWeight="600"
                    cursor="pointer"
                    onClick={() => handleSort("name")}
                    userSelect="none"
                    align="center"
                  >
                    <HStack w="fit-content">
                      <Tooltip label="Sort by Name" hasArrow>
                        <Text fontSize="md">File Name</Text>
                      </Tooltip>
                      {renderSortIcon("name")}
                    </HStack>
                  </Th>
                  {!isMobile && (
                    <Th
                      _hover={{ bg: bgHover }}
                      textTransform="capitalize"
                      transition="all 0.2s"
                      fontSize="md"
                      fontWeight="600"
                      cursor="pointer"
                      onClick={() => handleSort("creator")}
                      userSelect="none"
                      align="center"
                    >
                      <HStack>
                        <Tooltip label="Sort by Creator" hasArrow>
                          <Text fontSize="md">Creator</Text>
                        </Tooltip>
                        {renderSortIcon("creator")}
                      </HStack>
                    </Th>
                  )}
                  {!isMobile && (
                    <Th
                      _hover={{ bg: bgHover }}
                      textTransform="capitalize"
                      transition="all 0.2s"
                      fontSize="md"
                      fontWeight="600"
                      cursor="pointer"
                      onClick={() => handleSort("date")}
                      userSelect="none"
                      align="center"
                    >
                      <HStack>
                        <Tooltip label="Sort by Date" hasArrow>
                          <Text fontSize="md">Creation Date</Text>
                        </Tooltip>
                        {renderSortIcon("date")}
                      </HStack>
                    </Th>
                  )}
                  <Th textTransform="capitalize" fontSize="md" fontWeight="600" userSelect="none">
                    <Text fontSize="md">Actions </Text>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <AnimatePresence>
                  {sortFilesFunction()
                    .filter((file) => file && file._id && file.uploadedBy)
                    .map((file, index) => (
                      <FileRow
                        key={file._id}
                        file={file}
                        index={index}
                        selectedItems={selectedItems}
                        handleRowClick={handleRowClick}
                        handleRowDoubleClick={handleRowDoubleClick}
                        setContextMenu={setContextMenu}
                        bgHover={bgHover}
                        bgHoverIsSelected={bgHoverIsSelected}
                        bgDropTarget={bgDropTarget}
                        textColor={textColor}
                        textLightColor={textLightColor}
                        renderFileIcon={renderFileIcon}
                        formatFileSize={formatFileSize}
                        isFileTypeSelected={selectedFileTypes.has(getFileType(file))}
                        isImageFile={isImageFile}
                        folderColor={folderColor}
                        menuContextBgColor={menuContextBgColor}
                        isMobile={isMobile}
                        handleSelectAll={handleSelectAll}
                        currentWorkspace={currentWorkspace}
                      />
                    ))}
                </AnimatePresence>
              </Tbody>
            </Table>
          </Box>
        )}

        {/* Right-click context menu */}
        {contextMenu.isOpen && contextMenu.file && (
          <Portal>
            <Box
              ref={contextMenuRef}
              data-context-menu="true"
              bg={menuContextBgColor}
              position="fixed"
              top={contextMenu.y > window.innerHeight - 200 ? window.innerHeight - 200 : contextMenu.y}
              left={contextMenu.x > window.innerWidth - 200 ? window.innerWidth - 200 : contextMenu.x}
              boxShadow="md"
              borderRadius="md"
              zIndex={1000}
              overflow="hidden"
              minW="150px"
              border="1px solid"
              borderColor={borderColor}
            >
              <VStack align="stretch" spacing={0}>
                {isImageFile(contextMenu.file.originalFileName) && (
                  <Button
                    variant="ghost"
                    size="sm"
                    fontWeight="400"
                    borderRadius="0"
                    justifyContent="flex-start"
                    w="100%"
                    leftIcon={<PiEye fontSize="18px" />}
                    onClick={() => {
                      handleImagePreviewModalOpen(contextMenu.file);
                      setContextMenu({ ...contextMenu, isOpen: false });
                    }}
                    userSelect="none"
                  >
                    View Image
                  </Button>
                )}
                {contextMenu.file.type === "file" && (
                  <Button
                    variant="ghost"
                    justifyContent="flex-start"
                    w="100%"
                    size="sm"
                    borderRadius="0"
                    fontWeight="400"
                    leftIcon={<PiDownloadSimple fontSize="18px" />}
                    onClick={() => {
                      handleDownloadFile(contextMenu.file);
                      setContextMenu({ ...contextMenu, isOpen: false });
                    }}
                    userSelect="none"
                  >
                    Download File
                  </Button>
                )}
                {contextMenu.file.type === "link" && (
                  <Button
                    variant="ghost"
                    justifyContent="flex-start"
                    w="100%"
                    size="sm"
                    fontWeight="400"
                    borderRadius="0"
                    leftIcon={<PiLink fontSize="18px" />}
                    onClick={() => {
                      window.open(contextMenu.file.url, "_blank");
                      setContextMenu({ ...contextMenu, isOpen: false });
                    }}
                    userSelect="none"
                  >
                    Open Link
                  </Button>
                )}
                <Button
                  variant="ghost"
                  justifyContent="flex-start"
                  w="100%"
                  size="sm"
                  borderRadius="0"
                  fontWeight="400"
                  leftIcon={<PiPencilSimpleLine fontSize="18px" />}
                  onClick={() => {
                    handleRenameModalOpen(contextMenu.file._id);
                    setContextMenu({ ...contextMenu, isOpen: false });
                  }}
                  userSelect="none"
                >
                  Rename File
                </Button>
                <Divider my={1} />
                <Button
                  variant="ghost"
                  justifyContent="flex-start"
                  w="100%"
                  size="sm"
                  fontWeight="400"
                  borderRadius="0"
                  onClick={() => onOpenImportModal()}
                  leftIcon={<BsFileEarmarkPlus fontSize="18px" />}
                >
                  Import a File
                </Button>
                <Button
                  variant="ghost"
                  justifyContent="flex-start"
                  w="100%"
                  size="sm"
                  fontWeight="400"
                  borderRadius="0"
                  onClick={triggerFolderInput}
                  leftIcon={<BsFolderPlus fontSize="18px" />}
                >
                  Import a Folder
                </Button>
                <Button
                  variant="ghost"
                  justifyContent="flex-start"
                  w="100%"
                  size="sm"
                  fontWeight="400"
                  borderRadius="0"
                  onClick={() => onOpenCreateLinkModal()}
                  leftIcon={<PiLink fontSize="18px" />}
                >
                  Add a Link
                </Button>
                <Button
                  variant="ghost"
                  justifyContent="flex-start"
                  w="100%"
                  size="sm"
                  fontWeight="400"
                  borderRadius="0"
                  onClick={() => onOpenCreateFolderModal()}
                  leftIcon={<PiFolderSimple fontSize="18px" />}
                >
                  Create a Folder
                </Button>
                {(contextMenu.file.type === "file" || contextMenu.file.type === "link") && <Divider my={1} />}
                <Button
                  variant="ghost"
                  size="sm"
                  justifyContent="flex-start"
                  leftIcon={selectedItems.size > 0 ? <PiSelectionSlash fontSize="18px" /> : <PiSelection fontSize="18px" />}
                  onClick={() => {
                    handleSelectAll();
                    setGlobalContextMenu({ ...globalContextMenu, isOpen: false });
                  }}
                >
                  {selectedItems.size > 0 ? "Deselect All" : "Select All"}
                </Button>
                {selectedItems.size > 0 && (
                  <Button
                    variant="ghost"
                    leftIcon={<PiCopy fontSize="18px" />}
                    size="sm"
                    justifyContent="flex-start"
                    onClick={(e) => {
                      const copiedFiles = Array.from(selectedItems)
                        .map((id) => files.find((f) => f._id === id))
                        .filter(Boolean);
                      localStorage.setItem("copiedFiles", JSON.stringify(copiedFiles));
                      customToast({
                        title: "Copied",
                        description: `${copiedFiles.length} file(s) copied.`,
                        status: "success",
                      });
                      setGlobalContextMenu({ ...globalContextMenu, isOpen: false });
                    }}
                  >
                    Copy
                  </Button>
                )}
                {(() => {
                  const copied = localStorage.getItem("copiedFiles");
                  if (copied) {
                    const copiedFiles = JSON.parse(copied);
                    if (copiedFiles.length > 0) {
                      return (
                        <Button
                          variant="ghost"
                          leftIcon={<PiClipboard fontSize="18px" />}
                          size="sm"
                          justifyContent="flex-start"
                          onClick={async () => {
                            for (const file of copiedFiles) {
                              if (file.type === "file") {
                                try {
                                  await duplicateFileAPI(file._id, currentWorkspace._id, currentFolderId);
                                  customToast({
                                    title: "Pasted",
                                    description: `File "${file.originalFileName}" duplicated.`,
                                    status: "success",
                                  });
                                } catch (error) {
                                  console.error("Error duplicating file:", error);
                                  customToast({
                                    title: "Paste Error",
                                    description: `Could not duplicate "${file.originalFileName}".`,
                                    status: "error",
                                  });
                                }
                              } else if (file.type === "link" || file.type === "folder") {
                                // Optionally handle link duplication if desired.
                                customToast({
                                  title: "Paste Error",
                                  description: `File "${file.originalFileName}" cannot be duplicated for now, we are working on it.`,
                                  status: "error",
                                });
                              }
                            }
                            localStorage.removeItem("copiedFiles");
                            setGlobalContextMenu({ ...globalContextMenu, isOpen: false });
                          }}
                        >
                          Paste
                        </Button>
                      );
                    }
                  }
                  return null;
                })()}
                <Button
                  variant="ghost"
                  justifyContent="flex-start"
                  w="100%"
                  size="sm"
                  fontWeight="400"
                  borderRadius="0"
                  leftIcon={<PiTrash fontSize="18px" />}
                  colorScheme="red"
                  onClick={() => {
                    if (selectedItems.size >= 2) {
                      handleMassDelete(Array.from(selectedItems));
                    } else {
                      handleDeleteConfirmationModalOpen([contextMenu.file._id]);
                    }
                    setContextMenu({ ...contextMenu, isOpen: false });
                  }}
                  userSelect="none"
                >
                  Delete {selectedItems.size >= 2 ? "Selected" : ""}
                </Button>
              </VStack>
            </Box>
          </Portal>
        )}
        {/* --- Global Context Menu (for blank-area right–click) --- */}
        {globalContextMenu.isOpen && (
          <Portal>
            <Box
              position="fixed"
              top={globalContextMenu.y > window.innerHeight - 200 ? window.innerHeight - 200 : globalContextMenu.y}
              left={globalContextMenu.x > window.innerWidth - 200 ? window.innerWidth - 200 : globalContextMenu.x}
              bg={menuContextBgColor}
              data-global-context-menu="true"
              boxShadow="md"
              borderRadius="md"
              zIndex={1000}
              overflow="hidden"
              minW="150px"
              border="1px solid"
              borderColor={borderColor}
              onMouseLeave={() => setGlobalContextMenu({ ...globalContextMenu, isOpen: false })}
            >
              <VStack align="stretch" spacing={0}>
                <Button
                  variant="ghost"
                  size="sm"
                  justifyContent="flex-start"
                  leftIcon={selectedItems.size > 0 ? <PiSelectionSlash fontSize="18px" /> : <PiSelection fontSize="18px" />}
                  onClick={() => {
                    handleSelectAll();
                    setGlobalContextMenu({ ...globalContextMenu, isOpen: false });
                  }}
                >
                  {selectedItems.size > 0 ? "Deselect All" : "Select All"}
                </Button>
                {selectedItems.size > 0 && (
                  <Button
                    variant="ghost"
                    size="sm"
                    leftIcon={<PiCopy fontSize="18px" />}
                    justifyContent="flex-start"
                    onClick={() => {
                      const copiedFiles = Array.from(selectedItems)
                        .map((id) => files.find((f) => f._id === id))
                        .filter(Boolean);
                      localStorage.setItem("copiedFiles", JSON.stringify(copiedFiles));
                      customToast({
                        title: "Copied",
                        description: `${copiedFiles.length} file(s) copied.`,
                        status: "success",
                      });
                      setGlobalContextMenu({ ...globalContextMenu, isOpen: false });
                    }}
                  >
                    Copy
                  </Button>
                )}
                {(() => {
                  const copied = localStorage.getItem("copiedFiles");
                  if (copied) {
                    const copiedFiles = JSON.parse(copied);
                    if (copiedFiles.length > 0) {
                      return (
                        <Button
                          variant="ghost"
                          size="sm"
                          justifyContent="flex-start"
                          leftIcon={<PiClipboard fontSize="18px" />}
                          onClick={async () => {
                            for (const file of copiedFiles) {
                              if (file.type === "file") {
                                try {
                                  await duplicateFileAPI(file._id, currentWorkspace._id, currentFolderId);
                                  customToast({
                                    title: "Pasted",
                                    description: `File "${file.originalFileName}" duplicated.`,
                                    status: "success",
                                  });
                                } catch (error) {
                                  console.error("Error duplicating file:", error);
                                  customToast({
                                    title: "Paste Error",
                                    description: `Could not duplicate "${file.originalFileName}".`,
                                    status: "error",
                                  });
                                }
                              } else if (file.type === "link") {
                                // Optionally handle link duplication if desired.
                                customToast({
                                  title: "Paste Error",
                                  description: "Cannot duplicate links.",
                                  status: "error",
                                });
                              }
                            }
                            localStorage.removeItem("copiedFiles");
                            setGlobalContextMenu({ ...globalContextMenu, isOpen: false });
                          }}
                        >
                          Paste
                        </Button>
                      );
                    }
                  }
                  return null;
                })()}
                <Divider my={1} />
                <Button
                  variant="ghost"
                  justifyContent="flex-start"
                  w="100%"
                  size="sm"
                  fontWeight="400"
                  borderRadius="0"
                  onClick={() => onOpenImportModal()}
                  leftIcon={<BsFileEarmarkPlus fontSize="18px" />}
                >
                  Import a File
                </Button>
                <Button
                  variant="ghost"
                  justifyContent="flex-start"
                  w="100%"
                  size="sm"
                  fontWeight="400"
                  borderRadius="0"
                  onClick={triggerFolderInput}
                  leftIcon={<BsFolderPlus fontSize="18px" />}
                >
                  Import a Folder
                </Button>
                <Button
                  variant="ghost"
                  justifyContent="flex-start"
                  w="100%"
                  size="sm"
                  fontWeight="400"
                  borderRadius="0"
                  onClick={() => onOpenCreateLinkModal()}
                  leftIcon={<PiLink fontSize="18px" />}
                >
                  Add a Link
                </Button>
                <Button
                  variant="ghost"
                  justifyContent="flex-start"
                  w="100%"
                  size="sm"
                  fontWeight="400"
                  borderRadius="0"
                  onClick={() => onOpenCreateFolderModal()}
                  leftIcon={<PiFolderSimple fontSize="18px" />}
                >
                  Create a Folder
                </Button>
              </VStack>
            </Box>
          </Portal>
        )}

        {selectionBox.visible && (
          <div
            style={{
              position: "fixed", // using fixed since we're working in client (viewport) coordinates
              left: selectionBox.x,
              top: selectionBox.y,
              width: selectionBox.width,
              height: selectionBox.height,
              backgroundColor: "rgba(0,150,255,0.2)",
              border: "1px solid rgba(0,150,255,0.8)",
              pointerEvents: "none",
              zIndex: 3000,
            }}
          />
        )}
        <CustomDragLayer />
      </Box>
    </>
  );
};

export default FilesList;

// ============ FileRow COMPONENT ============

const FileRow = memo(
  ({
    file,
    index,
    selectedItems,
    handleRowClick,
    handleRowDoubleClick,
    setContextMenu,
    bgHover,
    bgHoverIsSelected,
    bgDropTarget,
    textColor,
    textLightColor,
    renderFileIcon,
    formatFileSize,
    isFileTypeSelected,
    handleSelectAll,
    currentWorkspace,
    menuContextBgColor,
    isImageFile,
    folderColor,
    isMobile,
  }) => {
    const {
      moveItems,
      files,
      handleDownloadFile,
      handleRenameModalOpen,
      handleDeleteConfirmationModalOpen,
      handleImagePreviewModalOpen,
      currentFolderId,
    } = useContext(FilesContext);

    const { themeColor } = useContext(WorkspaceContext);
    const customToast = useCustomToast();
    const isFolder = file.type === "folder";
    const isLink = file.type === "link";

    // useDrag for reordering in-app
    const [{ isDragging }, drag, preview] = useDrag({
      type: ItemTypes.FILE,
      item: {
        files: selectedItems.has(file._id)
          ? Array.from(selectedItems)
              .map((id) => files.find((f) => f._id === id))
              .filter(Boolean)
          : [file],
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    // Disable default drag preview for in-app drag
    useEffect(() => {
      preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);

    // useDrop for dropping onto folders
    const [{ isOver }, drop] = useDrop({
      accept: ItemTypes.FILE,
      canDrop: () => isFolder,
      drop: (item) => {
        // Prevent dropping into itself or subfolder
        if (item.files.some((f) => f._id === file._id)) {
          customToast({
            title: "Invalid Move",
            description: "Cannot move a folder into itself or its subfolder.",
            status: "error",
          });
          return;
        }
        const fileIds = item.files.map((f) => f._id);
        const newParentFolderId = file._id;
        moveItems(fileIds, newParentFolderId);
      },
      collect: (monitor) => ({
        isOver: monitor.isOver({ shallow: true }),
      }),
    });

    const rowRef = useRef(null);

    if (isFolder) {
      drag(drop(rowRef));
    } else {
      drag(rowRef);
    }

    let backgroundColor = "transparent";
    if (isOver) {
      backgroundColor = bgDropTarget;
    } else if (selectedItems.has(file._id)) {
      backgroundColor = bgHoverIsSelected;
    }
    const opacity = isDragging ? (isFileTypeSelected ? 0.5 : 0.3) : isFileTypeSelected ? 1 : 0.5;

    return (
      <MotionTr
        layout
        opacity={opacity}
        ref={rowRef}
        bg={backgroundColor}
        sx={{
          transition: "background-color 0.2s ease-in-out",
          "&:hover": {
            backgroundColor: isOver ? bgDropTarget : selectedItems.has(file._id) ? bgHoverIsSelected : bgHover,
          },
        }}
        cursor="pointer"
        onClick={(e) => handleRowClick(e, file)}
        onDoubleClick={(e) => handleRowDoubleClick(e, file)}
        onContextMenu={(e) => {
          e.preventDefault();
          setContextMenu({
            isOpen: true,
            x: e.clientX,
            y: e.clientY,
            file: file,
          });
        }}
        id={`file-row-${file._id}`}
      >
        <Td>
          <Tooltip
            label={isFolder ? "Double-click to open folder" : isLink ? "Double-click to open link" : "Double-click to download. Drag to move."}
            hasArrow
          >
            <Box display="flex" alignItems="center" userSelect="none">
              <Box as={PiDotsSixVertical} mr={isMobile ? 0 : 2} color="gray.500" fontSize={isMobile ? "0px" : "20px"} />
              {isFolder ? (
                <Box as={BsFolder} color={folderColor} fontSize="24px" />
              ) : isLink ? (
                <Box as={PiLink} color={`${themeColor}.500`} fontSize="24px" />
              ) : (
                renderFileIcon(file.originalFileName)
              )}
            </Box>
          </Tooltip>
        </Td>
        <Td>
          <Box>
            <Tooltip label={file.originalFileName} hasArrow>
              <Text fontSize="sm" color={textColor} userSelect="none" isTruncated={true} maxW={isMobile ? "150px" : "250px"}>
                {file.originalFileName}
              </Text>
            </Tooltip>
            {file.type === "file" ? (
              <Text fontWeight="500" fontSize="xs" color={textLightColor} userSelect="none">
                {formatFileSize(file.fileSize)}
              </Text>
            ) : file.type === "link" ? (
              <Text fontWeight="500" fontSize="xs" color={textLightColor} userSelect="none">
                {file.url.length > 60 ? `${file.url.substring(0, 60)}...` : file.url}
              </Text>
            ) : null}
          </Box>
        </Td>
        {!isMobile && (
          <Td>
            <Box display="flex" alignItems="center" userSelect="none">
              {file.uploadedBy._id && <UserAvatar isOnClickActive={true} userIds={[file.uploadedBy._id]} size="sm" />}
            </Box>
          </Td>
        )}
        {!isMobile && (
          <Td>
            <Text fontSize="sm" textTransform="none" fontWeight="500" userSelect="none">
              {new Date(file.createdAt).toLocaleString()}
            </Text>
          </Td>
        )}
        <Td onClick={(e) => e.stopPropagation()}>
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<PiDotsThreeBold fontSize="20px" />}
              size="sm"
              variant="outline"
              aria-label="Actions"
              colorScheme={themeColor}
            />
            <MenuList bg={menuContextBgColor}>
              {/* Preview if image */}
              {isImageFile(file.originalFileName) && (
                <MenuItem fontSize="sm" icon={<PiEye fontSize="18px" />} onClick={() => handleImagePreviewModalOpen(file)} userSelect="none">
                  View Image
                </MenuItem>
              )}

              {/* Download if file */}
              {file.type === "file" && (
                <MenuItem fontSize="sm" icon={<PiDownloadSimple fontSize="18px" />} onClick={() => handleDownloadFile(file)} userSelect="none">
                  Download File
                </MenuItem>
              )}

              {/* Rename */}
              <MenuItem fontSize="sm" icon={<PiPencilSimpleLine fontSize="18px" />} onClick={() => handleRenameModalOpen(file._id)} userSelect="none">
                Rename {file.type === "folder" ? "Folder" : "File"}
              </MenuItem>

              {/* Open Link if file is a link */}
              {file.type === "link" && (
                <MenuItem fontSize="sm" icon={<PiLink fontSize="18px" />} onClick={() => window.open(file.url, "_blank")} userSelect="none">
                  Open Link
                </MenuItem>
              )}

              {/* A divider before the selection and clipboard actions */}
              <MenuDivider />

              {/* Select All / Deselect All */}
              <MenuItem
                fontSize="sm"
                icon={selectedItems.size > 0 ? <PiSelectionSlash fontSize="18px" /> : <PiSelection fontSize="18px" />}
                onClick={() => handleSelectAll()}
                userSelect="none"
              >
                {selectedItems.size > 0 ? "Deselect All" : "Select All"}
              </MenuItem>

              {/* Copy – if one or more items are already selected, copy them; otherwise, copy this file */}
              <MenuItem
                fontSize="sm"
                icon={<PiCopy fontSize="18px" />}
                onClick={() => {
                  const filesToCopy =
                    selectedItems.size > 0
                      ? Array.from(selectedItems)
                          .map((id) => files.find((f) => f._id === id))
                          .filter(Boolean)
                      : [file];
                  localStorage.setItem("copiedFiles", JSON.stringify(filesToCopy));
                  customToast({
                    title: "Copied",
                    description: `${filesToCopy.length} file(s) copied.`,
                    status: "success",
                  });
                }}
                userSelect="none"
              >
                Copy
              </MenuItem>

              {/* Paste – only show if there are copied files in localStorage */}
              {(() => {
                const copied = localStorage.getItem("copiedFiles");
                if (copied) {
                  const copiedFiles = JSON.parse(copied);
                  if (copiedFiles.length > 0) {
                    return (
                      <MenuItem
                        fontSize="sm"
                        icon={<PiClipboard fontSize="18px" />}
                        onClick={async () => {
                          for (const copiedFile of copiedFiles) {
                            if (copiedFile.type === "file") {
                              try {
                                await duplicateFileAPI(copiedFile._id, currentWorkspace._id, currentFolderId);
                                customToast({
                                  title: "Pasted",
                                  description: `File "${copiedFile.originalFileName}" duplicated.`,
                                  status: "success",
                                });
                              } catch (error) {
                                console.error("Error duplicating file:", error);
                                customToast({
                                  title: "Paste Error",
                                  description: `Could not duplicate "${copiedFile.originalFileName}".`,
                                  status: "error",
                                });
                              }
                            } else if (copiedFile.type === "link" || copiedFile.type === "folder") {
                              customToast({
                                title: "Paste Error",
                                description: `File "${copiedFile.originalFileName}" cannot be duplicated for now, we are working on it.`,
                                status: "error",
                              });
                            }
                          }
                          localStorage.removeItem("copiedFiles");
                        }}
                        userSelect="none"
                      >
                        Paste
                      </MenuItem>
                    );
                  }
                }
                return null;
              })()}
              {/* Delete */}
              <MenuItem
                fontSize="sm"
                icon={<PiTrash fontSize="18px" />}
                color="red.500"
                onClick={() => handleDeleteConfirmationModalOpen([file._id])}
                userSelect="none"
              >
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
        </Td>
      </MotionTr>
    );
  }
);
