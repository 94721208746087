// src/components/MessagesConversationsContainer.js

import React, { useState, useRef, useEffect, useCallback, useContext, useMemo } from "react";
import {
  Box,
  Button,
  Input,
  List,
  ListItem,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  IconButton,
  useColorModeValue,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  TabIndicator,
  useBreakpointValue,
} from "@chakra-ui/react";
import { PiMagnifyingGlass, PiPlus } from "react-icons/pi";
import { HiUserGroup, HiUser } from "react-icons/hi2";
import UserAvatar from "./Reusable/UserAvatar";
import { MessagesContext } from "../5 - General/Context/MessagesContext";
// Import WorkspaceContext to access themeColor and permissions
import { WorkspaceContext } from "../5 - General/Context/WorkspaceContext";

const MessagesConversationsContainer = ({ setIsActiveDrawerOpen }) => {
  const { channels, currentChannelId, handleChannelClick, currentUser, setModals, formatTimestamp, perChannelUnreadCounts } =
    useContext(MessagesContext);

  // Destructure themeColor and permission checks
  const { themeColor, canUseModule } = useContext(WorkspaceContext);

  const [searchTerm, setSearchTerm] = useState("");
  const [isSearchActive, setIsSearchActive] = useState(false);
  const searchRef = useRef(null);

  const topBarBgColor = useColorModeValue("gray.50", "gray.900");
  const borderColor = useColorModeValue("gray.200", "gray.750");
  const listHoverColor = useColorModeValue("gray.50", "gray.700");
  const contentBgColor = useColorModeValue("white", "gray.700");
  const textColor = useColorModeValue("black", "white");
  const lightTextColor = useColorModeValue("gray.600", "gray.300");
  const containerBgColor = useColorModeValue("white", "gray.800");
  const unreadCountBgColor = useColorModeValue(`${themeColor}.700`, `${themeColor}.600`); // Dynamic unread count background
  const unreadCountTextColor = useColorModeValue("white", "white");

  const activeBgColor = useColorModeValue(`${themeColor}.50`, `${themeColor}.900`); // Dynamic active background
  const inactiveBgColor = useColorModeValue("white", "gray.800");

  const isMobile = useBreakpointValue({ base: true, md: false });

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleSearchClick = () => {
    setIsSearchActive((prev) => {
      if (prev) {
        setSearchTerm("");
      }
      return !prev;
    });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClickOutside = useCallback(
    (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsSearchActive(false);
        setSearchTerm("");
      }
    },
    [setSearchTerm]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const getConversationDisplayName = useCallback(
    (channel) => {
      if (channel.name) {
        return channel.name;
      } else if (channel.conversationType === "1on1") {
        const otherUser = channel.members.find((user) => user._id !== currentUser._id);
        return otherUser ? `${otherUser.firstName} ${otherUser.lastName}` : "Unknown User";
      } else {
        return "Unnamed Channel";
      }
    },
    [currentUser]
  );

  const getLastMessagePreview = (channel) => {
    const lastMessage = channel.lastMessage;
    if (!lastMessage) return "No messages yet.";
    if (!lastMessage.content && lastMessage.includesFile) {
      const senderFirstName = lastMessage.senderFirstName || "Someone";
      return `${senderFirstName} sent a file.`;
    }
    if (lastMessage.content?.length > 40) {
      return `${lastMessage.content.substring(0, 40)}...`;
    }
    return lastMessage.content || "";
  };

  const getTimestamp = (channel) => {
    if (channel.lastMessage && channel.lastMessage.timestamp) {
      const time = new Date(channel.lastMessage.timestamp).getTime();
      return isNaN(time) ? 0 : time;
    }
    return 0;
  };

  const oneOnOneChannels = useMemo(() => channels.filter((channel) => channel.conversationType === "1on1"), [channels]);
  const groupChannels = useMemo(() => channels.filter((channel) => channel.conversationType === "group"), [channels]);

  const filteredOneOnOneChannels = useMemo(() => {
    return oneOnOneChannels
      .filter((channel) => getConversationDisplayName(channel).toLowerCase().includes(searchTerm.toLowerCase()))
      .sort((a, b) => getTimestamp(b) - getTimestamp(a));
  }, [oneOnOneChannels, searchTerm, getConversationDisplayName]);

  const filteredGroupChannels = useMemo(() => {
    return groupChannels
      .filter((channel) => getConversationDisplayName(channel).toLowerCase().includes(searchTerm.toLowerCase()))
      .sort((a, b) => getTimestamp(b) - getTimestamp(a));
  }, [groupChannels, searchTerm, getConversationDisplayName]);

  // Calculate the unread counts for each tab by summing up the per-channel unread counts.
  const oneOnOneUnreadCount = useMemo(() => {
    return filteredOneOnOneChannels.reduce((total, channel) => total + (perChannelUnreadCounts[channel._id] || 0), 0);
  }, [filteredOneOnOneChannels, perChannelUnreadCounts]);

  const groupUnreadCount = useMemo(() => {
    return filteredGroupChannels.reduce((total, channel) => total + (perChannelUnreadCounts[channel._id] || 0), 0);
  }, [filteredGroupChannels, perChannelUnreadCounts]);

  const handleConversationClick = (channelId) => {
    handleChannelClick(channelId);
    if (isMobile) {
      setIsActiveDrawerOpen(true);
    }
  };

  return (
    <Box width="100%" height="100%" bg={containerBgColor} overflow="hidden">
      <Box borderColor={borderColor} position="relative">
        <Flex px={6} py={3} h="80px" borderBottom="1px solid" bg={topBarBgColor} borderColor={borderColor} justify="space-between" align="center">
          <Text fontSize="lg" ml={isMobile ? "30px" : null} fontWeight="500" color={textColor}>
            Conversations
          </Text>
          <Flex align="center" gap={2}>
            {/* Only show the "Create" button if canUseModule("messages") is true */}
            {canUseModule("messages") && (
              <Menu>
                <MenuButton as={Button} size="xs" variant="outline" leftIcon={<PiPlus fontSize="18px" />}>
                  Create
                </MenuButton>
                <MenuList>
                  <MenuItem
                    fontSize="sm"
                    onClick={() => {
                      setModals((prev) => ({ ...prev, oneOnOne: true }));
                    }}
                    icon={<HiUser fontSize="18px" />}
                  >
                    Chat with a user
                  </MenuItem>
                  <MenuItem
                    fontSize="sm"
                    onClick={() => {
                      setModals((prev) => ({ ...prev, create: true }));
                    }}
                    icon={<HiUserGroup fontSize="18px" />}
                  >
                    Create a group conversation
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
            {/* Wrap the search button and search input inside searchRef */}
            <Box position="relative" ref={searchRef}>
              <IconButton
                w="28px"
                aria-label="Search"
                icon={<PiMagnifyingGlass fontSize="16px" />}
                size="xs"
                onClick={handleSearchClick}
                variant="outline"
              />
              {isSearchActive && (
                <Box position="absolute" top="40px" right="0" width="250px" bg={contentBgColor} boxShadow="md" borderRadius="md" zIndex="1">
                  <Input value={searchTerm} onChange={handleSearchChange} placeholder="Search..." autoFocus />
                </Box>
              )}
            </Box>
          </Flex>
        </Flex>
      </Box>

      <Tabs
        mt="12px"
        isFitted
        variant="unstyled"
        height="calc(100% - 140px)"
        index={activeTabIndex}
        onChange={(index) => {
          setActiveTabIndex(index);
          setSearchTerm("");
        }}
      >
        <TabList px={4} mb="8px">
          <Tab _selected={{ color: `${themeColor}.500` }} h="40px">
            <Text fontSize="md">User Messages</Text>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              ml={2}
              bg={unreadCountBgColor}
              color={unreadCountTextColor}
              borderRadius="full"
              fontSize="xs"
              p={1}
              w={6}
              h={6}
            >
              {oneOnOneUnreadCount > 99 ? "99+" : oneOnOneUnreadCount}
            </Box>
          </Tab>
          <Tab _selected={{ color: `${themeColor}.500` }} h="40px">
            <Text fontSize="md">Group Chats</Text>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              ml={2}
              bg={unreadCountBgColor}
              color={unreadCountTextColor}
              borderRadius="full"
              fontSize="xs"
              p={1}
              w={6}
              h={6}
            >
              {groupUnreadCount > 99 ? "99+" : groupUnreadCount}
            </Box>
          </Tab>
        </TabList>
        <TabIndicator mt="-1.5px" height="3px" bg={`${themeColor}.500`} borderRadius="1px" />

        <TabPanels height="100%">
          {/* User Messages Tab Panel */}
          <TabPanel p={0} height="100%" display="flex" flexDirection="column">
            <List
              spacing={0}
              px={{ base: 0, md: 4 }}
              flex="1"
              overflowY="auto"
              sx={{
                "::-webkit-scrollbar": { width: "0px" },
                "::-webkit-scrollbar-thumb": { borderRadius: "4px" },
                "::-webkit-scrollbar-track": {},
              }}
            >
              {filteredOneOnOneChannels.length === 0 ? (
                <Flex justify="center" align="center" height="100%">
                  <Text pt={10} color={lightTextColor}>
                    No conversations found.
                  </Text>
                </Flex>
              ) : (
                filteredOneOnOneChannels.map((channel) => {
                  const unreadCount = perChannelUnreadCounts[channel._id] || 0;
                  const isActive = channel._id === currentChannelId;
                  const fontWeight = unreadCount > 0 ? "bold" : "normal";

                  return (
                    <ListItem
                      key={channel._id}
                      onClick={() => handleConversationClick(channel._id)}
                      py={2}
                      px={3}
                      my={1}
                      borderRadius="md"
                      borderColor={borderColor}
                      cursor="pointer"
                      bg={isActive ? activeBgColor : inactiveBgColor}
                      _hover={{ bg: listHoverColor }}
                      transition="background-color 0.2s"
                    >
                      <Flex align="center">
                        {(() => {
                          const otherUser = channel.members.find((user) => user._id !== currentUser._id);
                          if (otherUser) {
                            return <UserAvatar isOnClickActive={true} userIds={[otherUser._id]} size="md" mr={3} showInfo={false} />;
                          } else {
                            return <UserAvatar userIds={["unknown"]} size="md" mr={3} showInfo={false} />;
                          }
                        })()}
                        <Box flex="1">
                          <Flex justify="space-between" m={1}>
                            <Text fontWeight={fontWeight} isTruncated color={textColor}>
                              {getConversationDisplayName(channel)}
                            </Text>
                            <Text fontSize="xs" color={lightTextColor}>
                              {channel.lastMessage?.timestamp ? formatTimestamp(channel.lastMessage.timestamp) : "No messages"}
                            </Text>
                          </Flex>
                          <Flex justify="space-between" align="center" m={1}>
                            <Text fontSize="sm" color={lightTextColor} isTruncated flex="1" fontWeight={fontWeight}>
                              {getLastMessagePreview(channel)}
                            </Text>
                            {unreadCount > 0 && (
                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                ml="auto"
                                bg={unreadCountBgColor}
                                color={unreadCountTextColor}
                                borderRadius="full"
                                fontSize="xs"
                                p={1}
                                w={6}
                                h={6}
                              >
                                {unreadCount > 99 ? "99+" : unreadCount}
                              </Box>
                            )}
                          </Flex>
                        </Box>
                      </Flex>
                    </ListItem>
                  );
                })
              )}
            </List>
          </TabPanel>
          {/* Group Chats Tab Panel */}
          <TabPanel p={0} height="100%" display="flex" flexDirection="column">
            <List
              spacing={0}
              px={{ base: 0, md: 4 }}
              flex="1"
              overflowY="auto"
              sx={{
                "::-webkit-scrollbar": { width: "0px" },
                "::-webkit-scrollbar-thumb": { borderRadius: "4px" },
                "::-webkit-scrollbar-track": {},
              }}
            >
              {filteredGroupChannels.length === 0 ? (
                <Flex justify="center" align="center" height="100%">
                  <Text color={lightTextColor}>No group chats found.</Text>
                </Flex>
              ) : (
                filteredGroupChannels.map((channel) => {
                  const unreadCount = perChannelUnreadCounts[channel._id] || 0;
                  const isActive = channel._id === currentChannelId;
                  const fontWeight = unreadCount > 0 ? "bold" : "normal";

                  return (
                    <ListItem
                      key={channel._id}
                      onClick={() => handleConversationClick(channel._id)}
                      py={2}
                      px={3}
                      my={1}
                      borderRadius="md"
                      borderColor={borderColor}
                      cursor="pointer"
                      bg={isActive ? activeBgColor : inactiveBgColor}
                      _hover={{ bg: listHoverColor }}
                      transition="background-color 0.2s"
                    >
                      <Flex align="center">
                        <Box>
                          {Array.isArray(channel.members) && (
                            <UserAvatar
                              userIds={channel.members.map((user) => user._id)}
                              size="md"
                              showInfo={false}
                              useGroup={true}
                              maxAvatars={2}
                              spacing={-8}
                            />
                          )}
                        </Box>
                        <Box flex="1" ml={3}>
                          <Flex justify="space-between" m={1}>
                            <Text fontWeight={fontWeight} isTruncated color={textColor}>
                              {getConversationDisplayName(channel)}
                            </Text>
                            <Text fontSize="xs" color={lightTextColor}>
                              {channel.lastMessage?.timestamp ? formatTimestamp(channel.lastMessage.timestamp) : "No messages"}
                            </Text>
                          </Flex>
                          <Flex justify="space-between" align="center" m={1}>
                            <Text fontSize="sm" color={lightTextColor} maxW="200px" isTruncated flex="1" fontWeight={fontWeight}>
                              {getLastMessagePreview(channel)}
                            </Text>
                            {unreadCount > 0 && (
                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                ml="auto"
                                bg={unreadCountBgColor}
                                color={unreadCountTextColor}
                                borderRadius="full"
                                fontSize="xs"
                                p={1}
                                w={6}
                                h={6}
                              >
                                {unreadCount > 99 ? "99+" : unreadCount}
                              </Box>
                            )}
                          </Flex>
                        </Box>
                      </Flex>
                    </ListItem>
                  );
                })
              )}
            </List>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default MessagesConversationsContainer;
