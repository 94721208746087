// src/context/GanttContext.js

import React, { createContext, useState, useEffect, useMemo, useCallback, useRef } from "react";
import dayjs from "dayjs";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";

// Create the Context
export const GanttContext = createContext();

// GanttProvider Component
export const GanttProvider = ({
  projects = [],
  tasks = [],
  subtasks = [],
  onTaskSelect,
  onTasksDelete,
  onTaskUpdate,
  onTaskDoubleClick,
  dayWidth = 100,
  rowHeight = 60,
  children,
}) => {
  const toast = useCustomToast();
  const [collapseState, setCollapseState] = useState({ projects: {}, tasks: {} });
  const [linking, setLinking] = useState(false);
  const [linkSource, setLinkSource] = useState(null);
  const [taskToDelete, setTaskToDelete] = useState(null);
  const [tasksState, setTasksState] = useState(tasks);
  const [isDraggingTask, setIsDraggingTask] = useState(false);
  const [hoveredDay, setHoveredDay] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const chartRef = useRef(null);

  useEffect(() => {
    setTasksState(tasks);
  }, [tasks]);

  // Function to handle task updates
  const handleTaskUpdateFunc = useCallback(
    async (taskId, updatedFields) => {
      try {
        const updatedTask = await onTaskUpdate(taskId, updatedFields);
        if (!updatedTask) {
          throw new Error("No updated task returned from API.");
        }
        setTasksState((prevTasks) => prevTasks.map((t) => (t._id === taskId ? { ...t, ...updatedTask } : t)));
        toast({
          title: "Task Updated",
          description: `Task "${updatedTask.name}" updated successfully.`,
          status: "success",
        });
        return updatedTask; // Ensure the updated task is returned
      } catch (error) {
        console.error("Error updating task:", error);
        toast({
          title: "Error",
          description: `Failed to update task "${taskId}".`,
          status: "error",
        });
        throw error; // Propagate the error to handleLinkEnd
      }
    },
    [onTaskUpdate, toast]
  );

  // Function to handle task drag complete
  const handleTaskDragComplete = useCallback(
    (taskId, daysMoved) => {
      const task = tasksState.find((t) => t._id === taskId);
      if (!task) return;

      const newStartDate = dayjs(task.startDate).add(daysMoved, "day").format("YYYY-MM-DD");
      const newEndDate = dayjs(task.endDate).add(daysMoved, "day").format("YYYY-MM-DD");

      onTaskUpdate(task._id, { startDate: newStartDate, endDate: newEndDate });
      toast({
        title: "Task Moved",
        description: `Task "${task.name}" moved by ${daysMoved} day(s).`,
        status: "success",
      });
    },
    [tasksState, onTaskUpdate, toast]
  );

  // Function to handle resizing from the start
  const handleStartResizeComplete = useCallback(
    (taskId, daysChanged) => {
      const task = tasksState.find((t) => t._id === taskId);
      if (!task) return;

      const newStartDate = dayjs(task.startDate).add(daysChanged, "day");
      const newEndDate = dayjs(task.endDate);

      if (newStartDate.isBefore(newEndDate)) {
        // add 1 so that the width reflects the inclusive end date
        const updatedWidth = (dayjs(task.endDate).diff(newStartDate, "day") + 1) * dayWidth;
        onTaskUpdate(taskId, {
          startDate: newStartDate.format("YYYY-MM-DD"),
        });
        setTasksState((prevTasks) =>
          prevTasks.map((t) => (t._id === taskId ? { ...t, width: updatedWidth, left: task.left + daysChanged * dayWidth } : t))
        );
      }

      toast({
        title: "Task Resized",
        description: `Task "${task.name}" start date adjusted by ${daysChanged} day(s).`,
        status: "success",
      });
    },
    [tasksState, onTaskUpdate, dayWidth, toast]
  );

  // Function to handle resizing from the end
  const handleEndResizeComplete = useCallback(
    (taskId, daysChanged) => {
      const task = tasksState.find((t) => t._id === taskId);
      if (!task) return;

      const newEndDate = dayjs(task.endDate).add(daysChanged, "day");
      const newStartDate = dayjs(task.startDate);

      if (newEndDate.isAfter(newStartDate)) {
        // add 1 to include the end day
        const updatedWidth = (dayjs(newEndDate).diff(newStartDate, "day") + 1) * dayWidth;

        onTaskUpdate(taskId, {
          endDate: newEndDate.format("YYYY-MM-DD"),
        });

        setTasksState((prevTasks) => prevTasks.map((t) => (t._id === taskId ? { ...t, width: updatedWidth } : t)));
      }

      console.log(`Task "${task.name}" end date adjusted by ${daysChanged} day(s).`);
      toast({
        title: "Task Resized",
        description: `Task "${task.name}" end date adjusted by ${daysChanged} day(s).`,
        status: "success",
      });
    },
    [tasksState, onTaskUpdate, dayWidth, toast]
  );

  // Function to handle day changes
  const handleDayChange = useCallback(
    (taskId, newDay) => {
      const task = tasksState.find((t) => t._id === taskId);
      if (!task) return;

      onTaskUpdate(taskId, { startDate: newDay });
      toast({
        title: "Day Updated",
        description: `Task "${task.name}" updated to start on ${newDay}.`,
        status: "info",
      });
    },
    [tasksState, onTaskUpdate, toast]
  );

  // Function to handle day hover
  const handleDayHover = useCallback((newDay) => {
    setHoveredDay(newDay);
  }, []);

  // Function to compute the earliest start date from projects and tasks
  const calculateEarliestStart = useCallback(() => {
    let minDate = dayjs();
    projects.forEach((project) => {
      if (project.startDate && dayjs(project.startDate).isBefore(minDate)) {
        minDate = dayjs(project.startDate);
      }
    });
    tasksState.forEach((task) => {
      if (task.startDate && dayjs(task.startDate).isBefore(minDate)) {
        minDate = dayjs(task.startDate);
      }
    });
    return minDate;
  }, [projects, tasksState]);

  const earliestStart = useMemo(() => calculateEarliestStart(), [calculateEarliestStart]);

  // Calculate task position based on start date
  const calculateTaskPosition = useCallback(
    (task) => {
      if (!task.startDate || !task.endDate) {
        return { left: 0, width: 0, height: rowHeight };
      }
      const startOffset = dayjs(task.startDate).diff(earliestStart, "day") * dayWidth;
      // add 1 so that a one-day task has a width of one day
      const taskWidthRaw = dayjs(task.endDate).diff(dayjs(task.startDate), "day") + 1;
      const taskWidth = taskWidthRaw * dayWidth;
      return { left: startOffset, width: taskWidth, height: rowHeight };
    },
    [earliestStart, dayWidth, rowHeight]
  );
  // Calculate subtask position based on start date (same logic as task)
  const calculateSubtaskPosition = useCallback(
    (subtask) => {
      if (!subtask.startDate || !subtask.endDate) {
        console.error(`Subtask "${subtask.name}" is missing startDate or endDate.`);
        return { left: 0, width: 0, height: rowHeight };
      }
      const startOffset = dayjs(subtask.startDate).diff(earliestStart, "day") * dayWidth;
      const subtaskWidthRaw = dayjs(subtask.endDate).diff(dayjs(subtask.startDate), "day");
      const subtaskWidth = Math.max(subtaskWidthRaw, 1) * dayWidth;
      return { left: startOffset, width: subtaskWidth, height: rowHeight };
    },
    [earliestStart, dayWidth, rowHeight]
  );

  // Toggle collapse state
  const toggleCollapse = useCallback((type, _id) => {
    setCollapseState((prev) => ({
      ...prev,
      [type]: { ...prev[type], [_id]: !prev[type][_id] },
    }));
  }, []);

  // Build a flat list of visible tasks (projects, tasks, and subtasks)
  const getVisibleTasks = useCallback(() => {
    const visible = [];
    projects.forEach((project) => {
      visible.push({ ...project, level: 0, type: "project", dependencies: project.dependencies || [] });
      if (!collapseState.projects[project._id]) {
        const projectTasks = tasksState.filter((task) => task.projectId === project._id && !task.parentTask);
        projectTasks.forEach((task) => {
          visible.push({ ...task, level: 1, type: "task" });
          if (!collapseState.tasks[task._id]) {
            // Filter the flat subtasks array for those that belong to this task.
            const taskSubtasks = subtasks.filter((subtask) => subtask.parentTask && subtask.parentTask.toString() === task._id.toString());
            taskSubtasks.forEach((subtask) => {
              visible.push({ ...subtask, level: 2, type: "subtask" });
            });
          }
        });
      }
    });
    return visible;
  }, [projects, tasksState, subtasks, collapseState]);

  const visibleTasks = useMemo(() => getVisibleTasks(), [getVisibleTasks]);

  // Handle linking
  const handleLinkStart = useCallback((taskId, dependencyType) => {
    console.log("handleLinkStart called with:", { taskId, dependencyType });

    setLinking(true);
    console.log("Linking state set to true.");

    setLinkSource({ taskId, dependencyType });
    console.log("Link source set to:", { taskId, dependencyType });
  }, []);

  const handleLinkEnd = useCallback(
    async (targetTaskId, targetDependencyType) => {
      console.log("handleLinkEnd called with:", { targetTaskId, targetDependencyType });

      if (linkSource && targetTaskId && linkSource.taskId !== targetTaskId) {
        console.log("Valid link source and target task IDs provided.");

        const { taskId: sourceTaskId } = linkSource;
        console.log("Source Task _id:", sourceTaskId);

        const sourceTask = tasksState.find((t) => t._id === sourceTaskId);
        console.log("Source Task found:", sourceTask);

        const targetTask = tasksState.find((t) => t._id === targetTaskId);
        console.log("Target Task found:", targetTask);

        if (!sourceTask) {
          console.error("Source task does not exist.");
          toast({
            title: "Invalid Dependency",
            description: "Source task does not exist.",
            status: "error",
          });
          setLinking(false);
          setLinkSource(null);
          console.log("Linking state reset due to invalid source task.");
          return;
        }

        if (!targetTask) {
          console.error("Target task does not exist.");
          toast({
            title: "Invalid Dependency",
            description: "Target task does not exist.",
            status: "error",
          });
          setLinking(false);
          setLinkSource(null);
          console.log("Linking state reset due to invalid target task.");
          return;
        }

        // Enforce end-to-start dependency
        if (linkSource.dependencyType !== "end" || targetDependencyType !== "start") {
          console.warn("Invalid dependency types:", {
            sourceDependencyType: linkSource.dependencyType,
            targetDependencyType,
          });
          toast({
            title: "Invalid Dependency",
            description: "Dependencies can only be created from end of a task to start of another.",
            status: "error",
          });
          setLinking(false);
          setLinkSource(null);
          console.log("Linking state reset due to invalid dependency types.");
          return;
        }

        // Check for duplicate dependencies
        if (sourceTask.dependencies && sourceTask.dependencies.includes(targetTaskId)) {
          console.warn("Duplicate dependency detected:", {
            sourceTaskId,
            targetTaskId,
          });
          toast({
            title: "Duplicate Dependency",
            description: "This dependency already exists.",
            status: "warning",
          });
          setLinking(false);
          setLinkSource(null);
          console.log("Linking state reset due to duplicate dependency.");
          return;
        }

        // Check for circular dependencies
        const hasCircularDependency = (currentTaskId, targetIdToCheck, visited = new Set()) => {
          console.log(`Checking circular dependency: currentTaskId=${currentTaskId}, targetIdToCheck=${targetIdToCheck}`);

          if (visited.has(targetIdToCheck)) {
            console.log(`Already visited ${targetIdToCheck}, skipping to prevent infinite loop.`);
            return false;
          }
          visited.add(targetIdToCheck);

          const targetTaskObj = tasksState.find((t) => t._id === targetIdToCheck);
          console.log("Visiting Task:", targetTaskObj);

          if (!targetTaskObj || !targetTaskObj.dependencies) {
            console.log("No dependencies found for task:", targetIdToCheck);
            return false;
          }

          if (targetTaskObj.dependencies.includes(currentTaskId)) {
            console.log(`Circular dependency detected: ${targetIdToCheck} depends on ${currentTaskId}`);
            return true;
          }

          for (let depId of targetTaskObj.dependencies) {
            if (hasCircularDependency(currentTaskId, depId, visited)) {
              return true;
            }
          }

          return false;
        };

        const circularDependency = hasCircularDependency(sourceTaskId, targetTaskId);
        console.log("Circular Dependency Check Result:", circularDependency);

        if (circularDependency) {
          console.error("Creating this dependency would cause a circular relationship.");
          toast({
            title: "Circular Dependency",
            description: "Creating this dependency would cause a circular relationship.",
            status: "error",
          });
          setLinking(false);
          setLinkSource(null);
          console.log("Linking state reset due to circular dependency.");
          return;
        }

        // Update the dependency
        const updatedDependencies = [...(sourceTask.dependencies || []), targetTaskId];
        console.log("Updating dependencies for source task:", {
          sourceTaskId,
          updatedDependencies,
        });

        try {
          const updatedTask = await handleTaskUpdateFunc(sourceTaskId, {
            dependencies: updatedDependencies,
          });
          console.log("Dependency updated successfully: " + updatedTask);

          toast({
            title: "Dependency Added",
            description: `Task "${sourceTask.name}" now depends on "${targetTask.name}".`,
            status: "success",
          });
        } catch (error) {
          console.error("Error updating dependency:", error);
          toast({
            title: "Error",
            description: `Failed to add dependency.`,
            status: "error",
          });
        }
      } else {
        console.warn("Invalid link end parameters or same task selected.");
      }

      setLinking(false);
      setLinkSource(null);
      console.log("Linking state reset after handleLinkEnd execution.");
    },
    [linkSource, tasksState, toast, handleTaskUpdateFunc]
  );

  const handleDeleteDependency = useCallback(
    async (sourceTaskId, targetTaskId) => {
      const sourceTask = tasksState.find((t) => t._id === sourceTaskId);
      if (!sourceTask) {
        toast({
          title: "Error",
          description: `Source task with _id "${sourceTaskId}" not found.`,
          status: "error",
        });
        return;
      }

      if (!sourceTask.dependencies || !sourceTask.dependencies.includes(targetTaskId)) {
        toast({
          title: "Error",
          description: `Dependency does not exist.`,
          status: "error",
        });
        return;
      }

      const updatedDependencies = sourceTask.dependencies.filter((depId) => depId !== targetTaskId);

      try {
        const updatedTask = await onTaskUpdate(sourceTaskId, {
          dependencies: updatedDependencies,
        });
        console.log("Dependency removed successfully:", updatedTask);

        setTasksState((prevTasks) => prevTasks.map((t) => (t._id === sourceTaskId ? { ...t, dependencies: updatedDependencies } : t)));

        toast({
          title: "Dependency Removed",
          description: `Dependency from "${sourceTask.name}" to "${targetTaskId}" has been removed.`,
          status: "success",
        });
      } catch (error) {
        console.error("Error deleting dependency:", error);
        toast({
          title: "Error",
          description: `Failed to remove dependency.`,
          status: "error",
        });
      }
    },
    [tasksState, onTaskUpdate, toast]
  );

  // Handle mouse movements for linking
  useEffect(() => {
    const handleMouseMoveLink = (e) => {
      if (linking && chartRef.current) {
        const rect = chartRef.current.getBoundingClientRect();
        setMousePosition({
          x: e.clientX - rect.left + chartRef.current.scrollLeft,
          y: e.clientY - rect.top + chartRef.current.scrollTop,
        });
      }
    };

    window.addEventListener("mousemove", handleMouseMoveLink);

    return () => {
      window.removeEventListener("mousemove", handleMouseMoveLink);
    };
  }, [linking, chartRef]);

  // Confirm task deletion
  const confirmDeleteTask = useCallback((taskId) => {
    setTaskToDelete(taskId);
    setIsModalOpen(true);
  }, []);

  // Handle deletion confirmation
  const handleDeleteConfirmed = useCallback(() => {
    if (taskToDelete) {
      onTasksDelete(taskToDelete);
      setTaskToDelete(null);
      setIsModalOpen(false);
      toast({
        title: "Task Deleted",
        description: "The task has been successfully deleted.",
        status: "info",
      });
    }
  }, [taskToDelete, onTasksDelete, toast]);

  // Define onDragStart and onDragEnd
  const onDragStart = useCallback(() => {
    setIsDraggingTask(true);
  }, []);

  const onDragEnd = useCallback(() => {
    setIsDraggingTask(false);
  }, []);

  // Context Value with Memoization
  const contextValue = useMemo(
    () => ({
      projects,
      tasks: tasksState,
      subtasks,
      collapseState,
      toggleCollapse,
      visibleTasks,
      handleDeleteTask: confirmDeleteTask,
      handleTaskUpdate: handleTaskUpdateFunc,
      onDrag: handleTaskDragComplete,
      onResizeStart: handleStartResizeComplete,
      onResizeEnd: handleEndResizeComplete,
      handleDayChange,
      dayWidth,
      rowHeight,
      earliestStart,
      calculateTaskPosition,
      calculateSubtaskPosition,
      linking,
      handleLinkStart,
      handleLinkEnd,
      handleDeleteDependency,
      mousePosition,
      setMousePosition,
      isDraggingTask,
      setIsDraggingTask,
      hoveredDay,
      setHoveredDay,
      isModalOpen,
      setIsModalOpen,
      handleDeleteConfirmed,
      onDragStart,
      onDragEnd,
      chartRef,
      handleDayHover,
      // Add the double-click callback:
      handleTaskDoubleClick: onTaskDoubleClick,
    }),
    [
      projects,
      tasksState,
      subtasks,
      collapseState,
      visibleTasks,
      confirmDeleteTask,
      handleDeleteDependency,
      handleTaskUpdateFunc,
      handleTaskDragComplete,
      handleStartResizeComplete,
      handleEndResizeComplete,
      handleDayChange,
      dayWidth,
      rowHeight,
      earliestStart,
      calculateTaskPosition,
      calculateSubtaskPosition,
      linking,
      handleLinkStart,
      handleLinkEnd,
      mousePosition,
      setMousePosition,
      isDraggingTask,
      setIsDraggingTask,
      hoveredDay,
      setHoveredDay,
      isModalOpen,
      setIsModalOpen,
      handleDeleteConfirmed,
      onDragStart,
      onDragEnd,
      chartRef,
      handleDayHover,
      onTaskDoubleClick,
      toggleCollapse,
    ]
  );

  return <GanttContext.Provider value={contextValue}>{children}</GanttContext.Provider>;
};
