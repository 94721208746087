// client/src/components/ProjectsTasksList.jsx
import React, { useState, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Spinner,
  TabIndicator,
  useColorModeValue,
  HStack,
  Text,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";
import { PiKanban, PiChartBarHorizontal, PiArticle, PiListChecks } from "react-icons/pi";
import ProjectsTasksList from "./ProjectsTasksList";
import CreateTaskModal from "../3 - Modal/3 - Projects Modals/ProjectsCreateTaskModal";
import TaskView from "./ProjectsViewTaskView/ProjectsTaskView";
import { ProjectContext } from "../5 - General/Context/ProjectsContext";
import ProjectsTasksBoard from "./ProjectsTasksBoard";
import ProjectsTasksGanttChart from "./ProjectsTasksGanttChart";
import AssignMembersModal from "../3 - Modal/3 - Projects Modals/ProjectsAssignMembersModal";
import UserAvatar from "../2 - Components/Reusable/UserAvatar";
import ProjectsDescription from "./ProjectsDescription";
import ProjectEditableName from "./Reusable/ProjectEditableName";
import QuickCreateTaskModal from "../3 - Modal/3 - Projects Modals/ProjectsQuickCreateTaskModal";

const TaskGroups = () => {
  const { currentProjectId, projects, tasks, isLoading, currentTaskId, setCurrentTaskId } = useContext(ProjectContext);
  const [isAssignMembersModalOpen, setIsAssignMembersModalOpen] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [isCreateTaskModalOpen, setIsCreateTaskModalOpen] = useState(false);
  const { isOpen: isQuickCreateTaskOpen, onOpen: onQuickCreateTaskOpen, onClose: onQuickCreateTaskClose } = useDisclosure();
  const [parentTaskId, setParentTaskId] = useState(null);
  const [parentTask, setParentTask] = useState(null);
  const navigate = useNavigate();

  const bgColor = useColorModeValue("white", "gray.800");
  const tabTextColor = useColorModeValue("gray.600", "gray.300");
  const borderColor = useColorModeValue("gray.200", "gray.750");
  const currentProject = projects.find((project) => project._id === currentProjectId);
  const currentTask = tasks.find((task) => task._id === currentTaskId);

  const handleTaskClick = useCallback(
    (task) => {
      setCurrentTaskId(task._id);
      navigate(`/dashboard/projects/${currentProjectId}/${task._id}`);
    },
    [setCurrentTaskId, navigate, currentProjectId]
  );

  const handleAddTask = useCallback(() => {
    setCurrentTaskId(null);
    setParentTaskId(null);
    setParentTask(null);
    setIsCreateTaskModalOpen(true);
  }, [setCurrentTaskId, setParentTaskId, setParentTask, setIsCreateTaskModalOpen]);

  const handleQuickCreateTask = useCallback(
    (status) => {
      setCurrentStatus(status);
      onQuickCreateTaskOpen();
    },
    [onQuickCreateTaskOpen]
  );

  const handleCreateTaskModalClose = useCallback(() => {
    setIsCreateTaskModalOpen(false);
    setParentTaskId(null);
    setParentTask(null);
  }, [setIsCreateTaskModalOpen, setParentTaskId, setParentTask]);

  if (isLoading) {
    return (
      <Flex justify="center" align="center" w="100%" height="100%">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Box w="100%" bg={bgColor} overflow="hidden">
      {!currentTaskId ? (
        <>
          <Flex
            mb={["10", "0"]}
            justifyContent="space-between"
            alignItems="center"
            px={["4", "8"]}
            h={["80px", "100px"]}
            flexDirection={["column", "row"]}
          >
            <ProjectEditableName />
            <HStack spacing={2} mt={["1", 0]}>
              <UserAvatar userIds={currentProject?.members} size="sm" useGroup={true} showInfo={false} maxAvatars={5} spacing={-3} />
              <Button ml={["0", "4"]} colorScheme="gray" variant="outline" onClick={() => setIsAssignMembersModalOpen(true)}>
                Add Members
              </Button>
            </HStack>
          </Flex>
          <Tabs variant="unstyled" mx={["2", "8"]}>
            <TabList borderBottom="1px solid" borderColor={borderColor}>
              <Tab _selected={{ color: "primary.500" }} color={tabTextColor} fontSize={["sm", "15px"]}>
                <HStack spacing={2} align="center">
                  <PiListChecks fontSize="18px" />
                  <Text fontSize={["md", "lg"]} fontWeight="500">
                    List
                  </Text>
                </HStack>
              </Tab>
              <Tab _selected={{ color: "primary.500" }} color={tabTextColor} fontSize={["sm", "md"]}>
                <HStack spacing={2} align="center">
                  <PiArticle fontSize="18px" />
                  <Text fontSize={["md", "lg"]} fontWeight="500">
                    Description
                  </Text>
                </HStack>
              </Tab>
              <Tab _selected={{ color: "primary.500" }} color={tabTextColor} fontSize={["sm", "15px"]}>
                <HStack spacing={2} align="center">
                  <PiKanban fontSize="18px" />
                  <Text fontSize={["md", "lg"]} fontWeight="500">
                    Board
                  </Text>
                </HStack>
              </Tab>
              <Tab _selected={{ color: "primary.500" }} color={tabTextColor} fontSize={["sm", "15px"]}>
                <HStack spacing={2} align="center">
                  <PiChartBarHorizontal fontSize="18px" />
                  <Tooltip label="Work in Progress. Stay Updated." aria-label="Work in Progress">
                    <Text fontSize={["md", "lg"]} fontWeight="500">
                      Gantt
                    </Text>
                  </Tooltip>
                </HStack>
              </Tab>
            </TabList>

            <TabIndicator mt="-4px" height="3px" bg="primary.500" borderRadius="2px" />

            <Box overflowY="auto" overflowX="hidden" maxHeight={["calc(100vh - 200px)", "calc(100vh - 232px)"]}>
              <TabPanels>
                <TabPanel p={["2", "4"]}>
                  <ProjectsTasksList onTaskClick={handleTaskClick} onAddTask={handleAddTask} />
                </TabPanel>
                <TabPanel p={["2", "4"]}>
                  <ProjectsDescription />
                </TabPanel>
                <TabPanel p={["2", "4"]}>
                  <ProjectsTasksBoard onTaskClick={handleTaskClick} handleQuickCreateTask={handleQuickCreateTask} />
                </TabPanel>
                <TabPanel p={["2", "4"]}>
                  <ProjectsTasksGanttChart onTaskClick={handleTaskClick} onAddTask={handleAddTask} />
                </TabPanel>
              </TabPanels>
            </Box>
          </Tabs>
          <CreateTaskModal
            isOpen={isCreateTaskModalOpen}
            onClose={handleCreateTaskModalClose}
            projectId={currentProject?._id}
            parentTaskId={parentTaskId}
            parentTask={parentTask}
          />
          <QuickCreateTaskModal isOpen={isQuickCreateTaskOpen} onClose={onQuickCreateTaskClose} status={currentStatus} />
          <AssignMembersModal isOpen={isAssignMembersModalOpen} onClose={() => setIsAssignMembersModalOpen(false)} currentProject={currentProject} />
        </>
      ) : (
        <TaskView task={currentTask} />
      )}
    </Box>
  );
};

export default TaskGroups;
