// client/src/components/Projects/Modals/EditProjectModal.jsx

import React, { useState, useContext, useEffect } from "react";
import { Button, Spinner, IconButton, HStack, Box, FormControl, FormLabel, Input, useColorModeValue, Checkbox } from "@chakra-ui/react";
import { Select, chakraComponents } from "chakra-react-select";
import { PiArrowRightBold, PiXBold } from "react-icons/pi";
import ReusableModal from "../9 - General Modals/ReusableModal";
import { ProjectContext } from "../../5 - General/Context/ProjectsContext";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";
import TagMenu from "../../2 - Components/Reusable/TagMenu";
import UserAvatar from "../../2 - Components/Reusable/UserAvatar";
import UtilsDatePicker from "../../5 - General/Utils/UtilsDatePicker";
import { useQuery } from "@tanstack/react-query";
import { getAvailableUserAndCompanyOptions } from "../../4 - API/API-Projects";
import { WorkspaceContext } from "../../5 - General/Context/WorkspaceContext";

const EditProjectModal = ({ isOpen, onClose, project }) => {
  // -----------------------------
  // 1) Context / State
  // -----------------------------
  const { editProject, companies } = useContext(ProjectContext);
  const { currentUser } = useContext(WorkspaceContext);
  const customToast = useCustomToast();

  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState(project?.startDate || null);
  const [endDate, setEndDate] = useState(project?.endDate || null);
  const [status, setStatus] = useState("Not Started");
  const [priority, setPriority] = useState("Medium");

  const [companyOptions, setCompanyOptions] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [memberOptions, setMemberOptions] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [selectAllUsers, setSelectAllUsers] = useState(false);

  // -----------------------------
  // 2) Color definitions
  // -----------------------------
  const arrowColor = useColorModeValue("gray.500", "gray.300");
  const itemHoverBg = useColorModeValue("gray.100", "gray.600");
  const multiValueBg = useColorModeValue("gray.50", "gray.700");

  // -----------------------------
  // 3) Queries
  // -----------------------------
  const { data: availableUserAndCompanyOptions = [], isLoading: isAvailableUserAndCompanyOptionsLoading } = useQuery({
    queryKey: ["availableUsers", currentUser?._id],
    queryFn: () => getAvailableUserAndCompanyOptions(currentUser._id),
    enabled: isOpen && !!currentUser,
    staleTime: 5 * 60 * 1000,
  });

  // -----------------------------
  // 4) Effects
  // -----------------------------
  // Initialize
  useEffect(() => {
    if (project) {
      setName(project.name);
      setStartDate(project.startDate || null);
      setEndDate(project.endDate || null);
      setStatus(project.status || "Not Started");
      setPriority(project.priority || "Medium");

      if (project.assignedToCompanies) {
        const initialSelectedCompanies = project.assignedToCompanies.map((company) => ({
          value: company._id,
          label: company.companyName,
        }));
        setSelectedCompanies(initialSelectedCompanies);
      }
    }
  }, [project]);

  // Populate company options
  useEffect(() => {
    if (companies) {
      const options = companies.map((company) => ({
        value: company._id,
        label: company.companyName,
      }));
      setCompanyOptions(options);
    }
  }, [companies]);

  // Update member options
  useEffect(() => {
    const fetchCompanyUsers = () => {
      setIsUserLoading(true);
      try {
        let aggregatedUsers = [];
        selectedCompanies.forEach((company) => {
          const usersForCompany = availableUserAndCompanyOptions.filter((user) => user.companyId === company.value);
          const formattedUsers = usersForCompany.map((user) => ({
            value: user.value,
            label: `${user.firstName} ${user.lastName} - ${user.companyName}`,
            firstName: user.firstName,
            lastName: user.lastName,
            companyName: user.companyName,
          }));
          aggregatedUsers = aggregatedUsers.concat(formattedUsers);
        });
        setMemberOptions(aggregatedUsers);

        // Preselect
        if (project?.members) {
          const projectMemberIds = project.members.map((m) => (typeof m === "object" ? m._id : m));
          const preselectedMembers = aggregatedUsers.filter((user) => projectMemberIds.includes(user.value));
          if (preselectedMembers.length === 0 && currentUser) {
            const currentUserOption = aggregatedUsers.find((user) => user.value === currentUser._id);
            setSelectedMembers(currentUserOption ? [currentUserOption] : []);
          } else {
            setSelectedMembers(preselectedMembers);
          }
        }
      } catch (error) {
        console.error("Error fetching company users:", error);
        customToast({
          title: "Error",
          description: "Failed to fetch users for selected companies.",
          status: "error",
        });
      } finally {
        setIsUserLoading(false);
      }
    };

    if (selectedCompanies.length > 0) {
      fetchCompanyUsers();
    } else {
      setMemberOptions([]);
      setSelectedMembers([]);
    }
  }, [
    selectedCompanies,
    isAvailableUserAndCompanyOptionsLoading,
    availableUserAndCompanyOptions,
    project?.members,
    currentUser,
    customToast,
    isOpen,
  ]);

  // "Select All"
  useEffect(() => {
    if (isOpen) {
      if (selectAllUsers) {
        if (memberOptions.length > 0) {
          setSelectedMembers(memberOptions);
        }
      } else {
        if (selectedMembers.length === 0 && currentUser) {
          const currentUserOption = memberOptions.find((u) => u.value === currentUser._id);
          setSelectedMembers(currentUserOption ? [currentUserOption] : []);
        }
      }
    }
  }, [selectAllUsers, memberOptions, currentUser, selectedMembers, isOpen]);

  // Derived
  const isSubmitDisabled =
    !name.trim() ||
    selectedCompanies.length === 0 ||
    (!selectAllUsers && selectedMembers.length === 0) ||
    !startDate ||
    !endDate ||
    isAvailableUserAndCompanyOptionsLoading;

  // -----------------------------
  // 5) Handlers
  // -----------------------------
  const handleSubmit = async () => {
    if (project && name.trim()) {
      try {
        const updatedProjectData = {
          name,
          startDate,
          endDate,
          status,
          priority,
          assignedToCompanies: selectedCompanies.map((c) => c.value),
          members: selectedMembers.map((m) => m.value),
        };
        setIsUserLoading(true);
        await editProject(project._id, updatedProjectData);
        onClose();
      } catch (error) {
        customToast({
          status: "error",
          title: "Error Updating Project",
          description: "An error occurred while updating the project.",
        });
      } finally {
        setIsUserLoading(false);
      }
    } else {
      customToast({
        status: "error",
        title: "Invalid Data",
        description: "Please provide a valid project name.",
      });
    }
  };

  // -----------------------------
  // 6) JSX
  // -----------------------------
  return (
    <ReusableModal
      isOpen={isOpen}
      onClose={onClose}
      title={`Edit Project - ${name || "..."}`}
      footerButtons={
        <HStack ml="auto" spacing={3}>
          <Button variant="outline" onClick={onClose}>
            Close
          </Button>
          <Button isDisabled={isSubmitDisabled} colorScheme="primary" onClick={handleSubmit} isLoading={isUserLoading}>
            Save Project
          </Button>
        </HStack>
      }
      size="3xl"
      isCentered
      scrollBehavior="inside"
    >
      {/* Project Name */}
      <FormControl isRequired p={4}>
        <FormLabel fontSize="md" fontWeight="500">
          Project Name
        </FormLabel>
        <Input value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter project name..." />
      </FormControl>

      {/* Project Duration */}
      <FormControl isRequired p={4}>
        <FormLabel fontSize="md" fontWeight="500">
          Project Duration
        </FormLabel>
        <HStack spacing={2}>
          <UtilsDatePicker
            hasPortal
            endDate={endDate || null}
            value={startDate}
            placeholder="Start Date"
            onChange={setStartDate}
            inputVariant="default"
          />
          <PiArrowRightBold color={arrowColor} />
          <UtilsDatePicker
            hasPortal
            value={endDate}
            startDate={startDate || null}
            placeholder="End Date"
            onChange={setEndDate}
            inputVariant="default"
          />
        </HStack>
      </FormControl>

      {/* Status & Priority */}
      <FormControl isRequired p={4}>
        <FormLabel fontSize="md" fontWeight="500">
          Project Status and Priority
        </FormLabel>
        <HStack spacing={4}>
          <TagMenu
            options={[
              { value: "Not Started", label: "Not Started", color: "gray" },
              { value: "In Progress", label: "In Progress", color: "blue" },
              { value: "Completed", label: "Completed", color: "green" },
              { value: "On Hold", label: "On Hold", color: "orange" },
            ]}
            selectedValue={status}
            field="status"
            handleBlurSave={(id, field, value) => setStatus(value)}
          />
          <TagMenu
            options={[
              { value: "High", label: "High", color: "red" },
              { value: "Medium", label: "Medium", color: "yellow" },
              { value: "Low", label: "Low", color: "green" },
            ]}
            selectedValue={priority}
            field="priority"
            handleBlurSave={(id, field, value) => setPriority(value)}
          />
        </HStack>
      </FormControl>

      {/* Companies */}
      <FormControl isRequired p={4}>
        <FormLabel fontSize="md" fontWeight="500">
          Select Companies
        </FormLabel>
        <Select
          isMulti
          placeholder="Select companies..."
          options={companyOptions}
          value={selectedCompanies}
          onChange={(companies) => setSelectedCompanies(companies)}
          components={chakraComponents}
          closeMenuOnSelect={false}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            menu: (base) => ({ ...base, zIndex: 9999 }),
          }}
        />
      </FormControl>

      {/* Members */}
      <FormControl p={4}>
        {!selectAllUsers && (
          <>
            <FormLabel fontSize="md" fontWeight="500">
              Select Members
            </FormLabel>
            {isAvailableUserAndCompanyOptionsLoading ? (
              <Box display="flex" justifyContent="center" alignItems="center" py={4}>
                <Spinner size="sm" />
              </Box>
            ) : (
              <Select
                isMulti
                placeholder="Select members..."
                options={memberOptions}
                value={selectedMembers}
                onChange={setSelectedMembers}
                components={{
                  Option: (props) => {
                    const { data, innerRef, innerProps } = props;
                    return (
                      <Box ref={innerRef} {...innerProps} _hover={{ bg: itemHoverBg }}>
                        <HStack spacing={3} px={6} py={1}>
                          <UserAvatar userIds={[data.value]} maxAvatars={1} size="xs" showInfo={true} />
                        </HStack>
                      </Box>
                    );
                  },
                  MultiValue: (props) => {
                    const { data, innerRef, innerProps, removeProps } = props;
                    return (
                      <Box
                        ref={innerRef}
                        {...innerProps}
                        mb={2}
                        bg={multiValueBg}
                        borderRadius="md"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        w="100%"
                        px={4}
                        py={1}
                      >
                        <HStack spacing={1} mr="auto">
                          <UserAvatar userIds={[data.value]} maxAvatars={1} size="xs" showInfo={true} />
                        </HStack>
                        <IconButton
                          icon={<PiXBold fontSize="16px" />}
                          colorScheme="gray"
                          variant="ghost"
                          size="sm"
                          onClick={removeProps.onClick}
                          aria-label="Remove"
                        />
                      </Box>
                    );
                  },
                }}
                closeMenuOnSelect={false}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (base) => ({ ...base, zIndex: 9999 }),
                }}
                isDisabled={memberOptions.length === 0}
              />
            )}
          </>
        )}
        <Checkbox isChecked={selectAllUsers} onChange={(e) => setSelectAllUsers(e.target.checked)} mt={2}>
          Select all users from selected companies
        </Checkbox>
      </FormControl>

      {isAvailableUserAndCompanyOptionsLoading && (
        <Box display="flex" justifyContent="center" alignItems="center" py={4}>
          <Spinner />
        </Box>
      )}
    </ReusableModal>
  );
};

export default EditProjectModal;
