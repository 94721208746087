import React from "react";
import {
  Box,
  Text,
  CircularProgress,
  CircularProgressLabel,
  Heading,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const ProgressCard = ({
  profileCompletionPercentage,
  isProjectsDone,
  isMessagesDone,
  isFilesDone,
  isProfileComplete,
  bgLight = "white",
  bgDark = "gray.800",
  themeColor = "primary",
}) => {
  const navigate = useNavigate();
  const cardBgColor = useColorModeValue(bgLight, bgDark);
  const cardBorderColor = useColorModeValue("gray.200", "gray.700");

  // If profile is 100% complete, do not show the card
  if (isProfileComplete || profileCompletionPercentage === 100) {
    return null;
  }

  // Determine the next step and its destination
  let nextStepLink = "/dashboard/settings"; // default is profile settings
  let nextStepText = "Complete Profile Settings";

  if (!isProjectsDone) {
    nextStepLink = "/dashboard/projects";
    nextStepText = "Create a Project";
  } else if (!isMessagesDone) {
    nextStepLink = "/dashboard/messages";
    nextStepText = "Start a Conversation";
  } else if (!isFilesDone) {
    nextStepLink = "/dashboard/files";
    nextStepText = "Upload a File";
  }

  const onNextSteps = () => {
    navigate(nextStepLink);
  };

  return (
    <Box border="1px solid" borderColor={cardBorderColor} borderRadius="md" bg={cardBgColor} p={4} boxShadow="base">
      <Heading as="h3" size="sm" mb={2}>
        Account Setup
      </Heading>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={4}>
        <Box flex="1" mr={4}>
          <Text fontSize="sm">
            {`Keep going to finish setting up your account!`}
          </Text>
        </Box>
        <CircularProgress value={profileCompletionPercentage} color={`${themeColor}.500`} size="50px">
          <CircularProgressLabel fontSize="sm">{`${profileCompletionPercentage}%`}</CircularProgressLabel>
        </CircularProgress>
      </Box>
      <Button size="xs" colorScheme={themeColor} onClick={onNextSteps}>
        {nextStepText}
      </Button>
    </Box>
  );
};

ProgressCard.propTypes = {
  profileCompletionPercentage: PropTypes.number.isRequired,
  isProjectsDone: PropTypes.bool.isRequired,
  isMessagesDone: PropTypes.bool.isRequired,
  isFilesDone: PropTypes.bool.isRequired,
  isProfileComplete: PropTypes.bool.isRequired,
  bgLight: PropTypes.string,
  bgDark: PropTypes.string,
  themeColor: PropTypes.string,
};

export default ProgressCard;
