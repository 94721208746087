import React from "react";
import { Flex, Box, Text, VStack, useColorModeValue } from "@chakra-ui/react";
import { formatFileSize } from "./UtilsFormatData";
import { renderFileIcon } from "./UtilsFileIcon";

const FileCard = ({ file }) => {
  // -----------------------------
  // 1) (No local state here)
  // -----------------------------

  // -----------------------------
  // 2) Color definition
  // -----------------------------
  const textColor = useColorModeValue("black", "white");
  const textLightColor = useColorModeValue("gray.500", "gray.400");

  // -----------------------------
  // 3) Early return / Validation
  // -----------------------------
  if (!file || !file.name || !file.size) {
    return null;
  }

  // -----------------------------
  // 4) JSX
  // -----------------------------
  return (
    <Flex align="center" p={2} borderRadius="md" w="100%">
      <Box>{renderFileIcon(file.name)}</Box>
      <VStack align="start" spacing={0} ml={3} flex="1" color={textColor}>
        <Text fontWeight="600" fontSize="sm">
          {file.name.length > 30 ? `${file.name.substring(0, 25)}...` : file.name}
        </Text>
        <Text fontSize="xs" color={textLightColor}>{formatFileSize(file.size)}</Text>
      </VStack>
    </Flex>
  );
};

export default FileCard;
