import React from "react";
import { Box, Flex, Heading, Text, useColorModeValue, Button, Stack } from "@chakra-ui/react";

const SettingsPageNotifications = () => {
  // 1) Color variables
  const boxBg = useColorModeValue("gray.50", "gray.700");
  const textColor = useColorModeValue("black", "white");
  const headerBg = useColorModeValue("gray.50", "gray.900");
  const headerBorder = useColorModeValue("gray.100", "gray.750");

  // 2) JSX
  return (
    <>
      <Box
        position="relative"
        top={0}
        left={0}
        right={0}
        h="80px"
        bg={headerBg}
        borderBottomWidth="1px"
        borderColor={headerBorder}
        display="flex"
        alignItems="center"
        pl={{ base: 14, md: 10 }}
      >
        <Heading as="h1" size="md">
          Notification Settings
        </Heading>
      </Box>

      <Flex align="start" justify="center" minH="calc(100vh - 60px)" p={4} mt="90px">
        <Box
          border="1px solid"
          borderColor={headerBorder}
          bg={boxBg}
          p={{ base: 8, md: 10 }}
          rounded="lg"
          shadow="2xl"
          maxW="lg"
          w="full"
          textAlign="center"
        >
          <Heading as="h2" mb={6} size="md">
            Notification Settings
          </Heading>
          <Text fontSize="md" color={textColor} mb={3}>
            Manage how and when you receive notifications. Keep track of everything without getting overwhelmed.
          </Text>
          <Text fontSize="md" color={textColor} mb={6}>
            More notification options are on the way, including custom alerts and digest emails!
          </Text>
          <Stack spacing={4} direction="row" justify="center">
            <Button colorScheme="primary" isDisabled variant="outline">
              Update Notification Preferences
            </Button>
            <Button colorScheme="primary" isDisabled variant="solid">
              View Notification History
            </Button>
          </Stack>
        </Box>
      </Flex>
    </>
  );
};

export default SettingsPageNotifications;
