// client/src/components/Projects/ProjectsDescription.jsx

import React, { useState, useContext, useEffect, useRef } from "react";
import { Editor, EditorState, ContentState, convertFromHTML, RichUtils, Modifier } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import {
  Flex,
  Text,
  Button,
  Box,
  HStack,
  Spinner,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  PiFloppyDiskBackFill,
  PiPencilSimpleLine,
  PiXBold,
  PiTextBBold,
  PiTextItalicBold,
  PiTextUnderlineBold,
  PiTextHBold,
  PiListDashes,
  PiListNumbersBold,
  PiTextAlignCenter,
  PiTextAlignRight,
  PiTextAlignLeft,
  PiPaintBrushBold,
  PiArrowUUpLeftBold, // Undo
  PiArrowUUpRightBold, // Redo
} from "react-icons/pi";
import "../5 - General/Other/descriptionStyles.css";

import { ProjectContext } from "../5 - General/Context/ProjectsContext";

// ----- CUSTOM INLINE STYLES -----
const styleMap = {
  RED: { color: "red" },
  BLUE: { color: "blue" },
  GREEN: { color: "green" },
};

// ----- CUSTOM BLOCK STYLES -----
const blockStyleFn = (contentBlock) => {
  const type = contentBlock.getType();
  switch (type) {
    case "header-one":
      return "header-one-block";
    case "header-two":
      return "header-two-block";
    case "header-three":
      return "header-three-block";
    case "header-four":
      return "header-four-block";
    case "header-five":
      return "header-five-block";
    case "header-six":
      return "header-six-block";
    case "left-align":
      return "left-align-block";
    case "center-align":
      return "center-align-block";
    case "right-align":
      return "right-align-block";
    default:
      return null;
  }
};

const ProjectsDescription = () => {
  const { currentProjectId, projects, editProject, isLoading, hasError } = useContext(ProjectContext);
  const currentProject = projects.find((project) => project._id === currentProjectId);

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [initialEditorState, setInitialEditorState] = useState(() => EditorState.createEmpty());
  const [isEditing, setIsEditing] = useState(false);

  const borderColor = useColorModeValue("gray.200", "gray.750");
  const bgColor = useColorModeValue("gray.50", "gray.700");

  const toast = useToast();
  const editorRef = useRef(null);

  // --------------------- LOAD PROJECT DESCRIPTION ---------------------
  useEffect(() => {
    if (currentProject && currentProject.description) {
      const blocksFromHTML = convertFromHTML(currentProject.description);
      const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
      setInitialEditorState(newEditorState);
    } else {
      setEditorState(EditorState.createEmpty());
      setInitialEditorState(EditorState.createEmpty());
    }
  }, [currentProject]);

  // --------------------- SAVE / CANCEL ---------------------
  const handleSaveDescription = async () => {
    try {
      const htmlDescription = stateToHTML(editorState.getCurrentContent());
      await editProject(currentProject._id, { description: htmlDescription });
      setIsEditing(false);
      toast({
        title: "Description saved.",
        status: "success",
        isClosable: true,
      });
    } catch (error) {
      console.error("Error saving description:", error);
    }
  };

  const handleCancelEdit = () => {
    setEditorState(initialEditorState);
    setIsEditing(false);
  };

  // --------------------- KEY COMMANDS (CTRL+B, etc.) ---------------------
  const handleKeyCommand = (command, editorStateParam) => {
    const newState = RichUtils.handleKeyCommand(editorStateParam, command);
    if (newState) {
      setEditorState(_applyKeepNextTypingStyled(newState));
      return "handled";
    }
    return "not-handled";
  };

  // --------------------- HELPERS TO APPLY INLINED STYLES ---------------------
  const _applyKeepNextTypingStyled = (newState) => {
    const currentSelection = newState.getSelection();
    if (!currentSelection.isCollapsed()) {
      const collapsedAtEnd = currentSelection.merge({
        anchorOffset: currentSelection.getEndOffset(),
        focusOffset: currentSelection.getEndOffset(),
      });
      return EditorState.forceSelection(newState, collapsedAtEnd);
    }
    return newState;
  };

  // Toggle inline style (Bold, Italic, Underline, or color).
  const toggleInlineStyle = (style) => {
    const newState = RichUtils.toggleInlineStyle(editorState, style);
    setEditorState(_applyKeepNextTypingStyled(newState));
  };

  // Toggle color. Remove old color first, then apply new color.
  const toggleColor = (color) => {
    let newState = editorState;
    const currentStyle = editorState.getCurrentInlineStyle();
    ["RED", "BLUE", "GREEN"].forEach((c) => {
      if (currentStyle.has(c)) {
        newState = RichUtils.toggleInlineStyle(newState, c);
      }
    });
    newState = RichUtils.toggleInlineStyle(newState, color);
    setEditorState(_applyKeepNextTypingStyled(newState));
  };

  // --------------------- BLOCK STYLE TOGGLES ---------------------
  const toggleBlockType = (blockType) => {
    const newState = RichUtils.toggleBlockType(editorState, blockType);
    setEditorState(newState);
  };

  const toggleAlignment = (alignmentType) => {
    const newState = RichUtils.toggleBlockType(editorState, alignmentType);
    setEditorState(newState);
  };

  // --------------------- UNDO / REDO ---------------------
  const handleUndo = () => {
    const newEditorState = EditorState.undo(editorState);
    setEditorState(newEditorState);
  };

  const handleRedo = () => {
    const newEditorState = EditorState.redo(editorState);
    setEditorState(newEditorState);
  };

  // --------------------- REMOVE ALL FORMATTING ---------------------
  const removeFormatting = () => {
    let newState = editorState;
    const selection = newState.getSelection();
    let contentState = newState.getCurrentContent();
    const allStyles = ["BOLD", "ITALIC", "UNDERLINE", "RED", "BLUE", "GREEN"];
    allStyles.forEach((style) => {
      contentState = Modifier.removeInlineStyle(contentState, selection, style);
    });
    newState = EditorState.push(newState, contentState, "change-inline-style");
    const startKey = selection.getStartKey();
    const endKey = selection.getEndKey();
    if (startKey === endKey) {
      const startBlockType = newState.getCurrentContent().getBlockForKey(startKey).getType();
      if (startBlockType !== "unstyled") {
        newState = RichUtils.toggleBlockType(newState, "unstyled");
      }
    } else {
      const content = newState.getCurrentContent();
      const blockMap = content.getBlockMap();
      const blockKeys = blockMap
        .skipUntil((_, k) => k === startKey)
        .takeUntil((_, k) => k === endKey)
        .concat([[endKey, blockMap.get(endKey)]])
        .keySeq()
        .toArray();
      blockKeys.forEach((blockKey) => {
        const blockType = content.getBlockForKey(blockKey).getType();
        if (blockType !== "unstyled") {
          newState = RichUtils.toggleBlockType(newState, "unstyled");
        }
      });
    }
    setEditorState(newState);
  };

  // --------------------- UTILITIES ---------------------
  const isBlockActive = (blockType) => {
    const selection = editorState.getSelection();
    const block = editorState.getCurrentContent().getBlockForKey(selection.getStartKey());
    return block.getType() === blockType;
  };

  // HEADINGS H1–H6 configuration
  const headingItems = [
    { label: "Normal Text", style: "unstyled", fontSize: "md", fontWeight: "normal" },
    { label: "Header 1", style: "header-one", fontSize: "2xl", fontWeight: "bold" },
    { label: "Header 2", style: "header-two", fontSize: "xl", fontWeight: "bold" },
    { label: "Header 3", style: "header-three", fontSize: "lg", fontWeight: "bold" },
    { label: "Header 4", style: "header-four", fontSize: "md", fontWeight: "bold" },
    { label: "Header 5", style: "header-five", fontSize: "sm", fontWeight: "bold" },
    { label: "Header 6", style: "header-six", fontSize: "xs", fontWeight: "bold" },
  ];

  // BLOCK STYLE OPTIONS
  const blockTypes = [
    {
      label: "Unordered List",
      style: "unordered-list-item",
      icon: <PiListDashes />,
    },
    {
      label: "Ordered List",
      style: "ordered-list-item",
      icon: <PiListNumbersBold />,
    },
  ];

  // ALIGNMENT OPTIONS
  const alignments = [
    { label: "Align Left", style: "left-align", icon: <PiTextAlignLeft /> },
    { label: "Align Center", style: "center-align", icon: <PiTextAlignCenter /> },
    { label: "Align Right", style: "right-align", icon: <PiTextAlignRight /> },
  ];

  // COLOR OPTIONS
  const colorOptions = ["RED", "BLUE", "GREEN"];

  const isSaveDisabled = isLoading;

  // ----- CLICK-ANYWHERE-TO-FOCUS -----
  const handleEditorContainerClick = () => {
    const content = editorState.getCurrentContent();
    if (!content.hasText()) {
      const newEditorState = EditorState.moveFocusToEnd(EditorState.moveSelectionToEnd(editorState));
      setEditorState(newEditorState);
    }
  };

  return (
    <Flex
      mx={["2", "4"]}
      my={["2", "4"]}
      p={["3", "4"]}
      borderWidth="1px"
      borderColor={useColorModeValue("gray.200", "gray.600")}
      borderRadius="xl"
      bg={useColorModeValue("gray.50", "gray.800")}
      flexDir="column"
    >
      {/* ----- HEADER BAR ----- */}
      <Flex justifyContent="space-between" align="center" mb={["2", "4"]} flexWrap="wrap">
        <Text h={["28px", "32px"]} fontWeight="400" fontSize={["md", "lg"]}>
          Project Description
        </Text>
        {isEditing ? (
          <HStack spacing={2}>
            <Button variant="outline" aria-label="Cancel edit" colorScheme="gray" size="xs" onClick={handleCancelEdit} disabled={isLoading}>
              Cancel
            </Button>
            <Button
              w="fit-content"
              leftIcon={<PiFloppyDiskBackFill fontSize="18px" />}
              colorScheme="primary"
              onClick={handleSaveDescription}
              isLoading={isLoading}
              size="xs"
              variant="outline"
              disabled={isSaveDisabled}
            >
              Save Description
            </Button>
          </HStack>
        ) : (
          <Button
            w="fit-content"
            leftIcon={<PiPencilSimpleLine fontSize="18px" />}
            aria-label="Edit description"
            colorScheme="gray"
            size="xs"
            variant="outline"
            onClick={() => setIsEditing(true)}
          >
            Edit
          </Button>
        )}
      </Flex>

      {isEditing ? (
        <Box>
          {/* ----- TOOLBAR ----- */}
          <HStack spacing={2} mb={2} wrap="wrap">
            {/* UNDO / REDO */}
            <HStack spacing={0}>
              <Tooltip label="Undo">
                <IconButton icon={<PiArrowUUpLeftBold />} aria-label="Undo" onClick={handleUndo} variant="outline" size="sm" />
              </Tooltip>
              <Tooltip label="Redo">
                <IconButton icon={<PiArrowUUpRightBold />} aria-label="Redo" onClick={handleRedo} variant="outline" size="sm" />
              </Tooltip>
            </HStack>

            {/* INLINE STYLES (BOLD, ITALIC, UNDERLINE) */}
            <HStack spacing={0}>
              {[
                { label: "Bold", style: "BOLD", icon: <PiTextBBold /> },
                { label: "Italic", style: "ITALIC", icon: <PiTextItalicBold /> },
                { label: "Underline", style: "UNDERLINE", icon: <PiTextUnderlineBold /> },
              ].map((type) => {
                const isActive = editorState.getCurrentInlineStyle().has(type.style);
                return (
                  <Tooltip key={type.label} label={type.label}>
                    <IconButton
                      icon={type.icon}
                      aria-label={type.label}
                      onClick={() => toggleInlineStyle(type.style)}
                      variant={isActive ? "solid" : "outline"}
                      colorScheme={isActive ? "blue" : "gray"}
                      size="sm"
                    />
                  </Tooltip>
                );
              })}
            </HStack>

            {/* HEADINGS MENU */}
            <Menu>
              <Tooltip label="Headings">
                <MenuButton as={IconButton} icon={<PiTextHBold />} aria-label="Headings" size="sm" variant="outline" colorScheme="gray" />
              </Tooltip>
              <MenuList>
                {headingItems.map((item) => (
                  <MenuItem key={item.label} onClick={() => toggleBlockType(item.style)}>
                    <Text fontSize={item.fontSize} fontWeight={item.fontWeight}>
                      {item.label}
                    </Text>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>

            {/* LISTS: UL, OL */}
            <HStack spacing={0}>
              {blockTypes.map((type) => (
                <Tooltip key={type.label} label={type.label}>
                  <IconButton
                    icon={type.icon}
                    aria-label={type.label}
                    onClick={() => toggleBlockType(type.style)}
                    variant={isBlockActive(type.style) ? "solid" : "outline"}
                    colorScheme={isBlockActive(type.style) ? "blue" : "gray"}
                    size="sm"
                  />
                </Tooltip>
              ))}
            </HStack>

            {/* ALIGNMENT: Left, Center, Right */}
            <HStack spacing={0}>
              {alignments.map((type) => (
                <Tooltip key={type.label} label={type.label}>
                  <IconButton
                    icon={type.icon}
                    aria-label={type.label}
                    onClick={() => toggleAlignment(type.style)}
                    variant={isBlockActive(type.style) ? "solid" : "outline"}
                    colorScheme={isBlockActive(type.style) ? "blue" : "gray"}
                    size="sm"
                  />
                </Tooltip>
              ))}
            </HStack>

            {/* TEXT COLOR MENU */}
            <Menu>
              <Tooltip label="Text Color">
                <MenuButton as={IconButton} icon={<PiPaintBrushBold />} aria-label="Color" size="sm" variant="outline" colorScheme="gray" />
              </Tooltip>
              <MenuList>
                {colorOptions.map((color) => (
                  <MenuItem key={color} onClick={() => toggleColor(color)} icon={<Box w="12px" h="12px" bg={color.toLowerCase()} />}>
                    {color}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>

            {/* REMOVE FORMATTING */}
            <Tooltip label="Remove all formatting">
              <IconButton icon={<PiXBold />} aria-label="Remove formatting" onClick={removeFormatting} variant="outline" size="sm" />
            </Tooltip>
          </HStack>

          {/* ----- EDITOR AREA ----- */}
          <Box
            mt={4}
            bg={bgColor}
            border="1px solid"
            borderColor={borderColor}
            borderRadius="lg"
            p={["2", "4"]}
            minH="150px"
            onClick={handleEditorContainerClick}
          >
            <Editor
              ref={(element) => (editorRef.current = element)}
              editorState={editorState}
              onChange={(newState) => setEditorState(newState)}
              handleKeyCommand={handleKeyCommand}
              customStyleMap={styleMap}
              blockStyleFn={blockStyleFn}
            />
          </Box>
        </Box>
      ) : (
        // READ-ONLY VIEW
        <Box
          bg={bgColor}
          p={["3", "4"]}
          borderRadius="xl"
          border="1px solid"
          borderColor={borderColor}
          boxShadow="base"
          style={{
            lineHeight: "1.6",
            whiteSpace: "pre-wrap",
            fontSize: "16px",
          }}
        >
          {hasError ? (
            <Text color="red.500">Failed to load project description.</Text>
          ) : isLoading ? (
            <Spinner />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: currentProject.description || "No description provided.",
              }}
            />
          )}
        </Box>
      )}
    </Flex>
  );
};

export default ProjectsDescription;
