import React from "react";
import { Box, Heading, Text, Center, Icon, Button, SimpleGrid, Divider, useColorModeValue } from "@chakra-ui/react";
import { PiChartLineUp } from "react-icons/pi";

const Reports = () => {
  // -----------------------------
  // 1) (No local state)
  // -----------------------------

  // -----------------------------
  // 2) Color definitions
  // -----------------------------
  const containerBg = useColorModeValue("gray.50", "gray.800");
  const headingColor = useColorModeValue("primary.700", "primary.500");
  const iconColor = useColorModeValue("primary.500", "primary.500");
  const textColor = useColorModeValue("gray.600", "gray.200");
  const subHeadingColor = useColorModeValue("primary.600", "primary.500");
  const dividerColor = useColorModeValue("gray.200", "gray.600");

  // -----------------------------
  // 3) JSX
  // -----------------------------
  return (
    <Box bg={containerBg} w="100%" minH="100vh" p={5} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Center w="100%" flexDirection="column">
        <Icon as={PiChartLineUp} boxSize={20} color={iconColor} mb={4} />
        <Heading as="h1" size="2xl" color={headingColor} textAlign="center">
          Reports Module Coming Soon
        </Heading>
        <Text fontSize="lg" color={textColor} textAlign="center" maxW="800px" mt={4}>
          Get ready for our new Reports Module! Soon, you&apos;ll have access to comprehensive analytics and insights directly within your client
          portal.
        </Text>
        <Button colorScheme="primary" size="lg" mt={6} disabled>
          Notify Me When Available
        </Button>
      </Center>

      <Divider my={10} borderColor={dividerColor} />

      {/* Feature Highlights */}
      <Box maxW="800px" mx="auto">
        <Heading as="h2" size="lg" color={subHeadingColor} mb={4} textAlign="center">
          Upcoming Features
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
          <Box>
            <Heading as="h3" size="md" mb={2} color={textColor}>
              📈 Analytics Dashboards
            </Heading>
            <Text color={textColor}>Visualize key performance indicators with interactive charts and graphs.</Text>
          </Box>
          <Box>
            <Heading as="h3" size="md" mb={2} color={textColor}>
              📝 Custom Reports
            </Heading>
            <Text color={textColor}>Generate tailored reports focusing on specific metrics, projects, or time frames.</Text>
          </Box>
          <Box>
            <Heading as="h3" size="md" mb={2} color={textColor}>
              📤 Export Options
            </Heading>
            <Text color={textColor}>Export reports in various formats like PDF, Excel, or CSV for sharing and further analysis.</Text>
          </Box>
          <Box>
            <Heading as="h3" size="md" mb={2} color={textColor}>
              ⏰ Scheduled Reporting
            </Heading>
            <Text color={textColor}>Automate report generation and have them delivered to your inbox at regular intervals.</Text>
          </Box>
        </SimpleGrid>
      </Box>
      <Box w="10px" h="100px" />
    </Box>
  );
};

export default Reports;
