// client/src/components/SettingsPageTeam.jsx

import React, { useState, useContext, useMemo } from "react";
import { WorkspaceContext } from "../5 - General/Context/WorkspaceContext";
import {
  Box,
  Flex,
  Button,
  Text,
  useColorModeValue,
  useDisclosure,
  Heading,
  HStack,
  VStack,
  Icon,
  Divider,
  Input,
  Link,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerCloseButton,
  useBreakpointValue,
} from "@chakra-ui/react";
import InviteUserModal from "../3 - Modal/8 - Settings Modals/InviteUserModal";
import ModifyRightsModal from "../3 - Modal/8 - Settings Modals/ModifyRightsModal";
import DeactivateUserModal from "../3 - Modal/8 - Settings Modals/DeactivateUserModal";
import DeleteUserModal from "../3 - Modal/8 - Settings Modals/DeleteUserModal"; // Newly added
import { PiUser, PiLink } from "react-icons/pi";
import ModularTable from "./Reusable/ModularTable";
import { tableConfigs } from "./Reusable/TableConfigTeamSettings";
import { useInviteUser } from "../5 - General/Hooks/useUpdateCompany";
import { useUpdateUser } from "../5 - General/Hooks/useUpdateUser";
import { usePasswordReset } from "../5 - General/Hooks/useUpdateUser";
import { MobileActionsModal } from "../3 - Modal/8 - Settings Modals/MobileActionModalTeam";
import ConfirmActionModal from "../3 - Modal/9 - General Modals/ConfirmActionModal";

// SidePanelContent Component for additional information
const SidePanelContent = ({ themeColor }) => {
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const textColor = useColorModeValue("gray.600", "gray.200");
  const borderColor = useColorModeValue("gray.200", "gray.750");
  const linkColor = useColorModeValue(`${themeColor}.600`, `${themeColor}.400`);
  const iconColor = useColorModeValue(`${themeColor}.600`, `${themeColor}.400`);

  return (
    <Box
      // Separate border properties to ensure correct application
      borderLeftWidth={{ base: "0", md: "1px" }}
      borderLeftStyle={{ base: "none", md: "solid" }}
      borderLeftColor={borderColor}
      position={{ base: "static", md: "sticky" }}
      top="0"
      right="0"
      minW={{ base: "full", md: "350px" }}
      w={{ base: "full", md: "350px" }}
      height={{ base: "auto", md: "100vh" }}
      bg={bgColor}
      p={8}
      overflowY="auto"
      // Custom scrollbar styles
      sx={{
        /* WebKit-based browsers */
        "&::-webkit-scrollbar": {
          width: "8px",
        },
        "&::-webkit-scrollbar-track": {
          borderRadius: "4px",
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "4px",
          backgroundColor: useColorModeValue("gray.300", "gray.600"),
        },
        /* Firefox */
        scrollbarWidth: "thin",
        scrollbarColor: `${useColorModeValue("gray.300", "gray.600")} transparent`,
      }}
    >
      <Heading as="h2" fontWeight="600" size="md" mb={4} color={textColor}>
        Access Control
      </Heading>
      <Text fontSize="md" mb={6} color={textColor}>
        Manage team members of your organization and set their access level. You can invite new team members up to the seats allowed on your plan.
      </Text>

      {/* Admin Role */}
      <Heading as="h3" fontWeight="600" size="sm" mb={2} color={textColor}>
        Admin Role
      </Heading>
      <Text fontSize="sm" mb={4} color={textColor}>
        Admins have the highest level of access. They can view and manage all companies within the app, edit organization settings, manage members,
        and oversee security and billing settings.
      </Text>

      {/* Editor Role */}
      <Heading as="h3" fontWeight="600" size="sm" mb={2} color={textColor}>
        Editor Role
      </Heading>
      <Text fontSize="sm" mb={4} color={textColor}>
        Editors have full access within their own company, including editing and managing projects, files, and organization settings. However, they
        cannot view or interact with other companies in the app, except for their admin company.
      </Text>

      {/* Customer Role */}
      <Heading as="h3" fontWeight="600" size="sm" mb={2} color={textColor}>
        Customer Role
      </Heading>
      <Text fontSize="sm" color={textColor}>
        Customers have read-only access, allowing them to view projects and interact with most features. They are restricted from creating, editing,
        or deleting objects within the organization. They can send messages and add files and view most data.
      </Text>

      <Divider borderColor={textColor} my={6} />

      <HStack spacing={1} mb={4} alignContent="center">
        <Text fontSize="sm" color={textColor}>
          Learn more about
        </Text>
        <Link href="#" fontSize="sm" color={linkColor} cursor="pointer" _hover={{ textDecoration: "underline" }}>
          Team Member Roles.
        </Link>
        <Icon as={PiLink} fontSize="18px" color={iconColor} />
      </HStack>
    </Box>
  );
};

const SettingsPageTeam = () => {
  // Contexts
  const { currentUser, themeColor, currentUserId, teamMembers, isLoading, isError, error, canEditModule, currentUserCompanyId } =
    useContext(WorkspaceContext);

  // Mutations
  const inviteUserMutation = useInviteUser();
  const updateUserMutation = useUpdateUser();
  const passwordResetMutation = usePasswordReset();

  // States
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [userToResetPassword, setUserToResetPassword] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  // Modal Disclosure States
  const { isOpen: isInviteModalOpen, onOpen: onInviteModalOpen, onClose: onInviteModalClose } = useDisclosure();
  const { isOpen: isRightsModalOpen, onOpen: onRightsModalOpen, onClose: onRightsModalClose } = useDisclosure();
  const { isOpen: isDeactivateModalOpen, onOpen: onDeactivateModalOpen, onClose: onDeactivateModalClose } = useDisclosure();
  const { isOpen: isDeleteModalOpen, onOpen: onDeleteModalOpen, onClose: onDeleteModalClose } = useDisclosure();
  const { isOpen: isMobileModalOpen, onOpen: onMobileModalOpen, onClose: onMobileModalClose } = useDisclosure();
  const { isOpen: isConfirmModalOpen, onOpen: onConfirmModalOpen, onClose: onConfirmModalClose } = useDisclosure();
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();

  // Responsive Breakpoints
  const isMobile = useBreakpointValue({ base: true, md: false });

  // Color Mode Values
  const bgTopBarColor = useColorModeValue("gray.50", "gray.900");
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.700", "gray.200");
  const borderColor = useColorModeValue("gray.200", "gray.750");
  const drawerBg = useColorModeValue("gray.50", "gray.800");

  // Filter Users
  const filteredUsers = useMemo(() => {
    if (!searchQuery) return teamMembers;
    return teamMembers.filter(
      (user) =>
        user.firstName.toLowerCase().includes(searchQuery) ||
        user.lastName.toLowerCase().includes(searchQuery) ||
        user.email.toLowerCase().includes(searchQuery)
    );
  }, [teamMembers, searchQuery]);

  // Pagination
  const maxPerPage = 10;
  const totalItems = filteredUsers.length;
  const totalPages = Math.ceil(totalItems / maxPerPage);

  const handleOpenModal = (user) => {
    setSelectedUser(user);
    onMobileModalOpen();
  };

  // Handle Page Change
  const handlePageChange = (newPage) => {
    // Prevent going out of bounds
    if (newPage < 1 || newPage > totalPages) return;
    setCurrentPage(newPage);
  };

  // Paginate Data
  const paginatedData = filteredUsers.slice((currentPage - 1) * maxPerPage, currentPage * maxPerPage);

  // Handle search input
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };

  // Handle Modify Rights
  const handleModifyRights = (user) => {
    setSelectedUser(user);
    onRightsModalOpen();
  };

  // Handle Deactivate User
  const handleDeactivateUser = (user) => {
    setSelectedUser(user);
    onDeactivateModalOpen();
  };

  // Handle Delete User
  const handleDeleteUser = (user) => {
    setSelectedUser(user);
    onDeleteModalOpen();
  };

  // Handle Change Role
  const handleChangeRole = (userId, newRole) => {
    updateUserMutation.mutate({ userId, updates: { role: newRole } });
  };

  // Handle Activate User
  const handleActivateUser = (user) => {
    updateUserMutation.mutate({ userId: user._id, updates: { status: "Active" } });
  };

  // Handle Resend Invitation
  const handleResendInvitation = async (user) => {
    try {
      await inviteUserMutation.mutateAsync({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        companyId: currentUserCompanyId,
        invitedBy: currentUser.id, // Assuming you have currentUser.id
      });
    } catch (error) {
      console.error("Failed to resend invitation:", error);
      // Optionally, handle the error (e.g., show a toast notification)
    }
  };

  // Handle Send Password Reset with Confirmation
  const handleSendPasswordReset = (user) => {
    setUserToResetPassword(user);
    onConfirmModalOpen();
  };

  // Confirm Password Reset
  const confirmSendPasswordReset = () => {
    if (userToResetPassword) {
      passwordResetMutation.mutate({ userId: userToResetPassword._id });
      // Optionally, show a success message or toast here
      onConfirmModalClose();
      setUserToResetPassword(null);
    }
  };

  // Check if the current user can modify the target user
  const canModifyUser = (currentUserRole, targetUserRole, currentUserId, targetUserId, teamMembers = []) => {
    // 1. Customers cannot modify any user.
    if (currentUserRole === "Customer") {
      return false;
    }

    // 2. Self‑modification check.
    if (currentUserId === targetUserId) {
      // Prevent Admins from modifying themselves.
      if (currentUserRole === "Admin") {
        return false;
      }
      // For Editors (or similar), allow self‑modification only if there is at least one other privileged user.
      const privilegedCount = teamMembers.filter((user) => user.role === "Admin" || user.role === "Editor").length;
      return privilegedCount > 1;
    }

    // 3. If current user is Admin (and not modifying themselves), allow modification.
    if (currentUserRole === "Admin") {
      return true;
    }

    // 4. If current user is an Editor, allow modifying only if the target is an Editor or a Customer.
    if (currentUserRole === "Editor") {
      return targetUserRole === "Editor" || targetUserRole === "Customer";
    }

    // Default: Deny modification.
    return false;
  };

  // Table Configurations
  const { desktopColumns, mobileColumns, actionColumn, headerStyles, rowStyles, cellStyles, headerCellStyles } = tableConfigs.teamSettings({
    handleModifyRights,
    handleChangeRole,
    handleDeactivateUser,
    handleResendInvitation,
    handleSendPasswordReset,
    handleActivateUser,
    handleDeleteUser,
    currentUserRole: currentUser?.role,
    currentUserId: currentUserId,
    teamMembers: teamMembers,
    canModifyUser,
    isMobile,
    handleOpenModal,
  });

  // Determine which columns to render based on the screen size
  const columns = isMobile ? mobileColumns : desktopColumns;

  // Render Loading, Error States
  if (isLoading) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Text>Loading team members...</Text>
      </Flex>
    );
  }

  if (isError) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Text color="red.500">Error fetching team members: {error.message}</Text>
      </Flex>
    );
  }

  return (
    <Flex minH="100vh" bg={bgColor} flexDirection={{ base: "column", md: "row" }}>
      {/* Main Content */}
      <Box
        flex="1"
        overflowX="hidden"
        overflowY="auto"
        sx={{
          /* WebKit-based browsers */
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "4px",
          },
          /* Firefox */
          scrollbarWidth: "thin",
        }}
      >
        {/* Top Bar */}
        <Flex
          borderBottom="1px solid"
          px={{ base: 4, md: 8 }}
          borderColor={borderColor}
          maxH="80px"
          position="sticky"
          top="0"
          bg={bgTopBarColor}
          h="80px"
          align="center"
          justify="space-between"
          mb={8}
          zIndex={10}
        >
          {/* Title on the Left */}
          <Text fontSize="xl" pl={{ base: "35px", md: 0 }} fontWeight="500" color={textColor}>
            Team Settings
          </Text>

          {/* Search Bar and Button on the Right */}
          <Flex align="center" justify="flex-end" gap={4}>
            {!isMobile && (
              <>
                <Text fontWeight="500" fontSize="md" mr={2} color={textColor}>
                  {teamMembers.length} {teamMembers.length > 1 ? "Members" : "Member"}
                </Text>
                <Input placeholder="Search members" value={searchQuery} onChange={handleSearch} size="md" maxW="200px" />
              </>
            )}
            {canEditModule("team-settings") && (
              <Button leftIcon={<PiUser />} colorScheme={themeColor} size="sm" onClick={onInviteModalOpen}>
                Invite Member
              </Button>
            )}
          </Flex>
        </Flex>

        {/* Content */}
        <VStack spacing={8} align="stretch" width="100%" px={{ base: 4, md: 8 }} py={12} pt={0}>
          {isMobile && (
            <Button
              alignSelf="start"
              color={`${themeColor}.500`}
              aria-label="Open Access Control Information"
              leftIcon={<PiUser />}
              variant="ghost"
              onClick={onDrawerOpen}
            >
              Learn more about Access Control
            </Button>
          )}

          {/* Table */}
          <ModularTable
            key={isMobile ? "mobile" : "desktop"}
            columns={columns}
            data={paginatedData}
            actionColumn={actionColumn}
            allowColumnVisibility={false}
            allowSearch={false}
            allowSorting={true}
            headerStyles={headerStyles}
            rowStyles={rowStyles}
            cellStyles={cellStyles}
            headerCellStyles={headerCellStyles}
            currentPage={currentPage}
            totalPages={totalPages}
            totalItems={totalItems}
            maxPerPage={maxPerPage}
            onPageChange={handlePageChange}
          />
          {filteredUsers.length === 0 && (
            <Text mt={4} color={textColor} textAlign="center">
              No users found.
            </Text>
          )}
        </VStack>
      </Box>

      {/* Desktop Side Panel */}
      {!isMobile && <SidePanelContent themeColor={themeColor} />}

      {/* Mobile Side Panel Drawer */}
      {isMobile && (
        <Drawer placement="right" onClose={onDrawerClose} isOpen={isDrawerOpen}>
          <DrawerOverlay>
            <DrawerContent bg={drawerBg}>
              <DrawerCloseButton />
              <DrawerBody p={0}>
                <SidePanelContent themeColor={themeColor} />
              </DrawerBody>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      )}

      {/* Modals */}
      <InviteUserModal isOpen={isInviteModalOpen} onClose={onInviteModalClose} />
      <ModifyRightsModal isOpen={isRightsModalOpen} onClose={onRightsModalClose} selectedUser={selectedUser} />
      <DeactivateUserModal isOpen={isDeactivateModalOpen} onClose={onDeactivateModalClose} userToDeactivate={selectedUser} />
      <DeleteUserModal isOpen={isDeleteModalOpen} onClose={onDeleteModalClose} userToDelete={selectedUser} />
      {selectedUser && isMobile && (
        <MobileActionsModal
          user={selectedUser}
          isOpen={isMobileModalOpen}
          onClose={onMobileModalClose}
          handleModifyRights={handleModifyRights}
          handleDeactivateUser={handleDeactivateUser}
          handleResendInvitation={handleResendInvitation}
          handleSendPasswordReset={handleSendPasswordReset}
          handleActivateUser={handleActivateUser}
          handleDeleteUser={handleDeleteUser}
          currentUserRole={currentUser?.role}
          canModifyUser={canModifyUser}
        />
      )}

      <ConfirmActionModal
        isOpen={isConfirmModalOpen}
        onClose={onConfirmModalClose}
        onConfirm={confirmSendPasswordReset}
        title="Confirm Password Reset"
        description={`Are you sure you want to send a password reset to ${userToResetPassword?.firstName} ${userToResetPassword?.lastName}?`}
        confirmText="Yes, Reset Password"
        cancelText="Cancel"
      />
    </Flex>
  );
};

export default React.memo(SettingsPageTeam);
