// client/src/components/Projects.js

import React, { useContext, useEffect, useMemo, useCallback } from "react";
import { Flex, Spinner, HStack, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Text, useDisclosure, useColorModeValue, Button } from "@chakra-ui/react";
import { PiCaretRightBold, PiPlus } from "react-icons/pi";
import { ArrowBackIcon } from "@chakra-ui/icons"; // Chakra's built-in back icon
import { ProjectContext } from "../5 - General/Context/ProjectsContext";
import { WorkspaceContext } from "../5 - General/Context/WorkspaceContext";
import ProjectCards from "./ProjectsCards";
import CreateProjectModal from "../3 - Modal/3 - Projects Modals/ProjectsCreateProjectModal";
import TaskGroups from "./ProjectsTaskGroup";
import { useParams, useNavigate } from "react-router-dom";
import { useBreakpointValue } from "@chakra-ui/react";

const Projects = () => {
  const { currentWorkspace, themeColor, isWorkspaceLoading } = useContext(WorkspaceContext);
  const { isLoading, hasError, setCurrentProjectId, currentProjectId, tasks, projects, currentTaskId, setCurrentTaskId, setCurrentSubtaskId } =
    useContext(ProjectContext);

  // Find current project and task from context state
  const currentProject = projects.find((project) => project._id === currentProjectId);
  const currentTask = tasks.find((task) => task._id === currentTaskId);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { projectId, taskId, subtaskId } = useParams(); // Extract URL params

  // Synchronize URL params with context state
  useEffect(() => {
    setCurrentProjectId(projectId || null);
    setCurrentTaskId(taskId || null);
    setCurrentSubtaskId(subtaskId || null);
  }, [projectId, taskId, subtaskId, setCurrentProjectId, setCurrentTaskId, setCurrentSubtaskId]);

  const borderColor = useColorModeValue("gray.200", "gray.750");
  const breadcrumbTextColor = useColorModeValue("black", "white");
  const breadcrumbLinkColor = useColorModeValue("gray.500", "gray.400");
  const spinnerColor = useColorModeValue(`${themeColor}.500`, `${themeColor}.200`);
  const bgColor = useColorModeValue("white", "gray.800");
  const topBarBgColor = useColorModeValue("gray.50", "gray.900");

  // Detect mobile devices
  const isMobile = useBreakpointValue({ base: true, md: false });

  // Reset project, task, and subtask selections when currentWorkspace changes
  useEffect(() => {
    if (!isWorkspaceLoading && currentWorkspace) {
      setCurrentProjectId(projectId || null);
      setCurrentTaskId(taskId || null);
      setCurrentSubtaskId(subtaskId || null);
    }
  }, [projectId, taskId, subtaskId, currentWorkspace, isWorkspaceLoading, setCurrentProjectId, setCurrentTaskId, setCurrentSubtaskId]);

  // Compute Breadcrumb Dynamically – on desktop we include more details.
  const breadcrumb = useMemo(() => {
    const crumbs = ["Projects"];
    if (currentWorkspace) {
      crumbs.push(currentWorkspace.companyName || "Select a Company");
    }
    if (currentProject) {
      crumbs.push(currentProject.name || "Unnamed Project");
    }
    if (currentTask) {
      crumbs.push(currentTask.name || "Unnamed Task");
    }
    return crumbs;
  }, [currentWorkspace, currentProject, currentTask]);

  // Handle Breadcrumb Navigation (for clickable breadcrumb items if needed)
  const handleBreadcrumbClick = useCallback(
    (crumb, index) => {
      // (This function is used only on desktop when breadcrumbs are shown.)
      if (index === 0) {
        // "Projects" clicked: go to projects list.
        navigate("/dashboard/projects");
        setCurrentProjectId(null);
        setCurrentTaskId(null);
        setCurrentSubtaskId(null);
      } else if (index === 1) {
        // Company clicked: also go back to projects list.
        navigate("/dashboard/projects");
        setCurrentProjectId(null);
        setCurrentTaskId(null);
        setCurrentSubtaskId(null);
      } else if (index === 2) {
        // Project clicked.
        navigate(`/dashboard/projects/${currentProjectId}`);
        setCurrentTaskId(null);
        setCurrentSubtaskId(null);
      } else if (index === 3) {
        // Task clicked.
        navigate(`/dashboard/projects/${currentProjectId}/${currentTaskId}`);
        setCurrentSubtaskId(null);
      }
    },
    [navigate, currentProjectId, currentTaskId, setCurrentProjectId, setCurrentTaskId, setCurrentSubtaskId]
  );

  // Handle Back Button Click – used on mobile devices.
  const handleBackClick = useCallback(() => {
    if (currentTask) {
      // If viewing a task, go back to the project view.
      setCurrentTaskId(null);
      setCurrentSubtaskId(null);
      navigate(`/dashboard/projects/${currentProjectId}`);
    } else if (currentProject) {
      // If viewing a project, go back to the projects list.
      setCurrentProjectId(null);
      setCurrentTaskId(null);
      setCurrentSubtaskId(null);
      navigate("/dashboard/projects");
    }
  }, [currentTask, currentProject, currentProjectId, navigate, setCurrentProjectId, setCurrentTaskId, setCurrentSubtaskId]);

  // Handle Edge Case: currentWorkspace still null
  if (!currentWorkspace) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" color={spinnerColor} />
      </Flex>
    );
  }

  return (
    <Flex height="100%" flex="1" boxShadow="lg" borderRadius="lg" flexDirection="column" overflow="auto" bg={bgColor}>
      {/* Top Bar with Breadcrumb on desktop or Back Button on mobile */}
      <Flex
        justify="space-between"
        align="center"
        position="sticky"
        top={0}
        zIndex={1}
        pl={6}
        pr={6}
        h="80px"
        minH="80px"
        borderBottom="1px solid"
        borderColor={borderColor}
        bg={topBarBgColor}
      >
        {isMobile && (currentProject || currentTask) ? (
          <Button ml={6} onClick={handleBackClick} leftIcon={<ArrowBackIcon />} variant="outline" color={breadcrumbLinkColor}>
            {currentTask ? `Back to ${currentProject?.name}` : "Back to Projects"}
          </Button>
        ) : (
          <HStack spacing={4}>
            <Breadcrumb spacing="8px" fontSize="lg" separator={<PiCaretRightBold size="12px" color="gray.500" />}>
              {breadcrumb.map((crumb, index) => (
                <BreadcrumbItem key={index}>
                  {index === breadcrumb.length - 1 ? (
                    <Text color={breadcrumbTextColor} fontWeight="500">
                      {crumb}
                    </Text>
                  ) : (
                    <BreadcrumbLink
                      onClick={() => handleBreadcrumbClick(crumb, index)}
                      cursor="pointer"
                      color={breadcrumbLinkColor}
                      fontWeight="normal"
                    >
                      {crumb}
                    </BreadcrumbLink>
                  )}
                </BreadcrumbItem>
              ))}
            </Breadcrumb>
          </HStack>
        )}
        <Button onClick={onOpen} colorScheme={themeColor} leftIcon={<PiPlus fontSize="18px" />}>
          Create Project
        </Button>
      </Flex>

      {/* Main Content */}
      <Flex flex="1" height="100%" overflow="auto">
        {isLoading ? (
          <Flex justify="center" align="center" height="100%" width="100%">
            <Spinner size="xl" color={spinnerColor} />
          </Flex>
        ) : hasError ? (
          <Flex justify="center" align="center" height="100%" width="100%">
            <Text fontSize="xl" color="red.500">
              An error occurred while fetching projects.
            </Text>
          </Flex>
        ) : !currentProjectId ? (
          <ProjectCards />
        ) : (
          <TaskGroups />
        )}
      </Flex>

      {/* Create Project Modal */}
      <CreateProjectModal isOpen={isOpen} onClose={onClose} />
    </Flex>
  );
};

export default Projects;
