// client/src/components/ProjectsTasksList.jsx

import React, { useState, useContext, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  HStack,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  Button,
  Tag,
  useColorModeValue,
  Text,
  InputGroup,
  InputLeftElement,
  Input,
  MenuOptionGroup,
  useBreakpointValue,
  Portal,
  IconButton,
  MenuDivider,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { PiPlus, PiMagnifyingGlass, PiCaretUpBold, PiCaretDownBold, PiPencilSimpleLine } from "react-icons/pi";
import { AnimatePresence, motion } from "framer-motion";
import UserAvatar from "../2 - Components/Reusable/UserAvatar";
import { formatEndDate } from "../5 - General/Utils/UtilsFormatData";
import TagMenu from "./Reusable/TagMenu";
import { ProjectContext } from "../5 - General/Context/ProjectsContext";
import { debounce } from "lodash";
import AssignTaskMembersModal from "../3 - Modal/3 - Projects Modals/ProjectsAssignMembersToTaskModal";
import UtilsDatePicker from "../5 - General/Utils/UtilsDatePicker";
import { WorkspaceContext } from "../5 - General/Context/WorkspaceContext";

const columnWidths = ["4fr", "3fr", "2fr", "2fr", "2fr", "2fr"];

//
// InlineCreateTaskRow Component
// Renders the inline form for creating a task.
// On desktop it renders as a grid row matching the header columns,
// and on mobile as a vertical card.
//
const InlineCreateTaskRow = ({ onSave, onCancel }) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: false });
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.750");

  // Local state for the new task's name and due date.
  const [newTaskName, setNewTaskName] = useState("");
  const [newTaskDueDate, setNewTaskDueDate] = useState("");

  // Desktop layout: use a grid row with columns.
  if (!isMobile) {
    return (
      <Box
        display="grid"
        gridTemplateColumns={columnWidths.join(" ")}
        bg={bgColor}
        borderBottom="1px solid"
        borderColor={borderColor}
        p="10px"
        alignItems="center"
      >
        {/* Column 1: Task Name Input */}
        <Box>
          <FormControl id="task-name" isRequired>
            <Input placeholder="Enter task name" value={newTaskName} onChange={(e) => setNewTaskName(e.target.value)} w="90%" />
          </FormControl>
        </Box>
        {/* Column 2: Due Date Picker */}
        <Box>
          <FormControl id="due-date">
            <UtilsDatePicker value={newTaskDueDate} onChange={(date) => setNewTaskDueDate(date)} placeholder="Select due date" hasPortal={true} />
          </FormControl>
        </Box>
        {/* Column 6: Save/Cancel buttons */}
        <Box>
          <HStack ml={6} spacing={2}>
            <Button size="xs" colorScheme="primary" onClick={() => onSave(newTaskName, newTaskDueDate)}>
              Save
            </Button>
            <Button
              size="xs"
              variant="outline"
              onClick={() => {
                setNewTaskName("");
                setNewTaskDueDate("");
                onCancel();
              }}
            >
              Cancel
            </Button>
          </HStack>
        </Box>
        <Box />
        <Box />
        <Box />
      </Box>
    );
  }

  // Mobile layout: Render as a vertical card.
  return (
    <Box w="100%" bg={bgColor} border="1px solid" borderColor={borderColor} borderRadius="md" p={4} mb={4} boxShadow="md">
      <FormControl id="task-name" isRequired mb={3}>
        <FormLabel>Task Name</FormLabel>
        <Input placeholder="Enter task name" value={newTaskName} onChange={(e) => setNewTaskName(e.target.value)} variant="filled" />
      </FormControl>
      <FormControl id="due-date" mb={3}>
        <FormLabel>Due Date (optional)</FormLabel>
        <UtilsDatePicker value={newTaskDueDate} onChange={(date) => setNewTaskDueDate(date)} placeholder="Select due date" hasPortal={true} />
      </FormControl>
      <HStack spacing={2} justify="flex-end">
        <Button size="sm" colorScheme="blue" onClick={() => onSave(newTaskName, newTaskDueDate)}>
          Save
        </Button>
        <Button
          size="sm"
          variant="outline"
          onClick={() => {
            setNewTaskName("");
            setNewTaskDueDate("");
            onCancel();
          }}
        >
          Cancel
        </Button>
      </HStack>
    </Box>
  );
};

//
// TaskRow Component: Renders each task row.
// (Unchanged from your previous version.)
//
const TaskRow = ({ task, onTaskClick, onStatusChange, onPriorityChange, statusOptions, priorityOptions, py }) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: false });
  const bgColor = useColorModeValue("white", "gray.800");
  const hoverBg = useColorModeValue("gray.50", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.750");
  const taskColor = useColorModeValue("primary.500", "primary.700");
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const taskFlagColor = task.color || taskColor;
  const subtaskCount = task.subtasks?.length || 0;

  const getendDateColorScheme = (endDate) => {
    if (!endDate) return "gray";
    const now = new Date();
    const due = new Date(endDate);
    const diffDays = Math.ceil((due - now) / (1000 * 60 * 60 * 24));
    if (diffDays < 0) return "red";
    if (diffDays < 3) return "yellow";
    if (diffDays < 10) return "green";
    return "gray";
  };

  if (!task) {
    console.error("TaskRow received an undefined task.");
    return null;
  }

  const assignedTo = task.assignedTo.map((user) => user._id);

  // MOBILE LAYOUT: Render as a vertical card.
  if (isMobile) {
    return (
      <Box
        w="100%"
        bg={bgColor}
        border="1px solid"
        borderColor={borderColor}
        borderRadius="md"
        p={4}
        mb={4}
        boxShadow="md"
        _hover={{ backgroundColor: hoverBg }}
        onClick={() => onTaskClick(task)}
      >
        <Flex alignItems="center" mb={3}>
          <Box w="10px" h="30px" bg={taskFlagColor} mr={3} borderRadius="md" />
          <Text fontWeight="600" fontSize="xl" isTruncated>
            {task.name}
          </Text>
        </Flex>
        <Text fontSize="sm" mb={2}>
          <strong>Due In: </strong>
          {task.endDate
            ? new Date(task.endDate) < new Date()
              ? `Overdue by ${Math.abs(Math.ceil((new Date() - new Date(task.endDate)) / (1000 * 60 * 60 * 24)))} day${
                  Math.abs(Math.ceil((new Date() - new Date(task.endDate)) / (1000 * 60 * 60 * 24))) > 1 ? "s" : ""
                }`
              : formatEndDate(task.endDate)
            : "Undefined"}
        </Text>
        <Text fontSize="sm" mb={2}>
          <strong>Subtasks: </strong>
          {subtaskCount > 0 ? `${subtaskCount} ${subtaskCount === 1 ? "Subtask" : "Subtasks"}` : "No Subtasks"}
        </Text>
        <Text fontSize="sm" mb={2}>
          <strong>Status: </strong>
          {task.status}
        </Text>
        <Text fontSize="sm" mb={2}>
          <strong>Priority: </strong>
          {task.priority}
        </Text>
        <Box mt={3}>
          {Array.isArray(assignedTo) && assignedTo.length > 0 ? (
            <HStack spacing={2}>
              <UserAvatar userIds={assignedTo} size="sm" useGroup={true} maxAvatars={3} spacing={-1} showInfo={false} />
              <Button
                size="xs"
                variant="ghost"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsAssignModalOpen(true);
                }}
              >
                <PiPencilSimpleLine />
              </Button>
            </HStack>
          ) : (
            <Button
              size="xs"
              colorScheme="gray"
              onClick={(e) => {
                e.stopPropagation();
                setIsAssignModalOpen(true);
              }}
              mt={2}
            >
              Assign members
            </Button>
          )}
        </Box>
        <AssignTaskMembersModal isOpen={isAssignModalOpen} task={task} onClose={() => setIsAssignModalOpen(false)} />
      </Box>
    );
  }

  // DESKTOP LAYOUT: Render as a grid row.
  return (
    <Box
      display="grid"
      gridTemplateColumns={columnWidths.join(" ")}
      bg={bgColor}
      _hover={{ backgroundColor: hoverBg }}
      transition="background-color 0.2s"
      alignItems="center"
      cursor="pointer"
      borderBottom="1px solid"
      borderColor={borderColor}
      onClick={() => onTaskClick(task)}
      py={py}
    >
      {/* Task Name */}
      <Box w={columnWidths[0]} display="flex" alignItems="center">
        <Box w="10px" h="30px" bg={taskFlagColor} mr={4} borderRadius="md" />
        <Text fontWeight="400" fontSize="lg" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
          {task.name}
        </Text>
      </Box>

      {/* Due Date */}
      <Box w={columnWidths[1]}>
        <Tag size="lg" borderRadius="full" fontSize="sm" colorScheme={getendDateColorScheme(task.endDate)}>
          {task.endDate
            ? new Date(task.endDate) < new Date()
              ? `Overdue by ${Math.abs(Math.ceil((new Date() - new Date(task.endDate)) / (1000 * 60 * 60 * 24)))} day${
                  Math.abs(Math.ceil((new Date() - new Date(task.endDate)) / (1000 * 60 * 60 * 24))) > 1 ? "s" : ""
                }`
              : formatEndDate(task.endDate)
            : "Undefined"}
        </Tag>
      </Box>

      {/* Subtasks Count */}
      <Box w={columnWidths[2]}>
        {subtaskCount > 0 ? (
          <Tag colorScheme="gray" fontSize="sm">
            {subtaskCount} {subtaskCount === 1 ? "Subtask" : "Subtasks"}
          </Tag>
        ) : (
          <Text fontSize="sm" color="gray.500">
            No Subtasks
          </Text>
        )}
      </Box>

      {/* Status */}
      <Box w={columnWidths[3]}>
        <TagMenu options={statusOptions} selectedValue={task.status} field="status" handleBlurSave={onStatusChange} taskId={task._id} />
      </Box>

      {/* Priority */}
      <Box w={columnWidths[4]}>
        <TagMenu options={priorityOptions} selectedValue={task.priority} field="priority" handleBlurSave={onPriorityChange} taskId={task._id} />
      </Box>

      {/* Assigned To */}
      <Box w={columnWidths[5]}>
        {Array.isArray(assignedTo) && assignedTo.length > 0 ? (
          <HStack spacing={2}>
            <UserAvatar userIds={assignedTo} size="sm" useGroup={true} maxAvatars={3} spacing={-1} showInfo={false} />
            <IconButton
              icon={<PiPencilSimpleLine />}
              aria-label="Edit assigned members"
              size="xs"
              onClick={(e) => {
                e.stopPropagation();
                setIsAssignModalOpen(true);
              }}
            />
          </HStack>
        ) : (
          <HStack spacing={2}>
            <Button
              size="xs"
              colorScheme="gray"
              onClick={(e) => {
                e.stopPropagation();
                setIsAssignModalOpen(true);
              }}
            >
              Assign members
            </Button>
          </HStack>
        )}
      </Box>
      <AssignTaskMembersModal isOpen={isAssignModalOpen} task={task} onClose={() => setIsAssignModalOpen(false)} />
    </Box>
  );
};

//
// ProjectsTasksList Component
//
const ProjectsTasksList = ({ onTaskClick, onAddTask }) => {
  const { tasks, editTask, createTask, currentProjectId, currentTaskId } = useContext(ProjectContext);
  const { currentUserId } = useContext(WorkspaceContext);
  // Provide fallback value to ensure a consistent layout on first render.
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: false });

  // States for search, sorting, and filtering...
  const [searchQuery, setSearchQuery] = useState("");
  const [sortField, setSortField] = useState("endDate");
  const [sortDirection, setSortDirection] = useState("asc");
  const [filters, setFilters] = useState({ status: [], priority: [] });

  // New state for inline task creation
  const [isCreatingInline, setIsCreatingInline] = useState(false);

  // Color Definitions
  const bgColor = useColorModeValue("white", "gray.800");
  const searchBg = useColorModeValue("gray.50", "gray.700");
  const headerBg = useColorModeValue("gray.100", "gray.600");
  const hoverBg = useColorModeValue("gray.50", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.750");

  const thStyles = {
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: "600",
    cursor: "pointer",
    backgroundColor: bgColor,
  };

  const statusOptions = useMemo(
    () => [
      { value: "Not Started", label: "Not Started", color: "gray" },
      { value: "In Progress", label: "In Progress", color: "primary" },
      { value: "Completed", label: "Completed", color: "green" },
      { value: "On Hold", label: "On Hold", color: "orange" },
    ],
    []
  );

  const priorityOptions = useMemo(
    () => [
      { value: "High", label: "High", color: "red" },
      { value: "Medium", label: "Medium", color: "yellow" },
      { value: "Low", label: "Low", color: "green" },
    ],
    []
  );

  const onStatusChange = useCallback(
    async (taskId, field, value) => {
      try {
        await editTask(taskId, { [field]: value });
      } catch (error) {
        console.error(`Error updating task ${field}:`, error);
      }
    },
    [editTask]
  );

  const onPriorityChange = useCallback(
    async (taskId, field, value) => {
      try {
        await editTask(taskId, { [field]: value });
      } catch (error) {
        console.error(`Error updating task ${field}:`, error);
      }
    },
    [editTask]
  );

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  const renderSortIcon = (field) => {
    if (sortField !== field) return null;
    return sortDirection === "asc" ? <PiCaretUpBold style={{ marginLeft: "8px" }} /> : <PiCaretDownBold style={{ marginLeft: "8px" }} />;
  };

  const debouncedSetSearchQuery = useMemo(
    () =>
      debounce((value) => {
        setSearchQuery(value);
      }, 300),
    []
  );

  useEffect(() => {
    return () => {
      debouncedSetSearchQuery.cancel();
    };
  }, [debouncedSetSearchQuery]);

  const filterAndSortTasks = useCallback(
    (tasks) => {
      const safeSearchQuery = searchQuery || "";
      return tasks
        .filter((task) => {
          const matchesSearchTerm = (task?.name || "").toLowerCase().includes(safeSearchQuery.toLowerCase());
          const matchesStatus = filters.status.length === 0 || filters.status.includes(task?.status || "");
          const matchesPriority = filters.priority.length === 0 || filters.priority.includes(task?.priority || "");
          return matchesSearchTerm && matchesStatus && matchesPriority;
        })
        .sort((a, b) => {
          let aValue = a[sortField];
          let bValue = b[sortField];
          if (["endDate", "startDate", "createdAt", "updatedAt"].includes(sortField)) {
            aValue = aValue ? new Date(aValue).getTime() : 0;
            bValue = bValue ? new Date(bValue).getTime() : 0;
          } else if (["name", "status", "priority"].includes(sortField)) {
            aValue = aValue ? aValue.toString().toLowerCase() : "";
            bValue = bValue ? bValue.toString().toLowerCase() : "";
          } else if (sortField === "assignedTo") {
            aValue = aValue ? aValue.length : 0;
            bValue = bValue ? bValue.length : 0;
          }
          if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
          if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
          return 0;
        });
    },
    [searchQuery, filters, sortField, sortDirection]
  );

  const displayedTasks = useMemo(() => filterAndSortTasks(tasks), [tasks, filterAndSortTasks]);

  // Handler for saving a new task via inline form.
  const handleInlineSave = async (name, dueDate) => {
    if (!name.trim()) return;
    const taskData = {
      projectId: currentProjectId,
      parentTask: currentTaskId || null,
      name: name.trim(),
      description: "Enter task description...",
      status: "Not Started",
      priority: "Medium",
      endDate: dueDate || new Date(new Date().setDate(new Date().getDate() + 15)),
      startDate: new Date(),
      tags: ["Quick Created"],
      createdBy: currentUserId,
    };
    try {
      await createTask({
        projectId: currentProjectId,
        taskData,
      });
      setIsCreatingInline(false);
    } catch (error) {
      console.error("Error creating task:", error);
    }
  };

  // Handler for canceling inline task creation.
  const handleInlineCancel = () => {
    setIsCreatingInline(false);
  };

  return (
    <>
      {/* Header Section with Search and Filters */}
      <Flex
        justifyContent="space-between"
        alignItems="center"
        bg={bgColor}
        pt={4}
        boxShadow="sm"
        borderRadius="md"
        position="sticky"
        top={0}
        zIndex={2}
        flexDirection="column"
        px={4}
      >
        <Flex w="100%" justifyContent="space-between" mb={2} flexDirection={["column", "row"]} alignItems="center">
          {/* Search Bar */}
          <InputGroup width={["100%", "300px"]}>
            <InputLeftElement pointerEvents="none">
              <PiMagnifyingGlass color="gray.400" />
            </InputLeftElement>
            <Input
              placeholder="Search tasks..."
              onChange={(e) => debouncedSetSearchQuery(e.target.value)}
              bg={searchBg}
              borderRadius="md"
              _focus={{ borderColor: "primary.500" }}
            />
          </InputGroup>

          <HStack spacing={4} mt={["2", 0]}>
            {/* Filters Menu with Portal */}
            <Menu closeOnSelect={false}>
              <MenuButton as={Button} rightIcon={<PiCaretDownBold fontSize="16px" />} colorScheme="primary" variant="outline">
                Filters
              </MenuButton>
              <Portal>
                <MenuList zIndex={1000}>
                  <MenuOptionGroup
                    title="Status"
                    type="checkbox"
                    value={filters.status}
                    onChange={(values) => setFilters((prev) => ({ ...prev, status: values }))}
                  >
                    <MenuDivider />
                    {statusOptions.map((option) => (
                      <MenuItemOption fontSize="md" key={option.value} value={option.value}>
                        {option.label}
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                  <MenuDivider />
                  <MenuOptionGroup
                    title="Priority"
                    type="checkbox"
                    value={filters.priority}
                    onChange={(values) => setFilters((prev) => ({ ...prev, priority: values }))}
                  >
                    <MenuDivider />
                    {priorityOptions.map((option) => (
                      <MenuItemOption fontSize="md" key={option.value} value={option.value}>
                        {option.label}
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                </MenuList>
              </Portal>
            </Menu>

            <Button leftIcon={<PiPlus fontSize="18px" />} colorScheme="primary" onClick={onAddTask} variant="solid">
              Add Task
            </Button>
          </HStack>
        </Flex>

        {/* Table Header (desktop only) */}
        {!isMobile && (
          <Box display="grid" gridTemplateColumns={columnWidths.join(" ")} bg={headerBg} style={thStyles} p={2} w="100%">
            <Box onClick={() => handleSort("name")}>
              <Flex alignItems="center">Main tasks {renderSortIcon("name")}</Flex>
            </Box>
            <Box onClick={() => handleSort("endDate")}>
              <Flex alignItems="center">Due Date {renderSortIcon("endDate")}</Flex>
            </Box>
            <Box onClick={() => handleSort("subtaskCount")}>
              <Flex alignItems="center">Subtasks {renderSortIcon("subtaskCount")}</Flex>
            </Box>
            <Box onClick={() => handleSort("status")}>
              <Flex alignItems="center">Status {renderSortIcon("status")}</Flex>
            </Box>
            <Box onClick={() => handleSort("priority")}>
              <Flex alignItems="center">Priority {renderSortIcon("priority")}</Flex>
            </Box>
            <Box onClick={() => handleSort("assignedTo")}>
              <Flex alignItems="center">Assigned To {renderSortIcon("assignedTo")}</Flex>
            </Box>
          </Box>
        )}
      </Flex>

      {/* Tasks Table / List */}
      <Box overflowX="auto" minH="400px">
        {displayedTasks.length > 0 ? (
          isMobile ? (
            <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={4} p={2}>
              <AnimatePresence>
                {displayedTasks.map((task) => (
                  <motion.div key={task._id} initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -10 }} layout>
                    <TaskRow
                      task={task}
                      onTaskClick={onTaskClick}
                      onStatusChange={onStatusChange}
                      onPriorityChange={onPriorityChange}
                      statusOptions={statusOptions}
                      priorityOptions={priorityOptions}
                      py="10px"
                    />
                  </motion.div>
                ))}
              </AnimatePresence>
            </Box>
          ) : (
            <AnimatePresence>
              {displayedTasks.map((task) => (
                <motion.div key={task._id} initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -10 }} layout>
                  <TaskRow
                    task={task}
                    onTaskClick={onTaskClick}
                    onStatusChange={onStatusChange}
                    onPriorityChange={onPriorityChange}
                    statusOptions={statusOptions}
                    priorityOptions={priorityOptions}
                    py="10px"
                  />
                </motion.div>
              ))}
            </AnimatePresence>
          )
        ) : (
          <Flex justify="center" align="center" height="100%">
            <Text my={4} color="gray.500">
              No tasks found.
            </Text>
          </Flex>
        )}

        {/* Inline Create Task Row */}
        {isCreatingInline ? (
          <InlineCreateTaskRow onSave={handleInlineSave} onCancel={handleInlineCancel} />
        ) : (
          // Default "Add a New Task..." row (desktop or mobile)
          <Box
            onClick={() => setIsCreatingInline(true)}
            cursor="pointer"
            _hover={{
              backgroundColor: hoverBg,
              transition: "background-color 0.2s ease",
            }}
            py="8px"
            borderColor={borderColor}
          >
            <Flex justify="start" pl="50px" py={2} alignItems="center">
              <Text color="gray.500" fontStyle="italic">
                Quick add a new task...
              </Text>
            </Flex>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ProjectsTasksList;
