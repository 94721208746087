// client/src/components/2 - Components/ProjectsViewTaskView/ProjectsTaskViewTabsComments.js
import React, { useState, useContext } from "react";
import { Box, Flex, IconButton, Text, useColorModeValue, VStack, Textarea, HStack, Tooltip, Wrap, WrapItem, Button } from "@chakra-ui/react";
import { formatTimestamp } from "../../5 - General/Utils/UtilsFormatData";
import { ProjectContext } from "../../5 - General/Context/ProjectsContext";
import UserAvatar from "../Reusable/UserAvatar";
import { WorkspaceContext } from "../../5 - General/Context/WorkspaceContext";
import { PiCheckBold, PiPaperPlaneTiltFill, PiPencilSimpleLine, PiThumbsUp, PiThumbsUpFill, PiTrash, PiXBold } from "react-icons/pi";
import ConfirmActionModal from "../../3 - Modal/9 - General Modals/ConfirmActionModal";

const CommentSection = ({ subtask, isSubtask = false }) => {
  // -----------------------------
  // 1) State / Context
  // -----------------------------
  const { currentUser } = useContext(WorkspaceContext);
  const { currentTaskId, tasks, addComment, editComment, deleteComment, likeComment } = useContext(ProjectContext);

  const currentTask = isSubtask ? subtask : tasks.find((t) => t._id === currentTaskId);

  const [commentText, setCommentText] = useState("");
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editedCommentText, setEditedCommentText] = useState("");
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState(null);

  // -----------------------------
  // 2) Color / Style variables
  // -----------------------------
  const containerBorderColor = useColorModeValue("gray.200", "gray.600");
  const containerBg = useColorModeValue("gray.50", "gray.900");
  const commentBg = useColorModeValue("white", "gray.700");
  const textColor = useColorModeValue("gray.700", "gray.200");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const inputBg = useColorModeValue("white", "gray.800");
  const likedCommentsBgColor = useColorModeValue("gray.100", "gray.700");
  const timestampColor = useColorModeValue("gray.500", "gray.400");
  const noCommentsColor = useColorModeValue("gray.500", "gray.400");

  // -----------------------------
  // 3) Handlers
  // -----------------------------
  const handleAddComment = async () => {
    if (!commentText.trim()) return;
    try {
      await addComment(currentTask._id, commentText);
      setCommentText("");
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const handleEditComment = (comment) => {
    setEditingCommentId(comment._id);
    setEditedCommentText(comment.message);
  };

  const handleSaveEditedComment = async (commentId) => {
    if (!editedCommentText.trim()) return;
    try {
      await editComment(currentTask._id, commentId, editedCommentText);
      setEditingCommentId(null);
      setEditedCommentText("");
    } catch (error) {
      console.error("Error editing comment:", error);
    }
  };

  const handleDeleteComment = (commentId) => {
    setCommentToDelete(commentId);
    setIsConfirmModalOpen(true);
  };

  const confirmDeleteComment = async () => {
    try {
      await deleteComment(currentTask._id, commentToDelete);
      setIsConfirmModalOpen(false);
      setCommentToDelete(null);
    } catch (error) {
      console.error("Error deleting comment:", error);
      setIsConfirmModalOpen(false);
      setCommentToDelete(null);
    }
  };

  const handleLikeComment = async (commentId) => {
    try {
      await likeComment(currentTask._id, commentId);
    } catch (error) {
      console.error("Error liking comment:", error);
    }
  };

  const isInputEmpty = !commentText.trim();

  // -----------------------------
  // 4) JSX
  // -----------------------------
  return (
    <Flex direction="column" height="100%">
      {/* Comment Input */}
      <Flex mx={4} my={8} p={4} borderWidth="1px" borderColor={containerBorderColor} borderRadius="md" bg={containerBg} flexDir="column">
        <VStack align="start" spacing={2} w="100%">
          <Text fontWeight="400">Comments</Text>
          <Flex w="100%">
            <Textarea
              placeholder="Ask a question or post an update..."
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
              bg={inputBg}
              size="md"
              borderRadius="md"
            />
          </Flex>
          <Button
            leftIcon={<PiPaperPlaneTiltFill fontSize="18px" />}
            colorScheme="primary"
            onClick={handleAddComment}
            aria-label="Add comment"
            size="xs"
            ml="auto"
            isDisabled={isInputEmpty}
          >
            Send Comment
          </Button>
        </VStack>
      </Flex>

      {/* Comments List */}
      <Box p={4} overflowY="auto" flex="1" mb="70px">
        {currentTask.comments?.length > 0 ? (
          [...currentTask.comments]
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((comment, idx) => (
              <Box
                key={comment._id || idx}
                mb={4}
                position="relative"
                border="1px solid"
                borderColor={borderColor}
                role="group"
                p={3}
                bg={commentBg}
                borderRadius="md"
              >
                <Flex align="start" justifyContent="space-between" w="100%" mb={4}>
                  <UserAvatar isOnClickActive={true} userIds={[comment.userId]} size="sm" showInfo={true} mr={2} />
                  <HStack spacing={2}>
                    <Text fontSize="sm" fontWeight="600" color={timestampColor}>
                      {formatTimestamp(comment.createdAt)}
                    </Text>
                    {comment.likedBy && (
                      <Tooltip
                        label={
                          <Wrap>
                            {comment.likedBy.map((userId) => {
                              return userId ? (
                                <WrapItem key={userId}>
                                  <UserAvatar userIds={[userId]} size="xs" />
                                </WrapItem>
                              ) : null;
                            })}
                          </Wrap>
                        }
                        aria-label="Users who liked"
                        hasArrow
                        placement="bottom"
                      >
                        <Text
                          bg={likedCommentsBgColor}
                          p={2}
                          borderRadius="md"
                          fontSize="sm"
                          fontWeight="600"
                          color={timestampColor}
                          mr={2}
                          cursor="pointer"
                          w="70px"
                          textAlign="center"
                        >
                          {comment.likedBy.length} {comment.likedBy.length === 1 ? "Like" : "Likes"}
                        </Text>
                      </Tooltip>
                    )}
                  </HStack>
                </Flex>

                {editingCommentId === comment._id ? (
                  <Flex align="center" mb={2}>
                    <Textarea
                      value={editedCommentText}
                      onChange={(e) => setEditedCommentText(e.target.value)}
                      mr={2}
                      size="sm"
                      fontSize="md"
                      bg={inputBg}
                      borderRadius="md"
                    />
                    <IconButton
                      icon={<PiCheckBold fontSize="18px" />}
                      size="sm"
                      colorScheme="primary"
                      mr={2}
                      onClick={() => handleSaveEditedComment(comment._id)}
                      aria-label="Save edited comment"
                    />
                    <IconButton
                      icon={<PiXBold fontSize="18px" />}
                      size="sm"
                      colorScheme="gray"
                      onClick={() => setEditingCommentId(null)}
                      aria-label="Cancel editing"
                    />
                  </Flex>
                ) : (
                  <Text ml={2} fontSize="md" color={textColor} mb={2}>
                    {comment.message}
                  </Text>
                )}

                {editingCommentId !== comment._id && (
                  <Box position="absolute" bottom="2" right="2" display="flex" alignItems="center" transition="opacity 0.2s ease-in-out">
                    {comment.userId.toString() === currentUser?._id && (
                      <>
                        <IconButton
                          icon={<PiPencilSimpleLine fontSize="18px" />}
                          size="sm"
                          variant="ghost"
                          color="gray.500"
                          aria-label="Edit comment"
                          onClick={() => handleEditComment(comment)}
                          mr={1}
                        />
                        <IconButton
                          icon={<PiTrash fontSize="18px" />}
                          size="sm"
                          variant="ghost"
                          color="gray.500"
                          aria-label="Delete comment"
                          onClick={() => handleDeleteComment(comment._id)}
                        />
                      </>
                    )}
                    <IconButton
                      icon={comment.likedBy?.includes(currentUser?._id) ? <PiThumbsUpFill fontSize="18px" /> : <PiThumbsUp fontSize="18px" />}
                      size="sm"
                      variant="ghost"
                      color={comment.likedBy?.includes(currentUser?._id) ? "primary.500" : "gray.500"}
                      aria-label="Like comment"
                      onClick={() => handleLikeComment(comment._id)}
                      mr={1}
                    />
                  </Box>
                )}
              </Box>
            ))
        ) : (
          <Box p={4} flex={1} textAlign="center">
            <Text color={noCommentsColor} fontSize="md">
              No comments.
            </Text>
          </Box>
        )}
      </Box>

      <ConfirmActionModal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={confirmDeleteComment}
        title="Delete Comment"
        description="Are you sure you want to delete this comment?"
        confirmText="Delete"
        cancelText="Cancel"
        isDestructive={true}
      />
    </Flex>
  );
};

export default CommentSection;
