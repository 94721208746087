// client/src/components/Projects/Modals/CreateTaskModal.jsx

import React, { useState, useContext, useEffect, useMemo, useCallback } from "react";
import { Button, HStack, Box, FormControl, FormLabel, Input, Checkbox, useColorModeValue, IconButton } from "@chakra-ui/react";
import { Select, chakraComponents } from "chakra-react-select";
import { PiXBold, PiArrowRightBold } from "react-icons/pi";
import ReusableModal from "../9 - General Modals/ReusableModal";
import { ProjectContext } from "../../5 - General/Context/ProjectsContext";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";
import TagMenu from "../../2 - Components/Reusable/TagMenu";
import UserAvatar from "../../2 - Components/Reusable/UserAvatar";
import UtilsDatePicker from "../../5 - General/Utils/UtilsDatePicker";

const CreateTaskModal = ({ isOpen, onClose, projectId, parentTask }) => {
  // -----------------------------
  // 1) State / Context
  // -----------------------------
  const { createTask, currentProjectId, projects } = useContext(ProjectContext);
  const currentProject = projects.find((project) => project._id === currentProjectId);

  const [taskName, setTaskName] = useState("");
  const [status, setStatus] = useState("Not Started");
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [priority, setPriority] = useState("");
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [selectAllUsers, setSelectAllUsers] = useState(false);
  const [selectedParentTask, setSelectedParentTask] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const customToast = useCustomToast();

  // -----------------------------
  // 2) Color definitions
  // -----------------------------
  // For arrow color in the date pickers, or any hover backgrounds:
  const arrowColor = useColorModeValue("gray.500", "gray.300");
  const itemHoverBg = useColorModeValue("gray.100", "gray.600");
  const multiValueBg = useColorModeValue("gray.50", "gray.700");

  // -----------------------------
  // 3) Effects
  // -----------------------------
  useEffect(() => {
    if (isOpen) {
      setTaskName("");
      setStatus("Not Started");
      setPriority("Medium");
      setAssignedUsers([]);
      setSelectAllUsers(false);
      setSelectedParentTask(parentTask ? { value: parentTask._id, label: parentTask.name } : null);
    }
  }, [isOpen, parentTask]);

  useEffect(() => {
    if (isOpen && currentProject && currentProject.members) {
      const formattedMembers = currentProject.members.map((member) => ({
        value: member,
        label: member,
      }));
      setAvailableUsers(formattedMembers);
    } else {
      setAvailableUsers([]);
    }
  }, [isOpen, currentProject]);

  // Build parent task options from the current project's tasks
  const taskOptions = useMemo(() => {
    return (
      currentProject?.tasks?.map((task) => ({
        value: task._id,
        label: task.name,
      })) || []
    );
  }, [currentProject]);

  // -----------------------------
  // 4) Handlers / Logic
  // -----------------------------
  const resetForm = useCallback(() => {
    setTaskName("");
    setStatus("Not Started");
    setPriority("Medium");
    setAssignedUsers([]);
    setSelectAllUsers(false);
    setSelectedParentTask(null);
  }, []);

  const handleTaskSubmit = useCallback(async () => {
    if (!projectId) {
      customToast({
        title: "Error",
        description: "Project ID is undefined.",
        status: "error",
      });
      return;
    }
    if (!taskName.trim()) {
      customToast({
        title: "Error",
        description: "Task name is required.",
        status: "error",
      });
      return;
    }

    const taskData = {
      name: taskName,
      status,
      startDate,
      endDate,
      priority,
      assignedTo: selectAllUsers ? availableUsers.map((user) => user.value) : assignedUsers.map((user) => user.value),
      parentTaskId: selectedParentTask ? selectedParentTask.value : null,
    };

    try {
      setIsLoading(true);
      await createTask({ projectId, taskData });
      customToast({
        title: "Task Created",
        description: "The task has been created successfully.",
        status: "success",
      });
      onClose();
      resetForm();
    } catch (error) {
      console.error("Error creating task:", error);
      customToast({
        title: "Error",
        description: error.message || "Error creating the task.",
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    projectId,
    taskName,
    status,
    startDate,
    endDate,
    priority,
    selectAllUsers,
    availableUsers,
    assignedUsers,
    selectedParentTask,
    createTask,
    resetForm,
    customToast,
    onClose,
  ]);

  // Custom Option for Users
  const CustomUserOption = useCallback(
    (props) => {
      const { data, innerRef, innerProps } = props;
      return (
        <Box ref={innerRef} {...innerProps} _hover={{ bg: itemHoverBg }}>
          <HStack spacing={3} px={6} py={1}>
            <UserAvatar userIds={[data.value]} maxAvatars={1} size="sm" showInfo={true} />
          </HStack>
        </Box>
      );
    },
    [itemHoverBg]
  );

  // Custom MultiValue for Users
  const CustomUserMultiValue = useCallback(
    (props) => {
      const { data, innerRef, innerProps, removeProps } = props;
      return (
        <Box
          ref={innerRef}
          {...innerProps}
          mb={1}
          bg={multiValueBg}
          borderRadius="xl"
          w="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          px={4}
          py={1}
        >
          <HStack spacing={1}>
            <UserAvatar userIds={[data.value]} maxAvatars={1} size="sm" showInfo={true} />
          </HStack>
          <IconButton
            icon={<PiXBold fontSize="16px" />}
            colorScheme="gray"
            variant="ghost"
            size="sm"
            onClick={removeProps.onClick}
            aria-label="Remove"
          />
        </Box>
      );
    },
    [multiValueBg]
  );

  const footerButtons = (
    <HStack ml="auto" spacing={3}>
      <Button variant="outline" onClick={onClose}>
        Cancel
      </Button>
      <Button
        colorScheme="blue"
        onClick={handleTaskSubmit}
        isLoading={isLoading}
        disabled={!taskName.trim() || (selectAllUsers ? availableUsers.length === 0 : assignedUsers.length === 0)}
      >
        Create Task
      </Button>
    </HStack>
  );

  // -----------------------------
  // 5) JSX
  // -----------------------------
  return (
    <ReusableModal
      isOpen={isOpen}
      onClose={onClose}
      title="Create a New Task"
      footerButtons={footerButtons}
      size="lg"
      isCentered
      scrollBehavior="none"
    >
      {/* Task Name */}
      <FormControl isRequired p={4} pt={2}>
        <FormLabel fontSize="md" fontWeight="500">
          Task Name
        </FormLabel>
        <Input value={taskName} onChange={(e) => setTaskName(e.target.value)} placeholder="Enter task name..." />
      </FormControl>

      {/* Parent Task */}
      {parentTask && (
        <FormControl p={4}>
          <FormLabel fontSize="md" fontWeight="500">
            Parent Task (Optional)
          </FormLabel>
          <Select
            placeholder="Select parent task..."
            value={selectedParentTask}
            onChange={(option) => setSelectedParentTask(option)}
            options={taskOptions}
            isClearable
            components={chakraComponents}
            closeMenuOnSelect
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              menu: (base) => ({ ...base, zIndex: 9999 }),
            }}
          />
        </FormControl>
      )}

      {/* Task Duration */}
      <FormControl isRequired p={4}>
        <FormLabel fontSize="md" fontWeight="500">
          Task Duration
        </FormLabel>
        <HStack spacing={2}>
          <UtilsDatePicker value={startDate} endDate={endDate || null} placeholder="Start Date" onChange={setStartDate} inputVariant="default" />
          <PiArrowRightBold color={arrowColor} />
          <UtilsDatePicker
            value={endDate}
            placeholder="Due Date"
            onChange={setEndDate}
            inputVariant="default"
            isDisabled={!startDate}
            startDate={startDate ?? null}
            highlightDates={startDate ? [startDate] : []}
            highlightTooltips={startDate ? [`Task Start Date: ${startDate.toLocaleDateString()}`] : []}
            hasPortal
          />
        </HStack>
      </FormControl>

      {/* Status and Priority */}
      <FormControl isRequired p={4}>
        <FormLabel fontSize="md" fontWeight="500">
          Task Status and Priority
        </FormLabel>
        <HStack spacing={4}>
          <TagMenu
            options={[
              { value: "Not Started", label: "Not Started", color: "gray" },
              { value: "In Progress", label: "In Progress", color: "blue" },
              { value: "Completed", label: "Completed", color: "green" },
              { value: "On Hold", label: "On Hold", color: "orange" },
            ]}
            selectedValue={status}
            field="status"
            handleBlurSave={(id, field, value) => setStatus(value)}
          />
          <TagMenu
            options={[
              { value: "Low", label: "Low", color: "green" },
              { value: "Medium", label: "Medium", color: "yellow" },
              { value: "High", label: "High", color: "red" },
            ]}
            selectedValue={priority}
            field="priority"
            handleBlurSave={(id, field, value) => setPriority(value)}
          />
        </HStack>
      </FormControl>

      {/* Assign Members */}
      <FormControl isRequired p={4}>
        <FormLabel fontSize="md" fontWeight="500">
          Assign Members
        </FormLabel>
        {!selectAllUsers && (
          <Select
            isMulti
            placeholder="Select members..."
            options={availableUsers}
            value={assignedUsers}
            onChange={setAssignedUsers}
            components={{
              Option: CustomUserOption,
              MultiValue: CustomUserMultiValue,
            }}
            closeMenuOnSelect={false}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              menu: (base) => ({ ...base, zIndex: 9999 }),
            }}
            isDisabled={availableUsers.length === 0}
          />
        )}
        <Checkbox isChecked={selectAllUsers} onChange={(e) => setSelectAllUsers(e.target.checked)} mt={2}>
          Select all users from assigned companies
        </Checkbox>
      </FormControl>
    </ReusableModal>
  );
};

export default CreateTaskModal;
