// client/src/components/ProjectsTasksBoard.jsx
import React, { useState, useEffect, useContext, useCallback, useMemo } from "react";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { Box, Flex, Heading, VStack, Tag, HStack, Text, IconButton, useColorModeValue } from "@chakra-ui/react";
import { PiPlus } from "react-icons/pi";
import { StrictModeDroppable } from "../5 - General/Utils/UtilsStrictModeDroppable";
import { ProjectContext } from "../5 - General/Context/ProjectsContext";
import { formatReadableDate } from "../5 - General/Utils/UtilsFormatData";

// Memoized TaskCard component
const TaskCard = React.memo(({ task, onTaskClick, snapshot }) => {
  const cardBgColor = useColorModeValue("gray.50", "gray.700");
  const taskBorderColor = task.color ? task.color : cardBgColor;
  const textColor = useColorModeValue("gray.800", "white");

  const getColorSchemeForPriority = useMemo(() => {
    switch (task.priority) {
      case "High":
        return "red";
      case "Medium":
        return "yellow";
      case "Low":
        return "green";
      default:
        return "gray";
    }
  }, [task.priority]);

  return (
    <Box
      bg={cardBgColor}
      onClick={() => onTaskClick(task)}
      color={textColor}
      border="1px solid"
      borderColor={taskBorderColor}
      transform="scale(0.99)"
      borderRadius="lg"
      boxShadow={snapshot.isDragging ? "xl" : "base"}
      p={5}
      transition="all 0.2s ease-in-out"
      _hover={{
        transform: "scale(1)",
        boxShadow: "lg",
      }}
    >
      <Text fontWeight="bold" fontSize="lg" mb={2}>
        {task.name}
      </Text>
      <HStack spacing={1} alignItems="center">
        <Text fontSize="md">Due by {formatReadableDate(task.endDate)}</Text>
      </HStack>
      <HStack my={3} spacing={4} alignItems="center">
        <Tag borderRadius="full" colorScheme={getColorSchemeForPriority} px={4} py={1} fontSize="sm" fontWeight="500">
          {task.priority}
        </Tag>
      </HStack>
    </Box>
  );
});

// Memoized Column component
const Column = React.memo(({ column, tasks, onTaskClick, handleQuickCreateTask }) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.750");

  return (
    <StrictModeDroppable droppableId={column._id}>
      {(provided) => (
        <Box
          ref={provided.innerRef}
          {...provided.droppableProps}
          bg={bgColor}
          p={4}
          flex="1"
          borderRadius="md"
          boxShadow="md"
          minWidth={["90%", "300px"]}
          display="flex"
          flexDirection="column"
          border="1px solid"
          borderColor={borderColor}
          mb={["4", 0]}
        >
          <Flex justifyContent="space-between" alignItems="center" mb={4}>
            <Heading size="md" display="flex" alignItems="center">
              {column._id}
            </Heading>
            <HStack spacing={2}>
              <Tag ml={2} borderRadius="md" colorScheme="gray">
                {tasks.length}
              </Tag>
              <IconButton
                aria-label={`Add task to ${column._id}`}
                icon={<PiPlus fontSize="18px" />}
                size="sm"
                onClick={() => handleQuickCreateTask(column._id)}
              />
            </HStack>
          </Flex>
          <VStack spacing={3} align="stretch" flexGrow={1} overflowY="auto" paddingBottom={2}>
            {tasks.map((task, index) => (
              <Draggable key={task._id} draggableId={task._id} index={index}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <TaskCard task={task} onTaskClick={onTaskClick} snapshot={snapshot} />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </VStack>
        </Box>
      )}
    </StrictModeDroppable>
  );
});

const ProjectsTasksBoard = ({ onTaskClick, handleQuickCreateTask }) => {
  const { tasks, updateTaskOrder } = useContext(ProjectContext);
  const initialColumns = useMemo(
    () => ({
      "Not Started": {
        _id: "Not Started",
        list: tasks.filter((task) => task.status === "Not Started").sort((a, b) => a.order - b.order),
      },
      "In Progress": {
        _id: "In Progress",
        list: tasks.filter((task) => task.status === "In Progress").sort((a, b) => a.order - b.order),
      },
      Completed: {
        _id: "Completed",
        list: tasks.filter((task) => task.status === "Completed").sort((a, b) => a.order - b.order),
      },
      "On Hold": {
        _id: "On Hold",
        list: tasks.filter((task) => task.status === "On Hold").sort((a, b) => a.order - b.order),
      },
    }),
    [tasks]
  );

  const [columns, setColumns] = useState(initialColumns);

  useEffect(() => {
    setColumns(initialColumns);
  }, [initialColumns]);

  const onDragEnd = useCallback(
    async ({ source, destination }) => {
      if (!destination) return;
      if (source.droppableId === destination.droppableId && source.index === destination.index) {
        return;
      }

      const sourceColumn = columns[source.droppableId];
      const destinationColumn = columns[destination.droppableId];
      let newColumns = { ...columns };

      if (sourceColumn === destinationColumn) {
        const updatedList = Array.from(sourceColumn.list);
        const [movedTask] = updatedList.splice(source.index, 1);
        updatedList.splice(destination.index, 0, movedTask);
        updatedList.forEach((task, index) => {
          task.order = index;
        });
        newColumns = {
          ...columns,
          [sourceColumn._id]: {
            ...sourceColumn,
            list: updatedList,
          },
        };
      } else {
        const sourceList = Array.from(sourceColumn.list);
        const [movedTask] = sourceList.splice(source.index, 1);
        const destinationList = Array.from(destinationColumn.list);
        movedTask.status = destinationColumn._id;
        destinationList.splice(destination.index, 0, movedTask);
        sourceList.forEach((task, index) => {
          task.order = index;
        });
        destinationList.forEach((task, index) => {
          task.order = index;
        });
        newColumns = {
          ...columns,
          [sourceColumn._id]: {
            ...sourceColumn,
            list: sourceList,
          },
          [destinationColumn._id]: {
            ...destinationColumn,
            list: destinationList,
          },
        };
      }

      setColumns(newColumns);

      const tasksToUpdate = [];
      Object.values(newColumns).forEach((column) => {
        column.list.forEach((task) => {
          tasksToUpdate.push({
            _id: task._id,
            status: task.status,
            order: task.order,
          });
        });
      });

      try {
        await updateTaskOrder(tasksToUpdate);
      } catch (error) {
        console.error("Error updating task orders:", error);
        setColumns(columns);
      }
    },
    [columns, updateTaskOrder]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Flex direction={["column", "row"]} justifyContent="space-between" p={4} gap={4} overflow="auto">
        {useMemo(
          () =>
            Object.values(columns).map((column) => (
              <Column
                key={column._id}
                column={column}
                tasks={column.list}
                onTaskClick={onTaskClick}
                handleQuickCreateTask={handleQuickCreateTask}
              />
            )),
          [columns, onTaskClick, handleQuickCreateTask]
        )}
      </Flex>
    </DragDropContext>
  );
};

export default React.memo(ProjectsTasksBoard);
