import React from "react";
import {
  VStack,
  HStack,
  Tag,
  Text,
  Button,
  Tooltip,
} from "@chakra-ui/react";
import { formatReadableDate, formatTimestamp } from "../../5 - General/Utils/UtilsFormatData";
import UserAvatar from "../../2 - Components/Reusable/UserAvatar";
import ReusableModal from "../9 - General Modals/ReusableModal";

export const MobileActionsModal = ({
  user,
  isOpen,
  onClose,
  handleModifyRights,
  handleDeactivateUser,
  handleResendInvitation,
  handleSendPasswordReset,
  handleActivateUser,
  handleDeleteUser,
  currentUserRole,
  canModifyUser,
}) => {
  const isDisabled = !canModifyUser(currentUserRole, user.role);
  const isDisabledForAdmins = user.role === "Admin";

  const headerContent = (
    <HStack spacing={3} align="center">
      <UserAvatar userIds={[user._id]} size="lg" showInfo={false} />
      <VStack spacing={0} align="start">
        <Text fontSize="lg" fontWeight="600">
          {user.firstName} {user.lastName}
        </Text>
        <Text fontSize="sm" color="gray.500">
          {user.email}
        </Text>
      </VStack>
    </HStack>
  );

  const footerContent = (
    <VStack w="100%" spacing={3}>
      {!user.isVerified && (
        <Tooltip label="Resend the invitation email for verification" hasArrow>
          <Button size="sm" variant="outline" colorScheme="blue" onClick={() => handleResendInvitation(user)} isDisabled={isDisabled} w="100%">
            Resend Invitation To Verify Email
          </Button>
        </Tooltip>
      )}
      {user.isVerified && (
        <Button size="sm" variant="outline" colorScheme="gray" onClick={() => handleSendPasswordReset(user)} isDisabled={isDisabled} w="100%">
          Send Password Reset Email
        </Button>
      )}
      <Tooltip label="Modify this user's rights to individual modules" hasArrow>
        <Button size="sm" variant="outline" onClick={() => handleModifyRights(user)} isDisabled={isDisabled} w="100%">
          Modify The User's Rights
        </Button>
      </Tooltip>
      {user.status !== "Deactivated" ? (
        <Tooltip label="Suspend user account" hasArrow>
          <Button
            size="sm"
            colorScheme="gray"
            variant="outline"
            onClick={() => handleDeactivateUser(user)}
            isDisabled={isDisabled || isDisabledForAdmins}
            w="100%"
          >
            Suspend User Account
          </Button>
        </Tooltip>
      ) : (
        <Tooltip label="Reactivate User Account" hasArrow>
          <Button size="sm" variant="outline" colorScheme="green" onClick={() => handleActivateUser(user)} isDisabled={isDisabled} w="100%">
            Reactivate User Account
          </Button>
        </Tooltip>
      )}
      <Tooltip label={user.status === "Deactivated" ? "Delete user account" : "User account must be suspended to delete"} hasArrow>
        <Button
          size="sm"
          variant="outline"
          colorScheme="red"
          onClick={() => handleDeleteUser(user)}
          isDisabled={isDisabled || user.status !== "Deactivated"}
          w="100%"
        >
          Delete User Account
        </Button>
      </Tooltip>
    </VStack>
  );

  return (
    <ReusableModal
      isOpen={isOpen}
      onClose={onClose}
      title={headerContent}
      size="sm"
      isCentered={true}
      footerButtons={footerContent}
    >
      <VStack spacing={3} align="stretch">
        <HStack justify="space-between">
          <Text fontSize="md" fontWeight="medium">Role:</Text>
          <Tag size="sm" colorScheme="blue" variant="subtle">{user.role}</Tag>
        </HStack>
        <HStack justify="space-between">
          <Text fontSize="md" fontWeight="medium">Joined:</Text>
          <Tag size="sm" colorScheme="gray" variant="subtle">{formatReadableDate(user.createdAt)}</Tag>
        </HStack>
        <HStack justify="space-between">
          <Text fontSize="md" fontWeight="medium">Last Login:</Text>
          <Tag size="sm" colorScheme={user.lastLoggedIn ? "gray" : "red"} variant="subtle">
            {user.lastLoggedIn ? formatTimestamp(user.lastLoggedIn) : "Never"}
          </Tag>
        </HStack>
        <HStack justify="space-between">
          <Text fontSize="md" fontWeight="medium">Status:</Text>
          <Tag size="sm" colorScheme={user.status === "Deactivated" ? "red" : "green"} variant="subtle">
            {user.status === "Deactivated" ? "Deactivated" : "Active"}
          </Tag>
        </HStack>
        <HStack justify="space-between">
          <Text fontSize="md" fontWeight="medium">Email Verified:</Text>
          <Tag size="sm" colorScheme={user.isVerified ? "green" : "red"} variant="subtle">
            {user.isVerified ? "Verified" : "Not Verified"}
          </Tag>
        </HStack>
        <HStack justify="space-between">
          <Text fontSize="md" fontWeight="medium">Profile:</Text>
          <Tag size="sm" colorScheme={user.profileCompleted ? "green" : "red"} variant="subtle">
            {user.profileCompleted ? "Complete" : "Incomplete"}
          </Tag>
        </HStack>
      </VStack>
    </ReusableModal>
  );
};
