// client/src/components/Projects/Modals/AssignTaskMembersModal.jsx

import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  Button,
  IconButton,
  HStack,
  Box,
  FormControl,
  FormLabel,
  Text,
  useColorModeValue, // 1) Import from Chakra
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { PiXBold } from "react-icons/pi";
import ReusableModal from "../9 - General Modals/ReusableModal";
import { ProjectContext } from "../../5 - General/Context/ProjectsContext";
import UserAvatar from "../../2 - Components/Reusable/UserAvatar";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";

const AssignTaskMembersModal = ({ isOpen, onClose, subtask, task, isSubTask = false }) => {
  // -----------------------------
  // 1) Context / State
  // -----------------------------
  const { assignUsersToTask, currentProjectId, projects, currentTaskId, tasks } = useContext(ProjectContext);
  const customToast = useCustomToast();
  const currentProject = projects.find((project) => project._id === currentProjectId);
  const currentTask = isSubTask ? tasks.find((t) => t._id === currentTaskId) : task;
  const taskIdToUpdate = isSubTask ? subtask._id : currentTask._id;

  const [selectedMembers, setSelectedMembers] = useState([]);

  // -----------------------------
  // 2) Color definitions
  // -----------------------------
  // For hover, multi-value BG, etc.
  const itemHoverBg = useColorModeValue("gray.100", "gray.600");
  const multiValueBg = useColorModeValue("gray.50", "gray.700");
  const noMembersTextColor = useColorModeValue("gray.800", "gray.200");

  // -----------------------------
  // 3) Memos / Effects
  // -----------------------------
  const memberOptions = useMemo(() => {
    if (isSubTask) {
      if (!currentTask || !currentTask.assignedTo) return [];
      return currentTask.assignedTo.map((member) => ({
        value: member._id,
        label: `${member.firstName} ${member.lastName}`,
      }));
    } else {
      if (!currentProject || !currentProject.members) return [];
      return currentProject.members.map((memberId) => ({
        value: memberId,
        label: memberId,
      }));
    }
  }, [currentTask, currentProject, isSubTask]);

  useEffect(() => {
    if (isOpen && currentTask && memberOptions.length > 0) {
      const initialSelected = memberOptions.filter((option) => currentTask.assignedTo.includes(option.value));
      setSelectedMembers(initialSelected);
    }
  }, [isOpen, currentTask, memberOptions]);

  // -----------------------------
  // 4) Handlers
  // -----------------------------
  const handleSubmit = async () => {
    if (selectedMembers.length === 0) {
      customToast({
        title: "Error",
        description: "Please select at least one member for the task.",
        status: "error",
      });
      return;
    }
    const userIds = selectedMembers.map((option) => option.value);
    try {
      await assignUsersToTask({ taskId: taskIdToUpdate, userIds });
      onClose();
      setSelectedMembers([]);
    } catch (error) {
      console.error("Error assigning users to task:", error);
      customToast({
        title: "Error",
        description: "There was an error assigning users to the task.",
        status: "error",
      });
    }
  };

  const footerButtons = (
    <HStack ml="auto" spacing={3}>
      <Button variant="outline" onClick={onClose}>
        Cancel
      </Button>
      <Button colorScheme="primary" isDisabled={memberOptions.length === 0} onClick={handleSubmit}>
        Save
      </Button>
    </HStack>
  );

  // Custom Option
  const CustomUserOption = React.memo((props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <Box ref={innerRef} {...innerProps} _hover={{ bg: itemHoverBg }}>
        <HStack spacing={3} px={6} py={1}>
          <UserAvatar userIds={[data.value]} maxAvatars={1} size="sm" showInfo={true} />
        </HStack>
      </Box>
    );
  });

  // Custom MultiValue
  const CustomUserMultiValue = React.memo((props) => {
    const { data, innerRef, innerProps, removeProps } = props;
    return (
      <Box
        ref={innerRef}
        {...innerProps}
        mb={2}
        bg={multiValueBg}
        borderRadius="md"
        w="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px={4}
        py={1}
      >
        <HStack spacing={3}>
          <UserAvatar userIds={[data.value]} maxAvatars={1} size="sm" showInfo={true} />
        </HStack>
        <IconButton
          icon={<PiXBold fontSize="16px" />}
          colorScheme="gray"
          variant="ghost"
          size="sm"
          onClick={removeProps.onClick}
          aria-label="Remove member"
        />
      </Box>
    );
  });

  // -----------------------------
  // 5) JSX
  // -----------------------------
  return (
    <ReusableModal
      isOpen={isOpen}
      onClose={onClose}
      title="Assign Task Members"
      footerButtons={footerButtons}
      size="xl"
      isCentered
      scrollBehavior="inside"
    >
      <FormControl isRequired p={4}>
        <FormLabel fontSize="md" fontWeight="500">
          Select Members
        </FormLabel>
        <Select
          isMulti
          placeholder="Select members..."
          options={memberOptions}
          value={selectedMembers}
          onChange={(selected) => setSelectedMembers(selected || [])}
          components={{
            Option: CustomUserOption,
            MultiValue: CustomUserMultiValue,
          }}
          closeMenuOnSelect={false}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            menu: (base) => ({ ...base, zIndex: 9999 }),
          }}
          isDisabled={memberOptions.length === 0}
        />
      </FormControl>

      {memberOptions.length === 0 && (
        <Box p={4}>
          <Text fontSize="md" color={noMembersTextColor}>
            No members available for this task.
          </Text>
          <Text fontSize="md" mt={1} color={noMembersTextColor}>
            Assign members to the project or the parent task first.
          </Text>
        </Box>
      )}
    </ReusableModal>
  );
};

export default AssignTaskMembersModal;
